import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';
import { URLs } from '../../commom/constant';
import { ISaveFSN } from './index';

export const SaveLands = (api: ApiGateway, growerId: number, mpciPolicyId: number, request: ISaveFSN): Promise<any> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/Lands`;
    return api.post(url, ApplicationIdValues.Paw, request);
};

export const DeleteLands = (api: ApiGateway, growerId: number, mpciPolicyId: number, mpciCoverageId: number,
    mpciPremiumLineId: number, mpciPremiumLineLandId: number): Promise<any[]> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/Lands/${mpciPremiumLineLandId}`;
    return api.delete(url, ApplicationIdValues.Paw);
};
import { useContext, useEffect, useState } from 'react';
import { CloseButton, Col, Row, Spinner, Toast, ToastBody, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from '../../../store';
import { LandsContext } from './landsContext';
import styles from './viewCompanionLinkage.module.scss';
import * as CompanionLinksSaga from '../../../store/companionlink';
import { ActionTypes as CompanionActionTypes, DeleteCompanionLinksRequestAction, GetCompanionLinksRequestAction } from '../../../store/companionlink/actions';
import { useApiGateway } from '../../../components/apiGateway/useApiGateway';
import { CompanionHeaderText, ERRORS, SUCCESS, WARNINGS } from '../../../commom/constant';
import { CustomAlert } from '../../../components/alert/customAlert';
import { AlertType } from '../../../components/alert/alertType';
import * as LandSaga from '../../../store/land';

export interface IViewCompanionLinkProps {
    ShowUnlinkButton: boolean;
    CloseCompanionLinkage(showErrorMsg: boolean): void;
    MpciPremiumLineId: number;
    MpciCoverageId?: number;
    ParentMpciPremiumLineId?: number;
    UnitNumberDescription: string;
    IsShowHeader: boolean;
}

function ViewCompanionLink(viewCompanionLinkProps: IViewCompanionLinkProps) {
    const dispatch = useDispatch();
    const api = useApiGateway();
    const landsContext = useContext(LandsContext);
    const [companionLinksStore, setCompanionLinksStore] = useState<CompanionLinksSaga.ICompanionLinksStore>({} as CompanionLinksSaga.ICompanionLinksStore);
    const selectCompanionLinksStore = useSelector<GlobalState, CompanionLinksSaga.CompanionLinksState>((companionLinks) => companionLinks.companionLinksState);
    const [isDismissedCompanionError, setIsDismissedCompanionError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedCompanionlinkId, setSelectedCompanionlinkId] = useState<number>(0);
    const [companionlinkRequestType, setCompanionlinkRequestType] = useState<string>("");
    const [selectedPremiumlineId, setSelectedPremiumlineId] = useState<number>(0);
    const [isCompanionlinkDeleted, setIsCompanionlinkDeleted] = useState(false);

    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);


    useEffect(() => {
        getCompanionLinksFromStore();
    }, [selectCompanionLinksStore]);

    useEffect(() => {
        getCompanionLinksFromAPI();
    }, []);

    function onCloseCompanionLinkage() {
        viewCompanionLinkProps.CloseCompanionLinkage(true);
    }

    const getCompanionLinksFromStore = () => {
        let premiumLineId: number = setParentPremiumLineIdIfChild();
        let companionLinksRequest = selectCompanionLinksStore?.CompanionLinksRequest?.find(c => c.MpciPremiumLineID == premiumLineId);
        if (companionLinksRequest?.IsLoading == false) {
            setCompanionLinksStore(companionLinksRequest);
            if (companionlinkRequestType == "DELETE") {
                if (companionLinksRequest.CompanionLinks.findIndex(c => c.CompanionLinkId == selectedCompanionlinkId) < 0 && companionLinksRequest.CompanionLinks.length == 0) {
                    onCloseCompanionLinkage();
                    setCompanionlinkRequestType("");
                    let action = landState.Lands.map((x) => {
                        if (x.UnitNumber?.value == selectedPremiumlineId.toString() || x.ParentMpciPremiumLineOID == selectedPremiumlineId) {
                            return { ...x, CompanionFlag: "" };
                        }
                        return x;
                    });
                    dispatch(LandSaga.setLands(action));
                    setIsCompanionlinkDeleted(true);
                }
                else {
                    if (companionLinksRequest.CompanionLinks.findIndex(c => c.CompanionLinkId == selectedCompanionlinkId) < 0) {
                        setIsCompanionlinkDeleted(true);
                    }
                }
            }
        } else {
            companionLinksRequest = { IsLoading: true, MpciPremiumLineID: premiumLineId, CompanionLinks: [], HasError: false };
            setCompanionLinksStore(companionLinksRequest);
        }
    }

    const getCompanionLinksFromAPI = () => {
        let premiumLineId: number = setParentPremiumLineIdIfChild();
        let foundStoreLinks = selectCompanionLinksStore?.CompanionLinksRequest?.find(c => c.MpciPremiumLineID == premiumLineId);

        if (!foundStoreLinks || foundStoreLinks.HasError) {
            let growerId = landsContext.GrowerOid;
            let policyId = landsContext.PolicyOid;
            let action: GetCompanionLinksRequestAction = {
                type: CompanionActionTypes.GET_COMPANIONLINKS_REQUEST,
                Api: api,
                GrowerId: growerId.toString(),
                MpciPolicyId: policyId.toString(),
                MpciCoverageId: viewCompanionLinkProps.MpciCoverageId?.toString() ?? "",
                MpciPremiumLineId: premiumLineId,
                CompanionLinksRequest: {
                    MpciPremiumLineID: premiumLineId,
                    IsLoading: true,
                    CompanionLinks: [],
                    HasError: false,
                }
            }
            dispatch(CompanionLinksSaga.getCompanionLinks(action));
        }
        setIsDismissedCompanionError(true);
    }

    const setParentPremiumLineIdIfChild = (): number => {
        let parentMpciPremiumLineId = viewCompanionLinkProps.ParentMpciPremiumLineId ?? "";
        let premiumLineId: number;
        if (parentMpciPremiumLineId != "") {
            premiumLineId = Number(parentMpciPremiumLineId ?? 0);
        }
        else {
            premiumLineId = Number(viewCompanionLinkProps.MpciPremiumLineId ?? 0);
        }
        return premiumLineId;
    }

    function companionHeaderTextByFlag() {
        switch (companionLinksStore.CompanionLinks ? companionLinksStore.CompanionLinks.length > 0 ?
            companionLinksStore.CompanionLinks[0].CompanionFlag : "" : "") {
            case 'C':
                return CompanionHeaderText.C;
            case 'S':
                return CompanionHeaderText.S;
        }
    }

    function returnHeader() {
        let companionLinkHeader: { className: string, value: string, key: string }[] = [];

        if (viewCompanionLinkProps.UnitNumberDescription) {
            companionLinkHeader.push({ className: styles.bold, value: 'Manage Companion Links: ', key: '1' });
            companionLinkHeader.push({ className: styles.bold, value: viewCompanionLinkProps.UnitNumberDescription, key: '2' });
            companionLinkHeader.push({ className: styles.regular, value: " ", key: '3' });
            companionLinkHeader.push({ className: 'bx bx-link lh-base', value: '', key: '4' });
        }
        return (<div className={"float-xl-start ps-1"}>
            {companionLinkHeader.map(headerRecord => (<span key={headerRecord.key} className={headerRecord.className}>{headerRecord.value}</span>))}
        </div>
        )
    }

    function setDismissCompanionError() {
        setIsDismissedCompanionError(false);
    }

    function SetShowAlert(companionLinkId: number, premiumlineId: number) {
        setSelectedCompanionlinkId(companionLinkId);
        setSelectedPremiumlineId(premiumlineId);
        setShowAlert((showAlert: boolean) => !showAlert);
    }

    function DeleteCompanionLinakage(companionLinkId: number, premiumlineId: number) {
        setShowAlert((showAlert: boolean) => !showAlert);
        setIsCompanionlinkDeleted(false);
        let growerId = landsContext.GrowerOid;
        let policyId = landsContext.PolicyOid;
        let coverageId = landsContext.CoverageOid;
        let foundStoreLinks = selectCompanionLinksStore?.CompanionLinksRequest?.find(c => c.MpciPremiumLineID == premiumlineId);

        if (foundStoreLinks) {
            let action: DeleteCompanionLinksRequestAction = {
                type: CompanionActionTypes.DELETE_COMPANIONLINKS_REQUEST,
                Api: api,
                GrowerId: growerId.toString(),
                MpciPolicyId: policyId.toString(),
                MpciCoverageId: coverageId!.toString(),
                MpciPremiumLineId: premiumlineId!,
                CompanionLinkId: companionLinkId.toString(),
                CompanionLinksRequest: {
                    MpciPremiumLineID: premiumlineId!,
                    IsLoading: true,
                    CompanionLinks: foundStoreLinks?.CompanionLinks ? foundStoreLinks?.CompanionLinks : [],
                    HasError: false,
                }
            }
            dispatch(CompanionLinksSaga.deleteCompanionLinks(action));
            setCompanionlinkRequestType("DELETE");
        }
        setIsDismissedCompanionError(true);
    }

    function GetErrorMessage(errorStatusCode: string) {
        if (errorStatusCode == '400' && companionlinkRequestType == "DELETE") {
            return ERRORS.landId_delete_companionLinks_api_failed;
        }
        else if (errorStatusCode == '503') {
            return ERRORS.paw_maintance_error;
        }
        else {
            return ERRORS.landId_get_companionLinks_api_failed;
        }
    }

    return (
        <>
            <div className={styles.margin} data-testid={`viewCompanionComponent`}>
                <div>
                    <CustomAlert
                        dismissible={true}
                        onClose={setDismissCompanionError}
                        id="loadCompanionErrorAlert"
                        show={isDismissedCompanionError && companionLinksStore.HasError}
                        type={AlertType.Information}
                        variant="danger"
                    >
                        {GetErrorMessage(companionLinksStore.ErrorStatusCode!)}
                    </CustomAlert>
                </div>
                <div data-testid="deleteCompanionlinkSuccess">
                    <ToastContainer
                        position='top-end'
                    >
                        <Toast
                            bg="success"
                            onClose={() => { setIsCompanionlinkDeleted(false); }}
                            show={isCompanionlinkDeleted && companionLinksStore.ErrorStatusCode == undefined && !companionLinksStore.HasError} delay={6000} autohide>
                            <Toast.Header>
                                <strong className='me-auto'>Success!</strong>
                            </Toast.Header>
                            <ToastBody className={'text-white'}>
                                {SUCCESS.landID_delete_companionlink_api_success}
                            </ToastBody>
                        </Toast>
                    </ToastContainer>
                </div>

                {viewCompanionLinkProps.IsShowHeader &&
                    <div className={styles.header}
                        data-testid='showCompanionLinkageHeader'>
                        <Row className={styles.headerObjects}>
                            <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                                <div className={'d-flex align-items-center'} >
                                    {returnHeader()}
                                </div>
                            </Col>

                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                <CloseButton
                                    className={`float-end`}
                                    data-testid="closeCompanionLinkageButton"
                                    onClick={onCloseCompanionLinkage}
                                />
                            </Col>
                        </Row>
                    </div>
                }
                <div className={styles.border}>
                    <CustomAlert
                        data-testid="closeFieldsAlert"
                        id="closeFieldsAlert"
                        show={showAlert}
                        type={AlertType.Warning}
                        variant="warning"
                        onConfirm={() => DeleteCompanionLinakage(selectedCompanionlinkId, selectedPremiumlineId)}
                        onClose={() => SetShowAlert(selectedCompanionlinkId, selectedPremiumlineId)}
                    >
                        {WARNINGS.unlink_companion_link}
                    </CustomAlert>
                    <Row className={styles.margin} >
                        <Col className="font-weight-bold">
                            <b>{companionHeaderTextByFlag()}</b>
                        </Col>
                    </Row>

                    {(companionLinksStore.IsLoading == true || companionLinksStore.IsLoading == undefined) ?
                        <Spinner className={styles.spinner} animation={"border"} />
                        :
                        companionLinksStore.CompanionLinks.map((x) => (
                            <div key={x.CompanionLinkId}>
                                <Row className={styles.margin}>
                                    <Col>
                                        <span className="fw-bold">Policy: </span> <a href={(window as any).globalConfig?.paw_url + "/policy/MpciPolicy/Details?OID=" + x.LinkedPolicyId +
                                            "&selectedTab=1&mpciCoverageOID=" + x.LinkedCoverageId} target="_blank">{x.CompanionPolicyNumber}</a>

                                        <span className="fw-bold ms-2">Grower Name: </span> {x.GrowerName} - {Number((x.SharePercentage! * 100).toFixed(3))}%

                                        {viewCompanionLinkProps.ShowUnlinkButton && <span className={styles.clickableCell} ><i className="bx bx-link-alt lh-base ms-2" data-testid="unlinkButton" onClick={() => SetShowAlert(x.CompanionLinkId, companionLinksStore.MpciPremiumLineID)} ></i> </span>}
                                    </Col>
                                </Row>
                            </div>
                        ))
                    }
                </div>
            </div>

        </>
    );
}

export default ViewCompanionLink;
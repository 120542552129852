import { URLs } from '../../commom/constant';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';
import { ICompanionLink } from '../../views/mpci/lands/viewmodels/iCompanionLink';

export const GetCompanionLinks = (growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number, api: ApiGateway): Promise<ICompanionLink[]> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/CompanionLinks`;
    return api.get(url, ApplicationIdValues.Paw);
};

export const DeleteCompanionLinks = (growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number, CompanionLinkId: string, api: ApiGateway): Promise<ICompanionLink[]> => {    
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/CompanionLinks/${CompanionLinkId}`;
    return api.delete(url, ApplicationIdValues.Paw);
};
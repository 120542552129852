export class CreatableUtility {
  static sectionTownRangeFormat(unformatted: string) {
      // If input matches pattern of three sets of three digits followed by N/S and E/W
      const regex = /^([(]?\d{1,3}[)]?( ?))(\d{1,3}[NS]( ?))(\d{1,3}[EW]( ?))$|^([(]?\d{1,3}[)]?( ?))(\d{1,3}[EW]( ?))(\d{1,3}[NS]( ?))$/;
      let match = regex.exec(unformatted.toUpperCase());
      var sec = null
      var twp = null
      var rng = null
      if(match) {
        if (match[1]) {
          sec = match[1].trim().toUpperCase();
          twp = match[3].trim().toUpperCase();
          rng = match[5].trim().toUpperCase();
        }
        else if (!match[1]) {
          sec = match[7].trim().toUpperCase();
          twp = match[9].trim().toUpperCase();
          rng = match[11].trim().toUpperCase();
        }
        else {
          return unformatted;
        }
        //must have 2 spaces
        // var section = match.groups[1];
        sec = sec.replace('(', '').trim()
        sec = sec.replace(')', '').trim()
        sec = sec.padStart(3, '0').trim()
        if (((twp.endsWith('N') || twp.endsWith('S'))  && (rng.endsWith('E') || rng.endsWith('W'))) 
        ||((twp.endsWith('E') || twp.endsWith('W') )&& (rng.endsWith('N') || rng.endsWith('S')))) {
            twp = twp.toUpperCase()
            twp = twp.padStart(4, '0')
            rng = rng.toUpperCase()
            rng = rng.padStart(4, '0')
        }
        else {
          return unformatted;
        }
        if (sec === "000" ) {
          return unformatted;
        }
        return '(' + sec + ') ' + twp + ' ' + rng;
      } else {
        return unformatted;
      }
    }
  }

import { Action, ActionCreator } from 'redux';
import { IFeatureFlag } from '.';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';

const actionTypes = {
    GET_FEATUREFLAGS_REQUEST: '@@featureFlag/GET_FEATUREFLAGS_REQUEST',
    GET_FEATUREFLAGS_REQUEST_SUCCESS: '@@featureFlag/GET_FEATUREFLAGS_REQUEST_SUCCESS',
    GET_FEATUREFLAGS_REQUEST_FAIL: '@@featureFlag/GET_FEATUREFLAGS_REQUEST_FAIL'
}

export const ActionTypes = actionTypes;

export interface GetFeatureFlagRequestAction extends Action {
    api: ApiGateway;
};

export interface GetFeatureFlagResponseAction extends Action {
    type: string;
    featureFlags: IFeatureFlag[];
};

export const getFeatureFlagSuccess: ActionCreator<GetFeatureFlagResponseAction> = (featureFlags: IFeatureFlag[]) =>
({
    type: actionTypes.GET_FEATUREFLAGS_REQUEST_SUCCESS,
    featureFlags: featureFlags
});

export const getFeatureFlagFail: ActionCreator<GetFeatureFlagResponseAction> = () =>
({
    type: actionTypes.GET_FEATUREFLAGS_REQUEST_FAIL,
    featureFlags: [] as IFeatureFlag[],
});

export type FeatureFlagActions = GetFeatureFlagRequestAction | GetFeatureFlagResponseAction;
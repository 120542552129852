import {
    ColumnDef,
    ExpandedState,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import styles from "./Shareholders.module.scss";
import { ShareholderPremiumLineVM } from "./viewmodels";
import Collapse from "react-bootstrap/Collapse";
import { ErrorIcon } from "../../../components/columnfilter/Icons";
import { Table as ReactBootstrapTable } from "react-bootstrap";
import CompanionLink from "./CompanionLink";
import { ShareholderVM } from "./viewmodels/ShareholderVM";
//Import API gateway
import MaintainTransferofRights from "./MaintainTransferOfRights";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../store";
import * as FeatureFlagsSaga from '../../../store/proAgFeatureFlags';
import { ProAgFeatureFlags } from "../../../manager/common/proAgFeatureFlags";

interface props {
    premiumLines: ShareholderPremiumLineVM[];
    growerId: string;
    mpciPolicyId: string;
    shareholder: ShareholderVM;
    coverageId: number | undefined;
    errorHandler: (error: string) => void;
    setIsLoading: (setIsLoading: boolean) => void;
    canEditPremiumLineShareholders?: boolean;
}

const PremiumLineSummary: React.FC<props> = ({
    premiumLines,
    growerId,
    mpciPolicyId,
    shareholder,
    setIsLoading,
    coverageId,
    errorHandler,
    canEditPremiumLineShareholders
}) => {
    const [prLines, setPrLines] = useState<ShareholderPremiumLineVM[]>(premiumLines);

    useEffect(() => {
        setPrLines(premiumLines)
    }, [premiumLines]);

    const columns: ColumnDef<ShareholderPremiumLineVM>[] = [
        {
            accessorKey: "state",
            header: "State",
        },
        {
            accessorKey: "county",
            header: "County",
        },
        {
            accessorKey: "crop",
            header: "Crop",
        },
        {
            accessorKey: "type",
            header: "Type",
        },
        {
            accessorKey: "practice",
            header: "Practice",
        },
        {
            accessorKey: "basicUnit",
            header: "Basic Unit",
        },
        {
            accessorKey: "optionalUnit",
            header: "Optional Unit",
        },
        {
            accessorKey: "share",
            cell(props) {
                const index = prLines.findIndex(
                    (el) => el.oid == props.row.original.oid
                );
                const isPremimuLineShareLocked =
                    shareholder.isGrower ||
                    !prLines[index].enableDetaillineShare

                return (
                    <div className={prLines[index].errors && prLines[index].errors!.length > 0 ? "w-100 mx-auto d-flex flex-grow-1 justify-content-center ps-4" : "w-100 mx-auto d-flex flex-grow-1 justify-content-center"}>
                        <input
                            disabled={isPremimuLineShareLocked}
                            maxLength={5}
                            value={prLines[index].share || ""}
                            className={`shareInp-${props.row.original.oid} ${props.row.original.isAssociated
                                ? styles["l-15"]
                                : styles["r-10"]
                                } ${styles["inp-width"]} form-control text-end ${styles["readOnlyInputBox"]} ${styles["handleInpMargin"]
                                } ${styles["inp-height"]}`}
                            data-testid="SharePercentage"
                            tabIndex={-1}
                        />
                        {prLines[index].errors && prLines[index].errors!.length > 0 ? (
                            <span className={`${styles["errorIcon"]}`}>
                                <ErrorIcon tooltipText={prLines[index].errors![0]} />
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
            header: "Share %",
        },
    ];
    const featureFlags = useSelector<GlobalState, FeatureFlagsSaga.FeatureFlagState>((featureFlags) => featureFlags.featureFlagState);
    const shareholderUS91498 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.ShareholderUS91498).map((y) => y.enabled).toString();

    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const table = useReactTable({
        data: prLines,
        columns,
        state: {
            expanded,
            pagination: {
                pageIndex: 0,
                pageSize: prLines.length,
            },
        },
        onExpandedChange: setExpanded,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    });
    const handleSelectRow = (checked: boolean
    ) => {
        let pr = [...prLines];
        pr.forEach((pr) => {
            pr.isSelected = checked;
        });
        setPrLines(() =>
            pr)
    };
    return (
        <>
            {!shareholder.isGrower ? (
                <div className={` ${styles["width-11"]} d-flex justify-content-center`}>
                    {canEditPremiumLineShareholders &&(<input
                        checked={(prLines.every((pr) => pr.isSelected == true))}
                        className={` ${styles["margin-selectAll"]}`}
                        type="checkbox"
                        data-testid="chkSelectAll"
                        onChange={(e) => {
                            handleSelectRow(e.target.checked);
                        }}
                    />)}
                </div>
            ) : (
                <div></div>
            )}
            <ReactBootstrapTable
                striped
                bordered={false}
                className="table table-striped w-100"
            >
                <Collapse>
                    <thead>
                        {table.getHeaderGroups().map((_headerGroup) => {
                            return (
                                <tr key={_headerGroup.id} className="">
                                    {_headerGroup.headers.map((_header, i) => {
                                        return (
                                            <th
                                                className={
                                                    i == 0
                                                        ? `${styles["w-170-5"]}`
                                                        : i === _headerGroup.headers.length - 1
                                                            ? `${styles["w-430"]}`
                                                            : ""
                                                }
                                                key={_header.id}
                                                colSpan={_header.colSpan}
                                            >
                                                <div>
                                                    {_header.id !== "1_title_state" && (
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            {flexRender(
                                                                _header.column.columnDef.header,
                                                                _header.getContext()
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </th>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                </Collapse>
                <tbody>
                    {table.getRowModel().rows.map((_row) => {
                        const planCodes = ['01', '02', '03', '04', '05', '06', '90'];
                        const ineligibleCommodityCodes = ['0028', '0054', '0019', '0255', '0184', '0012', '0057', '0256', '0058', '0060',
                            '0218', '0223', '0220', '0201', '0053', '0202', '9936', '0023', '0309', '0501', '0227', '0257', '0034', '0089',
                            '0020', '0470', '0219', '0221', '0222', '0036', '0037', '0052', '0203', '0193', '1302', '0029']
                        return (
                            <tr
                                className={`${styles["bg-f5"]}  shareholdersummary-tr`}
                                key={_row.id}
                            >
                                {_row.getVisibleCells().map((_cell, key) => {
                                    return (
                                        <td
                                            className={"text-center"}
                                            key={_cell.id}
                                            data-testid={_cell.id
                                                .split(`${_row.index}_`)[1]
                                                .replaceAll(" ", "")
                                                .replaceAll(`'`, "")}
                                        >
                                            <span>
                                                {key === 0 && canEditPremiumLineShareholders && (
                                                    <input
                                                        className="me-3"
                                                        type="checkbox"
                                                        checked={_row.original.isSelected}
                                                        disabled={shareholder.isGrower}
                                                        data-testid="selectCheckbox"
                                                        onChange={(e) => {
                                                            let newPrLines = prLines.map((l) => {
                                                                if (l.oid === _row.original.oid) {
                                                                    l.isSelected = e.target.checked;
                                                                }
                                                                return l;
                                                            });

                                                            setPrLines(newPrLines);
                                                        }}
                                                    />
                                                )}
                                                {flexRender(
                                                    _cell.column.columnDef.cell,
                                                    _cell.getContext()
                                                )}
                                            </span>
                                        </td>
                                    );
                                })}
                                {<td className="align-item-cetner pt-3 justify-content-evenly ms-md-4 ms-lg-4">
                                    {(_row.original.isAssociated && shareholder.transferOfRightToIndemnity) && canEditPremiumLineShareholders &&
                                        (<MaintainTransferofRights
                                            growerId={growerId}
                                            mpciPolicyId={mpciPolicyId}
                                            premiumlineId={_row.original.oid}
                                            shareholderId={shareholder.oid}
                                            coverageId={coverageId}
                                            errorHandler={errorHandler}
                                            setIsLoading={setIsLoading}
                                            shareholderName={shareholder.isBusiness ? shareholder.businessName : shareholder.firstName + " " + shareholder.lastName}
                                        />)}
                                    {shareholderUS91498! === "true" && (_row.original.isAssociated && !shareholder.isGrower && !shareholder.transferOfRightToIndemnity && !shareholder.insureTenantLandlord && (_row.original.availableDetailLineShares > 0
                                        || (_row.original.availableDetailLineShares == 0 && _row.original.enableDetaillineShare))
                                        && planCodes.includes(_row.original.planCode)
                                        && !ineligibleCommodityCodes.includes(_row.original.commodityCode)) ? (
                                        <CompanionLink
                                                companionPolicyLink={
                                                    _row.original.companionPremiumLine[0]
                                                }
                                                premiumlineId={_row.original.oid}
                                                prlines={prLines}
                                                setPrlines={setPrLines}
                                                growerId={growerId}
                                                errorHandler={errorHandler}
                                                mpciPolicyId={mpciPolicyId}
                                                setIsLoading={setIsLoading}
                                                shareholderId={shareholder.oid}
                                                coverageId={coverageId}
                                                canEditPremiumLineShareholders={canEditPremiumLineShareholders}

                                        />
                                    ) :
                                        (_row.original.isAssociated && !shareholder.isGrower && !shareholder.transferOfRightToIndemnity && !shareholder.insureTenantLandlord && (_row.original.availableDetailLineShares > 0
                                            || (_row.original.availableDetailLineShares == 0 && _row.original.enableDetaillineShare))
                                            && _row.original.share! > 0 && planCodes.includes(_row.original.planCode)
                                            && !ineligibleCommodityCodes.includes(_row.original.commodityCode)) && (
                                            <CompanionLink
                                                companionPolicyLink={
                                                    _row.original.companionPremiumLine[0]
                                                }
                                                premiumlineId={_row.original.oid}
                                                prlines={prLines}
                                                setPrlines={setPrLines}
                                                growerId={growerId}
                                                errorHandler={errorHandler}
                                                mpciPolicyId={mpciPolicyId}
                                                setIsLoading={setIsLoading}
                                                shareholderId={shareholder.oid}
                                                coverageId={coverageId}
                                                canEditPremiumLineShareholders={canEditPremiumLineShareholders}
                                            />
                                        )}
                                </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </ReactBootstrapTable>
        </>
    );
};

export default PremiumLineSummary;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import AddNewShareholder from "./AddNewShareholder";
import ShareholdersSummary from "./ShareholdersSummary";
import PolicyTitle from "../../../components/mpci/PolicyTitle";
import { Container, Alert } from 'react-bootstrap';
import { IShareholder } from "./viewmodels/IShareholder";
import { ShareholderVM } from "./viewmodels/ShareholderVM";
import { URLs, ERRORS, ExpandCollapseState } from "../../../commom/constant";
import LoadSpinner from "../../../components/spinner/Spinner";
import { ConfirmMessageModalWindow } from '../../../components/warningMessageModal/ConfirmMessageModalWindow';
import { TITLE, WARNINGS, WINDOWTYPE } from '../../../commom/constant';

//Import API gateway
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import { IShareholderSearch } from "./viewmodels/IShareholderSearch";
import { IShareholderCoverage } from "./viewmodels/IShareholderCoverage";
import { IPremiumLine } from "./viewmodels/IPremiumLine";
import { ErrorUtility } from "../../../manager/common/errorUtility";
import { getFeatureFlags } from "../../../store/proAgFeatureFlags";
import { useDispatch } from "react-redux";
import { IUserAccess } from "../../../components/mpci/viewmodels/IUserAccess";

export default () => {
    const api = useApiGateway();//Init API Gateway
    const queryString = new URLSearchParams(useLocation().search);
    const growerId = queryString.get("growerId") ?? "0";
    const mpciPolicyId = queryString.get("mpciPolicyId") ?? "0";
    const dispatch = useDispatch();
    const [premiumlineId, setPremiumlineId] = useState(Number(queryString.get("premiumlineId") ?? "0"));
    const [isLoading, setIsLoading] = useState(false);
    const [showWarningMessage, setshowWarningMessage] = React.useState<boolean>(false);
    const [WarningMessageBody, setWarningMessageBody] = React.useState<string>('');
    const [WarningMessageTitle, setWarningMessageTitle] = React.useState<string>('');
    const [shareholders, setShareholders] = useState<ShareholderVM[]>([]);
    let shareholderSearchData: IShareholderSearch = {
        MpciPremiumLineOID: premiumlineId == 0 ? undefined : premiumlineId,
        IsAssociated: false
    }
    const [shareholderSearch, setShareholderSearch] = useState<IShareholderSearch>(shareholderSearchData);
    const [shareholderDefaultSearch, setShareholderDefaultSearch] = useState<IShareholderSearch>(shareholderSearchData);
    const [firstLoad,setFirstLoad]=useState<boolean>(true);
    const [expandCollapseState,setExpandCollapseState] = useState<ExpandCollapseState>(ExpandCollapseState.CollapsAll);
    const noSearchCriteria = (shareholderSearch: IShareholderSearch): boolean => {
        if ((shareholderSearch.BasicUnit == undefined || shareholderSearch.BasicUnit == "") &&
            (shareholderSearch.CommodityCode == undefined || shareholderSearch.CommodityCode == "") &&
            (shareholderSearch.CountyCode == undefined || shareholderSearch.CountyCode == "") &&
            (shareholderSearch.MpciCoverageOID == undefined || shareholderSearch.MpciCoverageOID == 0) &&
            (shareholderSearch.MpciPremiumLineOID == undefined || shareholderSearch.MpciPremiumLineOID == 0) &&
            (shareholderSearch.OptionalUnit == undefined || shareholderSearch.OptionalUnit == "") &&
            shareholderSearch.IsAssociated == false) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        setIsLoading(true);
        if (noSearchCriteria(shareholderSearch)) {
            getShareholderData(growerId, mpciPolicyId)
                .then((data) => {
                    setIsLoading(false);
                    let shareholders = ShareholderVM.calculateAvailableShares(ShareholderVM.fromIShareholders(data, 0));
                    setExpandCollapseState(ExpandCollapseState.CollapsAll)
                    setShareholders(shareholders);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                        errorHandler(ERRORS.get_shareholders_api_failed);
                    }
                });
        }
        else {
            getSearchedShareholderData(growerId, mpciPolicyId, shareholderSearch)
                .then((data) => {
                    if (firstLoad) {
                        if (premiumlineId != 0 && data.length > 0) {

                            let filteredAssociatedPremiumLinesData = data.filter((s: IShareholder) => s.ShareholderCoverages.findIndex((c: IShareholderCoverage) =>
                                c.PremiumLines &&
                                c.PremiumLines?.findIndex((p: IPremiumLine) => p.IsAssociated) > -1)
                                > -1);

                            let defaultIsAssociated = filteredAssociatedPremiumLinesData.filter((g: any) => g.IsGrower == false).length > 0;

                            if (defaultIsAssociated) {
                                data = filteredAssociatedPremiumLinesData;
                            }

                            setShareholderDefaultSearch({
                                CommodityCode: data[0].ShareholderCoverages[0].CommodityCode,
                                BasicUnit: data[0].ShareholderCoverages[0].PremiumLines[0].BasicUnit,
                                CountyCode: data[0].ShareholderCoverages[0].CountyCode,
                                MpciCoverageOID: data[0].ShareholderCoverages[0].OID,
                                MpciPremiumLineOID: data[0].ShareholderCoverages[0].MpciPremiumLineOID,
                                OptionalUnit: data[0].ShareholderCoverages[0].PremiumLines[0].OptionalUnit,
                                IsAssociated: defaultIsAssociated,
                            });
                        }
                        setExpandCollapseState(ExpandCollapseState.ExpandAll)
                        setFirstLoad(false);
                    }

                    setIsLoading(false);
                    if (data.length > 0) {
                        let shareholders = ShareholderVM.calculateAvailableShares(ShareholderVM.fromIShareholders(data, 0));
                        setShareholders(shareholders);
                    }
                    else {
                        setshowWarningMessage(true);
                        setWarningMessageBody(WARNINGS.no_result_found_shareholder);
                        setWarningMessageTitle(TITLE.Warning);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                        errorHandler(ERRORS.get_shareholders_api_failed);
                    }
                });
        }

    }, [shareholderSearch]);

    useEffect(() => {
        dispatch(getFeatureFlags(api));
    }, []);

    const shareholderSearchHandler = (shareholderSearch: IShareholderSearch, expandCollapseState : ExpandCollapseState): void => {
        setShareholderSearch({
            CommodityCode: shareholderSearch.CommodityCode,
            BasicUnit: shareholderSearch.BasicUnit,
            CountyCode: shareholderSearch.CountyCode,
            MpciCoverageOID: shareholderSearch.MpciCoverageOID,
            MpciPremiumLineOID: shareholderSearch.MpciPremiumLineOID,
            OptionalUnit: shareholderSearch.OptionalUnit,
            IsAssociated: shareholderSearch.IsAssociated,
        });
        setExpandCollapseState(expandCollapseState)
    }

    const updateShareholderData = (newData: ShareholderVM[]) => {
        newData = ShareholderVM.calculateAvailableShares(newData);
        setShareholders(ps => { return [...newData]; });
    }

    const getShareholderData = async (growerid: string, mpcipolicyid: string) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw)
        return res;
    }

    const getSearchedShareholderData = async (growerid: string, mpcipolicyid: string, shareholderSearch: IShareholderSearch) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders/AdvancedSearch";
        const res = await api.post(shareholderapiurl, ApplicationIdValues.Paw, shareholderSearch);
        return res;
    }

    const addShareholderHandler = (shareholder: IShareholder) => {
        shareholders.splice(1, 0, ShareholderVM.fromIShareholder(shareholder, Date.now()));
        updateShareholderData(shareholders);
    };

    const [errorMessage, seterrorMessage] = React.useState<string>("");
    const errorHandler = (error: string): void => {
        seterrorMessage(error);
        window.scrollTo(0, 0);
    }

    const [userAccess, setUserAccess] = React.useState<IUserAccess>();
    const userAccessHandler = (userAccess: IUserAccess): void => {
        setUserAccess(userAccess);
    }

    const clearError = (): void => {
        seterrorMessage("");
    }
    return (
        <>
            {isLoading ? <LoadSpinner /> : null}
            <Container fluid id="mainContent">
                <Alert variant="danger" show={errorMessage.length > 0} onClose={clearError} data-testid="errorAlert" dismissible>{errorMessage}</Alert>
                <PolicyTitle growerId={growerId} mpciPolicyId={mpciPolicyId} errorHandler={errorHandler} userAccessHandler={userAccessHandler} />
                {userAccess?.canEditPremiumLine && < AddNewShareholder growerId={growerId} mpciPolicyId={mpciPolicyId} addShareholderHandler={addShareholderHandler} errorHandler={errorHandler} setIsLoading={setIsLoading} shareholderSearch={shareholderSearch} />}
                <ShareholdersSummary growerId={growerId} mpciPolicyId={mpciPolicyId} shareholders={shareholders} setShareholderDataHandler={updateShareholderData} errorHandler={errorHandler} setIsLoading={setIsLoading} shareholderSearch={shareholderDefaultSearch} shareholderSearchHandler={shareholderSearchHandler} expandCollapseState={expandCollapseState} canEditPremiumLineShareholders={userAccess?.canEditPremiumLine} />
                <ConfirmMessageModalWindow
                    show={showWarningMessage}
                    windowType={WINDOWTYPE.Alert}
                    title={WarningMessageTitle}
                    messageBody={WarningMessageBody}
                    onConfirm={() => {
                    }}
                    onClose={() => {
                        setshowWarningMessage(false);
                    }}
                />
            </Container>
        </>
    );
}

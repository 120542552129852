import { Reducer } from 'redux';
import { IViewLand } from '../../views/mpci/lands/viewmodels/iViewLands';
import * as LandsSaga from './';
import { ActionTypes, LandsActions, PutLandsResponseAction, SaveLandsFailAction, SaveLandsSuccessAction, SetLandsHasEditAction, SetLandsRequestAction, SetLandsShouldSaveCoverage, SetLandsCoverageRequest, DeleteLandsFailAction, DeleteLandsResponseAction } from './actions';
import { isEmpty } from 'lodash';

const initialState: LandsSaga.LandState = { Lands: [], HasEdit: false, ShouldSaveCoverage: false, CoverageRequest: [] };

const reducer: Reducer<LandsSaga.LandState, LandsActions> =
    (state: LandsSaga.LandState = initialState, action: LandsActions): LandsSaga.LandState => {
        switch (action.type) {
            case ActionTypes.SET_LANDS_REQUEST_SUCCESS:
                const setLandsRequestAction = action as SetLandsRequestAction;
                state.HasEdit = false;
                return { ...state, Lands: setLandsRequestAction.lands };

            case ActionTypes.SAVE_LANDS_REQUEST:
                state.IsSaving = true;
                state.ErrorStatusCode = '';
                return { ...state };

            case ActionTypes.SAVE_LANDS_REQUEST_SUCCESS:
                const saveLandsRequestAction = action as SaveLandsSuccessAction;
                state.IsSaving = false;
                state.ErrorStatusCode = undefined;
                if (saveLandsRequestAction.lands.findIndex(x => x.IsPrimary == true) > -1) {
                    var mpciPremuimlineIds = saveLandsRequestAction.lands.map(x => x.UnitNumber?.value);
                    state.Lands.map(m => {
                        if (mpciPremuimlineIds.includes(m.UnitNumber?.value)) {
                            m.IsPrimary = false;
                        }
                        return m;
                    });
                }
                var landArray: IViewLand[] = [];
                state.Lands.forEach(land => land.IsNewlyAddedLand = false);
                saveLandsRequestAction.lands.forEach(thisLand => {
                    thisLand.IsNewlyAddedLand = true;
                    let foundUpdateLand = state.Lands.findIndex(land => land.OID == thisLand.OID)
                    if (foundUpdateLand == -1) {
                        landArray.push(thisLand);
                    }
                });
                return { ...state, Lands: landArray.concat(state.Lands) }
            case ActionTypes.SAVE_LANDS_REQUEST_FAIL:
                const saveLandsFailAction = action as SaveLandsFailAction;
                state.IsSaving = false;
                state.ErrorStatusCode = (saveLandsFailAction.errorCode)
                return { ...state };

            case ActionTypes.PUT_LANDS_REQUEST:
                return { ...state };

            case ActionTypes.PUT_LANDS_REQUEST_SUCCESS:
                const putLandsResponseAction = action as PutLandsResponseAction;
                return { ...state, Lands: putLandsResponseAction.lands }

            case ActionTypes.SET_LANDS_HAS_EDIT:
                const setLandsEditAction = action as SetLandsHasEditAction;
                return { ...state, HasEdit: setLandsEditAction.hasEdit }

            case ActionTypes.SET_LANDS_SHOULD_SAVE_COVERAGE:
                const saveCoverageAction = action as SetLandsShouldSaveCoverage;
                return { ...state, ShouldSaveCoverage: saveCoverageAction.shouldSaveCoverage }

            case ActionTypes.RESET_LANDS_COVERAGE_REQUEST:
                return { ...state, CoverageRequest: [] }

            case ActionTypes.SET_LANDS_COVERAGE_REQUEST:
                const setCoverageRequestAction = action as SetLandsCoverageRequest;
                if (isEmpty(setCoverageRequestAction.land)) {
                    return { ...state, CoverageRequest: [ ...state.CoverageRequest ] }
                }
                return { ...state, CoverageRequest: [ ...state.CoverageRequest, { ...setCoverageRequestAction.land }] }

            case ActionTypes.DELETE_LANDS_REQUEST:
                return { ...state };

            case ActionTypes.DELETE_LANDS_REQUEST_SUCCESS:
                const deleteLandsResponseAction = action as DeleteLandsResponseAction;
                return { ...state, Lands: deleteLandsResponseAction.lands };

            case ActionTypes.DELETE_LANDS_REQUEST_FAIL:
                const deleteLandsFailAction = action as DeleteLandsFailAction;
                state.IsSaving = false;
                state.ErrorStatusCode = (deleteLandsFailAction.errorCode)
                return { ...state };
        }
        return state;
    };

export default reducer;
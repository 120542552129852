import { ISelectList } from "components/forms/iSelectList";
import { IFSN } from "../../views/mpci/lands/viewmodels/iNewFarmLand";

export class AddNewFarmLandOnChangeUtility {
    static onChangeAddNewCounty(oldFSN: IFSN, value: ISelectList): IFSN {
        oldFSN.County = value;
        return oldFSN;
    }
    static onChangeAddNewCoverage(oldFSN: IFSN, value: ISelectList[] | undefined): IFSN {
        oldFSN.Coverage = value;
        return oldFSN;
    }
    static onChangeAddNewUnitNumber(oldFSN: IFSN, value: ISelectList[] | undefined): IFSN {
        oldFSN.UnitNumber = value;
        return oldFSN;
    }
    static onChangeAddNewFSN(oldFSN: IFSN, value: string): IFSN {
        oldFSN.FSN = value;
        return oldFSN;
    }
    static onChangeAddNewTract(oldFSN: IFSN, value: string): IFSN {
        oldFSN.Tract = value;
        return oldFSN;
    }
    static onChangeAddNewSecTwpRng(oldFSN: IFSN, value: ISelectList | undefined): IFSN {
        oldFSN.SecTwpRng = value;
        return oldFSN;
    }
    static onChangeAddNewPlaceName(oldFSN: IFSN, value: string): IFSN {
        oldFSN.PlaceName = value;
        return oldFSN;
    }
    static onChangeAddNewOtherLandId(oldFSN: IFSN, value: string): IFSN {
        oldFSN.OtherLandId = value;
        return oldFSN;
    }
    static onChangeAddNewIsPrimary(oldFSN: IFSN): IFSN {
        oldFSN.IsPrimary = !oldFSN.IsPrimary;
        return oldFSN;
    }
}
interface RoutePaths {
    loginCallback: string;
    home: string;
    contact: string;
    pr: string;
    shareholders: string;
    lands: string;
    blank: string;
    logout: string;
}

export const routePaths: RoutePaths = {
    loginCallback: '/login/callback',
    home: '/',
    contact: '/contact',
    pr: '/pr/:mpciOid/:coverageOid',
    shareholders: '/mpci/shareholders',
    lands: '/mpci/lands',
    blank: '/blank',
    logout:'/logout'
};


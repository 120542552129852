import { format, isValid, parse } from 'date-fns';
import { isEmpty } from 'lodash';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FormButton } from '../../../components/forms/button/formbutton';
import { InputDatePicker, InputDecimal } from '../../../components/forms/formBase';
import LandLabel from '../../../components/label/LandLabel';
import styles from './addNewField.module.scss';
import { useFieldValidation } from './customHooks/useFieldValidation';
import InputValidation from './input-validation';
import { INewField } from './viewmodels/iNewField';
import { IViewField } from './viewmodels/iViewField';
import PreventedPlantDisplay from './viewPreventPlantDisplay';
import { SectionEquivalentId } from './sectionEquivalentID';
import { PolicyUtility } from 'manager/lands/policyUtility';
import { IViewFieldErrors } from './viewmodels/iViewFieldErrors';
import { FieldUtility } from 'manager/lands/fieldUtility';

function AddNewField(newField: INewField) {
    const [isRlu, setIsRlu] = useState(false);
    const [formData, setFormData] = useState({} as IViewField)
    const fieldNumberRef = useRef<HTMLInputElement>(null);
    const plantDateRef = useRef<HTMLInputElement>(null);
    const selectRef = useRef<HTMLSelectElement>(null)
    const { errors, triggerValidation, clearErrors } = useFieldValidation({
        Field: undefined, 
        IsPreventedPlant: newField.IsPreventedPlant, 
        IsPlantDateRequired: newField.IsPlantDateRequired,
        HasSectionEquivalentId: newField.hasSectionEquivalence,
        IsDisabled: false,
        ReinsuranceYear: newField.ReinsuranceYear
    });
    let hasEnteredFieldNumber = false;
    const dispatch = useDispatch();

    useEffect(() => {
        setIsRlu(newField.IsRLUOnly || isRlu);
        setFormData({ ...formData, IsRlu: newField.IsRLUOnly || isRlu })
        fieldNumberRef?.current?.focus();
    }, [newField.IsRLUOnly]);

    useEffect(() => {
        if (formData.ShouldValidate) {
            triggerValidation(formData)
        }
    }, [formData?.FieldNumber, formData?.SubFieldNumber, formData?.ReportedAcres, formData?.PlantedDate, formData?.RluId, formData?.ShouldValidate])

    function onChangeOfCLUType(): void {
        if (!newField.IsRLUOnly) {
            setIsRlu(!isRlu);
            setFormData({ 'IsRlu': !isRlu } as IViewField)
            clearErrors()
        }
    }

    const onChange = (e: BaseSyntheticEvent): void => {
        const key = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [key]: value })
    }

    const onDateChange = (e: BaseSyntheticEvent): void => {
        const value = e.target.value;
        setFormData({ ...formData, PlantedDate: parse(value, 'yyyy-MM-dd', new Date()) })
    }

    const resetForm = (): void => {
        setFormData({ 'IsRlu': isRlu } as IViewField)
        clearErrors()
        fieldNumberRef.current?.focus()
        if (selectRef?.current?.value) {
            selectRef.current.selectedIndex = 0

        }
    }

    const addNewField = async (): Promise<void> => {
        setFormData({ ...formData, ShouldValidate: true })
        var errorCheck  = await triggerValidation(formData);
        if (isEmpty(errorCheck)) {
            formData.AdminStateCode = newField.StateCode;
            formData.AdminCountyCode = newField.AdminCountyCode;
            formData.ViewId = crypto.randomUUID();
            PolicyUtility.UpdateReportedAcres(dispatch, formData.ReportedAcres, newField.PremiumLineOid, newField.LandOid, formData )
            newField.addField({ ...formData })
            resetForm()
        }
    }

    const onBlurNewFieldNumber = () => {
        if (!hasEnteredFieldNumber && !isEmpty(fieldNumberOrRluIdValue)) {
            if (!!newField.SelectedDetailLinePlantedDate) {
                setFormData({ ...formData, PlantedDate: parse(newField.SelectedDetailLinePlantedDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()) })
            }
            hasEnteredFieldNumber = true;
        }
    }

    const fieldNumberOrRluIdValue = isRlu ? formData.RluId ? formData.RluId : '' : formData.FieldNumber ? formData.FieldNumber : ''

    return (
        <Row
            data-testid={"addNewField"}
            className={styles.border}>
            <Col
                xs={4}
                sm={2}
                lg={1}
            >
                <Row
                    className={`h-100`}
                >
                    <Col
                        xs={4}
                        className={'align-items-center d-flex'}
                        id='rowAddField'>
                    </Col>
                    <Col
                        xs={4}
                        className={'align-items-center d-flex'}
                    >
                        <Badge
                            tabIndex={0}
                            style={{ cursor: 'pointer' }}
                            data-testid={"testLandType"}
                            pill
                            bg={`${isRlu ? 'success' : 'primary'}`}
                            onClick={onChangeOfCLUType}
                            className={styles.cluBadge}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                    e.preventDefault()
                                    onChangeOfCLUType()
                                }
                            }}
                        >
                            {isRlu ? 'RLU ' : 'CLU '}
                            <i className={`bi-arrow-clockwise`}></i>
                        </Badge>
                    </Col>
                </Row>
            </Col>

            <Col
                xs={4}
                sm={2}
                xxl={1}
                className={`mt-1 p-1`}
            >
                <InputValidation
                    floatLabel={true}
                    name={isRlu ? 'RluId' : 'FieldNumber'}
                    type="input"
                    onChange={onChange}
                    onBlur={onBlurNewFieldNumber}
                    maxLength={isRlu ? 10 : 7}
                    label={isRlu ? 'RLU #' : 'Field #'}
                    value={fieldNumberOrRluIdValue}
                    innerRef={fieldNumberRef}
                    error={isRlu ? errors.RluId : errors.FieldNumber}
                />

            </Col>

            <Col
                xs={4}
                sm={2}
                xxl={1}
                className={`mt-1 p-1`}
            >
                <InputValidation
                    floatLabel={true}
                    label={"Sub Field #"}
                    name="SubFieldNumber"
                    type='input'
                    disabled={isRlu}
                    onChange={onChange}
                    maxLength={7}
                    value={formData.SubFieldNumber ? formData.SubFieldNumber : ''}
                    error={errors.SubFieldNumber}

                />
            </Col>

            <Col
                xs={12}
                sm={7}
                xxl={2}
                className={`p-0`}
            >
                <Row className={'p-0'}>
                    <Col
                        className={`mt-1 p-1`}
                        xs={4}
                    >
                        <LandLabel
                            data-testid="HR"
                            Label={"HR"}
                            Name={"HR"}
                        />
                    </Col>
                    <Col
                        xs={4}
                        data-testid={`UnratedCode${formData.CluId}`}
                        className={`mt-1 p-1`}
                    >
                        <LandLabel
                            data-testid="URA"
                            Label={"URA"}
                            Name={"URA"}
                        />
                    </Col>
                    <Col
                        xs={4}
                        data-testid={`SubCountyCode${formData.CluId}`}
                        className={`mt-1 p-1`}
                    >
                        <LandLabel
                            data-testid="subCounty"
                            Label={"Sub County"}
                            Name={"SubCounty"}
                        />
                    </Col>
                </Row>
            </Col>

            <Col
                xs={2}
                xxl={1}
                className={`mt-1 p-1`}
            >
                <InputDecimal
                    floatLabel={true}
                    label={"Field Acres"}
                    name="Acres"
                    onUpdate={onChange}
                    value={formData?.Acres ? formData.Acres : ''}
                />
            </Col>

            <Col
                xs={3}
                xxl={newField.hasSectionEquivalence ? 1 : 2}
                className={`mt-1 p-1`}
            >
                <InputDecimal
                    floatLabel={true}
                    label={"Reported Acres"}
                    name="ReportedAcres"
                    onUpdate={onChange}
                    value={formData?.ReportedAcres ? formData.ReportedAcres : ''}
                    error={errors.ReportedAcres}
                />
            </Col>

            <Col
                xs={newField.hasSectionEquivalence ? 5 : 4}
                xxl={newField.hasSectionEquivalence ? 4 : 3}
                className={`p-0`}
            >
                <Row className={'mt-1 p-1'}>
                    <Col xs={newField.hasSectionEquivalence ? 5 : 10}>
                        <InputDatePicker
                            floatingLabel={true}
                            name={'PlantDate'}
                            label={'Plant Date'}
                            preventFutureDates={true}
                            onChange={(e: any): void => onDateChange(e)}
                            useRef={plantDateRef}
                            error={errors.PlantDate}
                            value={!!formData.PlantedDate && isValid(formData.PlantedDate) ? format(formData.PlantedDate, 'yyyy-MM-dd') : ''}
                        />
                    </Col>

                    { 
                        newField.hasSectionEquivalence &&
                            <Col
                                xs={5}
                                className={`g-0`}
                            >
                                <SectionEquivalentId
                                    updateField={setFormData}
                                    viewField={formData}
                                    error={errors.SectionEquivalentId}
                                />
                            </Col>
                    }

                    <Col
                        xs={2}
                        className={`text-center d-flex align-items-center`}
                    >
                        <PreventedPlantDisplay
                            isPreventedPlant={newField.IsPreventedPlant}
                            isPRF={newField.IsPRF}
                        />
                    </Col>
                </Row>
            </Col>

            <Col
                xs={newField.hasSectionEquivalence ? 2 : 3}
                xxl={1}
                className='align-self-center'
            >
                <Row className={"p-1"}>
                    <Col
                        xs={12}
                    >
                        <FormButton
                            name={'add-new-field-button'}
                            icon={'bi bi-plus-square'}
                            variant={'outline-accent5'}
                            toolTip={"Add Field"}
                            onClick={addNewField}
                        />
                    </Col>
                </Row>
            </Col>
        </Row >
    );
}

export default AddNewField;
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './LandLabel.module.scss';

export interface LandLabel extends React.HTMLAttributes<HTMLDivElement> {
    Label: string,
    Value?: string | number,
    Name: string,
    ToolTip?: JSX.Element
    isLink?: boolean,
}

export default function LandLabel({ Label, Value, Name, ToolTip, isLink, ...rest }: LandLabel) {
    function getValue() {
        if (isLink) {
            return <a data-testid={Name+`_link`} href="url"><u>{Value}</u></a>
        }
        else return Value;
    }

    const label = (
        <div className={!isLink ?  styles.minRowHeight : styles.clickable}>
            <div
                data-testid={`Label-${Name}`}
                className={styles.labelCss}
            >
                {Label}</div>
            <div
                data-testid={`Value-${Name}`}
                className={styles.valueCss}
            >
                {getValue()}</div>
        </div>
    )

    return (
        <div { ...rest }>
            {
                ToolTip ?
                    <OverlayTrigger
                        placement='right'
                        data-testid={`ToolTip`}
                        overlay=
                        {
                            <Tooltip id='clearButton-Tooltip'>
                                {ToolTip}
                            </Tooltip>
                        }
                    >
                        {label}
                    </OverlayTrigger>
                    :
                    <>{label}</>
            }
        </ div>
    )
}
import { IStates } from '../../manager/lands/model/iMpciPolicy';
import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';


export interface StatesState {
    States: IStates[],
    Loading?: boolean;
    HasError?: boolean;
}
import { ShareholderPremiumLineVM } from "./ShareholderPremiumLineVM";
import { IShareholderCoverage } from "./IShareholderCoverage";
import { ShareholderVM } from "./ShareholderVM";

export class ShareholderCoverageVM {
    oid: number = 0;
    title: string = "";
    share?: number;
    state?: string;
    county?: string;
    crop?: string;
    plan?: string;
    unit?: string;
    type?: string;
    practice?: string;
    coverageDescription?: string;
    premiumLines?: ShareholderPremiumLineVM[];

    clone() {
        let result = new ShareholderCoverageVM();

        result.oid = this.oid;
        result.title = this.coverageDescription ?? "";
        result.share = this.share;
        result.state = this.state;
        result.county = this.county;
        result.crop = this.crop;
        result.plan = this.plan;
        result.unit = this.unit;
        result.type = this.type;
        result.practice = this.practice;
        result.premiumLines = ShareholderPremiumLineVM.cloneMany(this.premiumLines ?? []);
        return result;
    }

    static cloneMany(shareholderCoverageVMs: ShareholderCoverageVM[]) {
        let result: ShareholderCoverageVM[] = [];

        if (shareholderCoverageVMs?.length > 0) {
            for (var shareholderCoverageVM of shareholderCoverageVMs) {
                result.push(shareholderCoverageVM.clone());
            }
        }

        return result;
    }

    static fromIShareholderCoverage(shareCoverage: IShareholderCoverage) {
        let result = new ShareholderCoverageVM();

        result.oid = shareCoverage.OID;
        result.title = (shareCoverage.CoverageDescription ?? "").replace(/(\r\n|\n|\r)/gm, "");
        result.state = shareCoverage.StateAbbreviation;
        result.county = shareCoverage.County;
        result.crop = shareCoverage.CommodityName;
        result.plan = shareCoverage.PlanAbbreviation;
        result.unit = shareCoverage.UnitStructureCode;
        result.premiumLines = ShareholderPremiumLineVM.fromIShareholderCoverage(shareCoverage);
        return result;
    }

    static fromIShareholderCoverages(shareCoverages: IShareholderCoverage[]): ShareholderCoverageVM[] {
        var result: ShareholderCoverageVM[] = [];
        for (var coverage of shareCoverages) {
            result.push(ShareholderCoverageVM.fromIShareholderCoverage(coverage));
        }

        return result;
    }

    toIShareholderCoverage(predicatePremiumLineVM?: (premiumLineVM: ShareholderPremiumLineVM) => boolean): IShareholderCoverage {
        let shareholderCoverage: IShareholderCoverage = {
            OID: this.oid,
            PremiumLines: ShareholderPremiumLineVM.toIPremiumLines(this.premiumLines ?? [], predicatePremiumLineVM)
        };

        return shareholderCoverage;
    }

    static toIShareholderCoverages(shareholderCoverageVMs: ShareholderCoverageVM[]
        , predicateShareholderCoverageVM?: (shareholderCoverageVM: ShareholderCoverageVM) => boolean
        , predicatePremiumLineVM?: (premiumLine: ShareholderPremiumLineVM) => boolean): IShareholderCoverage[] {
        var shareholderCoverages: IShareholderCoverage[] = [];
        for (var shareholderCoverageVM of shareholderCoverageVMs) {
            if (predicateShareholderCoverageVM == undefined || predicateShareholderCoverageVM(shareholderCoverageVM)) {
                shareholderCoverages.push(shareholderCoverageVM.toIShareholderCoverage(predicatePremiumLineVM));
            }
        }
        return shareholderCoverages;
    }

}
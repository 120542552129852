import { useTitle } from 'services/browser';
import HeaderComponent from './Header';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from '../../commom/constant';
import SaveComponent from './Save';

export interface ProductionReporting {
    header: {
        GrowerName: string;
        PolicyNumber: string;
        ReinsuranceYear: string;
        StateCode: string;
        AipCode: string;
        StateName: string;
        LineOfBusiness: string;
    }
}

export default () => {
    useTitle('ProAg Production Reporting');

    const { mpciOid, coverageOid } = useParams();

    let defaultResponse = {} as ProductionReporting;
    defaultResponse.header = {
        ReinsuranceYear: '',
        GrowerName: '',
        PolicyNumber: '',
        StateCode: '',
        AipCode: '',
        StateName: '',
        LineOfBusiness: ''
    };


    const [response, setResponse] = useState(defaultResponse);

    useEffect(() => {
        fetchProductionReporting();
    }, []);

    async function fetchProductionReporting() {
        fetch(API.growerAPI, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(data => data.json())
            .then(data => storeResponse(data))
            .catch(error => handleError(error));
    }

    function storeResponse(data: any) {
        var response: ProductionReporting = data;
        setResponse(response);
    }

    function handleError(error: any) {
        console.log("Error Reaching PAW!");
        console.log(error);
    }

    return (
        <>
            <div className="pt-2 pb-2">
                <Col>
                    <Row>
                        <Col lg="9" md="12" xs="12" className="align-self-center">
                            <HeaderComponent header={response.header} />
                        </Col>
                        <Col lg="3" md="12" xs="12" >
                            <SaveComponent />
                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    );
};
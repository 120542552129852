import React, { memo } from 'react';
import { Button, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { IFormButtonProps } from './iFormButtonProps';

export const FormButton: React.FC<IFormButtonProps> = ({ name, className, toolTip, icon, onClick, disabled, variant, buttonText, isLoading }: IFormButtonProps) => {
    function withToolTip() {
        return (
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{toolTip}</Tooltip>}>
                <span className='d-inline-block'>
                    {button()}
                </span>
            </OverlayTrigger>
        );
    }

    function button() {
        return (
            <Button
                name={name}
                id={name}
                data-testid={name}
                className={className}
                onClick={onClick}
                disabled={disabled}
                variant={variant}
            >
                {isLoading ?
                    <Spinner size='sm' animation={"border"} />
                    :
                    icon ? <i className={icon}></i> : undefined}
                {buttonText}
            </Button>)
    }

    return (
        <Form.Group controlId={`button-` + name}>
            {toolTip ? withToolTip() : button()}
        </Form.Group>
    );
};

export default memo(FormButton);
import { ActionCreator } from 'redux';
import { GetPORRequestAction, DeletePORRequestAction, AutomatePORRequestAction, SavePORRequestAction } from './actions';

export const getPOR: ActionCreator<GetPORRequestAction> = (action: GetPORRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    PORRequest: action.PORRequest
});

export const deletePOR: ActionCreator<DeletePORRequestAction> = (action: DeletePORRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    CluId: action.CluId,
    PORRequest: action.PORRequest
});

export const automatePOR: ActionCreator<AutomatePORRequestAction> = (action: AutomatePORRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    PORRequest: action.PORRequest
});

export const SavePOR: ActionCreator<SavePORRequestAction> = (action: SavePORRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    PointOfReference: action.PointOfReference,
    PORRequest: action.PORRequest
});
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { GlobalState } from '../../store';
import * as LoadSpinnerSaga from '../../store/loadingSpinner'
import "./Spinner.scss";

function GlobalSpinner() {
    const spinnerState = useSelector<GlobalState, LoadSpinnerSaga.LoadSpinnerState>((state) => state.loadSpinnerState);

    return (
        <div>
            {spinnerState.LoadingKeys.length !== 0 &&
                <div className="loader-container" >
                    <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            }
        </div>
    );
}

export default GlobalSpinner;
import { takeLatest, put, call } from 'redux-saga/effects';
import { ActionTypes, GetFeatureFlagRequestAction, getFeatureFlagSuccess, getFeatureFlagFail } from './actions';
import * as Api from './api';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';

export function* getFeatureFlagRequestSaga() {
    yield takeLatest(ActionTypes.GET_FEATUREFLAGS_REQUEST, getFeatureFlagRequest);
};

function* getFeatureFlagRequest(action: GetFeatureFlagRequestAction) {
    yield call(getFeatureFlags, action.api);
}

function* getFeatureFlags(api: ApiGateway) {
    try {
        const featureFlag: any[] = yield call(Api.getFeatureFlags, api);
        yield put(getFeatureFlagSuccess(featureFlag));
    }
    catch {
        yield put(getFeatureFlagFail());
    }
}
import { useApiGateway } from "components/apiGateway/useApiGateway";
import { SelectForm } from "components/forms/formBase";
import { format } from "date-fns";
import { getCIMSCLUAsOfDates } from "manager/lands/api";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { IViewField } from "../viewmodels/iViewField";
import { ISelectList } from "components/forms/iSelectList";

interface CIMSDateSelectProps {
    fieldData: IViewField;
    updateField: (field: IViewField, isCluInformation?: boolean) => void;
}

export function CIMSDateSelect({ fieldData, updateField }: CIMSDateSelectProps): JSX.Element {
    const api = useApiGateway();
    const [CIMSOptions, setCIMSOptions] = useState<ISelectList[]>(() => [{ label: fieldData.CIMSDate ? fieldData.CIMSDate : 'Loading', value: fieldData.CIMSDate ? fieldData.CIMSDate : 'Loading' }])

    useEffect(() => {
        fetchCIMSDates()
    }, [])

    let dateValue = fieldData.CIMSDate ? format(new Date(fieldData.CIMSDate), 'MM/dd/yyyy') : ''
    const fetchCIMSDates = (): void => {
        getCIMSCLUAsOfDates(api).then((CIMSDate) => {
            if(!dateValue){
                dateValue = CIMSDate[0] ?? '';
            }
            setCIMSOptions(CIMSDate.map(date => { return { label: format(new Date(date), 'MM/dd/yyyy'), value: format(new Date(date), 'MM/dd/yyyy') } }));
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <SelectForm
                name={`clu_CIMSDate_${fieldData.CluId}`}
                label={'CIMS CLU Date'}
                value={dateValue}
                onChange={(e: BaseSyntheticEvent) => {
                    updateField({ ...fieldData, CIMSDate: e.target.value })
                }}
                options={CIMSOptions}
            />
        </>
    )
}
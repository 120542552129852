import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import Select, { ActionMeta, Props } from "react-select";
import { ISelectSingleProps } from './iSelectSingleProps';
import styles from './selectSingle.module.scss';

export const SelectSingle: React.FC<ISelectSingleProps> = (props: ISelectSingleProps) => {
    const { name, onSelectChange, required, error, label, placeHolder } = props
    const customStyles = {
        control: (base: any, state: { isFocused: any; }) => ({
            ...base,

            borderColor: state.isFocused ?
                '#ddd' : !isInError() ?
                    '#ddd' : '#dc3545',

            '&:hover': {
                borderColor: state.isFocused ?
                    '#ddd' : !isInError() ?
                        '#ddd' : '#dc3545'
            }
        })
    }

    function isInError(): boolean {
        if (error && error.length > 0) {
            return true;
        }
        return false;
    }

    const onhandleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        if (onSelectChange) {
            onSelectChange(newValue, actionMeta);
        }
    }

    const checkForUndefined = (value?: boolean): boolean | undefined => {
        if (value == undefined || value == null) {
            return undefined;
        } else {
            return value;
        }
    }

    return (
        <Form.Group controlId={`select-control-` + name}>
            {label ? <Form.Label>{label}</Form.Label> : null}
            <Select
                isSearchable={checkForUndefined(props.isSearchable)}
                isClearable={checkForUndefined(props.isClearable)}
                isDisabled={checkForUndefined(props.isDisabled)}
                closeMenuOnSelect={checkForUndefined(props.closeMenuOnSelect)}
                options={props.options ? props.options : undefined}
                name={props.name}
                value={props.value ? props.value : undefined}
                defaultValue={props.defaultValue ? props.defaultValue : undefined}
                id={props.id ? props.id : undefined}
                onChange={onhandleChange}
                styles={customStyles}
                className={props.className ? props.className : undefined}
                menuPlacement={props.menuPlacement}
                placeholder={placeHolder}
            />
            <Form.Label data-testid={`error-msg-${name}`} className={styles.error}>
                {error}
            </Form.Label>
        </Form.Group>
    );
};

export default memo(SelectSingle);
import { ActionCreator } from 'redux';
import { PremiumLineUpdateResults } from '.';
import { ActionTypes, ClearPremiumLineResults, DeletePremiumLineAcres, PutPremiumLineInformation, UpdatePremiumLineAcres, UpdatePremiumLineInformation, UpdatePremiumLineResults } from './actions';

export const putPremiumLineInformation: ActionCreator<PutPremiumLineInformation> = (
    premiumLineOid: number,
    totalUnitAcres: number,
    plantDate: string,
    acreageTypeCode: string,
    acreageTypeDescription: string,
    guaranteeReductionFlag: string,
    userCanEditPremiumLine: boolean,
    hasClaim: boolean,
    croptions: string
) =>
({
    type: ActionTypes.PUT_PREMIUMLINEINFORMATION,
    PremuimLineOid: premiumLineOid,
    ReportedUnitAcres: totalUnitAcres,
    PlantDate: plantDate,
    AcreageTypeCode: acreageTypeCode,
    AcreageTypeDescription: acreageTypeDescription,
    GuaranteeReductionFlag: guaranteeReductionFlag,
    UserCanEditPremiumLine: userCanEditPremiumLine,
    HasClaim: hasClaim,
    Croptions: croptions
});

export const updatePremiumLineInformation: ActionCreator<UpdatePremiumLineInformation> = (premiumLineOid: number, totalUnitAcres: number) =>
    ({ type: ActionTypes.UPDATE_PREMIUMLINEINFORMATION, PremuimLineOid: premiumLineOid, ReportedUnitAcres: totalUnitAcres });

export const updatePremiumLineTotalAcres: ActionCreator<UpdatePremiumLineAcres> = (premiumLineOid: number, landOid: number, oid: string, totalUnitAcres: number) =>
    ({ type: ActionTypes.UPDATE_PREMIUMLINEACRES, PremuimLineOid: premiumLineOid, LandOid: landOid, Oid: oid, ReportedUnitAcres: totalUnitAcres });

export const deletePremiumLineTotalAcres: ActionCreator<DeletePremiumLineAcres> = (premiumLineOid: number, landOid: number) =>
    ({ type: ActionTypes.DELETE_PREMIUMLINEACRES, PremuimLineOid: premiumLineOid, LandOid: landOid });

export const updatePremiumLineResults: ActionCreator<UpdatePremiumLineResults> = (results: PremiumLineUpdateResults[]) =>
    ({ type: ActionTypes.UPDATE_PREMIUMLINERESULTS, Results: results});

export const clearPremiumLineResults: ActionCreator<ClearPremiumLineResults> = () =>
    ({ type: ActionTypes.CLEAR_PREMIUMLINERESULTS });
import { ISelectList } from "components/forms/iSelectList";
import { Dispatch } from "redux";
import { ILandsContext } from "views/mpci/lands/viewmodels/iLandsContext";
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import * as LandSaga from '../../store/land';
import { ISaveFSN } from "../../store/land";
import { CoverageDetailsError, LegalDetailsError } from "../../views/mpci/lands/viewmodels/IFSNError";
import { IFSN } from "../../views/mpci/lands/viewmodels/iNewFarmLand";
import { IViewField } from "../../views/mpci/lands/viewmodels/iViewField";

export class AddNewFarmLandUtility {
    static createdFSN: IFSN;
    static saveFSN: ISaveFSN;

    static ClearValues() {
        this.createdFSN = {} as IFSN;
        this.saveFSN = {} as ISaveFSN;
    }

    static SaveNewLegal(api: ApiGateway, dispatch: Dispatch, landsContext: ILandsContext, fields: IViewField[] | undefined) {
        if (AddNewFarmLandUtility.AddNewShouldSave()) {
            if (AddNewFarmLandUtility.AddNewLegalHasNoErrors(AddNewFarmLandUtility.ValidateLegalDetails(this.createdFSN), AddNewFarmLandUtility.ValidateCoverageDetails(this.createdFSN))
                && !AddNewFarmLandUtility.HasFieldsError(fields)) {
                if (this.createdFSN && this.saveFSN) {
                    this.saveFSN.Fields = fields;
                    this.saveFSN.CommodityCode = this.createdFSN.CommodityCode ? this.createdFSN.CommodityCode : ''
                    this.saveFSN.InsurancePlanCode = this.createdFSN.InsurancePlanCode ? this.createdFSN.InsurancePlanCode : ''
                    this.saveFSN.CountyCode = this.createdFSN.County ? this.createdFSN.County.value : ''
                    this.saveFSN.ReInsuranceYear = this.createdFSN.ReInsuranceYear ? this.createdFSN.ReInsuranceYear : '',
                    this.saveFSN.StateCode = this.createdFSN.StateCode ? this.createdFSN.StateCode : ''

                    dispatch(LandSaga.saveLands(api, landsContext.GrowerOid, landsContext.PolicyOid, this.saveFSN))
                }
            }
        }
    }

    private static createSave() {
        let selectedCountyCode = this.createdFSN.County ? this.createdFSN.County.value : '';

        this.saveFSN = {
            FarmNumber: this.createdFSN.FSN?.trim(),
            TractNumber: this.createdFSN.Tract?.trim(),
            Section: this.createdFSN.SecTwpRng ? this.createdFSN.SecTwpRng.label.toString() : '',
            OtherId: this.createdFSN.OtherLandId?.trim() ? this.createdFSN.OtherLandId?.trim() : '',
            PlaceName: this.createdFSN.PlaceName?.trim() ? this.createdFSN.PlaceName?.trim() : '',
            IsPrimary: this.createdFSN.IsPrimary ? this.createdFSN.IsPrimary : false,
            CountyCode: selectedCountyCode,
            UnitNumber: this.createdFSN.UnitNumber,
            Coverage: this.createdFSN.Coverage,
            ReInsuranceYear: this.createdFSN.ReInsuranceYear ?? '0',
            CommodityCode: this.createdFSN.CommodityCode ?? '0',
            InsurancePlanCode: this.createdFSN.InsurancePlanCode ?? '0',
            StateCode: this.createdFSN.StateCode ?? '0'
        };
    }

    static STRRegex =
        new RegExp(/^\(\d\d\d\)\s\d\d\d[NnSs]\s\d\d\d[EeWw]$|(^\(\d\d\d\)\s\d\d\d[EeWw]\s\d\d\d[NnSs]$)/);

    static ValidateLegalDetails(newLegal: IFSN): LegalDetailsError {
        let errors: LegalDetailsError = {};
        if (!this.isPopulated(newLegal.FSN) &&
            !this.isSelectListPopulated(newLegal.SecTwpRng) &&
            !this.isPopulated(newLegal.PlaceName) &&
            !this.isPopulated(newLegal.OtherLandId)
        ) {
            errors.FarmNumber = 'Enter FN';
            errors.SecTwpRng = 'or Sec/Twp/Rng';
            errors.PlaceName = 'or Place Name';
            errors.OtherLandId = 'or Other Id';
        }
        if (this.isSelectListPopulated(newLegal.SecTwpRng)
            && newLegal.SecTwpRng && newLegal.SecTwpRng.label
            && !AddNewFarmLandUtility.STRRegex.test(newLegal.SecTwpRng.label.toString())
        ) {
            errors.SecTwpRng = 'Invalid Section Township Range';
        }
        if (this.isPopulated(newLegal.PlaceName) && !this.stringFails(newLegal.PlaceName, 100)) {
            errors.PlaceName = 'Maximum 100 characters';
        }
        if (this.isPopulated(newLegal.Tract) && !this.stringFails(newLegal.Tract, 7)) {
            errors.TractNumber = 'Maximum 7 characters';
        }
        if (this.isPopulated(newLegal.FSN) && !this.stringFails(newLegal.FSN, 7)) {
            errors.FarmNumber = 'Maximum 7 characters';
        }
        if (this.isPopulated(newLegal.OtherLandId) && !this.stringFails(newLegal.OtherLandId, 100)) {
            errors.OtherLandId = 'Maximum 100 characters';
        }

        this.createdFSN = newLegal;
        this.createSave();

        return errors;
    }

    static ValidateCoverageDetails(legal: IFSN): CoverageDetailsError {
        let errors: CoverageDetailsError = {};
        if (!this.isSelectListPopulated(legal.County)) {
            errors.County = 'County is a required field';
        }
        if (!this.isSelectListArrayPopulated(legal.Coverage)) {
            errors.Coverage = 'Coverage is a required field';
        }
        if (!this.isSelectListArrayPopulated(legal.UnitNumber)) {
            errors.UnitNumber = 'Unit Number is a required field';
        }

        this.createdFSN = legal;
        this.createSave();

        return errors
    }

    static ManualSet(currentError: LegalDetailsError): LegalDetailsError {
        currentError.FarmNumber = 'Enter FN';
        currentError.SecTwpRng = 'or Sec/Twp/Rng';
        currentError.PlaceName = 'or Place Name';
        currentError.OtherLandId = 'or Other Id';
        return currentError;
    }

    static AddNewLegalHasNoErrors(legalErrors: LegalDetailsError, coverageErrors: CoverageDetailsError): boolean {
        if (this.isPopulated(legalErrors.FarmNumber)
            || this.isPopulated(legalErrors.TractNumber)
            || this.isPopulated(legalErrors.SecTwpRng)
            || this.isPopulated(legalErrors.PlaceName)
            || this.isPopulated(legalErrors.OtherLandId)
            || this.isPopulated(coverageErrors.County)
            || this.isPopulated(coverageErrors.Coverage)
            || this.isPopulated(coverageErrors.UnitNumber)
            || this.isPopulated(legalErrors.IsPrimary)
            || this.isPopulated(legalErrors.TotalAcres)
            || this.isPopulated(legalErrors.ReportedAcres)
        ) {
            return false;
        }
        return true;
    }

    static HasFieldsError(fields: IViewField[] | undefined): boolean {
        let hasError = false;
        fields?.forEach(field => {
            if (field.HasError) {
                hasError = true;
            }
        })
        return hasError
    }


    static AddNewShouldSave(): boolean {
        if (this.createdFSN &&
            AddNewFarmLandUtility.AddNewLegalHasNoErrors(AddNewFarmLandUtility.ValidateLegalDetails(this.createdFSN), AddNewFarmLandUtility.ValidateCoverageDetails(this.createdFSN))
        ) {
            if (this.isPopulated(this.createdFSN.FSN)
                || this.isPopulated(this.createdFSN.Tract)
                || this.isSelectListPopulated(this.createdFSN.SecTwpRng)
                || this.isPopulated(this.createdFSN.PlaceName)
                || this.isPopulated(this.createdFSN.OtherLandId)
            ) {
                return true;
            }
        }
        return false;
    }

    static stringFails(myString: string | undefined | null, limit: number): boolean {
        if (myString != undefined && myString != null && myString != '' && myString.length > limit) {
            return false;
        }
        else {
            return true;
        }
    }

    static isPopulated(myString: string | undefined | null): boolean {
        if (myString == undefined || myString == null || myString == '' || myString.trim() == '') {
            return false;
        }
        else {
            return true;
        }
    }

    static isSelectListPopulated(selectList: ISelectList | undefined | null): boolean {
        if (selectList == undefined || selectList == null || selectList?.value == '') {
            return false;
        }
        else {
            return true;
        }
    }

    static isSelectListArrayPopulated(selectList: ISelectList[] | undefined): boolean {
        if (selectList == undefined || selectList == null || selectList.length == 0) {
            return false;
        }
        else {
            return true;
        }
    }

    static hasUnsavedFields(fields: IViewField[] | undefined): boolean {
        if (fields == undefined || fields == null || fields.length == 0) {
            return false;
        }
        else {
            if(fields.some((field) => !field.IsCluInformationSnapshotMatching || !field.IsFieldDetailsSnapshotMatching || !!field.ViewId)){            
                return true;
            } else{
                return false;
            }
        }
    }

}
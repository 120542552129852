import { ActionCreator } from 'redux';
import { GetFieldsRequestAction } from './actions';

export const getFields: ActionCreator<GetFieldsRequestAction> = (action: GetFieldsRequestAction) =>
({
    type: action.type,
    api: action.api,
    reinsuranceYear: action.reinsuranceYear,
    landOID: action.landOID,
    fieldsStore: action.fieldsStore
});
import { CallEffect, PutEffect, call, put, takeEvery } from "redux-saga/effects";
import { GetSectionTownshipRequestAction, SectionTownshipActionTypes, sectionTownshipErrorAction } from "./actions";
import { GetSectionTownship } from "./api";

export function* getTest() {
    yield takeEvery(SectionTownshipActionTypes.GET_SECTION_TOWNSHIP, getSectionTownship)
}
 
function* getSectionTownship(request: GetSectionTownshipRequestAction): Generator<CallEffect<GetSectionTownshipRequestAction>> | PutEffect<any> {
    try {
        const response = yield call(GetSectionTownship, { ...request })
        const key = `${request.farmNumber ?? ''}${request.stateCode ?? ''}${request.countyCode ?? ''}${request.tract ?? ''}`
        yield put({ type: SectionTownshipActionTypes.SET_SECTION_TOWNSHIP, sectionTownshipRanges: { [key]: response.map((r: any) => { return { label: r.Label, value: r.Value } }) }})
    } catch (error) {
        yield put(sectionTownshipErrorAction(error))
    }
}
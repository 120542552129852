   
export interface Message {
    type: string;
    body: any;
}

export class MessageUtility {
    
    static sendMessage(payload: Message) {
        const parentWindow: Window = window.parent;
        if (parentWindow) {
            parentWindow.postMessage(payload, "*");
        }
    }
}


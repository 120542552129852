
import React, { useEffect, useState, KeyboardEvent, useRef } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Col, Row } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import "./EditShareholder.scss";
import { ERRORS, URLs } from '../../../commom/constant';
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import styles from "./Shareholders.module.scss";
import { ITransferOfRights } from "./viewmodels/ITransferOfRights";
import { ErrorUtility } from "../../../manager/common/errorUtility";
interface props {
    growerId: string;
    mpciPolicyId: string;
    shareholderId: number | undefined;
    shareholderName: string;
    coverageId: number | undefined;
    errorHandler: (error: string) => void;
    premiumlineId: number | undefined;
    setIsLoading: (setIsLoading: boolean) => void;
}

const MaintainTransferofRights: React.FC<props> = ({
    growerId,
    mpciPolicyId,
    shareholderId,
    coverageId,
    shareholderName,
    errorHandler,
    premiumlineId,
    setIsLoading
}) => {
    const api = useApiGateway();//Init API Gateway
    const [errorText, setErrorText] = useState("");
    const [showError, setShowError] = useState(false);
    const [torDetails, setTorDetails] = useState<ITransferOfRights>();
    const [showModal, setShowModal] = useState<boolean>(false);
    
    const inputReportedAcres = useRef<HTMLLabelElement>(null);
    const getTransferOfRigtsData = async (growerId: string, mpciPolicyId: string, shareholderId: number, coverageId: number, premiumlineId: number) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId + "/Coverages/" + coverageId + "/PremiumLines/" + premiumlineId + "/TransferOfRights/";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    }

    const putTransferOfRigtsData = async (growerId: string, mpciPolicyId: string, shareholderId: number, coverageId: number, premiumlineId: number, dataToUpdate: ITransferOfRights) => {
            const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId + "/Coverages/" + coverageId + "/PremiumLines/" + premiumlineId + "/TransferOfRights/";
            const res = await api.put(shareholderapiurl, ApplicationIdValues.Paw, dataToUpdate);
    };

    const initialValues = {
        ApprovedDate: torDetails?.ApprovedDate,
        CheckPayableType: torDetails?.CheckPayableType,
        ReportedAcres: torDetails?.ReportedAcres,
        ReportedShare: Number((Number(torDetails?.ReportedShare!) * 100).toFixed(3)),
        GrowerShareToTransfer: Number((Number(torDetails?.TransferredShare!) * 100).toFixed(3)),
        ReportedAcresToTransfer: torDetails?.TransferredAcres
    };

    useEffect(() => {
        setIsLoading(true);
        getTransferOfRigtsData(growerId, mpciPolicyId, shareholderId!, coverageId!, premiumlineId!)
            .then((data) => {
                setTorDetails(data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
                if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) { 
                    errorHandler(ERRORS.get_transferOfRights_api_failed);
                }
            });
    }, []);

    const validationSchema = yup.object().shape({
        ReportedAcers: yup
            .number(),
        ReportedAcresToTransfer: yup
            .number()
            .max(parseFloat(inputReportedAcres.current?.innerText || "0"), "Reported Acres To Transfer should be less than or equal to Reported Acres")
    });

    const formik = useFormik({
        
        initialValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            values.ReportedAcresToTransfer = String(values.ReportedAcresToTransfer) == "" ? 0 : values.ReportedAcresToTransfer!;
            values.GrowerShareToTransfer = String(values.GrowerShareToTransfer) == "" ? 0 : values.GrowerShareToTransfer!;
            if (values.GrowerShareToTransfer! > 0 && values.ReportedAcresToTransfer! > 0) {
                setShowError(true);
                setErrorText("Either Transferred Acres or Transferred Shares is Required and not both.");
            }
            else if (values.GrowerShareToTransfer! == 0 && values.ReportedAcresToTransfer! == 0) {
                setShowError(true);
                setErrorText("Either Transferred Shares or Transferred Acres is Required.");
            }
            else if (values.ReportedAcresToTransfer! < 0) {
                setShowError(true);
                setErrorText("Transferred Acres cannot be less than Zero.");
            }
            else if (values.ReportedAcresToTransfer! > values.ReportedAcres!) {
                setShowError(true);
                setErrorText("Transferred Acres cannot be more than Reported Acres.");
            }
            else {
                setShowError(false);
                setErrorText("");
                setIsLoading(true);
                let dataToUpdate: ITransferOfRights = {
                    ApprovedDate: values.ApprovedDate ?? "",
                    CheckPayableType : values.CheckPayableType ?? "",
                    ReportedAcres : values.ReportedAcres ?? 0,
                    ReportedShare: values.ReportedShare ?? 0,
                    TransferredAcres: values.ReportedAcresToTransfer! ?? 0,
                    TransferredShare: values.GrowerShareToTransfer! > 1 ? values.GrowerShareToTransfer! / 100 : values.GrowerShareToTransfer! == 1 ? 1.00 : values.GrowerShareToTransfer!
                };
                putTransferOfRigtsData(growerId, mpciPolicyId, shareholderId!, coverageId!, premiumlineId!, dataToUpdate).then((data) => {
                    setIsLoading(false);
                    setShowModal(false);
            }).catch((err) => {
                setIsLoading(false);
                if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                    errorHandler(ERRORS.update_transferOfRights_api_failed)
                }
            });;
            }
    }
    });

    const shareTextboxBlurHandler = (e: any) => {

        if (isNaN(parseFloat(e.value))) {
            e.value = "";
        }
        else {
            let value: Number = parseFloat(e.value);
            
            if (value > 1) {
                value = 1.00;
                formik.values.GrowerShareToTransfer = 100;
            }
            e.value = String(Number((Number(value) * 100).toFixed(2)));
        }
    };
    const shareTextboxFocusHandler = (e: any) => {
        let value: Number = e.value == "" ? 0 : parseFloat(e.value);
        e.value = String(Number((Number(value) / 100).toFixed(3)));
    };
    const shareTextboxKeyPressHandler = (e: KeyboardEvent) => {
        const re = /[0-9\.]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };

    return (
        <>
        <Modal
            data-testid="modal"
            show={showModal}
            onHide={() => {
                setShowModal(false);
                formik.resetForm();
                setShowError(false);
                setErrorText("");
            }}
            backdrop={false}
            dialogClassName="m-60w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="edit-modal-header" closeButton closeVariant="white">
                <Modal.Title id="contained-modal-title-vcenter">
                    Maintain Transfer of Rights Details
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="m-3" onSubmit={formik.handleSubmit} noValidate>
                    <Row className="mt-1">
                        <Form.Group controlId="Transferee" data-testid="formTransferee" className="d-flex">
                                <Form.Label className="w-50">Transferee: </Form.Label> 
                                <Form.Label data-testid="transferee"> {shareholderName}</Form.Label> 
                     </Form.Group>
                    </Row>
                    <Row className="mt-1">
                        <Form.Group controlId="ReportedAcres" data-testid="formReportedAcres" className="d-flex">
                            <Form.Label className="w-50">Reported Acres :</Form.Label>
                                <Form.Label ref={inputReportedAcres} data-testid="reportedAcres">
                                    {formik.values.ReportedAcres}
                                    
                                </Form.Label>
                        </Form.Group>
                    </Row>
                    <Row className="mt-1">
                        <Form.Group controlId="GrowerShare" data-testid="formGrowerShare" className="d-flex">
                                <Form.Label className="w-50">Grower Share :</Form.Label>
                                <Form.Label data-testid="reportedShare">
                                    {formik.values.ReportedShare || 0}{"%"}
                            </Form.Label>
                        </Form.Group>
                    </Row>
                    <Row></Row>
                    <Row className="mt-1"> <div className="w-50 mt-3"><strong>Select One :</strong></div> </Row>
                    <Row className="mt-1">
                        <Form.Group controlId="ReportedAcresToTransfer" data-testid="formReportedAcresToTransfer" className="d-flex">
                            <Col className="w-50">
                                <Form.Label >Reported Acres To Transfer :</Form.Label>
                            </Col>
                            <Col>
                                    <Form.Control
                                    value={formik.values.ReportedAcresToTransfer}
                                    maxLength={20}
                                    className="text-end w-50"
                                    isInvalid={formik.touched.ReportedAcresToTransfer && !!formik.errors.ReportedAcresToTransfer}
                                        onChange={formik.handleChange}
                                        data-testid="reportedAcresToTransfer"
                                />
                                    <Form.Control.Feedback type="invalid" data-testid="reportedAcresError">
                                    {formik.errors.ReportedAcresToTransfer || 0}
                                </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Row>
                    <Row className="mt-1">
                        <Form.Group controlId="GrowerShareToTransfer" data-testid="formGrowerShareToTransfer" className="d-flex">
                            <Col className="w-50">
                                <Form.Label>Grower Share To Transfer :</Form.Label>
                            </Col>
                            <Col className="d-flex align-items-end">
                                    <Form.Control
                                        data-testid="shareTextbox"
                                        onKeyPress={(e) => shareTextboxKeyPressHandler(e)}
                                        maxLength={5}
                                        onBlur={(e) => shareTextboxBlurHandler(e.target)}
                                        value={formik.values.GrowerShareToTransfer}
                                        onFocus={(e) => {
                                            shareTextboxFocusHandler(e.target);
                                        }}
                                        className="form-control text-end w-50"
                                        isInvalid={formik.touched.GrowerShareToTransfer && !!formik.errors.GrowerShareToTransfer}
                                        onChange={formik.handleChange}
                                    />
                                    <div className="align-self-center"> &nbsp; of {formik.values.ReportedShare || 0}%</div>
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.GrowerShareToTransfer || 0}
                                    </Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    </Row>
                    {showError && 
                            <Row className="mt-1 text-danger" data-testid="errorMessage">
                            {errorText}
                        </Row>
                    }
                    <Form.Group className="d-flex justify-content-center mt-3">
                        <Button variant="success" type="submit" data-testid="btnSubmit">Update</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
        <p
                onClick={() => {
                    setShowModal(true);
                    setIsLoading(true);
                    getTransferOfRigtsData(growerId, mpciPolicyId, shareholderId!, coverageId!, premiumlineId!)
                        .then((data) => {
                            setTorDetails(data);
                            setIsLoading(false);
                        })
                        .catch((err) => {
                            setIsLoading(false);
                            console.log(err);
                            if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) { 
                                errorHandler(ERRORS.get_transferOfRights_api_failed);
                            }
                        });
                }}
                className={`ms-xl-2 ${styles["expcol-p"]} ${styles["cursor-pointer"]} ps-1 text-center`}
                data-testid="lnkTORInfo"
        >
          {"TOR"}
            </p>
        </>
    );
};
export default MaintainTransferofRights;
import { Map, View, Feature, MapBrowserEvent } from 'ol';
import TileLayer from 'ol/layer/Tile';
import "./map.css";
import "./por.css";
import { useContext, useEffect, useRef, useState } from 'react';
import { Zoom } from 'ol/control';
import VectorLayer from 'ol/layer/Vector';
import GML2 from 'ol/format/GML2';
import Image from 'ol/layer/Image';
import { Geometry, Point, Polygon, SimpleGeometry } from 'ol/geom';
import { ImageWMS, BingMaps, Source } from 'ol/source';
import { IPORProps } from './props/iPORProps';
import { Button, Col, OverlayTrigger, Row, Tooltip, Spinner } from 'react-bootstrap';
import { InputText } from '../../../components/forms/formBase';
import { KeyboardEvent } from 'react';
import { LANDSCONSTANTS, PRFFieldType, WARNINGS } from '../../../commom/constant';
import { GlobalState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import * as PORSaga from '../../../store/pointOfReference';
import { LandsContext } from './landsContext';
import { ActionTypes as PORActionTypes, AutomatePORRequestAction, DeletePORRequestAction, GetPORRequestAction, SavePORRequestAction } from '../../../store/pointOfReference/actions';
import { useApiGateway } from '../../../components/apiGateway/useApiGateway';
import { MapUtility } from '../../../manager/lands/MapUtility';
import { IPointOfReference } from '../../../store/pointOfReference';
import { CustomAlert } from '../../../components/alert/customAlert';
import { AlertType } from '../../../components/alert/alertType';
import VectorSource from 'ol/source/Vector';
import { Pointer } from 'ol/interaction';
import { fromLonLat, transform } from 'ol/proj';
import { IMpciPremiumLinePlantedCluPointOfReference } from './viewmodels/iMpciPremiumLinePlantedCluPointOfReference';
import { Coordinate } from 'ol/coordinate';
import { isEmpty } from 'lodash';
import { FeatureLike } from 'ol/Feature';
import Layer from 'ol/layer/Layer';
import LayerRenderer from 'ol/renderer/Layer';
import { WKT } from 'ol/format';

//var _plantedCluPORs: IMpciPremiumLinePlantedCluPointOfReference[] = [];
export default (props: IPORProps) => {
    const { FarmNumber, TractNumber, ViewLandOID, MpciCoverageId, MpciPremiumLineId, GroupId, EditLock, SetDisableSelectPOR, IsApiculture } = props
    const pointOfReferenceStore = useSelector<GlobalState, IPointOfReference | undefined>((por) => por.porState.PORRequest.find((p) => p.GroupId === GroupId));
    const [pointOfReference, setPointOfReference] = useState<IPointOfReference>();

    const landsContext = useContext(LandsContext);
    const [isDismissed, setIsDismissed] = useState(true);
    const [isMapLoaded, setIsMapLoaded] = useState(false);
    const [vectorSource, setVectorSource] = useState<VectorSource | undefined>(undefined);
    const [isDisplayPORWarningMsg, setIsDisplayPORWarningMsg] = useState(false);
    const [gridID, setGridID] = useState<string | undefined>("");
    const [globalCurrentGridWkt, setGlobalCurrentGridWkt] = useState<string>("");
    const [porValidationWarningMsg, setPorValidationWarningMsg] = useState<string>();
    const [longitude, setLongitude] = useState<string>("");
    const [latitude, setLatitude] = useState<string>("");
    const [isAddCordinates, setIsAddCordinates] = useState(false);
    const [isAutomated, setIsAutomated] = useState(false);
    const [mapState, setMapState] = useState<Map | undefined>(undefined);
    const [plantedCluPORs, setPlantedCluPORs] = useState<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    const [rluPORs, setRluPORs] = useState<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    const [apiCulturePORs, setApiCulturePORs] = useState<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    const latRef = useRef<HTMLInputElement>(null);
    const [isDisableLatLon, setIsDisableLatLon] = useState(false);
    const [isLatEmpty, setIsLatEmpty] = useState(false);
    const [isLonEmpty, setIsLonEmpty] = useState(false);
    const [isDisableAutomate, setIsDisableAutomate] = useState(false);
    const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(true);
    const [feature, setFeature] = useState<Feature<Point>>();
    const [PORPins, setPORPins] = useState<Feature<Point>[]>([])

    const dispatch = useDispatch();
    const api = useApiGateway();

    const mapRef = useRef<Map | undefined>();
    mapRef.current = mapState;

    const rluPORsRef = useRef<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    rluPORsRef.current = rluPORs;

    const gridIDRef = useRef<string>();
    gridIDRef.current = gridID;

    const featureRef = useRef<Feature<Point>>();
    featureRef.current = feature;

    const gridWktRef = useRef<string | undefined>();
    gridWktRef.current = globalCurrentGridWkt;

    const vectorSourceRef = useRef<VectorSource | undefined>();
    vectorSourceRef.current = vectorSource;

    const plantedCluPORsRef = useRef<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    plantedCluPORsRef.current = plantedCluPORs;

    const apiCulturePORsRef = useRef<IMpciPremiumLinePlantedCluPointOfReference[]>([]);
    apiCulturePORsRef.current = apiCulturePORs;

    const PORPinsRef = useRef<Feature<Point>[]>([]);
    PORPinsRef.current = PORPins;

    var _cluIds: string | undefined;
    var _coordinate: number[] = [];
    var _isloadOnlyRluLayer: boolean;

    var bingLayerAerial = new TileLayer({
        visible: true,
        source: new BingMaps({
            imagerySet: 'AerialWithLabels',
            key: LANDSCONSTANTS.viewMapKey
        })
    });

    var refLayer = new Image({
        visible: true,
        source: new ImageWMS({
            url: LANDSCONSTANTS.refLayerUrl,
            params: {
                "LAYERS": "section,township,highrisknofilter,rainfall",
                "FORMAT": "image/png",
                'TRANSPARENT': 'true',
                'subcountycode': '',
                'reinsuranceYear': '2022'
            }
        })
    });

    useEffect(() => {
        getPORFromAPI();

        const nextPointOfReference: IPointOfReference = { ...pointOfReferenceStore ?? {} as IPointOfReference };
        setPointOfReference(nextPointOfReference);
        setGlobalCurrentGridWkt(nextPointOfReference?.GlobalCurrentGridWkt ?? '');

        var gridID = nextPointOfReference?.MpciPremiumLinePointOfReferences?.map(item => item.GridId).filter((value, index, self) => self.indexOf(value) === index);
        if (gridID && gridID.length > 0) {
            setGridID(gridID[0]);
        }
    }, [pointOfReferenceStore]);

    const getPORFromAPI = () => {
        if (!pointOfReferenceStore) {
            let growerId = landsContext.GrowerOid;
            let policyId = landsContext.PolicyOid;
            let action: GetPORRequestAction = {
                type: PORActionTypes.GET_POR_REQUEST,
                Api: api,
                GrowerId: growerId.toString(),
                MpciPolicyId: policyId.toString(),
                MpciCoverageId: MpciCoverageId?.toString(),
                MpciPremiumLineId: Number(MpciPremiumLineId),
                PORRequest: {
                    GroupId: Number(GroupId ?? 0),
                    IsLoading: true,
                    MpciPremiumLinePointOfReferences: [],
                    HasError: false,
                    CanAutoPRF: false
                }
            }
            dispatch(PORSaga.getPOR(action));
        }
        else {
            var pors = pointOfReferenceStore.MpciPremiumLinePointOfReferences.filter(x => (x.CluId != null && x.CluId != ""));
            var nextRLUPORS = pointOfReferenceStore.MpciPremiumLinePointOfReferences.filter(x => (x.CluId == null || x.CluId == "") && x.IsRlu == 1);
            const nextApiCulturePORs = pointOfReferenceStore.MpciPremiumLinePointOfReferences.filter(x => (!x.CluId && !x.RluId));
            setPlantedCluPORs(pors);
            setRluPORs(nextRLUPORS);
            setApiCulturePORs(nextApiCulturePORs)

            setPointOfReference(pointOfReferenceStore);
            getPointOfReferences(pointOfReferenceStore);

            if (!isMapLoaded) {
                loadFields(pointOfReferenceStore);
            }
            else {
                removeFeatureByCLU();
                setExistingPins();
            }
            if (!pointOfReferenceStore.HasError && !pointOfReferenceStore.IsLoading) {
                if (isAddCordinates) {
                    setLatitude("");
                    setLongitude("");
                    setIsAddCordinates(false);
                }
                else if (isAutomated && (pointOfReferenceStore.ErrorMsgForPOR != '' || pointOfReferenceStore.ErrorMsgForPOR != undefined)) {
                    latRef.current?.focus();
                    setLatitude("");
                    setLongitude("");
                    setIsAutomated(false);
                }
            }
            if (!pointOfReferenceStore.CanAutoPRF && !pointOfReferenceStore.HasError && !pointOfReferenceStore.IsLoading) {
                setIsDisableLatLon(!!pointOfReferenceStore.ErrorMsgForPOR && !!pointOfReferenceStore.ErrorMsgForPOR);
            }
            else {
                setIsDisableLatLon(false);
            }
            if (pors.length == 0 && nextRLUPORS.length > 0) {
                SetDisableSelectPOR(true);
                setIsDisableAutomate(true);
            }
        }

    }

    function removeFeatureByCLU() {
        PORPinsRef.current.forEach(feature => {
            let featureToDelete: Feature = MapUtility.getPorFeatureById(feature.get('CluId'), vectorSourceRef.current)!;
            if (featureToDelete != null) {
                vectorSource?.removeFeature(featureToDelete);
            }
        });
    }

    function loadFields(pointOfReference: IPointOfReference) {
        _cluIds = pointOfReference?.MpciPremiumLinePointOfReferences.map((m) => { return "'" + m.CluId + "'"; }).join(",");
        if (_cluIds) {
            loadPORMap();
        }
        else {
            setIsDismissed(true);
        }
    }

    function getPointOfReferences(pointOfReference: IPointOfReference): void {
        var pointOfReferences = pointOfReference?.MpciPremiumLinePointOfReferences.filter(m => m.GroupId == GroupId && m.Lon != null && m.Lat != null);
        if (pointOfReferences && pointOfReferences!.length > 0) {
            let nextPORPins = [ ...PORPinsRef.current ];
            for (var i = 0; i < pointOfReferences!.length; i++) {
                var feature = MapUtility.coloredSvgMarker([pointOfReferences![i].Lon, pointOfReferences![i].Lat]);
                feature = MapUtility.updateFeature(feature, pointOfReferences![i])
                nextPORPins = [ ...nextPORPins, feature ]
            }
            setPORPins(nextPORPins)

        } else {
            setPORPins([]);
        }

        var totalCluRecords = pointOfReference?.MpciPremiumLinePointOfReferences.filter(x => x.CluId != null && x.CluId != "").length;
        _isloadOnlyRluLayer = totalCluRecords == 0 ? true : false;
    }

    function setExistingPins() {
        vectorSource?.addFeatures(PORPinsRef.current);
        if (mapState && vectorSource) {
            var vector = MapUtility.addCluLayer(mapState, vectorSource);
            setVectorSource(vector);
        }
    }

    function porGrid_onMouseOver(rowId: string) {
        const row = document.getElementById(rowId);
        row!.classList.add('porGridRowHoverOver');
        let feature = MapUtility.getPorFeatureById(rowId, vectorSourceRef.current);
        feature?.setStyle(MapUtility.setFeaturePushPinStyle(2, "Map-Marker-Bubble-Chartreuse.png"));
    }

    function porGrid_onMouseOut(rowId: string) {
        resetFeaturePushPinStyle(rowId);
    }

    function resetFeaturePushPinStyle(cluId: string) {
        const row = document.getElementById(cluId);
        row!.classList.remove('porGridRowHoverOver');
        let feature = MapUtility.getPorFeatureById(cluId, vectorSourceRef.current);
        feature?.setStyle(MapUtility.setFeaturePushPinStyle(1, "Map-Marker-Bubble-Azure.png"));
    }

    function DeletePORCoordinate(id: string, premiumlineId: number, groupId: number, fieldType: PRFFieldType) {
        let growerId = landsContext.GrowerOid;
        let policyId = landsContext.PolicyOid;
        if (pointOfReferenceStore) {
            const pointOfReference = pointOfReferenceStore?.MpciPremiumLinePointOfReferences.find(m => m.CluId === id || m.RluId === id || m.Id === id);

            if (pointOfReference) {
                let action: DeletePORRequestAction = MapUtility.getDeletePORRequestAction(growerId, policyId, MpciCoverageId.toString(), premiumlineId.toString(), id, groupId, pointOfReferenceStore, api)
                dispatch(PORSaga.deletePOR(action));
                removePinsFromMap(id, fieldType);
            } else if (fieldType === PRFFieldType.APICULTURE) {
                removePinsFromMap(id, fieldType);
            } else {
                setIsDisplayPORWarningMsg(true);
                setGridID(pointOfReference!.GridId);
                setPorValidationWarningMsg(WARNINGS.landId_delete_por_validation + ' (' + gridID?.trim() + ')');
            }
        }
    }

    function removePinsFromMap(id: string, fieldType: PRFFieldType) {
        var feature = MapUtility.getPorFeatureById(id, vectorSourceRef.current);

        if (feature != null) {
            updatePointOfReferenceCoordinates(fieldType, id, null, null)
            vectorSource?.removeFeature(feature);
        }
    }

    function AutomatePOR() {
        setIsDisplayPORWarningMsg(false);

        let growerId = landsContext.GrowerOid;
        let policyId = landsContext.PolicyOid;

        if (pointOfReferenceStore) {
            setIsAutomated(true);
            let action: AutomatePORRequestAction = {
                type: PORActionTypes.AUTOMATE_POR_REQUEST,
                Api: api,
                GrowerId: growerId.toString(),
                MpciPolicyId: policyId.toString(),
                MpciCoverageId: MpciCoverageId?.toString() ?? "",
                MpciPremiumLineId: Number(MpciPremiumLineId ?? 0),
                PORRequest: {
                    GroupId: GroupId ?? 0,
                    IsLoading: true,
                    MpciPremiumLinePointOfReferences: pointOfReferenceStore?.MpciPremiumLinePointOfReferences ?? [],
                    HasError: false,
                    CanAutoPRF: pointOfReferenceStore?.CanAutoPRF
                }
            }
            dispatch(PORSaga.automatePOR(action));
        }
    }

    function requiredValidationCheck(latitude: string, longitude: string): boolean {

        if (latitude == "" || longitude == "") {
            if (latitude == "") {
                setIsLatEmpty(true);
            }
            else {
                setIsLatEmpty(false);
            }
            if (longitude == "") {
                setIsLonEmpty(true);
            }
            else {
                setIsLonEmpty(false);
            }
            return true;
        }
        else {
            setIsLatEmpty(false);
            setIsLonEmpty(false);
            return false;
        }
    }

    function savePORRequestAction(por: IPointOfReference, foundStoreCoordinates: IPointOfReference): SavePORRequestAction {
        let growerId = landsContext.GrowerOid;
        let policyId = landsContext.PolicyOid;
        let action: SavePORRequestAction = {
            type: PORActionTypes.SAVE_POR_REQUEST,
            Api: api,
            GrowerId: growerId.toString(),
            MpciPolicyId: policyId.toString(),
            MpciCoverageId: MpciCoverageId?.toString() ?? "",
            MpciPremiumLineId: Number(MpciPremiumLineId ?? 0),
            PointOfReference: por,
            PORRequest: {
                GroupId: GroupId ?? 0,
                IsLoading: true,
                MpciPremiumLinePointOfReferences: foundStoreCoordinates?.MpciPremiumLinePointOfReferences ? foundStoreCoordinates?.MpciPremiumLinePointOfReferences : [],
                HasError: false,
                CanAutoPRF: foundStoreCoordinates?.CanAutoPRF
            }
        }
        return action;
    }

    // Add Button Click
    function AddPORCoordinate(latitude: string, longitude: string, map: Map) {

        let isInValid = requiredValidationCheck(latitude, longitude);

        if (!isInValid) {
            var lat = Number(latitude);
            var lon = Number(longitude);
            var coordinates = transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
            var pixel = map!.getPixelFromCoordinate(coordinates);

            var pors = pointOfReferenceStore?.MpciPremiumLinePointOfReferences.filter(p => (p.CluId != null && p.CluId != ""));

            var waringMsg = MapUtility.validatePORFeature(coordinates, pixel, map!, pors ?? [], globalCurrentGridWkt, gridID ?? '');

            if (waringMsg != null && waringMsg.length > 0) {
                setPorValidationWarningMsg(waringMsg);
                setIsDisplayPORWarningMsg(true);
                return;
            }

            var lonlat = transform(coordinates, 'EPSG:3857', 'EPSG:4326');

            var cluFeature = MapUtility.getCluFeatures(pixel, mapRef.current);
            setIsSaveButtonDisable(false)
            if (cluFeature != null) {
                let CluId = cluFeature ? cluFeature.get("cluid")?.toString() ?? "" : "";
                if (CluId != "") {
                    if (pointOfReferenceStore) {
                        setIsAddCordinates(true);
                        let por: IPointOfReference = JSON.parse(JSON.stringify(pointOfReferenceStore));
                        por.MpciPremiumLinePointOfReferences.map(p => {
                            if (p.CluId == CluId) {
                                p.Lat = lonlat[1].toFixed(5);
                                p.Lon = lonlat[0].toFixed(5);
                                return p;
                            }
                        });
                        var cluPor = por.MpciPremiumLinePointOfReferences.filter(x => x.CluId == CluId);
                        por.MpciPremiumLinePointOfReferences = cluPor;
                        setPlantedCluPORs(por.MpciPremiumLinePointOfReferences);

                        let action: SavePORRequestAction = savePORRequestAction(por, pointOfReferenceStore);
                        dispatch(PORSaga.SavePOR(action));
                    }
                }
            }
            else {
                createNewNonCluOrRlu(coordinates, vectorSource);
            }
        }
    }

    // Mouse Click
    function AddPORCoordinateByMouseEvent(latitude: string, longitude: string) {
        var lat = Number(latitude);
        var lon = Number(longitude);
        var coordinates = transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
        var pixel = mapRef.current!.getPixelFromCoordinate(coordinates);
        var waringMsg = MapUtility.validatePORFeature(coordinates, pixel, mapRef.current!, plantedCluPORsRef.current ?? [], gridWktRef.current ?? "", gridIDRef.current ?? '', IsApiculture);
        if (waringMsg != null && waringMsg.length > 0) {
            showWarningMsg(waringMsg);
            return;
        }
        setIsSaveButtonDisable(false);
        var lonlat = transform(coordinates, 'EPSG:3857', 'EPSG:4326');
        var plantedcluPors = plantedCluPORsRef.current ? [...plantedCluPORsRef.current] : [];
        var cluFeature = MapUtility.getCluFeatures(pixel, mapRef.current);

        if (cluFeature != null) {
            const CluId = cluFeature ? cluFeature.get("cluid")?.toString() ?? "" : "";
            if (CluId != "") {
                plantedcluPors.map(p => {
                    if (p.CluId == CluId) {
                        p.Lat = lonlat[1].toFixed(5);
                        p.Lon = lonlat[0].toFixed(5);
                    }
                });
            }
            setPlantedCluPORs(plantedcluPors);
            var cluPorFeature = plantedcluPors.find(x => x.CluId == CluId);
            var feature = MapUtility.coloredSvgMarker([cluPorFeature!.Lon, cluPorFeature!.Lat]);
            feature = MapUtility.updateFeature(feature, cluPorFeature!);
            let newFeature: Feature<Point>[] = [];

            newFeature.push(feature);
            setPORPins([ ...PORPinsRef.current, feature ])

            var removeFeature = vectorSourceRef.current?.getFeatures().filter(f => f.getId() == CluId);
            vectorSourceRef.current?.removeFeature(removeFeature![0]!);
            vectorSourceRef.current?.addFeatures(newFeature);
            if (mapState && vectorSourceRef.current) {
                var vector = MapUtility.addCluLayer(mapState, vectorSourceRef.current);
                setVectorSource(vector);
            }
        } else {
            createNewNonCluOrRlu(coordinates, vectorSourceRef.current);
        }
    }

    var createNewNonCluOrRlu = (coordinate: Coordinate, vectorSource: VectorSource | undefined) => {
        var lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
        const nextPORs = IsApiculture ? [ ...apiCulturePORsRef.current ] : [ ...rluPORsRef.current ]

        var isDuplicateCoordinate = MapUtility.validatePorDuplicatedCordinate(lonlat[1].toFixed(5), lonlat[0].toFixed(5), nextPORs);
        if (isDuplicateCoordinate) {
            showWarningMsg(WARNINGS.landId_dupliate_por_warning);
            return;
        }

        let por: IMpciPremiumLinePlantedCluPointOfReference | null = {} as IMpciPremiumLinePlantedCluPointOfReference;
        if (IsApiculture) {
            por.Id = (apiCulturePORsRef.current.length + 1).toString()
            por.FieldNumber = (apiCulturePORsRef.current.length + 1).toString();
            por.GridId = gridIDRef.current ?? '';
            por.GroupId = GroupId ?? 0;
            por.FarmNumber = FarmNumber ?? '';
            por.IsRlu = 0;
            por.TractNumber = TractNumber ?? '';

            var lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
            por.Lon = "" + lonlat[0].toFixed(5);
            por.Lat = "" + lonlat[1].toFixed(5);
            const nextPORs = isEmpty(apiCulturePORsRef.current) ? [] : [...apiCulturePORsRef.current]
            setApiCulturePORs([...nextPORs, por])
        } else {
            por = MapUtility.getFirstAvailableRlu([...rluPORsRef.current]);
        }
        var nonCluFeature = MapUtility.addPorFeatureOutsideClu(coordinate, por);
        if (nonCluFeature) {
            setPORPins([ ...PORPinsRef.current, nonCluFeature ])
            vectorSource?.addFeature(nonCluFeature);

        } else {
            setPorValidationWarningMsg(WARNINGS.landId_por_validation_failed);
            setIsDisplayPORWarningMsg(true);
        }
    }

    var mapOnClickAddPorEvent = function (e: any) {
        showWarningMsg(null);
        var lonlat = transform(e.coordinate, 'EPSG:3857', 'EPSG:4326');
        AddPORCoordinateByMouseEvent(lonlat[1].toFixed(5), lonlat[0].toFixed(5));
    }

    function loadPORMap() {

        var map = new Map({
            target: 'por_' + ViewLandOID,
            controls: [new Zoom],
            layers: [bingLayerAerial, refLayer],
            view: new View({
                center: [-8908887.277395891, 5381918.072437216],
                maxZoom: 19,
                zoom: 12
            }),
        });
        map.addInteraction(dragInteraction);
        setIsMapLoaded(true);
        makePorMapDataRequest(map);
        map.on("click", mapOnClickAddPorEvent);
        setMapState(map);
    }

    function makePorMapDataRequest(map: Map) {
        fetch(MapUtility.buildMapServerUrl(), {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },

            body: new URLSearchParams({
                cluids: _cluIds!
            }),

        }).then(data => data.text()).then(data => addShapeToMap(data, map)).catch(error => handleError(error));
    }

    function handleError(error: any) {
        setIsDismissed(true);
    }

    function addShapeToMap(data: any, map: Map) {
        var newVectorSource = MapUtility.getVectorSource();
        map.addLayer(new VectorLayer({
            source: newVectorSource,
            visible: true
        }));

        map.setView(new View({
            projection: 'EPSG:3857',
            center: [0, 0],
            zoom: 12,
            maxZoom: 19,
        }));

        if (gridWktRef.current) {
            const gridFeature = new Feature({
                geometry: new WKT().readGeometry(gridWktRef.current)
            })
            newVectorSource.addFeature(gridFeature);
        }

        if (_isloadOnlyRluLayer) {
            newVectorSource.addFeatures(PORPinsRef.current);
            MapUtility.cluLayerLoaderExtendSetter(newVectorSource, PORPinsRef.current, map);
        }
        else {
            var format = new GML2();
            var features = format.readFeatures(data);
            features.forEach(feature => MapUtility.setFeatureStyle(feature));
            //Adds the shapes
            newVectorSource.addFeatures(features);
            //Adds Pin
            newVectorSource.addFeatures(PORPinsRef.current);
            MapUtility.cluLayerLoaderExtendSetter(newVectorSource, features, map);
        }

        setVectorSource(newVectorSource);
        map.getView().fit(newVectorSource.getExtent())
    }

    function formatLatLonValue(num: string, value: string): string {
        let n = Number(value);
        let newVal = "";
        var number = (num.split('.'));
        if (value == "") {
            newVal = value;
        }
        else if (number[1] && number[1].length > 4) {
            if (!isNaN(n)) {
                newVal = (Math.trunc(n * 100000) / 100000).toString();
            }
            else {
                newVal = num;
            }
        }
        else {
            newVal = value;
        }
        return newVal;
    }

    function txtLatitudeOnChange(value: string) {
        let newVal = formatLatLonValue(latitude, value);
        requiredValidationCheck(newVal, longitude);
        setLatitude(newVal);
    };

    function txtLongitudeOnChange(value: string) {
        let newVal = formatLatLonValue(longitude, value);
        requiredValidationCheck(latitude, newVal);
        setLongitude(newVal);
    };

    const inputTextKeyPressHandler = (e: KeyboardEvent) => {
        const re = /[0-9\.\-]/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };

    const showWarningMsg = (waringMsg: string | null) => {
        if (waringMsg) {
            setPorValidationWarningMsg(waringMsg);
            setIsDisplayPORWarningMsg(true);
        } else {
            setIsDisplayPORWarningMsg(false);
        }
    }

    var updatePorGridItemWithLatLon = (coordinate: number[], id: string, fieldType: PRFFieldType) => {
        var lon: string | null = null;
        var lat: string | null = null;
        var lonlat: number[];

        lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');

        if (lonlat) {
            if (lonlat.length == 2) {
                lon = lonlat[0].toFixed(5);
                lat = lonlat[1].toFixed(5);
            }
        }

        updatePointOfReferenceCoordinates(fieldType, id, lon, lat)
    }

    const updatePointOfReferenceCoordinates = (
        fieldType: PRFFieldType,
        id: string,
        longitude: string | null,
        latitude: string | null, 
    ) => {
        let nextPointOfReferences: IMpciPremiumLinePlantedCluPointOfReference[] = [];
        let setNextPointOfReferences: (references: IMpciPremiumLinePlantedCluPointOfReference[]) => void;

        if (fieldType == PRFFieldType.RLU) {
            nextPointOfReferences = rluPORsRef.current;
            setNextPointOfReferences = setRluPORs;
        } else if (fieldType === PRFFieldType.CLU) {
            nextPointOfReferences = plantedCluPORsRef.current;
            setNextPointOfReferences = setPlantedCluPORs;
        } else {
            nextPointOfReferences = apiCulturePORsRef.current;
            setNextPointOfReferences = setApiCulturePORs;
        }

        const nextPORs = [ ...nextPointOfReferences ];
        setNextPointOfReferences(nextPORs.map(m => {
            if (m.Id === id) {
                m.Lat = latitude, m.Lon = longitude
            }
            return m;
        }));
    }

    function SavePOR() {
        setIsDisplayPORWarningMsg(false);

        let growerId = landsContext.GrowerOid;
        let policyId = landsContext.PolicyOid;

        var pors = MapUtility.mergeClusWithNonClus(GroupId, plantedCluPORs, rluPORs, apiCulturePORs);

        if (plantedCluPORs) {
            var action = MapUtility.getSavePORRequestAction(growerId, policyId, MpciCoverageId.toString(), MpciPremiumLineId.toString(), GroupId, pors, api);
            dispatch(PORSaga.SavePOR(action));
            setIsSaveButtonDisable(true);
        }
    }

    const handleDownEvent = (event: MapBrowserEvent<UIEvent>): boolean => {
        var PORFeature = event.map?.forEachFeatureAtPixel(event.pixel,
            (arg0: FeatureLike, arg1: Layer<Source, LayerRenderer<any>>, arg2: SimpleGeometry) => {
                if (arg0 && arg0.get("IsPOR")) {
                    return arg0;
                }
            });

        const nextPORFeature = PORPinsRef.current.find((f) => f.getId() === PORFeature?.getId())

        if (nextPORFeature) {
            _coordinate = event.coordinate;
            setFeature(nextPORFeature);
            return true;
        }
        else {
            return false;
        }
    }

    const handleDragEvent = (event: any): void => {
        if (featureRef?.current) {
            var deltaX = event.coordinate[0] - _coordinate[0];
            var deltaY = event.coordinate[1] - _coordinate[1];

            var geometry: Geometry | undefined = featureRef?.current?.getGeometry();
            if (geometry) {
                geometry.translate(deltaX, deltaY);
            }
            _coordinate = event.coordinate;
            const id = featureRef?.current?.get("Id");
            const fieldType = featureRef?.current?.get("FieldType");
            updatePorGridItemWithLatLon(event.coordinate, id, fieldType);
        }
    }

    const handleUpEvent = (evt: any): boolean => {
        var pixel = evt.map.getPixelFromCoordinate(evt.coordinate);
        var fieldType: PRFFieldType = featureRef?.current?.get("FieldType");
        var currentGridId = gridIDRef.current ? gridIDRef.current : "";
        var crrentGridWkt = gridWktRef.current ? gridWktRef.current : "";

        if (!featureRef?.current || !mapRef.current) {
            return false;
        }

        var pors: IMpciPremiumLinePlantedCluPointOfReference[] = [];
        if (fieldType == PRFFieldType.CLU) {
            pors = plantedCluPORsRef.current ? [...plantedCluPORsRef.current] : [];
        } else if (rluPORsRef.current && rluPORsRef.current.length > 0) {
            pors = rluPORsRef.current;
        } else {
            pors = [ ...apiCulturePORsRef.current ];
        }

        var warningMsg = MapUtility.validatePorFeatureForDrag(featureRef?.current, currentGridId, evt.coordinate, pors, crrentGridWkt, mapRef.current);
        if (warningMsg) {
            showWarningMsg(warningMsg);

            var originalCoordinates = fromLonLat([parseFloat(featureRef?.current?.get("Lon")), parseFloat(featureRef?.current?.get("Lat"))]);
            _coordinate = originalCoordinates;
            var feature = featureRef?.current;
            feature.setGeometry(new Point(_coordinate, "XY"));
            setFeature(feature);
            updatePorGridItemWithLatLon(_coordinate, featureRef?.current?.get("Id"), fieldType);
        } else {
            updateCluFeatureDetailsAfterDragNDrop(pixel, evt.coordinate, fieldType);
            setIsSaveButtonDisable(false);
        }

        _coordinate = [];
        setFeature({} as Feature<Point>);
        return false;
    }

    

    function updateCluFeatureDetailsAfterDragNDrop(pixel: Array<number>, coordinate: Array<number>, fieldType: PRFFieldType) {
        if (fieldType === PRFFieldType.RLU || fieldType === PRFFieldType.APICULTURE) {
            const id = featureRef.current?.get("Id");
            updatePorGridItemWithLatLon(coordinate, id, fieldType);
        } else {
            var cluFeature = MapUtility.getCluFeatures(pixel, mapRef.current);

            if (cluFeature!.get("id") == featureRef?.current?.get("Id")) {
                updatePorGridItemWithLatLon(coordinate, featureRef?.current?.get("Id"), fieldType);
            }
            else {
                updatePorGridItemWithLatLon([], featureRef?.current?.get("CluId"), fieldType);
                updatePorGridItemWithLatLon(coordinate, cluFeature!.get("Id"), fieldType);

                var lonLat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
                var feature = featureRef?.current;
                feature?.set("Lon", lonLat[0]);
                feature?.set("Lat", lonLat[1]);
                feature?.set("CluId", cluFeature!.get("cluid"));
                feature?.setId(cluFeature!.get("cluid").toUpperCase());
                setFeature(feature);
            }
        }
    }

    var dragInteraction = new Pointer({
        handleDragEvent: handleDragEvent,
        handleDownEvent: handleDownEvent,
        handleUpEvent: handleUpEvent
    })

    return (
        <div className='p-1'>
            <Row className="porError" data-testid={`porError_` + MpciPremiumLineId}>
                <CustomAlert
                    dismissible={true}
                    onClose={() => setIsDismissed(false)}
                    id="loadErrorAlert"
                    show={isDismissed && (pointOfReference?.ErrorMsgForPOR != undefined && pointOfReference?.ErrorMsgForPOR != "")}
                    type={AlertType.Information}
                    variant="danger"
                >
                    {pointOfReference?.ErrorMsgForPOR}
                </CustomAlert>
                <CustomAlert
                    dismissible={true}
                    onClose={() => setIsDisplayPORWarningMsg(false)}
                    id="por_Warning_Msg"
                    show={isDisplayPORWarningMsg}
                    type={AlertType.Information}
                    variant="danger"
                >
                    {porValidationWarningMsg}
                </CustomAlert>
            </Row>
            <Row className='porRow'>
                <Col xs={12} sm={12} md={7} lg={8} xl={9} xxl={9}>
                    <div id={'por_' + ViewLandOID} className="map-container" />
                </Col>
                <Col xs={12} sm={12} md={5} lg={4} xl={3} xxl={3}>
                    <Row className="mt-1">
                        <Col className='pe-0 text-start col-auto porPaddingLeft ms-1'>
                            <OverlayTrigger placement='bottom' overlay={<Tooltip>Automate</Tooltip>}>
                                <Button
                                    data-testid={`btnAutomatePOR_1`}
                                    variant="outline-accent5"
                                    disabled={(pointOfReference?.CanAutoPRF == false) || EditLock || isDisableAutomate}
                                    onClick={() => AutomatePOR()}
                                >
                                    Automate
                                </Button>
                            </OverlayTrigger>
                        </Col>
                        <Col className='p-0 mb-3 ps-4 col-auto'>
                            <OverlayTrigger placement='bottom' overlay={<Tooltip>Save</Tooltip>}>
                                <Button
                                    data-testid={`btnSavePOR_1`}
                                    variant="outline-accent5"
                                    disabled={isSaveButtonDisable || EditLock}
                                    onClick={() => SavePOR()}
                                >
                                    Save
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                            {' '}
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="pe-0">
                            <InputText
                                data-testid="txtLatitude"
                                name="txtLatitude"
                                inputType="text"
                                label="Latitude"
                                onKeyPress={inputTextKeyPressHandler}
                                disabled={EditLock || isDisableLatLon}
                                value={latitude}
                                onChange={(e: any) => { txtLatitudeOnChange(e.target.value); }}
                                innerRef={latRef}
                                error={(isLatEmpty && !isDisableLatLon) ? "Enter Lat" : undefined}
                            />
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="pe-0">
                            <InputText
                                data-testid="txtLongitude"
                                name="txtLongitude"
                                inputType="text"
                                label="Longitude"
                                onKeyPress={inputTextKeyPressHandler}
                                disabled={EditLock || isDisableLatLon}
                                value={longitude}
                                className="pe-0"
                                onChange={(e: any) => txtLongitudeOnChange(e.target.value)}
                                error={(isLonEmpty && !isDisableLatLon) ? "Enter Long" : undefined}
                            />
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className=" align-self-center align-items-center mt-4">
                            <OverlayTrigger
                                placement="bottom" overlay={<Tooltip>Add manual coordinates</Tooltip>}>
                                <Button
                                    data-testid="btnAdd"
                                    variant="outline-success"
                                    type="submit"
                                    disabled={EditLock || isDisableLatLon}
                                    onClick={() => AddPORCoordinate(latitude, longitude, mapState!)}
                                >
                                    <i className="bi bi-plus-square"></i>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <div className="porCoordinatesDiv mt-3">
                        {(!pointOfReference || pointOfReference?.IsLoading) ?
                            <Spinner className="spinner" animation={"border"} />
                            : 
                            <>
                                {
                                    [...plantedCluPORs, ...rluPORs, ...apiCulturePORs].filter(p => p.Lon != null && p.Lat != null).map((p) => {
                                        const PORId = p.CluId || p.RluId || p.Id;
                                        return (
                                            <Row className={'d-flex align-items-center ps-1 pt-1 pb-1'}
                                                key={PORId} id={PORId} onMouseOver={() => porGrid_onMouseOver(PORId)} onMouseOut={() => porGrid_onMouseOut(PORId)}>
                                                <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
                                                    <label id={`${PORId}.field`} data-testid={`${PORId}.field`} >{p.FieldNumber}</label>
                                                </Col>
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className='ps-3'>
                                                    <label id={`${PORId}.lat`} data-testid={`${PORId}.lat`} >{`${p.CluId ? '' : '*'}`}{p.Lat}</label>
                                                </Col>
                                                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className='ps-2'>
                                                    <label id={`${PORId}.lon`} data-testid={`${PORId}.lon`} >{`${p.CluId ? '' : '*'}`}{p.Lon}</label>
                                                </Col>
                                                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} >
                                                    <OverlayTrigger placement='left' overlay={<Tooltip>{LANDSCONSTANTS.deletePORToolTip}</Tooltip>}>
                                                        <Button
                                                            data-testid={`btnCordinate_` + PORId}
                                                            variant="outline-accent5"
                                                            onClick={() => DeletePORCoordinate(PORId, Number(MpciPremiumLineId ?? 0), GroupId ?? 0, !!p.CluId ? PRFFieldType.CLU : !!p.RluId ? PRFFieldType.RLU : PRFFieldType.APICULTURE)}
                                                            disabled={EditLock}
                                                        >
                                                            <i className='bi bi-trash-fill' data-testid={`deleteCoordinateIcon_` + PORId}></i>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </>
                        }
                    </div>
                </Col>
            </Row>
        </div>
    );
}
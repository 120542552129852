import {
  Button as RBButton,
  ButtonProps as RBButtonProps,
  Spinner,
} from 'react-bootstrap';

export interface ButtonProps extends RBButtonProps {
  label: string;
  loading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({ label, loading, ...props }) => {
  return (
    <RBButton {...props}>
      {loading ? (
        <Spinner as='span' animation='border' size='sm' variant='white' />
      ) : (
        label
      )}
    </RBButton>
  );
};

import { Reducer } from 'redux';
import * as PremiumLineInformationSaga from './';
import { ActionTypes, DeletePremiumLineAcres, PremiumLineInformationActions, PutPremiumLineInformation, UpdatePremiumLineAcres, UpdatePremiumLineInformation, UpdatePremiumLineResults } from './actions';

const initialState: PremiumLineInformationSaga.PremiumLineInformation = { PremiumLines: [] };

const reducer: Reducer<PremiumLineInformationSaga.PremiumLineInformation, PremiumLineInformationActions> =
    (state: PremiumLineInformationSaga.PremiumLineInformation = initialState, action: PremiumLineInformationActions): PremiumLineInformationSaga.PremiumLineInformation => {
        switch (action.type) {
            case ActionTypes.PUT_PREMIUMLINEINFORMATION:
                let putAction = action as PutPremiumLineInformation
                let newPremiumLine: PremiumLineInformationSaga.PremiumLineDetails = {
                    PremiumLineOid: putAction.PremuimLineOid,
                    TotalAcres: putAction.ReportedUnitAcres,
                    PlantDate: putAction.PlantDate,
                    AcreageTypeCode: putAction.AcreageTypeCode,
                    AcreageTypeDescription: putAction.AcreageTypeDescription,
                    GuaranteeReductionFlag: putAction.GuaranteeReductionFlag,
                    UserCanEditPremiumLine: putAction.UserCanEditPremiumLine,
                    HasClaim: putAction.HasClaim,
                    Croptions: putAction.Croptions,
                    ReportedAcres: 0,
                    Acres: []
                }
                let findPremiumLine = state.PremiumLines.findIndex(prem =>
                    prem.PremiumLineOid == putAction.PremuimLineOid
                )
                if (findPremiumLine > -1) {
                    state.PremiumLines[findPremiumLine] = newPremiumLine
                }
                else {
                    state.PremiumLines.push(newPremiumLine)
                }
                return { ...state }
            case ActionTypes.UPDATE_PREMIUMLINEACRES:
                let newAction = action as UpdatePremiumLineAcres
                let foundPremiumLine = state.PremiumLines.find(prem =>
                    prem.PremiumLineOid == newAction.PremuimLineOid
                )
                if (foundPremiumLine) {
                    let acreReported = foundPremiumLine.Acres.findIndex(acreInformation =>
                        acreInformation.LandOid == newAction.LandOid &&
                        acreInformation.Oid == newAction.Oid
                    )
                    if (acreReported != -1) {
                        foundPremiumLine.Acres.splice(acreReported, 1)
                    }
                    foundPremiumLine.Acres.push({
                        PremiumLineOid: newAction.PremuimLineOid,
                        LandOid: newAction.LandOid,
                        Oid: newAction.Oid,
                        Acres: newAction.ReportedUnitAcres
                    })
                }
                return { ...state }
            case ActionTypes.UPDATE_PREMIUMLINEINFORMATION:
                let updatePremiumLineInformationAction = action as UpdatePremiumLineInformation
                let foundUpdatePremiumLine = state.PremiumLines.findIndex(prem =>
                    prem.PremiumLineOid == updatePremiumLineInformationAction.PremuimLineOid
                )
                if (foundUpdatePremiumLine > -1) {
                    state.PremiumLines[foundUpdatePremiumLine].TotalAcres = updatePremiumLineInformationAction.ReportedUnitAcres
                }
                return { ...state }
            case ActionTypes.DELETE_PREMIUMLINEACRES:
                let deleteAction = action as DeletePremiumLineAcres
                let foundPremiumLineForDelete = state.PremiumLines.find(prem =>
                    prem.PremiumLineOid == deleteAction.PremuimLineOid
                )
                if (foundPremiumLineForDelete) {
                    foundPremiumLineForDelete.Acres = foundPremiumLineForDelete.Acres.filter(acre => acre.LandOid != deleteAction.LandOid)
                }
                return { ...state }
            case ActionTypes.UPDATE_PREMIUMLINERESULTS:
                let updateResultsAction = action as UpdatePremiumLineResults
                return { ...state, PremiumLineUpdates: updateResultsAction.Results }
            case ActionTypes.CLEAR_PREMIUMLINERESULTS:
                return { ...state, PremiumLineUpdates: [] }
        }
        return state;
    };

export default reducer;
import { ISelectList } from 'components/forms/iSelectList';
import { first, isEmpty } from 'lodash';
import { BaseSyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { Col, Collapse, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { FilterState } from 'store/filter';
import { setLandsCoverageRequest } from 'store/land/actions';
import { ERRORS, FSNValidation, LANDSCONSTANTS, WARNINGS } from '../../../commom/constant';
import { AlertType } from '../../../components/alert/alertType';
import { CustomAlert } from '../../../components/alert/customAlert';
import { useApiGateway } from "../../../components/apiGateway/useApiGateway";
import { FormButton, InputSwitch, InputText, SelectForm } from "../../../components/forms/formBase";
import { AddNewFarmLandUtility } from '../../../manager/lands/AddNewFarmLandUtility';
import { validateFarmandTractNumber, validateFarmNumber, validateFarmNumberSectionTownshipRange, validateFarmTractAndSection, validateSectionTownshipRange } from "../../../manager/lands/api";
import { PolicyUtility } from '../../../manager/lands/policyUtility';
import { SaveFarmLandUtility } from '../../../manager/lands/SaveFarmLandUtility';
import { GlobalState } from '../../../store';
import * as FieldsSaga from '../../../store/field';
import * as LandSaga from '../../../store/land';
import * as PremiumLineInformationSage from '../../../store/premiumLineInformation';
import { useViewFields } from './customHooks/useViewField';
import { LandsContext } from './landsContext';
import Map from './map';
import POR from './por';
import { SectionTownshipSelect } from './sectionTownshipSelect';
import ViewCompanionLink from './viewCompanionLink';
import styles from './viewFarmLands.module.scss';
import ViewFields from './viewFields';
import { IFarmLand } from './viewmodels/iFarmLand';
import { IValidateLandId } from './viewmodels/iValidateLandId';
import { IViewField } from './viewmodels/iViewField';
import { IViewLandErrors } from './viewmodels/IViewLandErrors';
import { IViewLand } from './viewmodels/iViewLands';

function ViewFarmLand({ CoverageAndPremiumLines, ShowUnitNumber, ViewFSN, ViewAllFields, updateLands, EditLock, CollapseAll, index }: IFarmLand) {
    //Selectors
    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);
    const filterState = useSelector<GlobalState, FilterState>((state) => state.filterState);
    const shouldSave = useSelector<GlobalState, boolean>((state) => !!state?.landState?.ShouldSaveCoverage);
    const selectFieldsStore = useSelector<GlobalState, FieldsSaga.FieldsState>((fields) => fields.fieldsState);

    //Defaults
    const defaultIcon: string = "bi bi-check-circle";
    const validIcon: string = "bi bi-check-circle-fill";
    const inValidIcon: string = "bi bi-x-circle-fill";
    const greenOutline: string = "outline-accent5";
    const redOutline: string = "outline-danger";
    const landsContext = useContext(LandsContext);
    const dispatch = useDispatch();
    const api = useApiGateway();

    //State
    const [strErrorIsVisible, setStrErrorIsVisible] = useState(false);
    const [showFields, setShowFields] = useState(false);
    const [isDismissed, setIsDismissed] = useState(false);
    const [viewMap, setViewMap] = useState(false);
    const [canViewMap, setCanViewMap] = useState(false);
    const [isDisableViewFieldsBtn, setIsDisableViewFieldsBtn] = useState(false);
    const [viewLandErrors, setViewLandErrors] = useState({} as IViewLandErrors);
    const [showCompanionLinkage, setShowCompanionLinkage] = useState(false);
    const [validateIcon, setValidateIcon] = useState<string>(!!ViewFSN.IsValid ? validIcon : inValidIcon);
    const [variantClass, setVariantClass] = useState<string>(!ViewFSN.IsValid ? redOutline : greenOutline);
    const [tooltipMessage, setTooltipMessage] = useState<string>(FSNValidation.defaultTooltip);
    const [hasViewFieldChange, setHasViewFieldChange] = useState(false);
    const farmRef = useRef<HTMLInputElement>(null);
    const tractRef = useRef<HTMLInputElement>(null);
    const [viewPOR, setViewPOR] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [disableFarmLand, setDisableFarmLand] = useState(false);
    const [fieldsStore, setFieldsStore] = useState<FieldsSaga.IFieldsStore>({} as FieldsSaga.IFieldsStore);
    const [unitNumbers, setUnitNumbers] = useState<ISelectList[]>(CoverageAndPremiumLines.MpciPremiumLines);
    const [coveragesOptions, setCoveragesOptions] = useState<ISelectList[]>(CoverageAndPremiumLines.MpciCoverages);
    const [disableSelectPOR, setDisableSelectPOR] = useState(false);

    //CustomHooks
    const { viewFields, resetViewFields, clearViewFields, addField, updateField } = useViewFields([] as IViewField[]);
    const isApiculture = PolicyUtility.getSelectedCoverage(landsContext.Coverages, Number(ViewFSN.Coverage?.value ?? 0).toString())?.CommodityCode === '1191';

    useEffect(() => {
        if (shouldSave) {
            if (hasViewFieldChange) {
                dispatch(setLandsCoverageRequest({ ...ViewFSN, Fields: viewFields.filter((f) => f.IsChecked) }))
            }
            else if (!ViewFSN.IsSnapshotMatching) {
                dispatch(setLandsCoverageRequest({ ...ViewFSN, Fields: undefined }))
            } else {
                dispatch(setLandsCoverageRequest({}))
            }
        }
    }, [shouldSave])

    useEffect(() => {
        if (ViewAllFields) {
            if (!showFields) {
                showOrHideFields();
            }
        } else {
            setShowFields(false);
        }
    }, [ViewAllFields]);

    useEffect(() => {
        if (CollapseAll) {
            setViewMap(false);
            setViewPOR(false);
            setShowFields(false)
        }
    }, [CollapseAll])

    useEffect(() => {
        if (fieldsStore.Fields) {
            resetViewFields([...fieldsStore.Fields])
        }

    }, [fieldsStore.Fields, filterState])

    useEffect(() => {
        getFieldsFromStore();
        setViewMapDetails();
    }, [selectFieldsStore]);

    useEffect(() => {
        let fieldsRequest = selectFieldsStore?.FieldsStore?.find(land => land.LandOID == ViewFSN.OID)

        if (showFields && fieldsRequest?.HasError) {
            let erroredfieldRequest = PolicyUtility.getAllFieldsRequestByPremiumLineOid(selectFieldsStore.FieldsStore, api, landsContext.ReinsuranceYear, ViewFSN.MpciPremiumLineOID)
            dispatch(FieldsSaga.getFields(erroredfieldRequest[0]))
        }
        setDisableFarmLand(showFields || EditLock)
    }, [showFields, EditLock]);

    const setViewMapDetails = () => {
        let fieldsRequest = selectFieldsStore?.FieldsStore?.find(land => land.LandOID === ViewFSN.OID)
        if (ViewFSN.CompanionFlag != 'C') {
            if (fieldsRequest && fieldsRequest.Fields && fieldsRequest.IsLoading == false) {
                let fields = fieldsRequest.Fields.filter(c => c.IsRlu == false);
                if (fields && fields.length > 0)
                    setCanViewMap(true);
                else
                    setCanViewMap(false);
            }
            else
                setCanViewMap(false);
        }
    }

    useEffect(() => {
        assignDefaultUnitNumbers(Number(ViewFSN.Coverage?.value));
        assignDefaultCoverages();
        const hasErrors = !isEmpty(viewLandErrors)

        if (hasErrors !== ViewFSN.HasError) {
            updateLands({ ...ViewFSN, HasError: hasErrors })
        }
    }, [ViewFSN]);

    useEffect(() => {
        setHasViewFieldChange(viewFields.some((f) => f.ViewId && f.IsChecked || (!f.ViewId && (!f.IsCluInformationSnapshotMatching || !f.IsFieldDetailsSnapshotMatching))))
    }, [viewFields]);

    useEffect(() => {
        if ((ViewFSN.FarmNumber == null || ViewFSN.FarmNumber.trim() == "") &&
            (ViewFSN.PlaceName == null || ViewFSN.PlaceName?.trim() == "") &&
            (ViewFSN.OtherId == null || ViewFSN.OtherId?.trim() == "") &&
            !AddNewFarmLandUtility.isSelectListPopulated(ViewFSN.Section)) {
            setIsDisableViewFieldsBtn(true);
        }
        else {
            setIsDisableViewFieldsBtn(false);
        }

    }, [ViewFSN.FarmNumber, ViewFSN.PlaceName, ViewFSN.Section, ViewFSN.OtherId]);

    useEffect(() => {
        displayMap();
    }, [canViewMap, viewMap]);

    useEffect(() => {
        ValidateMe(ViewFSN);
    }, [
        ViewFSN.FarmNumber,
        ViewFSN.TractNumber,
        ViewFSN.Section,
        ViewFSN.PlaceName,
        ViewFSN.OtherId
    ])
    function displayMap() {
        if (viewMap) {

            var por = document.getElementById('por_' + ViewFSN.OID);
            var viewMapElement = document.getElementById('map_' + ViewFSN.OID);
            var NoMapelement = document.getElementById('mapMsg_' + ViewFSN.OID);
            var spinner = document.getElementById('spinner_' + ViewFSN.OID);
            if (por != null) {
                por.scrollIntoView({ behavior: "smooth" });
            }
            else if (spinner != null) {
                spinner.scrollIntoView({ behavior: "smooth" });
            }
            else if (NoMapelement != null) {
                NoMapelement.scrollIntoView({ behavior: "smooth" });
            }
            else if (viewMapElement != null) {
                viewMapElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    }

    const getFieldsFromStore = () => {
        let fieldsRequest = selectFieldsStore?.FieldsStore?.find(land => land.LandOID == ViewFSN.OID)
        if ((fieldsRequest?.Fields)) {
            setFieldsStore(fieldsRequest);

            if (fieldsRequest.HasError) {
                setShowFields(false)
            }
        } else {
            fieldsRequest = {
                PremiumLineOID: ViewFSN.OID,
                IsLoading: true,
                LandOID: ViewFSN.OID,
                Fields: [],
                HasError: false,
                PreLoad: true
            };
            setFieldsStore(fieldsRequest);
        }
    }

    function showOrHideFields() {
        if (!isDisableViewFieldsBtn) {
            setShowFields(!showFields);
            setIsDismissed(true)
        }
    }

    function showOrHideCompanionLinkage() {
        setShowCompanionLinkage(!showCompanionLinkage);
    }

    function CloseFields() {
        setShowFields(false);
        clearViewFields(viewFields);
    }

    function CloseCompanionLinkage() {
        setShowCompanionLinkage(false);
    }

    function handleFNChange(e: BaseSyntheticEvent) {
        const nextLand = { ...ViewFSN, FarmNumber: e?.target?.value }
        updateLands(nextLand)
        setDefaultIcon();
    }

    function handlePlaceNameChange(e: BaseSyntheticEvent) {
        const nextLand = { ...ViewFSN, PlaceName: e?.target?.value }
        updateLands(nextLand)
    }

    function handleOtherIdChange(e: BaseSyntheticEvent) {
        const nextLand = { ...ViewFSN, OtherId: e?.target?.value };
        updateLands(nextLand)
    }

    function handleTractChange(e: BaseSyntheticEvent) {
        const nextLand = { ...ViewFSN, TractNumber: e?.target?.value }
        updateLands(nextLand)
        setDefaultIcon();
    }

    function handleUnitChange(e: BaseSyntheticEvent) {
        const nextLand = { ...ViewFSN, UnitNumber: { label: e?.target?.value, value: e?.target?.value } };
        updateLands(nextLand);
    }

    function ValidateMe(land: IViewLand) {
        setViewLandErrors(SaveFarmLandUtility.validateViewLand(land))
    }

    const OnhandleMapClick = () => {
        setViewMap((current: boolean) => !current);

        if ((ViewFSN.IsPRF || isApiculture) && ViewFSN.CompanionFlag != 'C') {
            setViewPOR((current: boolean) => !current);
        }
    }

    function setDismiss() {
        setIsDismissed(false)
        setShowFields(false)
        ValidateMe(ViewFSN)
    }

    function setDismissSTRError() {
        setStrErrorIsVisible(false)
    }

    function assignDefaultCoverages() {
        let covergesOids = landsContext.Coverages?.filter(x => x.CountyCode == ViewFSN.County?.value).map(c => c.OID);
        let filteredCoverages = CoverageAndPremiumLines.MpciCoverages.filter(c => covergesOids?.includes(Number(c.value)));
        setCoveragesOptions(filteredCoverages);
    }

    function handleChangeEventForCoverage(data: BaseSyntheticEvent) {
        const coverageId = Number(data.target.value)
        const selectedCoverage = coveragesOptions.find((c) => c.value === coverageId.toString())
        const filteredUnits = getFilteredUnits(coverageId);
        const nextLand = { ...ViewFSN, Coverage: selectedCoverage, UnitNumber: first(filteredUnits) };

        updateLands(nextLand);
        assignDefaultUnitNumbers(coverageId);
    }

    const getFilteredUnits = (coverageID: number) => {
        let premiumLineOids = landsContext.Coverages?.filter(x => x.OID == coverageID)?.flatMap(x => x.MpciPremiumLines).map(opt => opt.OID.toString());
        return CoverageAndPremiumLines.MpciPremiumLines.filter(c => premiumLineOids?.includes(c.value));
    }

    function assignDefaultUnitNumbers(coverageID: number) {
        const filteredUnits = getFilteredUnits(coverageID)
        setUnitNumbers(filteredUnits);
    }

    function setStrIsCreatedFunction(e?: any) {
        const nextLand = { ...ViewFSN, Section: e };
        updateLands(nextLand)
        setDefaultIcon();

    }

    const onViewPORClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setViewPOR((current: boolean) => !current);
    }

    const setDefaultIcon = () => {
        setVariantClass(greenOutline);
        setValidateIcon(defaultIcon);
        setTooltipMessage(FSNValidation.defaultTooltip);
    }

    const setErrorIcon = () => {
        setVariantClass(redOutline)
        setValidateIcon(inValidIcon);
        setValidateIcon(inValidIcon);
    }

    const setValidatedIcon = () => {
        setVariantClass(greenOutline);
        setValidateIcon(validIcon);
    }

    const validateFields = async (isNotNullOrEmptyFSN: boolean, isNotNullOrEmptyTract: boolean, isNotNullOrEmptySecTwpRng: boolean, section: string): Promise<IValidateLandId | undefined> => {
        const stateCode = landsContext.StateCode;
        const countyCode = String(ViewFSN.County?.value);
        const fsn = ViewFSN.FarmNumber;
        const tract = ViewFSN.TractNumber;

        if (isNotNullOrEmptyFSN && isNotNullOrEmptyTract && section != null) {
            return await validateFarmTractAndSection(stateCode, countyCode, fsn, tract, section, api);
        }
        else if (isNotNullOrEmptyFSN && isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {

            return await validateFarmandTractNumber(stateCode, countyCode, fsn, tract, api);
        }
        else if (isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {

            return await validateFarmNumber(stateCode, countyCode, fsn, api);
        }
        else if (!isNotNullOrEmptyTract && isNotNullOrEmptyFSN && section != null) {
            return await validateFarmNumberSectionTownshipRange(stateCode, countyCode, fsn, section, api);
        }
        else if (!isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && section != null) {
            return await validateSectionTownshipRange(stateCode, countyCode, section, api);
        }
    }

    const formatSectionValue = (section: string) => {
        let str = null;
        if (AddNewFarmLandUtility.STRRegex.test(section)) {
            str = section.replace(/\(|\)/g, '').replace(/\s+/g, '-');
        }
        return str;
    }

    const handleOnValidateClick = async (e: Event) => {
        e.preventDefault();
        await validateFarmland();
    }

    const validateFarmland = async () => {
        let result: IValidateLandId | undefined = undefined;

        let isNotNullOrEmptyFSN = AddNewFarmLandUtility.isPopulated(ViewFSN.FarmNumber);
        let isNotNullOrEmptyTract = AddNewFarmLandUtility.isPopulated(ViewFSN.TractNumber);
        let isNotNullOrEmptySecTwpRng = AddNewFarmLandUtility.isSelectListPopulated(ViewFSN.Section);

        let section = isNotNullOrEmptySecTwpRng ? formatSectionValue(ViewFSN.Section!.label.toString()) : null;

        // If SecTwnRng in incorrect format
        if (isNotNullOrEmptySecTwpRng && section == null) {
            setTooltipMessage(FSNValidation.strTooltipError);
            setErrorIcon();
            return;
        }

        if (!isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {
            setTooltipMessage(FSNValidation.fnTooltipError);
            setErrorIcon();
        }
        else {
            result = await validateFields(isNotNullOrEmptyFSN, isNotNullOrEmptyTract, isNotNullOrEmptySecTwpRng, section!);
            if (result) {
                if (result.IsValidLandId) {
                    setValidatedIcon();
                }
                else {
                    setErrorIcon();
                }
                setTooltipMessage(result.ErrorMessageInfo[0]);
            }
            else {
                setDefaultIcon();
            }
        }
    }

    function deleteLandId() {
        const growerId = landsContext.GrowerOid;
        const policyId = landsContext.PolicyOid;
        const coverageId = ViewFSN.Coverage?.value;
        const premiumLineId = Number(ViewFSN.UnitNumber?.value!);
        const premiumLineLandId = ViewFSN.OID;

        dispatch(LandSaga.deleteLands(api, growerId, policyId, coverageId, premiumLineId, premiumLineLandId, landState.Lands))
        dispatch(PremiumLineInformationSage.deletePremiumLineTotalAcres(premiumLineId, premiumLineLandId));
        setWarningAlert(false);

        if (PolicyUtility.isPRF(landsContext.Coverages?.find(cove => coverageId ? Number(coverageId) : 0 == cove.OID))) {
            CoverageAndPremiumLines.refetch()
        }
    }

    function hasAttachedFields() {
        var hasAttachedFields = false
        if (viewFields.length > 0) {
            viewFields.forEach(field => { if (field.IsChecked) { hasAttachedFields = true; } })
        }
        return hasAttachedFields;
    }

    function getViewFieldsIcon() {
        if (fieldsStore.IsLoading)
            return 'bi bi-cloud-download-fill'
        else
            return `bi ${showFields ? 'bi-chevron-up' : hasAttachedFields() ? 'bi-chevron-double-down' : 'bi-chevron-down'}`
    }

    function getViewFieldsLabel() {
        if (fieldsStore.IsLoading)
            return 'Loading...'
        else
            return isDisableViewFieldsBtn ? LANDSCONSTANTS.showFieldsToolTipForCoverageSection : showFields ? 'Hide Fields' : hasAttachedFields() ? 'View Fields' : 'Add Fields'
    }
    
    const toggleIsPrimary = () => {
        if (!ViewFSN.IsPrimary) {
            updateLands({ ...ViewFSN, IsPrimary: true }, Number(ViewFSN.UnitNumber?.value))
        }
    }

    return (
        <>
            <Row className={`${styles.viewFarmLandsError}`} data-testid={`viewLoadingError_` + ViewFSN.OID}>
                <CustomAlert
                    dismissible={true}
                    onClose={setDismiss}
                    id="loadErrorAlert"
                    show={isDismissed && fieldsStore.HasError}
                    type={AlertType.Information}
                    variant="danger"
                >
                    {ERRORS.landId_get_fields_api_failed}
                </CustomAlert>
                <CustomAlert
                    dismissible={true}
                    onClose={setDismissSTRError}
                    id="strloadErrorAlert"
                    show={strErrorIsVisible}
                    type={AlertType.Information}
                    variant="danger"
                >
                    {ERRORS.landId_get_str_api_failed}
                </CustomAlert>
                <CustomAlert
                    id={"deleteLandId_" + ViewFSN.OID}
                    show={warningAlert}
                    type={AlertType.Warning}
                    variant="warning"
                    onConfirm={() => deleteLandId()}
                    onClose={() => setWarningAlert(false)}
                >
                    {WARNINGS.delete_lands}
                </CustomAlert>
            </Row>
            <Row className={EditLock ? `${styles.viewFarmLandLocked}` : `${styles.viewFarmLand}`}>
                <Col lg={12} className={`${!ViewFSN.IsSnapshotMatching || hasViewFieldChange ? 'change-indicator' : ''} hoverBackground ${index % 2 ? 'alternatingBackground' : ''}`}>
                    {ShowUnitNumber &&
                        <Row className='mt-1'>
                            {/* County */}
                            <Col xs='12' sm='6' md='6' lg='2' xl='2' xxl='2' className={`align-top p-1`}>
                                <SelectForm
                                    name={`viewCountyDiv_${ViewFSN.OID}`}
                                    label={"County"}
                                    isDisabled={true}
                                    options={CoverageAndPremiumLines.Counties}
                                    required
                                    value={ViewFSN.County?.value.trim() ?? ""}
                                />
                            </Col>
                            {/* Coverage */}
                            <Col xs='12' sm='6' md='6' lg='3' xl='3' xxl='3' className={`align-top p-1`}>
                                <SelectForm
                                    name={`viewCoverageDiv_${ViewFSN.OID}`}
                                    label={"Coverage"}
                                    isDisabled={disableFarmLand}
                                    onChange={handleChangeEventForCoverage}
                                    options={coveragesOptions}
                                    required
                                    value={ViewFSN.Coverage?.value.trim() ?? ""}
                                />
                            </Col>
                            {/* Unit Number */}
                            <Col xs='12' sm='12' md='12' lg='7' xl='7' xxl='7' className={`align-top p-1`}>
                                <SelectForm
                                    name={`viewUnitNumberDiv_${ViewFSN.OID}`}
                                    label={"Unit Number"}
                                    isDisabled={disableFarmLand}
                                    options={unitNumbers.filter(unitNumber => !unitNumber.optionDisabled)}
                                    required
                                    value={ViewFSN.UnitNumber?.value.trim() ?? ""}
                                    onChange={handleUnitChange}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        {/* FN */}
                        <Col xs='3' sm='3' md='2' lg='1' xl='1' xxl='1' className={`align-top mt-1 mb-1 p-1`}>
                            <InputText
                                inputType="text"
                                name={`ViewFSN[${ViewFSN.OID}].FarmNumber`}
                                floatLabel={true}
                                onChange={handleFNChange}
                                value={ViewFSN?.FarmNumber?.trim() ?? ""}
                                disabled={disableFarmLand}
                                error={viewLandErrors.FarmNumber}
                                label={"FN"}
                                innerRef={farmRef}
                            />
                        </Col>
                        {/* Tract */}
                        <Col xs='3' sm='3' md='2' lg='1' xl='1' xxl='1' className={`align-top mt-1 mb-1 p-1`}>
                            <InputText
                                inputType="text"
                                name={`ViewFSN[${ViewFSN.OID}].TractNumber`}
                                floatLabel={true}
                                onChange={handleTractChange}
                                disabled={disableFarmLand}
                                error={viewLandErrors.TractNumber}
                                label={"Tract"}
                                innerRef={tractRef}
                                value={ViewFSN?.TractNumber?.trim() ?? ''}
                            />
                        </Col>
                        {/* Sec/Twp/Rng */}
                        <Col xs='6' sm='6' md='4' lg='2' xl='3' xxl='2' className={`align-top mt-1 mb-1 p-1`}>
                            <div data-testid={`viewSectionDiv_` + ViewFSN.OID} >
                                <SectionTownshipSelect
                                    stateCode={landsContext.StateCode.toString()}
                                    countyCode={ViewFSN?.County?.value ?? ''}
                                    farmNumber={ViewFSN.FarmNumber}
                                    tract={ViewFSN.TractNumber}
                                    onChange={setStrIsCreatedFunction}
                                    value={ViewFSN.Section}
                                    isDisabled={disableFarmLand}
                                    error={viewLandErrors.Section}
                                />
                            </div>
                        </Col>
                        {/* Place Name */}
                        <Col xs='6' sm='6' md='4' lg='2' xl='2' xxl='2' className={`align-top mt-1 mb-1 p-1`}>
                            <InputText
                                inputType="text"
                                name={`ViewFSN[${ViewFSN.OID}].PlaceName`}
                                floatLabel={true}
                                onChange={handlePlaceNameChange}
                                value={ViewFSN?.PlaceName ?? ""}
                                disabled={disableFarmLand}
                                error={viewLandErrors.PlaceName}
                                label={"Place Name"}
                            />
                        </Col>
                        {/* Other Id */}
                        <Col xs='6' sm='6' md='4' lg='1' xl='1' xxl='2' className={`align-top mt-1 mb-1 p-1`}>
                            <InputText
                                inputType="text"
                                name={`ViewFSN[${ViewFSN.OID}].OtherLandId`}
                                floatLabel={true}
                                onChange={handleOtherIdChange}
                                value={ViewFSN?.OtherId ?? ""}
                                disabled={disableFarmLand}
                                error={viewLandErrors.OtherId}
                                label={"Other Id"}
                            />
                        </Col>
                        {/* Is Primary Slider */}
                        <Col xs='3' sm='2' md='2' lg='1' xl='1' xxl='1' className={`align-top mt-1 mb-1 p-1`}>
                            <Col xs='2' sm='2' md='2' lg='2' >
                                <InputSwitch
                                    name={`ViewFSN[${ViewFSN.OID}].IsPrimary`}
                                    checked={!!ViewFSN.IsPrimary}
                                    label='Primary'
                                    disabled={EditLock}
                                    onChange={toggleIsPrimary}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            toggleIsPrimary()
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                        {/* Buttons */}
                        <Col xs='9' sm='10' md='6' lg='4' xl='3' xxl='3' className={`align-self-center align-items-center mt-2 mb-1`}>
                            <Row>
                                <Col xs='2' sm='2' md='2' lg='2' xl='2' xxl='2'>
                                    <FormButton
                                        name={`btnViewFields_` + ViewFSN.OID}
                                        icon={getViewFieldsIcon()}
                                        isLoading={fieldsStore.IsLoading}
                                        variant="outline-accent5"
                                        toolTip={getViewFieldsLabel()}
                                        disabled={isDisableViewFieldsBtn}
                                        onClick={showOrHideFields}
                                    />
                                </Col>
                                <Col xs='2' sm='2' md='2' lg='2' xl='2' xxl='2'>
                                    <FormButton
                                        name={`btnValidateLandId_` + ViewFSN.OID}
                                        icon={validateIcon}
                                        variant={variantClass}
                                        toolTip={tooltipMessage}
                                        onClick={handleOnValidateClick}
                                        disabled={ViewFSN.IsCancelledCoverage || EditLock}
                                    />
                                </Col>
                                <Col xs='2' sm='2' md='2' lg='2' xl='2' xxl='2' >
                                    <FormButton
                                        name={`btnViewMap_` + ViewFSN.OID}
                                        icon={`bi bi-map`}
                                        variant={"outline-accent5"}
                                        toolTip={!viewMap ? "View Map" : "Hide Map"}
                                        onClick={OnhandleMapClick}
                                        disabled={ViewFSN.IsCancelledCoverage}
                                    />
                                </Col>
                                <Col xs='2' sm='2' md='2' lg='2' xl='2' xxl='2' >
                                    <FormButton
                                        name={`btnViewLinkage_` + ViewFSN.OID}
                                        icon={ViewFSN.CompanionFlag ? "bx bx-link lh-base" : "bx bx-unlink lh-base"}
                                        variant={"outline-accent5"}
                                        toolTip={ViewFSN.CompanionFlag ? "Companion Linkage" : "No Companion Linkage"}
                                        disabled={ViewFSN.IsCancelledCoverage || (ViewFSN.CompanionFlag ? false : true)}
                                        onClick={showOrHideCompanionLinkage}
                                    />
                                </Col>
                                <Col xs='2' sm='2' md='2' lg='2' xl='2' xxl='2' >
                                    <FormButton
                                        name={`btnDeleteFSN_` + ViewFSN.OID}
                                        icon={`bi bi-trash-fill`}
                                        variant={"outline-accent5"}
                                        toolTip={"Delete Land ID and associated Fields"}
                                        disabled={EditLock}
                                        onClick={() => setWarningAlert(!warningAlert)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Col>
                <Collapse in={showFields}>
                    <div data-testid="collapseAddFSNFields" className={styles.nopadding}>
                        {(showFields && !fieldsStore.HasError) &&
                            <ViewFields
                                data-testid={`viewFieldComponent`}
                                FnId={ViewFSN.OID}
                                IsViewFromCoverageSection={true}
                                CloseAddFSNFields={CloseFields}
                                Fields={viewFields}
                                IsLoading={fieldsStore.IsLoading}
                                SelectedCoverage={ViewFSN.Coverage?.value as string}
                                HasError={fieldsStore.HasError}
                                Tract={ViewFSN.TractNumber}
                                FarmNumber={ViewFSN.FarmNumber}
                                UnitNumber={ViewFSN.UnitNumber?.label as string}
                                SectionTownshipRange={ViewFSN.Section?.label ? ViewFSN.Section?.label as string : ''}
                                PlaceName={ViewFSN.PlaceName}
                                MpciPremiumLineId={Number(ViewFSN.UnitNumber?.value ?? 0)}
                                MpciCoverageId={Number(ViewFSN.Coverage?.value ?? 0)}
                                ParentMpciPremiumLineId={Number(ViewFSN.ParentMpciPremiumLineOID ?? 0)}
                                addField={addField}
                                updateField={updateField}
                                setViewFields={resetViewFields}
                                editLock={EditLock}
                                OtherLandId={ViewFSN.OtherId}
                            />
                        }
                    </div>
                </Collapse>
                <Collapse in={showCompanionLinkage && ViewFSN.CompanionFlag != undefined && ViewFSN.CompanionFlag !== ""}>
                    <div data-testid="collapseCompanionLinkage" className={styles.nopadding}>
                        <ViewCompanionLink
                            data-testid={`viewCompanionComponent`}
                            ShowUnlinkButton={true}
                            CloseCompanionLinkage={CloseCompanionLinkage}
                            MpciPremiumLineId={Number(ViewFSN.UnitNumber?.value ?? 0)}
                            MpciCoverageId={Number(ViewFSN.Coverage?.value ?? 0)}
                            ParentMpciPremiumLineId={ViewFSN.ParentMpciPremiumLineOID}
                            UnitNumberDescription={ViewFSN.UnitNumber?.label ? ViewFSN.UnitNumber.label.toString() : ''}
                            IsShowHeader={true}
                        ></ViewCompanionLink>
                    </div>
                </Collapse>
                <Collapse in={viewMap}>
                    <>
                        {(viewMap && (ViewFSN.IsPRF ?? false) && ViewFSN.CompanionFlag != 'C') && <Col className={`${styles.header}`}>
                            <Row className="float-end me-4">
                                <Form.Check
                                    type="switch"
                                    data-testid="btnExpandCLUs"
                                    id="btnExpandCLUs"
                                    defaultChecked={viewPOR}
                                    label={'Select POR'}
                                    onClick={e => onViewPORClick(e)}
                                    disabled={disableSelectPOR}
                                />
                            </Row>
                        </Col>
                        }
                        {(viewMap && !viewPOR) ?
                            (fieldsStore.IsLoading == true) ? <div id={'spinner_' + ViewFSN.OID}> <Spinner className={styles.spinner} animation={"border"} /> </div> :
                                (canViewMap == true) ? <Map StateCode={ViewFSN.StateCode} CountyCode={ViewFSN.County?.value} FarmNumber={ViewFSN.FarmNumber} TractNumber={ViewFSN.TractNumber} ViewLandOID={ViewFSN.OID} /> :
                                    <div id={'mapMsg_' + ViewFSN.OID} className="map-containernoMap" >
                                        No Map available for FN / Tract
                                    </div> :
                            null
                        }

                        {/* POR */}
                        {viewMap && viewPOR && 
                            <POR
                                StateCode={ViewFSN.StateCode}
                                CountyCode={ViewFSN.County?.value}
                                FarmNumber={ViewFSN.FarmNumber}
                                TractNumber={ViewFSN.TractNumber}
                                ViewLandOID={ViewFSN.OID}
                                MpciPremiumLineId={ViewFSN.UnitNumber?.value ?? "0"}
                                MpciCoverageId={Number(ViewFSN.Coverage?.value ?? 0)}
                                GroupId={ViewFSN.GroupId}
                                EditLock={disableFarmLand}
                                SetDisableSelectPOR={setDisableSelectPOR}
                                IsApiculture={isApiculture}  
                            />
                        }
                    </>
                </Collapse>
            </Row>
        </>
    );
}
export default ViewFarmLand;
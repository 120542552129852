import { Modal } from 'react-bootstrap';
import { Button } from "./Button";

import style from './ConfirmMessageModalWindow.module.scss';
import { WINDOWTYPE } from "../../commom/constant";

interface ConfirmMessageModalWindowProps {
   show: boolean;
   title: string;
   messageBody: string;
   onClose: () => void;
   onConfirm: () => void;
   windowType: WINDOWTYPE;
}

export const ConfirmMessageModalWindow: React.FC< ConfirmMessageModalWindowProps> = ({
    show,
    title,
    messageBody,
    onClose,
    onConfirm,
    windowType,
}) => {
  return (
      <Modal data-testid='confirmMessageModal' centered show={show}>
      <Modal.Header>
           <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
          <Modal.Body id='ConfirmMessageBody'>{messageBody}</Modal.Body>
          {WINDOWTYPE.Alert === windowType && <Modal.Footer>
              <div className={style.confirmMessageModelFooter}>
                  <div className={style.okAlertBtnCenter}>
                      <Button className='btn-confirm' label='Ok' variant='success' onClick={onClose} />
                  </div>
              </div>
          </Modal.Footer>
          }
          {WINDOWTYPE.Warning === windowType && <Modal.Footer>
              <div className={style.confirmMessageModelFooter}>
                  <div className={style.okButton}>
                      <Button className='btn-confirm' label='Ok' variant='success' onClick={onConfirm} />
                  </div>
                  <div className={style.closeButton}>
                      <Button className='btn-closeBtn' label='Close' variant='outline-secondary' onClick={onClose} />
                  </div>
              </div>
          </Modal.Footer>
          }
         
    </Modal>
  );
};

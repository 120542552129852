import { Reducer } from "redux";
import { SectionTownshipState } from ".";
import { GetSectionTownshipAction, SectionTownshipActionTypes, SectionTownshipActions, SectionTownshipErrorAction } from "./actions";

const initialState: SectionTownshipState = { sectionTownShips: {}, errorCode: '', isLoading: false }

const reducer: Reducer<SectionTownshipState, SectionTownshipActions> = 
    (state: SectionTownshipState = initialState, action: SectionTownshipActions): SectionTownshipState => {
        switch(action.type) {
            case SectionTownshipActionTypes.GET_SECTION_TOWNSHIP:
                return { ...state, isLoading: true }
            case SectionTownshipActionTypes.SET_SECTION_TOWNSHIP:
                const getAction = action as GetSectionTownshipAction;
                return { sectionTownShips: { ...state.sectionTownShips, ...getAction.sectionTownshipRanges }, errorCode: state.errorCode, isLoading: false }
            case SectionTownshipActionTypes.ERROR_SECTION_TOWNSHIP:
                const errorAction = action as SectionTownshipErrorAction;
                return { ...state, errorCode: errorAction.errorCode, isLoading: false }
        }
        return state
    }

export default reducer;
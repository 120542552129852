import Map from 'ol/Map';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import VectorSource from 'ol/source/Vector';
import Filter from 'ol/format/WFS';
import GML2 from 'ol/format/GML2';
import { Feature, View } from 'ol';
import { Geometry, Point } from 'ol/geom';
import Text from 'ol/style/Text';
import Icon from 'ol/style/Icon';
import { LANDSCONSTANTS, PRFFieldType, WARNINGS } from '../../commom/constant';
import VectorLayer from 'ol/layer/Vector';
import { IMpciPremiumLinePlantedCluPointOfReference } from '../../views/mpci/lands/viewmodels/iMpciPremiumLinePlantedCluPointOfReference';
import { WKT } from 'ol/format';
import { fromLonLat, transform } from 'ol/proj';
import { Coordinate } from 'ol/coordinate';
import * as PORSaga from '../../store/pointOfReference';
import { ActionTypes as PORActionTypes, DeletePORRequestAction, SavePORRequestAction } from '../../store/pointOfReference/actions';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IPointOfReference } from '../../store/pointOfReference';
export class MapUtility {

    static coloredSvgMarker(Latlong: any) {
        var feature = new Feature({
            geometry: new Point(fromLonLat(Latlong))
        });

        feature.setStyle(
            MapUtility.setFeaturePushPinStyle(1, "Map-Marker-Bubble-Azure.png")
        );
        return feature;
    }

    static setFeaturePushPinStyle(scale: number, imgSrc: string) {
        return new Style({
            image: new Icon({
                anchor: [0.5, 0.9],
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                scale: scale,
                opacity: 1,
                src: imgSrc
            })
        })
    }


    static getVectorSource() {
        return new VectorSource({
            format: new Filter({
                featureNS: 'http://mapserver.gis.umn.edu/mapserver',
                featureType: 'clu',
                gmlFormat: new GML2()
            })
        });
    }

    static getLabelForEmbeddedMap(feature: Feature<Geometry>) {
        return feature.get("fieldnum") + "\n" + "(" + feature.get("calcacre") + ")";
    }

    static cluLayerLoaderExtendSetter(vectorSource: VectorSource, features: Feature<Geometry>[], map: Map) {
        if (features.length > 0) {
            map.getView().fit(vectorSource.getExtent());
        }
    }

    static setFeatureStyle(feature: Feature<Geometry>) {
        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: "#000000",
                    width: 3
                }),
                fill: new Fill({
                    color: "#9ACD32"
                }),
                text: new Text({
                    textAlign: "center",
                    textBaseline: "middle",
                    font: "normal 12px Arial",
                    text: MapUtility.getLabelForEmbeddedMap(feature),
                    fill: new Fill({ color: "#000000" }),
                    stroke: new Stroke({ color: "#FFFFFF", width: 3 }),
                    offsetX: 0,
                    offsetY: 0,
                    rotation: 0
                })
            })
        );
    }

    static addCluLayer = function (sourceMap: Map, vector: VectorSource): VectorSource {
        sourceMap.addLayer(new VectorLayer({
            source: vector,
            visible: true
        }));

        sourceMap.setView(new View({
            projection: 'EPSG:3857',
            center: [0, 0],
            zoom: 2,
        }));

        sourceMap.getView().fit(vector.getExtent());
        return vector;
    }

    static buildMapServerUrl = () => {
        return LANDSCONSTANTS.mapServerUrlProxied +
            "?map=" + LANDSCONSTANTS.map_file_location + "/" + "clubycluid.map" +
            "&service=WFS" +
            "&version=1.1.0" +
            "&request=GetFeature" +
            "&OUTPUTFORMAT=GML2" +
            "&typename=" + "clu" +
            "&SRS=EPSG:3857" +
            "&maxFeatures=1000";
    }

    static updateFeature = (feature: Feature<Point>, pointOfReference: IMpciPremiumLinePlantedCluPointOfReference): Feature<Point> => {
        if (feature) {
            feature.set("GroupId", pointOfReference.GroupId);
            feature.set("GridId", pointOfReference.GridId);
            feature.set("Lat", pointOfReference.Lat);
            feature.set("Lon", pointOfReference.Lon);
            feature.set("IsPOR", true);
            if (pointOfReference.CluId) {
                feature.setId(pointOfReference.CluId.toUpperCase());
                feature.set("Id", pointOfReference.CluId);
                feature.set("CluId", pointOfReference.CluId);
                feature.set("FieldType", PRFFieldType.CLU);
            } else if (pointOfReference.RluId && pointOfReference.IsRlu == 1) {
                feature.setId(pointOfReference.RluId.toUpperCase());
                feature.set("Id", pointOfReference.RluId.toUpperCase());
                feature.set("FieldType", PRFFieldType.RLU);
            } else if (pointOfReference.Id) {
                feature.setId(pointOfReference.Id);
                feature.set("Id", pointOfReference.Id);
                feature.set("FieldType", PRFFieldType.APICULTURE);
            }
        }
        return feature;
    }

    /*
     * Validation method for when dragging a POR feature. We are NOT allowing users to move a CLU-based
     * POR to be gragged outside that CLU. Nor are we allowing a non-CLU based POR to be dragged inside a CLU.
     */
    static validatePorFeatureForDrag = (
        feature: Feature<Point>,
        gridID: string,
        coordinate: Coordinate,
        pors: IMpciPremiumLinePlantedCluPointOfReference[],
        gridWkt: string,
        map: Map): string | null => {

        var pixel = map.getPixelFromCoordinate(coordinate);

        if (!feature) {
            return null;
        }

        var moveOutOfOriginalBoundariesMsg = MapUtility.validatePorFeatureMoveOutOfOriginalBoundaries(pixel, feature, map);
        if (moveOutOfOriginalBoundariesMsg) {
            return moveOutOfOriginalBoundariesMsg;
        }

        if (gridWkt && !MapUtility.validatePorWithinGrid(coordinate, gridWkt)) {
            var gridValidationMsg = WARNINGS.landId_porwithingrid_validation + ' (' + gridID + ').';
            return gridValidationMsg;
        }

        var fieldType: PRFFieldType = feature.get("FieldType");

        if (fieldType == PRFFieldType.CLU) {
            var cluFeature = MapUtility.getCluFeatures(pixel, map);
            var isDuplicateCoordinate = MapUtility.validatePorDuplicatedClu(cluFeature, pors, feature);
            if (isDuplicateCoordinate) {
                return WARNINGS.landId_dupliate_por_warning;
            }
        } else if (fieldType == PRFFieldType.RLU) {
            var idToSkip = feature.get("Id");
            if (idToSkip) {
                idToSkip = idToSkip.toString();
            }
            var lonLat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
            var isDuplicateCoordinate = MapUtility.validatePorDuplicatedCordinate(lonLat[1].toFixed(5), lonLat[0].toFixed(5), pors, idToSkip);
            if (isDuplicateCoordinate) {
                return WARNINGS.landId_dupliate_por_warning;
            }
        }
        return null;
    }

    static validatePorFeatureMoveOutOfOriginalBoundaries = function (pixel: Array<number>, porFeature: Feature<Point>, map?: Map): string | null {
        var porCluId = porFeature.get("CluId");
        var porIsCluBased = (porCluId != null && porCluId.length == 36);
        var cluFeature = MapUtility.getCluFeatures(pixel, map);
        var isInsideBoundary = cluFeature != null;

        //the POR was created for a CLU field
        if (porIsCluBased) {
            if (!isInsideBoundary) {
                return WARNINGS.landId_por_clu_validation;
            }
        }
        //the POR was an out-of-boundary POR
        else {
            if (isInsideBoundary) {
                return WARNINGS.landId_por_rlu_outside_clu_validation;
            }
        }
        return null;
    }

    static getCluFeature = function (
        pixel: Array<number>,
        map: Map,
        featuresForPor: Feature<Point>[]
    ): Feature<Point> | null {
        var cluFeature: Feature<Point> | null = null;
        const features: Feature[] = map?.getFeaturesAtPixel(pixel) as Feature[];
        var cluId: string | undefined | number;
        var isClu: boolean = false;

        if (features) {
            features.forEach(feature => {
                var geometryName = feature.getGeometryName()
                if (geometryName == 'msGeometry') {
                    isClu = true;
                } else if (geometryName == 'geometry') {
                    cluId = feature.getId();
                }
            })
        }

        if (isClu) {
            featuresForPor.forEach(feature => {
                if (feature.getId() == cluId) {
                    cluFeature = feature;
                }
            });
        }
        return cluFeature;
    }

    static isCluFeature = function (pixel: Array<number>, map?: Map): boolean {
        var feature = MapUtility.getFeature(pixel, map);
        return true;
    }

    static getFeature = function (pixel: Array<number>, map?: Map): Feature<Geometry> | null {
        var cluFeature: Feature<Geometry> | null = null;
        const features: Feature[] = map?.getFeaturesAtPixel(pixel) as Feature[];

        if (features) {
            features.forEach(feature => {
                var geometryName = feature.getGeometryName()
                if (geometryName == 'msGeometry') {
                    cluFeature = feature
                }
            })
        }

        return cluFeature;
    }

    static validatePORFeature = function (
        coordinate: Array<number>,
        pixel: Array<number>,
        sourceMap: Map,
        pors: IMpciPremiumLinePlantedCluPointOfReference[],
        globalCurrentGridWkt: string,
        gridID: string,
        isApiculture?: boolean
    ): string {

        var fieldType: PRFFieldType;
        var cluFeature = MapUtility.getCluFeatures(pixel, sourceMap);

        if (cluFeature) {
            fieldType = PRFFieldType.CLU;
        } else if (isApiculture) {
            fieldType = PRFFieldType.APICULTURE
        } else {
            fieldType = PRFFieldType.RLU;
        }

        if (!MapUtility.validatePorWithinGrid(coordinate, globalCurrentGridWkt)) {
            return WARNINGS.landId_porwithingrid_validation + ' (' + gridID.trim() + ').';
        }

        if (fieldType == PRFFieldType.RLU) {
            var lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');
            if (MapUtility.validatePorDuplicatedCordinate(lonlat[1].toFixed(5), lonlat[0].toFixed(5), pors)) {
                return WARNINGS.landId_por_duplicatedclu_validation;
            }
        }
        else if (fieldType == PRFFieldType.CLU) {
            if (MapUtility.validatePorDuplicatedClu(cluFeature, pors)) {
                return WARNINGS.landId_por_duplicatedclu_validation;
            }
        }
        return ''
    }

    static validatePorWithinGrid = function (coordinate: Array<number>, globalCurrentGridWkt: string): boolean {
        if (globalCurrentGridWkt) {
            var gridCellGeometry = (new WKT()).readGeometry(globalCurrentGridWkt);
            if (gridCellGeometry.intersectsCoordinate(coordinate)) {
                return true;
            }
        }
        return false;
    }

    static getCluFeatures = function (pixel: Array<number>, sourceMap?: Map, isNonClu: boolean = false): Feature<Geometry> | null {
        var cluFeature: Feature<Geometry> | null = null;
        const featuresAtPixel: Feature[] = sourceMap!.getFeaturesAtPixel(pixel) as Feature[];

        if (isNonClu) {
            cluFeature = featuresAtPixel[0];
        } else {
            if (featuresAtPixel) {
                featuresAtPixel.forEach(feature => {
                    var geometryName = feature.getGeometryName()
                    if (geometryName == 'msGeometry') {
                        cluFeature = feature;
                    }
                })
            }
        }
        return cluFeature;
    }

    static validatePorDuplicatedClu = function (
        cluFeature: any,
        pors: IMpciPremiumLinePlantedCluPointOfReference[] | undefined,
        porFeatureToSkip: Feature<Point> | null = null
    ) {
        var pointOfReference: IMpciPremiumLinePlantedCluPointOfReference[] | undefined = undefined;
        if (cluFeature) {
            var cluId: string = cluFeature?.get("cluid");
            if (porFeatureToSkip) {
                var cluIdToSkip = porFeatureToSkip.get("CluId");
                pointOfReference = pors?.filter(m => m.Lon != null && m.Lat != null && (m.CluId?.toLowerCase() == cluId.toLowerCase() && m.CluId?.toLowerCase() != cluIdToSkip.toLowerCase()));
            } else {
                pointOfReference = pors?.filter(m => m.Lon != null && m.Lat != null && m.CluId.toLowerCase() == cluId.toLowerCase());
            }
            // Has duplicate Pin
            if (pointOfReference != null && pointOfReference.length > 0) {
                return true;
            }
        }
        return false;
    }

    static validatePorDuplicatedCordinate = function (
        lat: string,
        lon: string,
        pors: IMpciPremiumLinePlantedCluPointOfReference[] | undefined,
        idToSkip: string | null = null
    ) {
        var pointOfReference: IMpciPremiumLinePlantedCluPointOfReference[] | undefined = undefined;
        if (idToSkip) {
            pointOfReference = pors?.filter(m => m.Lon != null && m.Lat != null && m.RluId.toLowerCase() != idToSkip.toLowerCase() && (m.Lon == lon && m.Lat == lat));
        } else {
            pointOfReference = pors?.filter(m => m.Lon != null && m.Lat != null && (m.Lon == lon && m.Lat == lat));
        }
        // Has duplicate Cordinate
        if (pointOfReference != null && pointOfReference.length > 0) {
            return true;
        }
        return false;
    }

    static addPorFeatureOutsideClu = function (
        coordinate: Array<number>,
        nonCluPOR: IMpciPremiumLinePlantedCluPointOfReference | null = null,
    ): Feature<Point> | undefined {
        var lonlat = transform(coordinate, 'EPSG:3857', 'EPSG:4326');

        if (nonCluPOR === null) {
            return
        }

        nonCluPOR.Lon = "" + lonlat[0].toFixed(5);
        nonCluPOR.Lat = "" + lonlat[1].toFixed(5);

        //setting feature and displaying
        var feature = MapUtility.coloredSvgMarker(lonlat);
        MapUtility.updateFeature(feature, nonCluPOR);
        return feature;
    }

    static getFirstAvailableRlu(nonCluPORs: IMpciPremiumLinePlantedCluPointOfReference[]): IMpciPremiumLinePlantedCluPointOfReference | null {
        var result = nonCluPORs.filter(e => e.IsRlu == 1 && (!e.Lat || !e.Lon));
        return result && result.length > 0 ? result[0] : null;
    }

    static getPorFeatureById = (id: string, vectorSource: VectorSource | undefined): Feature | undefined => {
        if (id != null && id != "") {
            return vectorSource?.getFeatures().find(f => f.getId() === id.toUpperCase());
        }
    }

    static getDeletePORRequestAction(growerId: number,
        policyId: number,
        MpciCoverageId: string,
        MpciPremiumLineId: string,
        cluId: string,
        GroupId: number,
        pointOfReference: PORSaga.IPointOfReference,
        api: ApiGateway): DeletePORRequestAction {
        return {
            type: PORActionTypes.DELETE_POR_REQUEST,
            Api: api,
            GrowerId: growerId.toString(),
            MpciPolicyId: policyId.toString(),
            MpciCoverageId: MpciCoverageId?.toString() ?? "",
            MpciPremiumLineId: Number(MpciPremiumLineId ?? 0),
            CluId: cluId,
            PORRequest: {
                GroupId: GroupId ?? 0,
                IsLoading: true,
                MpciPremiumLinePointOfReferences: pointOfReference?.MpciPremiumLinePointOfReferences ? pointOfReference?.MpciPremiumLinePointOfReferences : [],
                HasError: false,
                CanAutoPRF: pointOfReference?.CanAutoPRF,
                GlobalCurrentGridWkt: pointOfReference.GlobalCurrentGridWkt
            }
        }
    }

    static getSavePORRequestAction(growerId: number,
        policyId: number,
        MpciCoverageId: string,
        MpciPremiumLineId: string,
        GroupId: number | undefined,
        pors: IMpciPremiumLinePlantedCluPointOfReference[],
        api: ApiGateway): SavePORRequestAction {
        let action: SavePORRequestAction = {
            type: PORActionTypes.SAVE_POR_REQUEST,
            Api: api,
            GrowerId: growerId.toString(),
            MpciPolicyId: policyId.toString(),
            MpciCoverageId: MpciCoverageId?.toString() ?? "",
            MpciPremiumLineId: Number(MpciPremiumLineId ?? 0),
            PointOfReference: { GroupId: GroupId, MpciPremiumLinePointOfReferences: pors } as IPointOfReference,
            PORRequest: {
                GroupId: GroupId ?? 0,
                IsLoading: true,
                MpciPremiumLinePointOfReferences: pors,
                HasError: false,
                CanAutoPRF: false
            }
        }
        return action;
    }

    static mergeClusWithNonClus(GroupId: number | undefined, plantedCluPORs: IMpciPremiumLinePlantedCluPointOfReference[], rluPORs: IMpciPremiumLinePlantedCluPointOfReference[], apiCulturePORs: IMpciPremiumLinePlantedCluPointOfReference[]) {
        var pors = plantedCluPORs.filter(m => m.GroupId == GroupId && m.Lat && m.Lon);
        var rlus = rluPORs.filter(m => m.GroupId == GroupId && m.Lat && m.Lon);
        let apiCultures = apiCulturePORs.filter(m => m.GroupId && m.Lat && m.Lon)
        const nextPORs = [ ...pors, ...rlus, ...apiCultures ]
        return nextPORs;
    }
}

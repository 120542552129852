import { Action, ActionCreator } from 'redux';
import { IFilter } from '.';
import { ISelectList } from "components/forms/iSelectList";
import { IViewLand } from '../../views/mpci/lands/viewmodels/iViewLands';

export const ActionTypes = {
    SET_FILTERS_REQUEST: '@@filters/SET_FILTERS_REQUEST',
    SET_FILTERS_REQUEST_SUCCESS: '@@filters/SET_FILTERS_REQUEST_SUCCESS',
    SET_COUNTIES_REQUEST: '@@filters/SET_COUNTIES_REQUEST',
    SET_COUNTIES_REQUEST_SUCCESS: '@@filters/SET_COUNTIES_REQUEST_SUCCESS',
    SET_COVERAGE_REQUEST: '@@filters/SET_COVERAGE_REQUEST',
    SET_COVERAGE_REQUEST_SUCCESS: '@@filters/SET_COVERAGE_REQUEST_SUCCESS',
    SET_UNITNUMBER_REQUEST: '@@filters/SET_UNITNUMBER_REQUEST',
    SET_UNITNUMBER_REQUEST_SUCCESS: '@@filters/SET_UNITNUMBER_REQUEST_SUCCESS',
    SET_TRACTNUMBER_REQUEST: '@@filters/SET_TRACTNUMBER_REQUEST',
    SET_TRACTNUMBER_REQUEST_SUCCESS: '@@filters/SET_TRACTNUMBER_REQUEST_SUCCESS',
    SET_FARMNUMBER_REQUEST: '@@filters/SET_FARMNUMBER_REQUEST',
    SET_FARMNUMBER_REQUEST_SUCCESS: '@@filters/SET_FARMNUMBER_REQUEST_SUCCESS',
    SET_SECTIONS_REQUEST: '@@filters/SET_SECTIONS_REQUEST',
    SET_SECTIONS_REQUEST_SUCCESS: '@@filters/SET_SECTIONS_REQUEST_SUCCESS',
    SET_PLACENAMES_REQUEST: '@@filters/SET_PLACENAMES_REQUEST',
    SET_PLACENAMES_REQUEST_SUCCESS: '@@filters/SET_PLACENAMES_REQUEST_SUCCESS',
    SET_SHOWNLFUNITS_REQUEST_SUCCESS: '@@filters/SET_SHOWNLFUNITS_REQUEST_SUCCESS',
    SET_SHOWNLFUNITS_REQUEST: '@@filters/SET_SHOWNLFUNITS_REQUEST',
    CLEAR_FILTER: '@@filters/CLEAR_FILTER',
    SET_BASICUNITS_REQUEST: '@@filters/SET_BASICUNITS_REQUEST',
    SET_BASICUNITS_REQUEST_SUCCESS: '@@filters/SET_BASICUNITS_REQUEST_SUCCESS',
    SET_OPTIONALUNITS_REQUEST: '@@filters/SET_OPTIONALUNITS_REQUEST',
    SET_OPTIONALUNITS_REQUEST_SUCCESS: '@@filters/SET_OPTIONALUNITS_REQUEST_SUCCESS',
    MAX_LANDS_REACHED: '@@filters/MAX_LANDS_REACHED',
    SET_FILTERED_LANDS: '@@filters/SET_FILTERED_LANDS'
}

export interface SetFiltersRequestAction extends Action {
    filters: IFilter;
}

export const setFiltersSuccess: ActionCreator<SetFiltersRequestAction> = (filter: IFilter) =>
({
    type: ActionTypes.SET_FILTERS_REQUEST,
    filters: filter
});

/* County */
export interface SetCountiesRequestAction extends Action {
    counties: ISelectList[];
}

export const setCountiesSuccess: ActionCreator<SetCountiesRequestAction> = (counties: ISelectList[]) =>
({
    type: ActionTypes.SET_COUNTIES_REQUEST,
    counties: counties
});

/* Coverage */
export interface SetCoveragesRequestAction extends Action {
    coverages: ISelectList[];
}

export const setCoveragesSuccess: ActionCreator<SetCoveragesRequestAction> = (coverages: ISelectList[]) =>
({
    type: ActionTypes.SET_COVERAGE_REQUEST,
    coverages: coverages
});

/* UnitNumber */
export interface SetUnitNumbersRequestAction extends Action {
    unitNumbers: ISelectList[];
}

export const setUnitNumbersSuccess: ActionCreator<SetUnitNumbersRequestAction> = (unitNumbers: ISelectList[]) =>
({
    type: ActionTypes.SET_UNITNUMBER_REQUEST,
    unitNumbers: unitNumbers
});

/* Tract Number */
export interface SetTractNumbersRequestAction extends Action {
    tractNumbers: ISelectList[];
}

export const setTractNumbersSuccess: ActionCreator<SetTractNumbersRequestAction> = (tractNumber: ISelectList[]) =>
({
    type: ActionTypes.SET_TRACTNUMBER_REQUEST,
    tractNumbers: tractNumber
});

/* Farm Number */
export interface SetFarmNumbersRequestAction extends Action {
    farmNumbers: ISelectList[];
}

export const setFarmNumbersSuccess: ActionCreator<SetFarmNumbersRequestAction> = (farmNumber: ISelectList[]) =>
({
    type: ActionTypes.SET_FARMNUMBER_REQUEST,
    farmNumbers: farmNumber
});

/* Sec/Twp/Rng */
export interface SetSectionsRequestAction extends Action {
    sections: ISelectList[];
}

export const setSectionsSuccess: ActionCreator<SetSectionsRequestAction> = (section: ISelectList[]) =>
({
    type: ActionTypes.SET_SECTIONS_REQUEST,
    sections: section
});

/* Place Name */
export interface SetPlaceNamesRequestAction extends Action {
    placeNames: ISelectList[];
}

export const setPlaceNamesSuccess: ActionCreator<SetPlaceNamesRequestAction> = (placeName: ISelectList[]) =>
({
    type: ActionTypes.SET_PLACENAMES_REQUEST,
    placeNames: placeName
});

/* ShowNLFUnits */
export interface SetShowNLFUnitsRequestAction extends Action {
    showNLFUnits: boolean;
}

export const setShowNLFUnitsSuccess: ActionCreator<SetShowNLFUnitsRequestAction> = (showNLFUnits: boolean) =>
({
    type: ActionTypes.SET_SHOWNLFUNITS_REQUEST,
    showNLFUnits: showNLFUnits
});

/* Basic Units */
export interface SetBasicUnitsRequestAction extends Action {
    basicUnits: ISelectList[];
}

export const setBasicUnitsSuccess: ActionCreator<SetBasicUnitsRequestAction> = (basicUnits: ISelectList[]) =>
({
    type: ActionTypes.SET_BASICUNITS_REQUEST,
    basicUnits: basicUnits
});

/* Optional Units */
export interface SetOptionalUnitsRequestAction extends Action {
    optionalUnits: ISelectList[];
}

export const setOptionalUnitsSuccess: ActionCreator<SetOptionalUnitsRequestAction> = (optionalUnits: ISelectList[]) =>
({
    type: ActionTypes.SET_OPTIONALUNITS_REQUEST,
    optionalUnits: optionalUnits
});

//max reached
export interface MaxLandsReachedAction extends Action {
    isMaxLandsReached: boolean
};

export const maxLandsReachedSuccess: ActionCreator<MaxLandsReachedAction> = (isMaxLandsReached: boolean) =>
({
    type: ActionTypes.MAX_LANDS_REACHED,
    isMaxLandsReached: isMaxLandsReached
});

// Filtered lands
export interface SetFilteredLandsAction extends Action {
    lands: IViewLand[]
};

export const setFilteredLandsSuccess: ActionCreator<SetFilteredLandsAction> = (lands: IViewLand[]) =>
({
    type: ActionTypes.SET_FILTERED_LANDS,
    lands: lands
});

export type FilterActions = SetFiltersRequestAction | SetCoveragesRequestAction | SetUnitNumbersRequestAction
    | SetCountiesRequestAction | SetTractNumbersRequestAction | SetSectionsRequestAction | SetFarmNumbersRequestAction |
    SetPlaceNamesRequestAction | SetShowNLFUnitsRequestAction | SetBasicUnitsRequestAction | SetOptionalUnitsRequestAction |
    MaxLandsReachedAction | SetFilteredLandsAction;

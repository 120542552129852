type PreventedPlantDisplayProps = {
    isPreventedPlant: boolean;
    isPRF: boolean;
};

function PreventedPlantDisplay({ isPreventedPlant, isPRF }: PreventedPlantDisplayProps): JSX.Element  {
    if (!isPRF){
        if (isPreventedPlant) {
            return (
                <div data-testid={"preventedPlantField"}><i className="bi bi-check-square"></i><span>&nbsp;PP</span></div>
            );
        }
        else {
            return (
                <div data-testid={"preventedPlantField"}><i className="bi bi-square-fill disabled" ></i><span>&nbsp;PP</span></div>
            );
        }
    } else {
        return (
            <div />
        );
    }      
}
export default PreventedPlantDisplay;
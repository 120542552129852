import { SelectForm } from "components/forms/formBase"
import { first } from "lodash"
import { getCounties } from "manager/lands/api"
import { BaseSyntheticEvent, useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { GlobalState } from "store"
import * as StatesSaga from '../../../../store/states';
import { useApiGateway } from "components/apiGateway/useApiGateway"
import { IViewField } from "../viewmodels/iViewField"
import { ISelectList } from "components/forms/iSelectList";

interface AdminLocationSelectProps {
    fieldData: IViewField;
    updateField: (field: IViewField, isCluInformation?: boolean) => void
}

export function AdminLocationSelect ({ fieldData, updateField }: AdminLocationSelectProps): JSX.Element {
    const api = useApiGateway();
    const dispatch = useDispatch();
    const [stateOptions, setStateOptions] = useState<ISelectList[]>(() => [{ label: fieldData.AdminStateCode ? fieldData.AdminStateCode : 'Loading', value: fieldData.AdminStateCode ? fieldData.AdminStateCode : 'Loading' }])
    const [countyOptions, setCountyOptions] = useState<ISelectList[]>(() => [{ label: fieldData.AdminCountyCode ? fieldData.AdminCountyCode : 'Loading', value: fieldData.AdminCountyCode ? fieldData.AdminCountyCode : 'Loading' }])
    const statesState = useSelector<GlobalState, StatesSaga.StatesState>((state) => state.statesState);

    useEffect(() => {
        fetchStates();

        if (fieldData.AdminStateCode) {
            fetchCounties(fieldData.AdminStateCode)
        }
    }, [])

    useEffect(() => {
        if (fieldData.AdminStateCode) {
            fetchCounties(fieldData.AdminStateCode)
        }
    }, [fieldData.AdminStateCode]);

    useEffect(() => {
        if (!fieldData.AdminStateCode && statesState.States.length > 0) {
            updateField({ ...fieldData, AdminStateCode: first(statesState.States)?.StateCode })
        }
        setStateOptions(statesState.States.map((state) => { return { label: state.StateName, value: state.StateCode }  }));
    }, [statesState.States]);

    const fetchCounties = (stateCode: string): void => {
        getCounties(stateCode, api).then((counties) => {
            if (!fieldData.AdminCountyCode) {
                updateField({ ...fieldData, AdminCountyCode: first(counties)?.CountyCode })
            }
            setCountyOptions(counties.map((county) => { return { label: county.Name, value: county.CountyCode } }));
        }).catch((error) => {
            console.log(error);
        });
    }

    function fetchStates() {
        if (statesState.Loading == undefined && statesState.States.length == 0) {
            dispatch(StatesSaga.getStates(api));
        }
    }

    return (
        <>
            <Col xs='6' sm='6' md='3' lg='2' xl='2' xxl='2' className='p-1'>
                <SelectForm
                    name={`clu_AdminStateCode_${fieldData.CluId}`}
                    label={'Admin State'}
                    value={fieldData.AdminStateCode ? fieldData.AdminStateCode : ''}
                    onChange={(e: BaseSyntheticEvent) => {
                        updateField({ ...fieldData, AdminStateCode: e.target.value, AdminCountyCode: '' })
                    }}
                    options={stateOptions}
                />
            </Col>
            <Col xs='6' sm='6' md='3' lg='2' xl='2' xxl='2' className='p-1'>
                <SelectForm
                    name={`clu_AdminCountyCode_${fieldData.CluId}`}
                    label={'Admin County'}
                    value={fieldData.AdminCountyCode ? fieldData.AdminCountyCode : ''}
                    onChange={(e: BaseSyntheticEvent) => {
                        updateField({ ...fieldData, AdminCountyCode: e.target.value })
                    }}
                    options={countyOptions}
                />
            </Col>
        </>
    )
}
import { AlertType } from 'components/alert/alertType';
import { CustomAlert } from 'components/alert/customAlert';
import { FilterUtility } from 'manager/lands/filterUtility';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from 'store';
import { resetLandsCoverageRequest, setLandsHasEdit, setLandsShouldSaveCoverage } from 'store/land/actions';
import { WARNINGS } from '../../../commom/constant';
import { useApiGateway } from "../../../components/apiGateway/useApiGateway";
import { refreshHighRisk, updateCoverageLegals, updatePremiumLines } from '../../../manager/lands/api';
import { PolicyUtility } from '../../../manager/lands/policyUtility';
import { FilterState } from '../../../store/filter';
import * as LandSaga from '../../../store/land';
import { RefreshHighRiskLandIdResult } from '../../../store/land';
import * as LoadSpinnerSaga from '../../../store/loadingSpinner';
import * as PremSaga from '../../../store/premiumLineInformation';
import { PremiumLineUpdateResults } from '../../../store/premiumLineInformation';
import { clearPremiumLineResults, updatePremiumLineInformation, updatePremiumLineResults } from '../../../store/premiumLineInformation/actions';
import { LandsContext } from "./landsContext";
import { RefreshHighRiskNotifications } from './refreshHighRiskNotifications';
import { SaveNotifications, PostSaveAlert } from './saveNotifications';
import { IPageActionsProps } from './viewmodels/IPageActionProps';
import { IViewLand } from './viewmodels/iViewLands';
import { ActionTypes as PORActionTypes } from '../../../store/pointOfReference/actions';
import * as PORSaga from '../../../store/pointOfReference';

const PageActionsComponent = ({ MpciPremiumLines, refetch }: IPageActionsProps) => {
    //Selectors
    const landsContext = useContext(LandsContext);
    const coverageRequest = useSelector<GlobalState, IViewLand[]>((state) => state.landState.CoverageRequest);
    const premiumLineStore = useSelector<GlobalState, PremSaga.PremiumLineInformation>((state) => state.premiumLineInformationState);
    const filterState = useSelector<GlobalState, FilterState>((state) => state.filterState);
    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);
    const selectPORStore = useSelector<GlobalState, PORSaga.PORState>((por) => por.porState);

    //Defaults
    const dispatch = useDispatch();
    const api = useApiGateway();

    //State
    const [results, setResults] = useState<PremiumLineUpdateResults[] | undefined>(undefined)
    const [postSaveAlert, setPostSaveAlert] = useState<PostSaveAlert | undefined>(undefined)
    const [presaveErrors, setPreSaveError] = useState<string[] | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [shouldAutoUpdatePremiumLine, setShouldAutoUpdatePremiumLine] = useState(false)
    const [premiumLineOidList, setList] = useState<number[]>([])
    const [refreshHighRiskResults, setRefreshHighRiskResults] = useState<RefreshHighRiskLandIdResult[] | undefined>(undefined)
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        if (coverageRequest.length > 0) {
            CallCoverageApi();
        } else {
            if(shouldAutoUpdatePremiumLine){
                updatePremiumLineOnClick();
            }
            dispatch(setLandsShouldSaveCoverage(false))
        }
    }, [coverageRequest])

    useEffect(() => {
        calculatePreSaveAlerts();
    }, [filterState])

    const SaveOnClick = async () => {
        setPostSaveAlert(undefined)
        setShouldAutoUpdatePremiumLine(true);
        dispatch(setLandsShouldSaveCoverage(true))
    }

    useEffect(() => {
        setResults(premiumLineStore.PremiumLineUpdates)
        if (premiumLineStore.PremiumLineUpdates && premiumLineStore.PremiumLineUpdates?.length == 1) {
            refetch()
        }

    }, [premiumLineStore.PremiumLineUpdates])

    async function CallCoverageApi() {
        setList([])
        dispatch(clearPremiumLineResults())
        var hasErrors = PolicyUtility.CheckCoverRequestForErrors(coverageRequest);
        if (hasErrors && coverageRequest.length > 0) {
            setPostSaveAlert({ message: 'Please fix errors below.', isError: true })
        }
        else {
            setPostSaveAlert(undefined)
            var landIdUpdates = PolicyUtility.MapViewFSNtoCovereageApi(coverageRequest, landsContext.ReinsuranceYear.toString());
            PolicyUtility.UpdateAcreageTypeOnFields(landIdUpdates, premiumLineStore.PremiumLines, selectPORStore)
            if (landIdUpdates && landIdUpdates.MpciPremiumLineLandIdUpdates.length > 0) {
                dispatch(LoadSpinnerSaga.enableLoadingSpinner('reload-coverage'))
                setIsLoading(true)
                try {
                    var saveResponse = await updateCoverageLegals(api, landsContext.GrowerOid, landsContext.PolicyOid, landIdUpdates)
                    setIsLoading(false)
                    dispatch(updatePremiumLineResults(saveResponse.PremiumLineUpdateResults))
                    dispatch(setLandsHasEdit(false));
                } catch (error: any) {
                    error.json().then((body: any) => {
                        if (body.Status === 400 && body.Detail.includes('name:')) {
                            const message = body.Detail.split('name:')
                            setPostSaveAlert({ message: message[1], isError: true })
                        }
                    })
                    setPostSaveAlert({ message: 'Save failed please try again.', isError: true })
                }
                setIsLoading(false)
                dispatch(LoadSpinnerSaga.disableLoadingSpinner('reload-coverage'))
            }
            dispatch({ type: PORActionTypes.RESET_POR })
        }
        setShouldAutoUpdatePremiumLine(false);
        dispatch(resetLandsCoverageRequest())
        dispatch(setLandsShouldSaveCoverage(false))
    }

    function calculatePreSaveAlerts() {
        var stringList: string[] = []
        let pastLockOut = false

        let unitNumberList = filterState.Filter.UnitNumbers
        if (unitNumberList && unitNumberList.length > 0) {
            unitNumberList.forEach(unitNumber => {
                let premiumLine = premiumLineStore.PremiumLines.find(prem => prem.PremiumLineOid.toString() == unitNumber.value)
                if (premiumLine) {
                    if (!premiumLine.UserCanEditPremiumLine) {
                        pastLockOut = true
                    }
                }
            })
            if (pastLockOut) {
                stringList.push('It is past your Lockout Date!')
            }
            if (unitNumberList.length > 0) {
                setPreSaveError(stringList)
            }
        }
        if (stringList.length == 0) {
            setPreSaveError(undefined)
        }
    }

    const updatePremiumLineOnClick = async () => {
        try {
            dispatch(LoadSpinnerSaga.enableLoadingSpinner('update-premium-line'))
            setPostSaveAlert(undefined)
            var saveResponse = await updatePremiumLines(
                api,
                landsContext.GrowerOid,
                landsContext.PolicyOid,
                premiumLineOidList.length > 0 ? premiumLineOidList : filterState.Filter.UnitNumbers.map(unitNumberSelect => { return Number(unitNumberSelect.value) })
            )
            dispatch(updatePremiumLineResults(saveResponse.PremiumLineUpdateResults))
            updatePremiumLineStore(saveResponse.PremiumLineUpdateResults)
            setList([])
        } catch (e) {
            setPostSaveAlert({ message: 'Premium Line Failed to update, please try again.', isError: true })
        }
        setShouldAutoUpdatePremiumLine(false);
        dispatch(LoadSpinnerSaga.disableLoadingSpinner('update-premium-line'))
    }

    const RefreshHighRiskOnClick = () => {
        if (landState.HasEdit) {
            setShowWarning(true);
        } else {
            callRefreshHighRisk();
        }
    }

    const callRefreshHighRisk = async () => {
        setPostSaveAlert(undefined)
        let mpciPremuimlineLandIds = FilterUtility.getFilteredData(landState, filterState, MpciPremiumLines).map(x => x.OID);
        if (mpciPremuimlineLandIds && mpciPremuimlineLandIds?.length > 0) {
            setRefreshHighRiskResults(undefined);
            dispatch(LoadSpinnerSaga.enableLoadingSpinner('refresh-high-risk'));
            setIsLoading(true);
            try {
                let response = await refreshHighRisk(api, landsContext.PolicyOid.toString(), mpciPremuimlineLandIds);
                await refetch();
                setIsLoading(false);
                setRefreshHighRiskResults(response);
            } catch (e) {
                console.log(e);
                setPostSaveAlert({ message: WARNINGS.landId_refresh_high_risk_warning, isError: true })
            }
            setIsLoading(false);
            dispatch(LoadSpinnerSaga.disableLoadingSpinner('refresh-high-risk'));
        }
    }

    const clearResultsOnClick = async () => {
        dispatch(clearPremiumLineResults())
    }

    const updatePremiumLineStore = (results: PremiumLineUpdateResults[] | undefined) => {
        if (results) {
            results.forEach(res => {
                if (res.ReportedAcres) {
                    dispatch(updatePremiumLineInformation(res.PremiumLineOid, res.ReportedAcres))
                }
            })
        }
    }

    return (
        <Row>
            <Col md='auto' className="mt-1">
                <CustomAlert
                    id='filter-change-warning'
                    show={showWarning}
                    type={AlertType.Warning}
                    variant="danger"
                    onClose={() => setShowWarning(false)}
                    onConfirm={() => {
                        setShowWarning(false);
                        callRefreshHighRisk();
                        dispatch(setLandsHasEdit(false));
                    }}
                    continueLabel='Continue'
                    closeLabel='Cancel'
                >
                    Unsaved data will be lost when you refresh high risk. Do you wish to Continue? Or click cancel to make further edits.
                </CustomAlert>
            </Col>
            <Col md='auto' className="mt-1">
                <SaveNotifications
                    premiumLineUpdateResults={results}
                    mpciPremiumLines={MpciPremiumLines}
                    postSaveAlert={postSaveAlert}
                    preSaveAlerts={presaveErrors}
                    lists={premiumLineOidList}
                    setList={setList}
                    updatePremiumLines={updatePremiumLineOnClick}
                    clearResults={clearResultsOnClick}
                    setPostSaveAlert={setPostSaveAlert}
                />
                <RefreshHighRiskNotifications
                    refreshHighRiskLandIdResults={refreshHighRiskResults}
                />
            </Col>
            <Col>
                <Row className="float-md-end">
                    <Col xs='4' md='auto' className="mt-1">
                        <Button variant="outline-accent5" data-testid="btnRefreshHighRisk" id="btnRefreshHighRisk" className="nowrap" onClick={RefreshHighRiskOnClick} disabled={isLoading}>Refresh High Risk</Button>
                    </Col>
                    <Col xs='4' md='auto' className="mt-1">
                        <Button variant="outline-accent5" data-testid="btnSave" id="btnSave" onClick={SaveOnClick} disabled={isLoading}>Save</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
export default PageActionsComponent;
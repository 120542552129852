import { Action, ActionCreator } from 'redux';

export const ActionTypes = {
    ENABLE_SPINNER: '@@spinner/ENABLE',
    DISABLE_SPINNER: '@@spinner/DISABLE'
}

export interface EnableSpinnerAction extends Action {
    key: string
};
export interface DisableSpinnerAction extends Action {
    key: string
};

export const enableSpinner: ActionCreator<EnableSpinnerAction> = (nextKey: string) => (
    {
        type: ActionTypes.ENABLE_SPINNER,
        key: nextKey
    });

export const disableSpinner: ActionCreator<DisableSpinnerAction> = (nextKey: string) => (
    {
        type: ActionTypes.DISABLE_SPINNER,
        key: nextKey
    });

export type LoadSpinnerActions = EnableSpinnerAction | DisableSpinnerAction; 
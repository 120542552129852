import React, { useRef } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { ISelectFormProps } from './iSelectFormProps';

export const SelectForm: React.FC<ISelectFormProps> = ({ name, label, value, options, isDisabled, onChange, onBlur, isClearable, useRef }: ISelectFormProps) => {
    return (
        <FloatingLabel label={label}>
            <Form.Select
                data-testid={name}
                id={name}
                name={name}
                onChange={onChange}
                value={value}
                contentEditable={false}
                disabled={isDisabled}
                onBlur={onBlur}
                ref={useRef}
            >
                {isClearable ? <option key={-1} value={-1} label={`Select...`}>{"Select..."}</option> : undefined}
                {
                    options?.map((option) => {
                        return (<option key={option.value} value={option.value}>{option.label}</option>);
                    })
                }
            </Form.Select>
        </FloatingLabel>
    );
};

export default (SelectForm);
import { URLs } from '../../commom/constant';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';
import { IPointOfReference } from '.';

export const GetPOR = (api: ApiGateway, growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number): Promise<IPointOfReference> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/PointOfReferences`;
    return api.get(url, ApplicationIdValues.Paw);
};

export const AutomatePOR = (api: ApiGateway, growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number): Promise<any> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/PointOfReferences/AutomatePointOfReferences`;
    return api.post(url, ApplicationIdValues.Paw, {});
};

export const DeletePOR = (api: ApiGateway, growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number, plantedCluId: string, groupId: number): Promise<IPointOfReference> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/Groups/${groupId}/CLUs/${plantedCluId}/PointOfReferencesDELETE`;
    return api.put(url, ApplicationIdValues.Paw, {});
};

export const SavePOR = (api: ApiGateway, growerId: string, mpciPolicyId: string, mpciCoverageId: string, mpciPremiumLineId: number, por: IPointOfReference): Promise<boolean> => {
    let url = URLs.paw_api_version + `/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/MpciCoverages/${mpciCoverageId}/MpciPremiumLines/${mpciPremiumLineId}/PointOfReferences`;
    return api.post(url, ApplicationIdValues.Paw, por);
};
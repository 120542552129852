import { URLs } from '../../commom/constant';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';

export const GetFields = (api: ApiGateway, reinsuranceYear: number, mpciPremiumLineLandId: number): Promise<any[]> => {
    let url = URLs.paw_api_version + `/Growers/MpciPolicies/MpciCoverages/MpciPremiumLines/ReinsuranceYear/${reinsuranceYear}/Lands/${mpciPremiumLineLandId}/Fields`;
    return api.getRetry(url, ApplicationIdValues.Paw);
};

export const ValidateSectionEquivalent = (api: ApiGateway, sectionEquivalentId: string, reInsuranceYear: string): Promise<boolean> => {
    let url = URLs.paw_api_version + `/Growers/MpciPolicies/ReinsuranceYear/${reInsuranceYear}/Lands/Fields/ValidateSectionEquivalent/${sectionEquivalentId}`;
    return api.post(url, ApplicationIdValues.Paw, {});
};
import { ICompanionLink } from '../../views/mpci/lands/viewmodels/iCompanionLink';
import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

export interface ICompanionLinksStore {
    MpciPremiumLineID: number,
    IsLoading: boolean,
    CompanionLinks: ICompanionLink[],
    HasError?: boolean,
    ErrorStatusCode?: string
}

export interface CompanionLinksState {
    CompanionLinksRequest: ICompanionLinksStore[]
}
import { InputDatePicker, FormButton, InputText } from 'components/forms/formBase';
import { BaseSyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import styles from './viewFields.module.scss';
import { parse, format } from 'date-fns';
import { ViewFieldsDetailsProps } from './viewmodels/IViewFieldsDetailsProps';
import { formatWithComma } from 'components/utils/formatting/FormatUtils';

function ViewFieldsDetails({ isDisabled, fieldProps, premiumLineList, totalReportedAcres, updateViewField, editLock, isFieldsLoading, fieldsLoadFailed }: ViewFieldsDetailsProps): JSX.Element {
    const [nextPlantDate, setNextPlantDate] = useState<string>();
    const inputRef = useRef<HTMLInputElement>(null);
    const premiumLine = useMemo(() => premiumLineList?.PremiumLines?.find(prem => prem.PremiumLineOid == fieldProps.MpciPremiumLineId), [premiumLineList])
    const [invalidDate, setInvalidDate] = useState(false)


    useEffect(() => {
        if (!!premiumLine?.PlantDate) {
            setNextPlantDate(format(parse(premiumLine.PlantDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()), 'yyyy-MM-dd'));
        }
    }, [premiumLine])

    const setPlantDates = (): void => {
        const isValid = inputRef?.current?.checkValidity();
        setInvalidDate(!isValid)
        if (!!nextPlantDate && isValid) {
            const nextViewFields = [...fieldProps.Fields]
            nextViewFields.forEach((field) => {
                if (field.IsChecked) {
                    field.PlantedDate = parse(nextPlantDate, "yyyy-MM-dd", new Date())
                    updateViewField(field, false)
                }
            })
        }
    }

    const onDateChange = (e: BaseSyntheticEvent): void => {
        const value = e.target.value;
        setInvalidDate(false)
        setNextPlantDate(value)
    }

    const onkeyup = (e: BaseSyntheticEvent): void => {
        const value = e.target.value;
        if (!value) {
            setInvalidDate(false)
        }
    }

    function determine() {
        if (fieldsLoadFailed) {
            return 'Error, please refresh!'
        }
        else if (isFieldsLoading) {
            return <Spinner animation="border" size={'sm'} role="status" />
        }
        else {
            return formatWithComma(totalReportedAcres)
        }
    }
    return (
        <>
            {!(isDisabled && fieldProps.Fields.length == 0) &&
                <div data-testid={'acresPlantDateSection'}>
                    <Row className='ms-1 mt-1 me-1'
                        data-testid="calculatedAcres"
                    >
                        <Col xs='12' sm='12' md='12' lg='6' xl='6' xxl='6' className='p-1'>
                            <InputText
                                name={'acreageType'}
                                label="Acreage Type"
                                inputType={'text'}
                                floatLabel={true}
                                value={premiumLine?.AcreageTypeDescription}
                                disabled={true}
                            />
                        </Col>
                        <Col xs='12' sm='12' md='5' lg='3' xl='3' xxl='3' className={fieldsLoadFailed ? styles.red : 'fw-bold'}>
                            Total Unit Acres: {formatWithComma(premiumLine?.TotalAcres.toString())}
                            <br />
                            Total Reported Acres: {determine()}
                        </Col>
                        <Col xs='8' sm='8' md='4' lg='2' xl='2' xxl='2'>
                            <InputDatePicker
                                floatingLabel={true}
                                name={'setAllPlantDatePicker'}
                                label={'Plant Date'}
                                preventFutureDates={true}
                                onChange={onDateChange}
                                value={nextPlantDate ? nextPlantDate : ''}
                                useRef={inputRef}
                                isInvalid={invalidDate}
                                message={{ rangeOverflow: 'must be less than today', badInput: 'format must be mm/dd/yyyy' }}
                                onKeyUp={onkeyup}
                                disabled={editLock}
                            />
                        </Col>
                        <Col xs='4' sm='4' md='3' lg='1' xl='1' xxl='1'>
                            <FormButton
                                name={`testSetAllPlantDates`}
                                className={styles.applyBtn}
                                variant='outline-accent5'
                                buttonText={"Apply"}
                                onClick={setPlantDates}
                                disabled={editLock}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default ViewFieldsDetails
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Col, Row } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import "./EditShareholder.scss";
import { IShareholderInfo } from "./viewmodels/IShareholderInfo";
import { ShareholderVM } from "./viewmodels/ShareholderVM";
import LoadSpinner from "components/spinner/Spinner";
import { ERRORS, URLs } from '../../../commom/constant';
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import { ErrorUtility } from "../../../manager/common/errorUtility";
interface props {
    show: boolean;
    fullData: ShareholderVM[];
    setFullData: (data: ShareholderVM[]) => void;
    onHide: () => void;
    growerId: string;
    mpciPolicyId: string;
    shareholderData?: IShareholderInfo;
    errorHandler: (error: string) => void;
    setIsLoading: (setIsLoading: boolean) => void;
}

const EditShareholder: React.FC<props> = ({
  show,
  onHide,
  shareholderData,
  fullData,
  setFullData,
  growerId,
  mpciPolicyId,
  errorHandler,
  setIsLoading,
}) => {

  const initialValues = {
    isBusiness: shareholderData?.IsBusiness ?? false,
    firstName: shareholderData?.firstName,
    middleName: shareholderData?.middleName,
    lastName: shareholderData?.lastName,
    suffix: shareholderData?.suffix,
    businessName: shareholderData?.BusinessName
  };
  const validationSchema = yup.object().shape({
    isBusiness: yup.boolean(),
    firstName: yup.string().when("isBusiness", {
        is: false,
        then: yup.string().required('First Name is required')
    }),
    lastName: yup.string().when("isBusiness", {
        is: false,
        then: yup.string().required('Last Name is required')
    }),
    businessName: yup.string().when("isBusiness", {
        is: true,
        then: yup.string().required('Business Name is required')
    }),
});
  const api = useApiGateway();
  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues,
    onSubmit: async (values) => {
      let currData = fullData;
      let elIndex = currData.findIndex((el) => el.oid === shareholderData?.oid);
      if(shareholderData?.IsBusiness){
        currData[elIndex].businessName = values.businessName ?? "";  
      }
      else{
      currData[elIndex].firstName = values.firstName ?? "";
      currData[elIndex].lastName = values.lastName ?? "";
      currData[elIndex].middleName = values.middleName ?? "";
      currData[elIndex].suffix = values.suffix ?? "";
      }
        
        setIsLoading(true);
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderData?.oid;
        await api.put(shareholderapiurl, ApplicationIdValues.Paw, currData[elIndex]).then((data) => {
            setFullData(currData);
            setTimeout(() => {
                onHide();
                setIsLoading(false);
            }, 1500);
        }).catch((err) => {
            setIsLoading(false);
            if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                errorHandler(ERRORS.update_shareholders_api_failed)
            }
        });
    },
  });
  return (
    <Modal
      data-testid="modal"
      show={show}
      onHide={onHide}
      backdrop={false}
      dialogClassName="m-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="edit-modal-header" closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          Modify Shareholder
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="m-3" onSubmit={formik.handleSubmit} noValidate>
          <Row id="frmIndivisual" className="align-items-end">
            {!shareholderData?.IsBusiness ? (
              <>
                {" "}
                <Form.Group
                  as={Col}
                  controlId="firstName"
                  data-testid="formFirstName"
                >
                  <Form.Label className="text-center">First Name</Form.Label>
                  <Form.Control
                    data-testid="firstName"
                    onChange={formik.handleChange}
                    isValid={
                      formik.touched.firstName && !formik.errors.firstName
                    }
                    isInvalid={
                      formik.touched.firstName && !!formik.errors.firstName
                    }
                    maxLength={20}
                    value={formik.values.firstName}
                  />
                  <Form.Control.Feedback type="invalid" className="absolute-pos">
                    {formik.errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="middleName"
                  data-testid="formMiddleName"
                >
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    data-testid="middleName"
                    onChange={formik.handleChange}
                    maxLength={20}
                    value={formik.values.middleName}
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  controlId="lastName"
                  data-testid="formLastName"
                >
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    data-testid="lastName"
                    onChange={formik.handleChange}
                    isValid={formik.touched.lastName && !formik.errors.lastName}
                    isInvalid={
                      formik.touched.lastName && !!formik.errors.lastName
                    }
                    maxLength={50}
                    value={formik.values.lastName}
                  />
                  <Form.Control.Feedback type="invalid" className="absolute-pos">
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="2"
                  controlId="suffix"
                                  data-testid="formSuffix"
                                  className="pe-0"
                >
                  <Form.Label>Suffix</Form.Label>
                  <Form.Control
                    data-testid="suffix"
                    className="ms-0 w-75"
                    onChange={formik.handleChange}
                    maxLength={10}
                    value={formik.values.suffix}
                  />
                </Form.Group>
              </>
            ) : (
              <Form.Group
              className="col-10"
              controlId="businessName"
                data-testid="formBusinessName"
              >
                <Form.Label className="text-center">Business Name</Form.Label>
                <Form.Control
                  data-testid="businessName"
                  onChange={formik.handleChange}
                  isValid={formik.touched.businessName && !formik.errors.businessName}
                  isInvalid={
                    formik.touched.businessName && !!formik.errors.businessName
                  }
                  maxLength={50}
                  value={formik.values.businessName}
                />
                <Form.Control.Feedback type="invalid" className="absolute-pos">
                  {formik.errors.businessName}
                </Form.Control.Feedback>
              </Form.Group>
            )}
                      <Form.Group as={Col} className="mx-width-fit-content ps-0 pe-4">
              <Button variant="default" type="submit" data-testid="btnSubmit">Update</Button>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditShareholder;
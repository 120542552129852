import { ISelectList } from "components/forms/iSelectList";
import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { ERRORS } from '../../../commom/constant';
import { AlertType } from "../../../components/alert/alertType";
import { CustomAlert } from "../../../components/alert/customAlert";
import { useApiGateway } from "../../../components/apiGateway/useApiGateway";
import PolicyTitle from "../../../components/mpci/PolicyTitle";
import GlobalSpinner from "../../../components/spinner/GlobalSpinner";
import { getMpciPolicy } from "../../../manager/lands/api";
import { IMpciPolicy } from "../../../manager/lands/model/iMpciPolicy";
import { PolicyUtility } from "../../../manager/lands/policyUtility";
import * as FieldsSaga from '../../../store/field';
import * as LandSaga from '../../../store/land';
import * as LoadSpinnerSaga from '../../../store/loadingSpinner';
import AddNewFarmLand from "./addNewFarmLand";
import Filter from "./filter";
import styles from './index.module.scss';
import { LandsContext, landsContextTemplate } from "./landsContext";
import PageActionsComponent from "./pageActions";
import ViewFarmLands from "./viewFarmLands";
import { ILandsContext } from "./viewmodels/iLandsContext";

//Example url
//http://localhost:3000/mpci/lands?groweroid=12&policyoid=234&coverageoid=194632578&premiumlineoid=22683546

export default () => {
    const queryString = new URLSearchParams(useLocation().search);
    let policyOid = queryString.get("policyoid");
    let growerOid = queryString.get("groweroid");
    let mpciCoverageOid = queryString.get("coverageoid");
    let mpciPremiumLineOid = queryString.get("premiumlineoid");
    const dispatch = useDispatch();
    const api = useApiGateway();

    let defaultPolicyCoverages = [] as ISelectList[];
    let defaultPolicyPremiumLines = [] as ISelectList[];
    let defaultPolicyCounties = [] as ISelectList[];

    const [policyCoverages, setPolicyCoverages] = useState(defaultPolicyCoverages);
    const [policyPremiumLines, setPolicyPremiumLines] = useState(defaultPolicyPremiumLines);
    const [policyCounties, setPolicyCounties] = useState(defaultPolicyCounties);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState({ status: '' });
    const [stateCode, setStateCode] = useState('');
    const [landState, setLandContext] = useState<ILandsContext>(landsContextTemplate);
    const [collapseAll, setCollapseAll] = useState(false)

    if (!growerOid) {
        growerOid = "0";
    }

    if (!policyOid) {
        policyOid = "0";
    }

    if (!mpciCoverageOid) {
        mpciCoverageOid = "0";
    }

    if (!mpciPremiumLineOid) {
        mpciPremiumLineOid = "0";
    }

    const errorHandler = (error: string): void => {
        setHasError(true);
    };

    useEffect(() => {
        document.title = 'Manage Land IDs';
        fetchMpciPolicy().then((mpciPolicy) => {
        }).catch((error) => {
            handleError(true);
            setError(error);
        })
    }, []);

    const fetchMpciPolicy = async (): Promise<IMpciPolicy> => {
        dispatch(LoadSpinnerSaga.enableLoadingSpinner('fetch-all'))
        setCollapseAll(true)
        var mpciPolicy = await getMpciPolicy(growerOid ? growerOid : '', policyOid ? policyOid : '', true, api);
        storeResponse(mpciPolicy);
        setCollapseAll(false)
        dispatch(LoadSpinnerSaga.disableLoadingSpinner('fetch-all'))
        return mpciPolicy;
    }

    function handleError(error: any) {
        setHasError(true);
    }

    function getError() {
        if (hasError != false) {
            dispatch(LoadSpinnerSaga.disableLoadingSpinner('fetch-all'))
            if (error?.status == '503') {
                return ERRORS.paw_maintance_error;
            }
        }
        return ERRORS.generic_api_failed;
    }

    function storeResponse(mpciPolicy: IMpciPolicy): void {
        if (mpciPolicy) {

            setStateCode(mpciPolicy.StateCode);

            var coverages = mpciPolicy.MpciCoverages;

            let returnedContext: ILandsContext = {
                GrowerOid: Number(growerOid),
                PolicyOid: Number(policyOid),
                ReinsuranceYear: Number(mpciPolicy.ReinsuranceYear),
                StateCode: mpciPolicy.StateCode,
                PremiumLineOid: Number(mpciPremiumLineOid),
                CoverageOid: Number(mpciCoverageOid)
            }

            if (coverages) {
                let landsViewModel = PolicyUtility.getLandsViewModel(dispatch, coverages, mpciPolicy.StateCode);

                setPolicyCoverages(landsViewModel.CoverageList);
                setPolicyPremiumLines(landsViewModel.PremiumLineList);
                setPolicyCounties(landsViewModel.CountyList);
                if (landsViewModel.LandList && landsViewModel.LandList.length > 0) {
                    dispatch(LandSaga.setLands([...landsViewModel.LandList]));
                }

                if (landsViewModel.LandList) {
                    let fieldRequests = PolicyUtility.getAllFieldsRequests(landsViewModel.LandList, api, Number(mpciPolicy.ReinsuranceYear), returnedContext.PremiumLineOid ? returnedContext.PremiumLineOid : 0)
                    if (fieldRequests.length > 0) {
                        fieldRequests.forEach(rec => dispatch(FieldsSaga.getFields(rec)))
                    }
                }
                returnedContext.Coverages = coverages;
            }
            setLandContext(returnedContext)
        }
    }

    return (
        <LandsContext.Provider value={landState}>
            <GlobalSpinner />
            <Container fluid id="mainContent">
                <Row><PolicyTitle growerId={growerOid} mpciPolicyId={policyOid} errorHandler={errorHandler}></PolicyTitle></Row>
                <Row>
                    <PageActionsComponent
                        MpciPremiumLines={policyPremiumLines}
                        refetch={fetchMpciPolicy}
                    />
                </Row>
                <Row className="p-2">
                    <AddNewFarmLand
                        MpciCoverages={policyCoverages}
                        MpciPremiumLines={policyPremiumLines}
                        Counties={policyCounties}
                        refetch={fetchMpciPolicy}
                    ></AddNewFarmLand>
                </Row>
                <Row className="p-2">
                    <Filter
                        MpciCoverages={policyCoverages}
                        MpciPremiumLines={policyPremiumLines}
                        Counties={policyCounties}
                        refetch={fetchMpciPolicy}
                    ></Filter>
                </Row>
                <Row data-testid="mainerror" className={styles.farmlands}>
                    <CustomAlert type={AlertType.Information} variant="danger" show={hasError} onClose={() => setHasError(false)} dismissible>{getError()}</CustomAlert>
                    <ViewFarmLands
                        MpciCoverages={policyCoverages}
                        MpciPremiumLines={policyPremiumLines}
                        Counties={policyCounties}
                        collapseAll={collapseAll}
                        refetch={fetchMpciPolicy}
                    />
                </Row>
            </Container>
        </LandsContext.Provider>
    );
}


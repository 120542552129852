import { FilterUtility } from "manager/lands/filterUtility";
import { default as React, useEffect, useRef, useState } from "react";
import { Accordion, Col, Collapse, Form, Row, Toast, ToastBody, ToastContainer, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { ActionMeta, MultiValue, SingleValue } from "react-select";
import { ERRORS, FSNValidation, LANDSCONSTANTS, SUCCESS, WARNINGS } from "../../../commom/constant";
import { AlertType } from '../../../components/alert/alertType';
import { CustomAlert } from '../../../components/alert/customAlert';
import { useApiGateway } from '../../../components/apiGateway/useApiGateway';
import { FormButton, InputSwitch, InputText, SelectMulti, SelectSingle } from "../../../components/forms/formBase";
import { ISelectList as IFormSelectList } from '../../../components/forms/iSelectList';
import { AddNewFarmLandOnChangeUtility } from "../../../manager/lands/addNewFarmlandOnChangeUtility";
import { AddNewFarmLandUtility } from "../../../manager/lands/AddNewFarmLandUtility";
import { getAddNewFields, validateFarmNumber, validateFarmTractAndSection, validateFarmandTractNumber, validateSectionTownshipRange, validateFarmNumberSectionTownshipRange } from "../../../manager/lands/api";
import { PolicyUtility } from "../../../manager/lands/policyUtility";
import { GlobalState } from "../../../store";
import * as LandSaga from '../../../store/land';
import * as LoadSpinnerSaga from '../../../store/loadingSpinner';
import styles from './addNewFarmLand.module.scss';
import { LandsContext } from "./landsContext";
import ViewFields from './viewFields';
import { ICoverageAndPremiumLines } from './viewmodels/iCoverageAndPremiumLines';
import { CoverageDetailsError, LegalDetailsError } from "./viewmodels/IFSNError";
import { IFSN } from "./viewmodels/iNewFarmLand";
import { IViewField } from './viewmodels/iViewField';
import { useViewFields } from "./customHooks/useViewField";
import Map from './map';
import { SectionTownshipSelect } from "./sectionTownshipSelect";
import { ISelectList } from "components/forms/iSelectList";
import * as PremiumLineInformationSaga from '../../../store/premiumLineInformation';
import * as FilterSaga from '../../../store/filter';
import * as PremSaga from '../../../store/premiumLineInformation';

function AddNewFarmLand(CoverageAndPremiumLines: ICoverageAndPremiumLines) {
    //Selectors
    const filterState = useSelector<GlobalState, FilterSaga.FilterState>((state) => state.filterState);
    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);

    //Defaults
    const landsContext = React.useContext(LandsContext);
    const api = useApiGateway();
    const dispatch = useDispatch();
    const defaultFieldCallState = { coverageOid: '0', premiumlineOid: '0', fsn: '0', tract: '0', calledInError: false, placeName: '' }
    const farmRef = useRef<HTMLInputElement>(null);
    const tractRef = useRef<HTMLInputElement>(null);
    const placeRef = useRef<HTMLInputElement>(null);
    const otherRef = useRef<HTMLInputElement>(null);
    const isPrimaryRef = useRef<HTMLInputElement>(null);
    const defaultIcon: string = "bi bi-check-circle";
    const validIcon: string = "bi bi-check-circle-fill";
    const inValidIcon: string = "bi bi-x-circle-fill";
    const greenOutline: string = "outline-accent5";
    const redOutline: string = "outline-danger";
    const defaultTooltip: string = "Validate";
    const strTooltipError: string = "Invalid Section Township Range";

    //State
    const [fieldsButtonTooltipText, setFieldsButtonTooltipText] = useState(LANDSCONSTANTS.showFieldsToolTip);
    const [isFieldsButtonDisabled, setIsFieldsButtonDisabled] = useState(true);
    const [isLoadingFields, setIsLoadingFields] = useState(true);
    const [lastFieldCallState, setLastFieldCallState] = useState(defaultFieldCallState);
    const [showAddFields, setShowAddFields] = useState(false);
    const [errorAlertState, setErrorAlertState] = useState({ show: false, variant: 'danger', message: ERRORS.landId_get_fields_api_failed, dismissible: true });
    const [alertState, setAlertState] = useState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
    const [coverageOptions, setCoverageOptions] = useState<ISelectList[]>(CoverageAndPremiumLines.MpciCoverages);
    const [unitNumbers, setUnitNumbers] = useState<ISelectList[]>(CoverageAndPremiumLines.MpciPremiumLines);
    const [createdFSN, setCreatedFSN] = useState<IFSN>(
        {
            County: undefined,
            Coverage: undefined,
            UnitNumber: undefined,
            FSN: '',
            Id: 0,
            Tract: '',
            SecTwpRng: undefined,
            StateCode: landsContext.StateCode,
            ReInsuranceYear: landsContext.ReinsuranceYear.toString()
        })
    const [legalDetailsError, setLegalDetailsError] = useState({} as LegalDetailsError);
    const [coverageDetailsError, setCoverageDetailsError] = useState({} as CoverageDetailsError);
    const [viewMap, setViewMap] = useState(false);
    const [scrollMap, setscrollMap] = useState(false);
    const [isErrorSavingOverride, setIsErrorSavingOverride] = useState(false);
    const [isSavingLandsOverride, setIsSavingLandsOverride] = useState(false);
    const [isDisable, setIsDisable] = useState<boolean>();
    const [isViewMapButtonDisble, setIsViewMapButtonDisble] = useState<boolean>(true);
    const [validateIcon, setValidateIcon] = useState<string>(defaultIcon);
    const [variantClass, setVariantClass] = useState<string>(greenOutline);
    const [tooltipMessage, setTooltipMessage] = useState<string>(defaultTooltip);
    const [isAddLandIdentifierBtnClick, setIsAddLandIdentifierBtnClick] = useState(false);
    const [cancelledCoverage, setCancelledCoverage] = useState<boolean>();
    const [useUnitMultiSelect, setUseUnitMultiSelect] = useState(true);
    const [showUnsavedFieldsAlert, setUnsavedFieldsAlert] = useState(false);
    const [isFilterMismatchedWithNewRecord, setFilterMismatchedWithNewRecord] = useState(false);
    const [isAddFSNBtnClicked, setIsAddFSNBtnClicked] = useState(false);

    //CustomHooks
    const { viewFields, resetViewFields, addField, updateField } = useViewFields([] as IViewField[]);

    useEffect(() => {
        assignDefaults();
    }, [CoverageAndPremiumLines])

    useEffect(() => {
        if (!!createdFSN.UnitNumber) {
            const nextFSN = { ...createdFSN }
            setSaveData(nextFSN);
            setCreatedFSN(nextFSN)
        }
    }, [createdFSN.UnitNumber])

    useEffect(() => {
        var canViewFields = PolicyUtility.canViewFields(createdFSN.Coverage, createdFSN.UnitNumber, createdFSN.SecTwpRng, createdFSN.FSN, createdFSN.PlaceName, createdFSN.OtherLandId);

        setFieldsButtonToolTip(canViewFields);

        if (!viewMap) {
            var canViewMap = PolicyUtility.canViewMap(createdFSN.Coverage, createdFSN.UnitNumber, createdFSN.FSN, createdFSN.Tract);
            setIsViewMapButtonDisble(canViewMap);
        }
        else if (viewMap && scrollMap && !isLoadingFields) {

            var viewMapElement = document.getElementById('map_' + createdFSN.Id);
            var NoMapelement = document.getElementById('mapMsg');

            if (NoMapelement != null) {
                NoMapelement.scrollIntoView({ behavior: "smooth" });
            }
            else if (viewMapElement != null) {
                viewMapElement.scrollIntoView({ behavior: "smooth" });
            }
        }

    }, [
        createdFSN.County,
        createdFSN.Coverage,
        createdFSN.UnitNumber,
        createdFSN.FSN,
        createdFSN.SecTwpRng,
        createdFSN.PlaceName,
        createdFSN.Tract,
        createdFSN.OtherLandId,
        showAddFields, viewMap
    ]);

    useEffect(() => {
        setLandStoreProps();
    }, [landState.Lands, landState.IsSaving]);

    useEffect(() => {
        setFilterMismatchedWithNewRecord(false);
        if (isAddFSNBtnClicked) {
            isFilterMisMatched();
        }
    }, [filterState.Lands, filterState]);

    function setSaveData(nextFSN: IFSN): void {
        nextFSN.CommodityCode = PolicyUtility.getSelectedCoverage(landsContext.Coverages, (createdFSN.Coverage) ? createdFSN.Coverage[0]?.value : undefined)?.CommodityCode;
        nextFSN.InsurancePlanCode = PolicyUtility.getSelectedCoverage(landsContext.Coverages, (createdFSN.Coverage) ? createdFSN.Coverage[0]?.value : undefined)?.InsurancePlanCode;
        nextFSN.ReInsuranceYear = landsContext.ReinsuranceYear.toString();
        nextFSN.StateCode = landsContext.StateCode;
    }

    const assignDefaults = () => {
        let nextFSN = { ...createdFSN }
        if (landsContext.PremiumLineOid != undefined && landsContext.PremiumLineOid > 0) {
            if (!createdFSN.Coverage) {
                let defaultCoverage = CoverageAndPremiumLines.MpciCoverages.filter(opt => opt.value == landsContext.CoverageOid?.toString());
                if (defaultCoverage.length > 0) {
                    let coverage = PolicyUtility.getSelectedCoverage(landsContext.Coverages, landsContext.CoverageOid?.toString());
                    setUseUnitMultiSelect(!PolicyUtility.isPRF(coverage));
                    nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewCoverage(nextFSN, defaultCoverage);
                    let filteredUnits = FilterUtility.filterUnitOptions(defaultCoverage, landsContext, CoverageAndPremiumLines);
                    setUnitNumbers(filteredUnits);
                    nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewUnitNumber(nextFSN, filteredUnits);
                    isCancelledCoverage(defaultCoverage);
                }
            }

            if (!createdFSN.UnitNumber) {
                let defaultUnitNumber = CoverageAndPremiumLines.MpciPremiumLines.filter(opt => opt.value == landsContext.PremiumLineOid?.toString());
                if (defaultUnitNumber.length > 0) {
                    checkIsTargetCompanionLinked(defaultUnitNumber);
                    nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewUnitNumber(nextFSN, defaultUnitNumber)
                }
            }

            let countyCode = landsContext.Coverages?.find(c => c.OID == landsContext.CoverageOid)?.CountyCode
            let selectedCounty = CoverageAndPremiumLines.Counties.find(opt => opt.value == countyCode);
            if (selectedCounty != undefined) {
                nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewCounty(nextFSN, selectedCounty)
                let filteredCoverages = FilterUtility.filterCoverageOptions(selectedCounty, landsContext, CoverageAndPremiumLines);
                setCoverageOptions(filteredCoverages);
            }

        }
        setSaveData(nextFSN)
        setCreatedFSN(nextFSN)
    }

    function setFieldsButtonToolTip(canViewFields: boolean) {
        if (canViewFields) {
            if (showAddFields) {
                setFieldsButtonTooltipText("Hide Fields")
            }
            else {
                setFieldsButtonTooltipText("View Fields")
            }
        } else {
            if (
                (createdFSN.Coverage && (createdFSN.Coverage.length > 1 || createdFSN.Coverage.length == 0))
                || (createdFSN.UnitNumber && (createdFSN.UnitNumber?.length > 1 || createdFSN.UnitNumber?.length == 0))) {
                setFieldsButtonTooltipText(LANDSCONSTANTS.showFieldsToolTip);
            }
            else {
                setFieldsButtonTooltipText(LANDSCONSTANTS.showFieldsToolTipForCoverageSection);
            }
        }
        setIsFieldsButtonDisabled(!canViewFields);
    }

    const handleSelectedCoverages = (options: IFormSelectList[] | unknown) => {
        let valuesAsOptionsList = options as IFormSelectList[];
        let filteredUnits: ISelectList[] = [];
        let nextFSN = { ...createdFSN }
        if (valuesAsOptionsList) {
            nextFSN.Coverage = valuesAsOptionsList;
            if (valuesAsOptionsList.length > 0) {
                filteredUnits = FilterUtility.filterUnitOptions(valuesAsOptionsList, landsContext, CoverageAndPremiumLines);
                setUnitNumbers(filteredUnits);
            }
            else {
                filteredUnits = FilterUtility.filterUnitOptions(coverageOptions, landsContext, CoverageAndPremiumLines);
                setUnitNumbers(filteredUnits);
            }
            if (createdFSN.UnitNumber != undefined && createdFSN.UnitNumber!.length > 0) {
                let units = filteredUnits.filter(u => createdFSN.UnitNumber!.findIndex(un => un.value === u.value) > -1);
                nextFSN.UnitNumber = units
                nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewUnitNumber(nextFSN, units)
            }
        }
        nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewCoverage(nextFSN, valuesAsOptionsList)
        setCreatedFSN(nextFSN)
        validateOnCoverageBlur(nextFSN, valuesAsOptionsList);
    };

    const handleSelectedUnits = (options: MultiValue<ISelectList> | SingleValue<ISelectList> | unknown) => {
        let nextFSN = { ...createdFSN }
        if (options !== null) {
            let valuesAsOptionsList: ISelectList[] = [];
            if (Array.isArray(options)) {
                valuesAsOptionsList = options.map(i => i);
            } else {
                let value = (options as ISelectList);
                valuesAsOptionsList.push(value);
            }
            checkIsTargetCompanionLinked(valuesAsOptionsList);
            nextFSN = AddNewFarmLandOnChangeUtility.onChangeAddNewUnitNumber(nextFSN, valuesAsOptionsList)
            setCreatedFSN(nextFSN)
        }
        validateOnCoverageBlur(nextFSN, nextFSN.Coverage);
    }

    const handleSelectedCounty = (newValue: ISelectList, actionMeta: ActionMeta<any>): void => {
        const defaultValue: ISelectList[] = [];
        const nextFSN = { ...createdFSN };
        if (newValue && newValue.value != createdFSN.County?.value) {
            nextFSN.County = newValue;
            nextFSN.Coverage = defaultValue;
            nextFSN.UnitNumber = defaultValue;
            let filteredCoverages = FilterUtility.filterCoverageOptions(newValue, landsContext, CoverageAndPremiumLines);
            setCoverageOptions(filteredCoverages);
            let filteredUnits = FilterUtility.filterUnitOptions(filteredCoverages, landsContext, CoverageAndPremiumLines);
            setUnitNumbers(filteredUnits);
        }
        setCreatedFSN(nextFSN)
        let coverage = nextFSN.Coverage != undefined ? nextFSN.Coverage : defaultValue;
        validateOnCoverageBlur(nextFSN, coverage);
    }

    function ClickShowFields() {
        if (!showAddFields) {
            setAlertState({ show: true, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
            setShowAddFields(true);

            if (!viewMap) {
                try {
                    setIsLoadingFields(true);
                    getFields();
                }

                catch {
                    setShowAddFields(true);
                    setErrorAlertState({ show: true, variant: 'danger', message: ERRORS.landId_get_fields_api_failed, dismissible: true });
                    setIsLoadingFields(false);
                }
            }
        }
        else {
            if (AddNewFarmLandUtility.hasUnsavedFields(viewFields)) {
                setUnsavedFieldsAlert(true);
            } else {
                HideFields();
            }
        }
    }

    function HideFields() {
        setUnsavedFieldsAlert(false);
        setAlertState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
        setErrorAlertState({ show: false, variant: 'danger', message: ERRORS.landId_fields_readonly, dismissible: false });
        setShowAddFields(false);
    }

    function CloseAddFSNFields() {
        setAlertState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
        setShowAddFields(false);
    }

    const getFields = async () => {
        if (createdFSN.Coverage?.length == 1 && createdFSN.UnitNumber?.length == 1) {
            if (lastFieldCallState.coverageOid != createdFSN.Coverage[0].value ||
                lastFieldCallState.premiumlineOid != createdFSN.UnitNumber[0].value ||
                lastFieldCallState.fsn != createdFSN.FSN ||
                lastFieldCallState.tract != createdFSN.Tract ||
                lastFieldCallState.placeName != createdFSN.PlaceName ||
                lastFieldCallState.calledInError
            ) {
                setLastFieldCallState({
                    coverageOid: createdFSN.Coverage[0].value,
                    premiumlineOid: createdFSN.UnitNumber[0].value,
                    fsn: createdFSN.FSN,
                    tract: createdFSN.Tract ? createdFSN.Tract : '0',
                    calledInError: false,
                    placeName: createdFSN.PlaceName ? createdFSN.PlaceName : ''
                });

                setErrorAlertState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
                let fieldsForUnsavedLegal = null;
                try {
                    resetViewFields([])
                    if (createdFSN.FSN) {
                        fieldsForUnsavedLegal = await getAddNewFields(landsContext.PolicyOid, createdFSN.Coverage[0].value, createdFSN.UnitNumber[0].value, createdFSN.FSN, createdFSN.Tract ? createdFSN.Tract : '', api);
                    }
                }
                catch {
                    setShowAddFields(false);
                    setLastFieldCallState({ coverageOid: createdFSN.Coverage[0].value, premiumlineOid: createdFSN.UnitNumber[0].value, fsn: createdFSN.FSN, tract: createdFSN.Tract ? createdFSN.Tract : '0', placeName: createdFSN.PlaceName ? createdFSN.PlaceName : '', calledInError: true })
                    setErrorAlertState({ show: true, variant: 'danger', message: ERRORS.landId_get_fields_api_failed, dismissible: true });
                    setLastFieldCallState(defaultFieldCallState);
                }

                let commodityCode = PolicyUtility.getSelectedCoverage(landsContext.Coverages, createdFSN.Coverage[0].value)?.CommodityCode ?? ''
                let insurancePlanCode = PolicyUtility.getSelectedCoverage(landsContext.Coverages, createdFSN.Coverage[0].value)?.InsurancePlanCode ?? ''
                if (fieldsForUnsavedLegal) {
                    resetViewFields(PolicyUtility.addSupplementalFieldDetail(fieldsForUnsavedLegal, commodityCode, insurancePlanCode))
                }
            }
        }
        setIsLoadingFields(false);
    }

    function closeAlert() {
        setShowAddFields(false);
        setErrorAlertState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
    }

    function closeMisMatchedAlert() {
        setFilterMismatchedWithNewRecord(false);
    }

    const checkIsTargetCompanionLinked = (selectedPremiumLines: ISelectList[]) => {
        let selectedPremiumLineIds = selectedPremiumLines.flatMap(sp => sp.value);
        let targetCompanionUnits: string[] = [];
        selectedPremiumLineIds.forEach(function (selectedPremiumLineId) {
            let filterTargetCompanionUnit = landsContext.Coverages?.flatMap(p => p.MpciPremiumLines.filter(pr => pr.OID.toString() == selectedPremiumLineId && (pr.CompanionFlag == "C" || pr.CompanionFlag == "BC")));
            if (filterTargetCompanionUnit != undefined && filterTargetCompanionUnit.length > 0) {
                let targetCompanionUnit = selectedPremiumLines.filter(p => p.value == selectedPremiumLineId);
                targetCompanionUnits.push(targetCompanionUnit[0].label.toString());
            }
        });
        if (targetCompanionUnits.length > 0) {
            setIsDisable(true);
            setAlertState({
                show: true, variant: 'warning', message: "Unit Number: " + targetCompanionUnits.join(", ") + (targetCompanionUnits.length == 1 ? " is " : " are ") + ERRORS.disable_for_target_policy_unit, dismissible: false
            });
        }
        else {
            setIsDisable(false);
        }
    }

    const setLandStoreProps = () => {
        if (landState.IsSaving) {
            dispatch(LoadSpinnerSaga.enableLoadingSpinner('land-save'))
        }
        if (landState.IsSaving == false) {
            dispatch(LoadSpinnerSaga.disableLoadingSpinner('land-save'))
        }

        if (landState.IsSaving == false && isAddLandIdentifierBtnClick) {
            setIsSavingLandsOverride(true);
            setIsAddLandIdentifierBtnClick(false)
        }
        if (landState.ErrorStatusCode) {
            setIsErrorSavingOverride(true);
        }
        if (landState.IsSaving == false && landState.ErrorStatusCode == undefined) {
            if (createdFSN.UnitNumber?.length == 1) {
                dispatch(PremiumLineInformationSaga.deletePremiumLineTotalAcres(createdFSN.UnitNumber[0].value, 0));
            }
            setIsErrorSavingOverride(false);
            ClearAddNewForSave();
            setIsAddFSNBtnClicked(true);
        }
    }

    function closeSaveErrorAlert() {
        setIsErrorSavingOverride(false);
    }

    function submitAddNew() {
        const legalErrors = AddNewFarmLandUtility.ValidateLegalDetails(createdFSN)
        const coverageErrors = AddNewFarmLandUtility.ValidateCoverageDetails(createdFSN)
        if (!AddNewFarmLandUtility.AddNewShouldSave()) {
            if (AddNewFarmLandUtility.AddNewLegalHasNoErrors(legalErrors, coverageErrors)) {
                setLegalDetailsError({ ...AddNewFarmLandUtility.ManualSet(legalErrors) })
            }
        }
        if (AddNewFarmLandUtility.AddNewLegalHasNoErrors(legalErrors, coverageErrors)) {
            AddNewFarmLandUtility.SaveNewLegal(api, dispatch, landsContext, viewFields.filter(item => item.IsChecked))
        }
        setLegalDetailsError(legalErrors);
        setCoverageDetailsError(coverageErrors);
        setAlertState({ show: false, variant: 'warning', message: ERRORS.landId_fields_readonly, dismissible: false });
        setVariantClass(greenOutline);
        setValidateIcon(defaultIcon);
        setTooltipMessage(defaultTooltip);
        setIsAddLandIdentifierBtnClick(true);
    }

    function ClearAddNewForSave() {
        if (farmRef && farmRef.current) {
            farmRef.current.value = '';
        }
        if (tractRef && tractRef.current) {
            tractRef.current.value = '';
        }
        if (placeRef && placeRef.current) {
            placeRef.current.value = '';
        }
        if (otherRef && otherRef.current) {
            otherRef.current.value = '';
        }

        farmRef?.current?.focus();
        setCreatedFSN({
            County: createdFSN.County,
            Coverage: createdFSN.Coverage,
            UnitNumber: createdFSN.UnitNumber,
            FSN: undefined,
            Id: 0,
            Tract: undefined,
            StateCode: landsContext.StateCode,
            ReInsuranceYear: landsContext.ReinsuranceYear
        } as unknown as IFSN)
        AddNewFarmLandUtility.ClearValues();
        setShowAddFields(false);
    }

    function OnClearEntriesClick() {
        const defaultValue: ISelectList[] = [];
        let nextFSN = {
            County: createdFSN.County,
            Coverage: defaultValue,
            UnitNumber: defaultValue,
            FSN: undefined,
            Id: 0,
            Tract: undefined,
            StateCode: landsContext.StateCode,
            ReInsuranceYear: landsContext.ReinsuranceYear
        } as unknown as IFSN
        validateOnCoverageBlur(nextFSN, nextFSN.Coverage);
        setLegalDetailsError(AddNewFarmLandUtility.ValidateLegalDetails(nextFSN))

        if (farmRef && farmRef.current) {
            farmRef.current.value = '';
            farmRef.current.disabled = false;
        }
        if (tractRef && tractRef.current) {
            tractRef.current.value = '';
            tractRef.current.disabled = false;
        }
        if (placeRef && placeRef.current) {
            placeRef.current.value = '';
            placeRef.current.disabled = false;
        }
        if (otherRef && otherRef.current) {
            otherRef.current.value = '';
            otherRef.current.disabled = false;
        }
        if (isPrimaryRef && isPrimaryRef.current) {
            isPrimaryRef.current.checked = false;
            isPrimaryRef.current.disabled = false;
        }

        setCreatedFSN(nextFSN);
        AddNewFarmLandUtility.ClearValues();
        setShowAddFields(false);
        setVariantClass(greenOutline);
        setValidateIcon(defaultIcon);
        setTooltipMessage(defaultTooltip);
    }

    function handleFNChange(e: any) {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewFSN(createdFSN, e.target.value) }
        setCreatedFSN(nextFSN);
        setDefaultIcon();
        validateOnBlur(nextFSN);
    }

    function handleSTRChange(e?: ISelectList) {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewSecTwpRng(createdFSN, e) }
        setCreatedFSN(nextFSN);
        setDefaultIcon();
        validateOnBlur(nextFSN);
    }

    function handleTractChange(e: any) {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewTract(createdFSN, e.target.value) };
        setCreatedFSN(nextFSN);
        setDefaultIcon();
        validateOnBlur(nextFSN);
    }

    function handlePlaceNameChange(e: any) {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewPlaceName(createdFSN, e.target.value) }
        setCreatedFSN(nextFSN);
        setDefaultIcon();
        validateOnBlur(nextFSN);
    }

    function handleOtherIdChange(e: any) {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewOtherLandId(createdFSN, e.target.value) }
        setCreatedFSN(nextFSN)
        validateOnBlur(nextFSN);
    }
    
    function handleIsPrimaryChange() {
        const nextFSN = { ...AddNewFarmLandOnChangeUtility.onChangeAddNewIsPrimary(createdFSN) }
        setCreatedFSN(nextFSN)
        validateOnBlur(nextFSN);
    }

    const validateOnBlur = (nextLand: IFSN) => {
        setLegalDetailsError(AddNewFarmLandUtility.ValidateLegalDetails(nextLand))
    }

    const validateOnCoverageBlur = (nextFSN: IFSN, coverage?: IFormSelectList[]) => {
        if (!coverage) {
            return
        }
        let nextFSNError = { ...coverageDetailsError };
        let isCancelledCoverage = false;
        let coverageSelected = AddNewFarmLandUtility.isSelectListArrayPopulated(nextFSN.Coverage);
        let unitSelected = AddNewFarmLandUtility.isSelectListArrayPopulated(nextFSN.UnitNumber);

        coverage?.map(x => {
            let coverageId = Number(x.value);
            let isCancelled = PolicyUtility.isCancelledCoverage(coverageId, landsContext);
            if (isCancelled) {
                isCancelledCoverage = true;
            }
        });
        setCancelledCoverage(isCancelledCoverage);

        //If No Cancelled Coverage Selected - Generate Errors
        if (!isCancelledCoverage) {
            nextFSNError = AddNewFarmLandUtility.ValidateCoverageDetails(nextFSN)
        }
        //If Cancelled Coverage Selected with No Active Unit Number - Generate Error related to Unit Number Only
        else if (isCancelledCoverage && !unitSelected) {
            //When coverage is selected and combination of unit and coverage has been removed and added another - Remove Error Related to Coverage
            if (coverageSelected && nextFSNError.Coverage != undefined) {
                nextFSNError.Coverage = undefined;
            }
            nextFSNError.UnitNumber = 'Unit Number is a required field';
            clearLegalDetailsError();
        }
        //If Cancelled Coverage Selected with Active Unit Number but still has Error related to Unit Number - Remove Error related to Unit Number Only
        else if (isCancelledCoverage && unitSelected && nextFSNError.UnitNumber != undefined) {
            nextFSNError.UnitNumber = undefined;
        }
        //If Cancelled Coverage Selected with Active Unit Number but still has Error related to Coverage - Remove Error related to Coverage Only
        else if (isCancelledCoverage && coverageSelected && nextFSNError.Coverage != undefined) {
            nextFSNError.Coverage = undefined;
            clearLegalDetailsError();
        }
        else {
            clearLegalDetailsError();
        }
        setCoverageDetailsError(nextFSNError)
    }

    function clearLegalDetailsError() {
        setLegalDetailsError({})
    }

    const setDefaultIcon = () => {
        setVariantClass(greenOutline);
        setValidateIcon(defaultIcon);
        setTooltipMessage(defaultTooltip);
    }

    const setErrorIcon = () => {
        setVariantClass(redOutline)
        setValidateIcon(inValidIcon);
    }

    const setValidatedIcon = () => {
        setVariantClass(greenOutline);
        setValidateIcon(validIcon);
    }

    const handleOnMapClick = () => {
        displayMap();
    }

    const displayMap = () => {
        setViewMap((current: boolean) => !current);
        if (!viewMap) {
            if (!showAddFields) {
                setscrollMap(false);
                setIsLoadingFields(true);
                try {
                    getFields();
                }
                catch {
                    setIsLoadingFields(false);
                    setErrorAlertState({ show: true, variant: 'danger', message: ERRORS.landId_get_fields_api_failed, dismissible: true });
                    setViewMap(false);
                }
            }
            else {
                setscrollMap(true);
            }

        }
    }

    const handleOnValidateClick = async (e: Event) => {
        let result: any = null;
        e.preventDefault();

        let isNotNullOrEmptyFSN = AddNewFarmLandUtility.isPopulated(createdFSN.FSN);
        let isNotNullOrEmptyTract = AddNewFarmLandUtility.isPopulated(createdFSN.Tract);
        let isNotNullOrEmptySecTwpRng = AddNewFarmLandUtility.isSelectListPopulated(createdFSN.SecTwpRng);

        let section = isNotNullOrEmptySecTwpRng ? formatSectionValue(createdFSN.SecTwpRng!.label.toString()) : null;

        // If SecTwnRng in incorrect format
        if (isNotNullOrEmptySecTwpRng && section == null) {
            setTooltipMessage(strTooltipError);
            setErrorIcon();
            return;
        }

        if (!isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {
            setTooltipMessage(FSNValidation.fnTooltipError);
            setErrorIcon();
        }
        else {
            result = await validateFields(isNotNullOrEmptyFSN, isNotNullOrEmptyTract, isNotNullOrEmptySecTwpRng, section!);
            if (result != null) {
                if (result.IsValidLandId) {
                    setValidatedIcon();
                }
                else {
                    setErrorIcon();
                }
                setTooltipMessage(result.ErrorMessageInfo[0]);
            }
            else {
                setDefaultIcon();
            }
        }
    }

    const validateFields = async (isNotNullOrEmptyFSN: boolean, isNotNullOrEmptyTract: boolean, isNotNullOrEmptySecTwpRng: boolean, section: string) => {

        if (isNotNullOrEmptyFSN && isNotNullOrEmptyTract && section != null) {

            return await validateFarmTractAndSection(landsContext.StateCode, createdFSN.County!.value, createdFSN.FSN, createdFSN.Tract, section, api);
        }
        else if (isNotNullOrEmptyFSN && isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {

            return await validateFarmandTractNumber(landsContext.StateCode, createdFSN.County!.value, createdFSN.FSN, createdFSN.Tract, api);
        }
        else if (isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && !isNotNullOrEmptySecTwpRng) {

            return await validateFarmNumber(landsContext.StateCode, createdFSN.County!.value, createdFSN.FSN, api);
        }
        else if (!isNotNullOrEmptyTract && isNotNullOrEmptyFSN && section != null) {
            return await validateFarmNumberSectionTownshipRange(landsContext.StateCode, createdFSN.County!.value, createdFSN.FSN, section, api);
        }
        else if (!isNotNullOrEmptyFSN && !isNotNullOrEmptyTract && section != null) {
            return await validateSectionTownshipRange(landsContext.StateCode, createdFSN.County!.value, section, api);
        }
    }

    const formatSectionValue = (section: string) => {
        let str = null;
        if (AddNewFarmLandUtility.STRRegex.test(section)) {
            str = section.replace(/\(|\)/g, '').replace(/\s+/g, '-');
        }
        return str;

    }

    function isCancelledCoverage(coverage: IFormSelectList[]) {
        let isCancelledCoverage = false;
        coverage?.map(x => {
            let coverageId = Number(x.value);
            let isCancelled = PolicyUtility.isCancelledCoverage(coverageId, landsContext);
            if (isCancelled) {
                isCancelledCoverage = true;
            }
        });
        setCancelledCoverage(isCancelledCoverage!);
    }

    function isFilterMisMatched() {
        let newlyAddedLands = landState.Lands.filter(x => x.IsNewlyAddedLand == true).map(x => x.OID) || [];
        let coverageDetailsLandOIDs = filterState.Lands?.map(l => l.OID) || [];
        let isNewlyAddedLandsMismatched = false;
        newlyAddedLands.forEach(newLandOID => {
            if (!coverageDetailsLandOIDs.includes(newLandOID)) {
                isNewlyAddedLandsMismatched = true;
                return;
            }
        });
        setFilterMismatchedWithNewRecord(isNewlyAddedLandsMismatched);
        setIsAddFSNBtnClicked(false);
    }

    return (
        <>
            <Accordion defaultActiveKey="0" >
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="m-0">
                        <b>Add New FN/Tract</b>
                    </Accordion.Header>
                    <Accordion.Body data-testid="123">
                        <Row>
                            <CustomAlert
                                id="fsnInformationAlert"
                                show={alertState.show}
                                variant={alertState.variant}
                                type={AlertType.Information}
                            >
                                {alertState.message}
                            </CustomAlert>
                            <CustomAlert
                                id="addNewFSNCheckFilter"
                                show={isFilterMismatchedWithNewRecord}
                                dismissible
                                onClose={closeMisMatchedAlert}
                                variant={alertState.variant}
                                type={AlertType.Information}
                            >
                                {WARNINGS.filter_section_mismatch}
                            </CustomAlert>
                            <CustomAlert
                                data-testid="apiFailedAlert"
                                id="fsnErrorAlert"
                                show={errorAlertState.show}
                                variant={errorAlertState.variant}
                                type={AlertType.Information}
                                dismissible={errorAlertState.dismissible}
                                onClose={closeAlert}
                            >
                                {errorAlertState.message}
                            </CustomAlert>
                            <CustomAlert
                                data-testId="saveLandError"
                                id="saveLandErrorAlert"
                                dismissible
                                show={isErrorSavingOverride}
                                variant='danger'
                                type={AlertType.Information}
                                onClose={closeSaveErrorAlert}
                            >
                                {ERRORS.landId_create_lands_api_failed}
                            </CustomAlert>

                            <CustomAlert
                                id="closeFieldsAlert"
                                data-testid="closeFieldsAlert"
                                show={showUnsavedFieldsAlert}
                                variant="warning"
                                onClose={() => {
                                    setUnsavedFieldsAlert(false);
                                    HideFields();
                                }
                                }
                                onCancel={() => setUnsavedFieldsAlert(false)}
                                type={AlertType.Confirmation}
                            >
                                {WARNINGS.landId_fields_unsaved_data_warning}
                            </CustomAlert>
                            <Col>
                                <Form>
                                    <div
                                        data-testid="saveLandsSuccess"
                                    >
                                        <ToastContainer
                                            position='top-end'
                                        >
                                            <Toast
                                                bg="success"
                                                onClose={() => { setIsSavingLandsOverride(false); }}
                                                show={isSavingLandsOverride && landState.ErrorStatusCode == undefined} delay={3000} autohide>
                                                <Toast.Header>
                                                    <strong className='me-auto'>Success!</strong>
                                                </Toast.Header>
                                                <ToastBody className={'text-white'}>
                                                    {SUCCESS.landID_create_lands_api_success}
                                                </ToastBody>
                                            </Toast>
                                        </ToastContainer>
                                    </div>
                                    <Row>
                                        <Col xs='12' sm='12' md='5' lg='2' className="mt-1 p-1" data-testid="addNewCountyDiv">
                                            <SelectSingle
                                                name={"County"}
                                                label={"County"}
                                                isDisabled={showAddFields || viewMap}
                                                isSearchable={false}
                                                onSelectChange={handleSelectedCounty}
                                                options={CoverageAndPremiumLines.Counties}
                                                required={true}
                                                value={createdFSN.County}
                                                error={coverageDetailsError.County}
                                            />
                                        </Col>
                                        <Col xs='12' sm='12' md='7' lg='3' className="mt-1 p-1" data-testid="addNewCoverageDiv">
                                            <SelectMulti
                                                name={"Coverage"}
                                                label={"Coverage"}
                                                options={coverageOptions}
                                                required={true}
                                                closeMenuOnSelect={false}
                                                isDisabled={showAddFields || viewMap}
                                                isClearable={false}
                                                onChange={handleSelectedCoverages}
                                                value={createdFSN.Coverage}
                                                error={coverageDetailsError.Coverage}
                                            />
                                        </Col>
                                        <Col xs='12' sm='12' md='12' lg='7' className="mt-1 p-1" data-testid="addNewUnitNumberDiv">
                                            {useUnitMultiSelect ? (
                                                <SelectMulti
                                                    name={"UnitNumber"}
                                                    label={"Unit Number"}
                                                    options={unitNumbers.filter(unitNumber => !unitNumber.optionDisabled)}
                                                    required={true}
                                                    closeMenuOnSelect={false}
                                                    isDisabled={showAddFields || viewMap}
                                                    isClearable={false}
                                                    onChange={handleSelectedUnits}
                                                    value={createdFSN.UnitNumber}
                                                    error={coverageDetailsError.UnitNumber}
                                                />
                                            ) : (
                                                <SelectSingle
                                                    name={"UnitNumber"}
                                                    label={"Unit Number"}
                                                    options={unitNumbers}
                                                    required={true}
                                                    closeMenuOnSelect={true}
                                                    isDisabled={showAddFields || viewMap}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    onSelectChange={handleSelectedUnits}
                                                    value={createdFSN.UnitNumber}
                                                    error={coverageDetailsError.UnitNumber}
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs='12' sm='6' md='4' lg='1' className="mt-1 p-1">
                                            <InputText
                                                name="FarmNumber"
                                                inputType="text"
                                                onChange={(e: any) => { handleFNChange(e); }}
                                                label="FN"
                                                disabled={showAddFields || isDisable || viewMap || cancelledCoverage}
                                                error={legalDetailsError.FarmNumber}
                                                className={styles.controlheight}
                                                innerRef={farmRef}
                                            />
                                        </Col>
                                        <Col xs='12' sm='6' md='4' lg='1' className="mt-1 p-1">
                                            <InputText
                                                name="Tract"
                                                inputType="text"
                                                onChange={(e: any) => { handleTractChange(e); }}
                                                label="Tract"
                                                disabled={showAddFields || isDisable || viewMap || cancelledCoverage}
                                                error={legalDetailsError.TractNumber}
                                                className={styles.controlheight}
                                                innerRef={tractRef}
                                            />
                                        </Col>
                                        <Col xs='12' sm='6' md='4' lg='2' className="mt-1 p-1" data-testid="addNewSectionDiv">
                                            <SectionTownshipSelect
                                                stateCode={landsContext.StateCode.toString()}
                                                countyCode={createdFSN?.County?.value ?? ''}
                                                farmNumber={createdFSN.FSN}
                                                tract={createdFSN.Tract}
                                                onChange={handleSTRChange}
                                                value={createdFSN.SecTwpRng}
                                                isDisabled={showAddFields || isDisable || viewMap || cancelledCoverage}
                                                error={legalDetailsError.SecTwpRng}
                                                label="Sec/Twp/Rng"
                                            />
                                        </Col>
                                        <Col xs='12' sm='6' md='3' lg='2' className="mt-1 p-1">
                                            <InputText
                                                name="PlaceName"
                                                inputType="text"
                                                onChange={handlePlaceNameChange}
                                                label="Place Name"
                                                disabled={showAddFields || isDisable || viewMap || cancelledCoverage}
                                                error={legalDetailsError.PlaceName}
                                                className={styles.controlheight}
                                                innerRef={placeRef}
                                            />
                                        </Col>
                                        <Col xs='12' sm='6' md='3' lg='2' className="mt-1 p-1">
                                            <InputText
                                                name="OtherLandId"
                                                inputType="text"
                                                onChange={handleOtherIdChange}
                                                label="Other Id"
                                                disabled={showAddFields || isDisable || viewMap || cancelledCoverage}
                                                error={legalDetailsError.OtherLandId}
                                                className={styles.controlheight}
                                                innerRef={otherRef}
                                            />
                                        </Col>
                                        <Col xs='3' sm='3' md='2' lg='1' className="mt-1 p-2 ps-4">
                                            <InputSwitch
                                                name='IsPrimary'
                                                onChange={handleIsPrimaryChange}
                                                label='Primary'
                                                checked={createdFSN.IsPrimary ? createdFSN.IsPrimary : false}
                                                innerRef={isPrimaryRef}
                                                disabled={cancelledCoverage}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleIsPrimaryChange()
                                                    }
                                                }}
                                            />
                                        </Col>
                                        <Col xs='9' sm='9' md='4' lg='3' className=" align-self-center align-items-center ">
                                            <Row>
                                                <Col xs='2' sm='2' md='2' lg='2' className='mt-1 p-1'>
                                                    <FormButton
                                                        name="AddNewFSN"
                                                        icon="bi bi-plus-square"
                                                        variant="outline-accent5"
                                                        toolTip={'Add New FN/Tract'}
                                                        onClick={submitAddNew}
                                                        disabled={isDisable || cancelledCoverage || landState.IsSaving}
                                                    />
                                                </Col>
                                                <Col xs='2' sm='2' md='2' lg='2' className='mt-1 p-1'>
                                                    <FormButton
                                                        name="Validate"
                                                        icon={validateIcon}
                                                        variant={variantClass}
                                                        toolTip={tooltipMessage}
                                                        onClick={handleOnValidateClick}
                                                        disabled={isDisable || cancelledCoverage}
                                                    />
                                                </Col>
                                                <Col xs='2' sm='2' md='2' lg='2' className='mt-1 p-1'>
                                                    <FormButton
                                                        name="View Fields"
                                                        icon={`bi ${showAddFields ? 'bi-chevron-up' : 'bi-chevron-down'}`}
                                                        variant="outline-accent5"
                                                        toolTip={fieldsButtonTooltipText}
                                                        disabled={isFieldsButtonDisabled || isDisable || cancelledCoverage}
                                                        onClick={ClickShowFields}
                                                        isLoading={isLoadingFields && showAddFields}
                                                    />
                                                </Col>
                                                <Col xs='2' sm='2' md='2' lg='2' className='mt-1 p-1'>
                                                    <FormButton
                                                        name="View Map"
                                                        icon='bi bi-map'
                                                        variant="outline-accent5"
                                                        toolTip={!viewMap ? "View Map" : "Hide Map"}
                                                        disabled={isViewMapButtonDisble || cancelledCoverage}
                                                        onClick={handleOnMapClick}
                                                    />
                                                </Col>
                                                <Col xs='2' sm='2' md='2' lg='2' className='mt-1 p-1'>
                                                    <FormButton
                                                        name="Clear Entries"
                                                        variant="outline-accent5"
                                                        disabled={showAddFields}
                                                        icon="bi bi-eraser"
                                                        toolTip={showAddFields ? "Disabled when View Fields is open" : "Clear Entries"}
                                                        onClick={OnClearEntriesClick}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Collapse in={showAddFields} key="collapseAddFSNField" >
                                <div data-testid="collapseAddFSNFields" className={styles.nopadding}>
                                    {(showAddFields && !errorAlertState.show) &&
                                        <ViewFields
                                            FnId={0}
                                            IsViewFromCoverageSection={false}
                                            CloseAddFSNFields={CloseAddFSNFields}
                                            Fields={viewFields}
                                            IsLoading={isLoadingFields}
                                            SelectedCoverage={(createdFSN?.Coverage && createdFSN.Coverage.length > 0) ? createdFSN.Coverage[0].value : landsContext.CoverageOid ? landsContext.CoverageOid.toString() : ''}
                                            HasError={errorAlertState.show}
                                            Tract={createdFSN.Tract}
                                            FarmNumber={createdFSN.FSN}
                                            UnitNumber={(createdFSN?.UnitNumber && createdFSN.UnitNumber.length > 0) ? createdFSN.UnitNumber[0].label.toString() : ''}
                                            SectionTownshipRange={createdFSN.SecTwpRng?.label ? createdFSN.SecTwpRng.label.toString() : ''}
                                            PlaceName={createdFSN?.PlaceName}
                                            MpciPremiumLineId={(createdFSN?.UnitNumber && createdFSN.UnitNumber.length == 1) ? Number(createdFSN.UnitNumber[0].value) : 0}
                                            MpciCoverageId={0}
                                            ParentMpciPremiumLineId={0}
                                            addField={addField}
                                            updateField={updateField}
                                            setViewFields={resetViewFields}
                                            editLock={false}
                                            OtherLandId={createdFSN?.OtherLandId}
                                        />
                                    }
                                </div>
                            </Collapse>
                            <Collapse in={viewMap}>
                                <>
                                    {(viewMap) ?
                                        (isLoadingFields == true) ? <div id={'spinner_' + createdFSN.Id}> <Spinner className={styles.spinner} animation={"border"} /> </div> :
                                            (viewFields && viewFields.filter(c => c.IsRlu == false).length > 0) ?
                                                <Map StateCode={landsContext.StateCode} CountyCode={createdFSN.County?.value} FarmNumber={createdFSN.FSN} TractNumber={createdFSN.Tract} ViewLandOID={createdFSN.Id} /> :
                                                <div id='mapMsg' className="map-containernoMap" >
                                                    No Map available for FN / Tract
                                                </div> :
                                        null
                                    }
                                </>
                            </Collapse>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion >
        </>
    );
}
export default AddNewFarmLand;
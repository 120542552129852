import { isEqual, omit } from "lodash";
import { IViewField } from "../viewmodels/iViewField";
import { useState } from "react";
import { isValid } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setLandsHasEdit } from "store/land/actions";
import { GlobalState } from "store";
import { FieldUtility } from "manager/lands/fieldUtility";

interface UseViewFieldProps {
    viewFields: IViewField[];
    resetViewFields: (fields: IViewField[]) => void;
    clearViewFields: (fields: IViewField[]) => void;
    addField: (field: IViewField) => void;
    updateField: (field: IViewField, isCluInformation?: boolean) => void;
}

export function useViewFields(initialValue: IViewField[]): UseViewFieldProps {
    const [viewFields, setViewFields] = useState(initialValue)
    const dispatch = useDispatch();
    const hasEdit = useSelector<GlobalState, boolean>(state => !!state.landState?.HasEdit)

    const addField = (field: IViewField): void => {
        const newField: IViewField = { ...field, Determination: 'G', IsCluInformationSnapshotMatching: true, IsFieldDetailsSnapshotMatching: true, IsChecked: true, HasError: false }
        if (!hasEdit) {
            dispatch(setLandsHasEdit(true));
        } 
        setViewFields([{ ...newField, Snapshot: newField }, ...viewFields]);
    }

    const updateField = (field: IViewField, isCluInformation: boolean = true): void => {
        const lookUpId = FieldUtility.getFieldKey(field);
        if (!!lookUpId) {
            if (isCluInformation) {
                const omitValues = ['Id', 'LandIdType', 'UnratedCode', 'Snapshot', 'IsCluInformationSnapshotMatching', 'IsFieldDetailsSnapshotMatching', 'PlantedDate', 'ReportedAcres', 'AcreageType', 'HasError']
                const areMatching = isEqual(
                    omit(field, omitValues),
                    omit(field.Snapshot, omitValues)
                )

                field.IsCluInformationSnapshotMatching = areMatching;
            } else {
                const plantedDate = isValid(field.PlantedDate) ? field?.PlantedDate?.setHours(0, 0, 0, 0).toString() : ''
                const snapshotPlantedDate = isValid(field?.Snapshot?.PlantedDate) ? field?.Snapshot?.PlantedDate?.setHours(0, 0, 0, 0).toString() : ''
                const areMatching = isEqual(
                    { Acres: field.ReportedAcres ?? '', PlantedDate: plantedDate },
                    { Acres: field?.Snapshot?.ReportedAcres ?? '', PlantedDate: snapshotPlantedDate }
                )

                field.IsFieldDetailsSnapshotMatching = areMatching;
            }
            const nextFields = [...viewFields]
            const fieldIndex = nextFields.findIndex((f) => f.OID?.toString() === lookUpId || f.ViewId === lookUpId || f.CluId === lookUpId || f.RluId === lookUpId)

            nextFields[fieldIndex] = field;
            if (!hasEdit) {
                dispatch(setLandsHasEdit(true));
            }  
            setViewFields(nextFields)
        }
    }

    const resetViewFields = (fields: IViewField[]): void => {
        setViewFields(
            fields.map((f) => ({
                ...f,
                IsChecked: f.OID ? !!f.OID : !!f.IsChecked,
                IsCluInformationSnapshotMatching: true,
                IsFieldDetailsSnapshotMatching: true,
                Snapshot: {
                    ...f,
                    IsChecked: f.OID ? !!f.OID : !!f.IsChecked,
                    Acres: !!f.Acres ? f.Acres : "0",
                },
                Acres: !!f.Acres ? f.Acres : "0",
                HasError: false
            }))
        );
    }


    const clearViewFields = (fields: IViewField[]): void => {
        setViewFields(
            fields.filter((field) => !field.ViewId).map((f) => ({
                ...f.Snapshot,
                IsCluInformationSnapshotMatching: true,
                IsFieldDetailsSnapshotMatching: true,
                Snapshot: {
                    ...f.Snapshot,
                    IsChecked: f.Snapshot?.OID ? !!f.Snapshot?.OID : !!f.Snapshot?.IsChecked,
                    Acres: !!f.Snapshot?.Acres ? f.Snapshot?.Acres : "0",
                },
                HasError: false
            }))
        );
    }

    const inputProps = {
        viewFields: viewFields,
        resetViewFields: resetViewFields,
        clearViewFields: clearViewFields,
        addField: addField,
        updateField: updateField
    };

    return inputProps;
}
import { ActionCreator } from 'redux';
import { IFilter } from '.';
import { ISelectList } from "components/forms/iSelectList";
import {
    ActionTypes, SetFiltersRequestAction, SetCountiesRequestAction, SetCoveragesRequestAction, SetUnitNumbersRequestAction,
    SetTractNumbersRequestAction, SetSectionsRequestAction, SetFarmNumbersRequestAction, SetPlaceNamesRequestAction,
    SetShowNLFUnitsRequestAction, SetBasicUnitsRequestAction, SetOptionalUnitsRequestAction, MaxLandsReachedAction,
    SetFilteredLandsAction
} from './actions';
import { IViewLand } from '../../views/mpci/lands/viewmodels/iViewLands';

export const setFilter: ActionCreator<SetFiltersRequestAction> = (filters: IFilter) =>
    ({ type: ActionTypes.SET_FILTERS_REQUEST_SUCCESS, filters: filters });

export const setCounties: ActionCreator<SetCountiesRequestAction> = (counties: ISelectList[]) =>
    ({ type: ActionTypes.SET_COUNTIES_REQUEST_SUCCESS, counties: counties });

export const setCoverages: ActionCreator<SetCoveragesRequestAction> = (coverages: ISelectList[]) =>
    ({ type: ActionTypes.SET_COVERAGE_REQUEST_SUCCESS, coverages: coverages });

export const setUnitNumbers: ActionCreator<SetUnitNumbersRequestAction> = (unitNumbers: ISelectList[]) =>
    ({ type: ActionTypes.SET_UNITNUMBER_REQUEST_SUCCESS, unitNumbers: unitNumbers });

export const setTractNumbers: ActionCreator<SetTractNumbersRequestAction> = (tractNumbers: ISelectList[]) =>
    ({ type: ActionTypes.SET_TRACTNUMBER_REQUEST_SUCCESS, tractNumbers: tractNumbers });

export const setSections: ActionCreator<SetSectionsRequestAction> = (sections: ISelectList[]) =>
    ({ type: ActionTypes.SET_SECTIONS_REQUEST_SUCCESS, sections: sections });

export const setFarmNumbers: ActionCreator<SetFarmNumbersRequestAction> = (farmNumbers: ISelectList[]) =>
    ({ type: ActionTypes.SET_FARMNUMBER_REQUEST_SUCCESS, farmNumbers: farmNumbers });

export const setPlaceNames: ActionCreator<SetPlaceNamesRequestAction> = (placeNames: ISelectList[]) =>
    ({ type: ActionTypes.SET_PLACENAMES_REQUEST_SUCCESS, placeNames: placeNames });

export const setShowNLFUnits: ActionCreator<SetShowNLFUnitsRequestAction> = (showNLFUnits: boolean) =>
    ({ type: ActionTypes.SET_SHOWNLFUNITS_REQUEST_SUCCESS, showNLFUnits: showNLFUnits });

export const setBasicUnits: ActionCreator<SetBasicUnitsRequestAction> = (basicUnits: ISelectList[]) =>
    ({ type: ActionTypes.SET_BASICUNITS_REQUEST_SUCCESS, basicUnits: basicUnits });

export const setOptionalUnits: ActionCreator<SetOptionalUnitsRequestAction> = (optionalUnits: ISelectList[]) =>
    ({ type: ActionTypes.SET_OPTIONALUNITS_REQUEST_SUCCESS, optionalUnits: optionalUnits });

export const maxLandsReached: ActionCreator<MaxLandsReachedAction> = (isMaxLandsReached: boolean) =>
    ({ type: ActionTypes.MAX_LANDS_REACHED, isMaxLandsReached: isMaxLandsReached });

export const setFilteredLands: ActionCreator<SetFilteredLandsAction> = (lands: IViewLand[]) =>
    ({ type: ActionTypes.SET_FILTERED_LANDS, lands: lands });
import { Reducer } from 'redux';
import * as FieldsSaga from './';
import { ActionTypes, FieldsActions } from './actions';

const initialState: FieldsSaga.FieldsState = {
    FieldsStore: []
};

const reducer: Reducer<FieldsSaga.FieldsState, FieldsActions> =
    (state: FieldsSaga.FieldsState = initialState, action: FieldsActions): FieldsSaga.FieldsState => {
        switch (action.type) {
            case ActionTypes.GET_FIELDS_REQUEST:
                if (action.fieldsStore) {
                    action.fieldsStore.IsLoading = true
                    if (state.FieldsStore.findIndex(Field => Field.LandOID === action.fieldsStore?.LandOID) === -1) {
                        state.FieldsStore.push(action.fieldsStore);
                    }
                    else {
                        state.FieldsStore.splice(state.FieldsStore.findIndex(item =>
                            item.LandOID == action.fieldsStore?.LandOID
                        ), 1)
                        state.FieldsStore.push(action.fieldsStore);
                    }
                }
                return { ...state };

            case ActionTypes.GET_FIELDS_REQUEST_SUCCESS:
                if (action.fieldsStore) {
                    var foundFields = state.FieldsStore.findIndex(item =>
                        item.LandOID == action.fieldsStore?.LandOID
                    );
                    if (foundFields !== -1) {
                        state.FieldsStore.splice(foundFields, 1)
                    }
                    action.fieldsStore.IsLoading = false
                    state.FieldsStore.push(action.fieldsStore)
                }
                return { ...state }

            case ActionTypes.GET_FIELDS_REQUEST_FAIL:
                if (action.fieldsStore) {
                    state.FieldsStore.splice(state.FieldsStore.findIndex(item =>
                        item.LandOID == action.fieldsStore?.LandOID
                    ), 1)

                    action.fieldsStore.HasError = true
                    action.fieldsStore.IsLoading = false
                    state.FieldsStore.push(action.fieldsStore)
                }
                return { ...state };
        }
        return state;
    };
export default reducer;
import React, { useState, BaseSyntheticEvent } from 'react';
import { Accordion, Button, Col, Row, Form, Alert, } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import Select, { MultiValue, SingleValue } from 'react-select';
import { GlobalState } from '../../../store';
import * as LandSaga from '../../../store/land';
import styles from './filter.module.scss';
import { ICoverageAndPremiumLines } from './viewmodels/iCoverageAndPremiumLines';
import { LandsContext } from "./landsContext";
import * as FilterSaga from '../../../store/filter';
import { ActionTypes } from 'store/filter/actions';
import { FilterUtility } from '../../../manager/lands/filterUtility';
import { ISelectList } from 'components/forms/iSelectList';
import { CustomAlert } from 'components/alert/customAlert';
import { AlertType } from 'components/alert/alertType';
import { setLandsHasEdit } from 'store/land/actions';
import { MAX_LANDS_TO_DISPLAY } from 'commom/constant';
import { PolicyUtility } from 'manager/lands/policyUtility';


function Filter(CoverageAndPremiumLines: ICoverageAndPremiumLines) {
    const dispatch = useDispatch();

    const landsContext = React.useContext(LandsContext);
    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);

    const [selectedCoverage, setSelectedCoverage] = useState<ISelectList[]>([]);
    const [selectedUnitNumber, setSelectedUnitNumber] = useState<ISelectList[]>([]);
    const [selectedCounty, setSelectedCounty] = useState<ISelectList[]>([]);
    const [selectedSection, setSelectedSection] = useState<ISelectList[]>([]);
    const [selectedTractNumber, setSelectedTractNumber] = useState<ISelectList[]>([]);
    const [selectedFarmNumber, setSelectedFarmNumber] = useState<ISelectList[]>([]);
    const [selectedPlaceName, setSelectedPlaceName] = useState<ISelectList[]>([]);
    const [showNLFUnitNumber, setShowNLFUnitNumber] = useState<boolean>();
    const [filter, setFilter] = useState<FilterSaga.IFilter>({} as FilterSaga.IFilter);
    const filterState = useSelector<GlobalState, FilterSaga.FilterState>((state) => state.filterState);
    const [selectedBasicUnit, setSelectedBasicUnit] = useState<ISelectList[]>([]);
    const [selectedOptionalUnit, setSelectedOptionalUnit] = useState<ISelectList[]>([]);
    const [coverageOptions, setCoverageOptions] = useState<ISelectList[]>([...CoverageAndPremiumLines.MpciCoverages]);
    const [unitNumberOptions, setUnitNumberOptions] = useState<ISelectList[]>([...CoverageAndPremiumLines.MpciPremiumLines]);
    const [showWarning, setShowWarning] = useState(false);
    const [nextFilterFunction, setNextFilterFunction] = useState((() => () => console.log('default')));
    const [useUnitMultiSelect, setUseUnitMultiSelect] = useState(true);



    const onShowNLFsClick = () => {
        dispatch(FilterSaga.setShowNLFUnits(!showNLFUnitNumber));
        setShowNLFUnitNumber(!showNLFUnitNumber);
    }

    React.useEffect(() => {
        assignDefaultCounty();
        assignDefaultCoverage();
        assignDefaultUnitNumber();
    }, [CoverageAndPremiumLines]);

    const onClearFilterClick = () => {
        const defaultValue: ISelectList[] = [];
        setSelectedCoverage(defaultValue);
        setSelectedCounty(defaultValue);
        setSelectedUnitNumber(defaultValue);
        setSelectedFarmNumber(defaultValue);
        setSelectedPlaceName(defaultValue);
        setSelectedSection(defaultValue);
        setSelectedTractNumber(defaultValue);
        setShowNLFUnitNumber(false);
        setSelectedBasicUnit(defaultValue);
        setSelectedOptionalUnit(defaultValue);
        dispatch({ type: ActionTypes.CLEAR_FILTER });
    };

    const handleSelectedCoverages = (options: MultiValue<ISelectList>) => {
        if (!!options) {
            let filteredUnits: ISelectList[] = [];
            let selectedCoverageOptions = options.map(i => i);
            setSelectedCoverage(selectedCoverageOptions);
            dispatch(FilterSaga.setCoverages(selectedCoverageOptions));
            if (selectedCoverageOptions.length > 0) {
                filteredUnits = FilterUtility.filterUnitOptions(selectedCoverageOptions, landsContext, CoverageAndPremiumLines);
            }
            else {
                filteredUnits = FilterUtility.filterUnitOptions(coverageOptions, landsContext, CoverageAndPremiumLines);
            }
            setUnitNumberOptions(filteredUnits);
            let units = [] as ISelectList[];
            if (!!selectedUnitNumber && selectedUnitNumber!.length > 0) {
                units = filteredUnits.filter(u => selectedUnitNumber!.findIndex(un => un.value === u.value) > -1);
                setSelectedUnitNumber(units);
                dispatch(FilterSaga.setUnitNumbers(units));
            }

            ResetFilterDropdownOptions(units, filteredUnits, selectedFarmNumber, selectedTractNumber, selectedSection, selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedCounty = (options: MultiValue<ISelectList>) => {
        if (options != null) {
            let filteredCoverages: ISelectList[] = [];
            let filteredUnits: ISelectList[] = [];
            let selectedCountyOption = options.map(i => i);
            setSelectedCounty(selectedCountyOption);
            dispatch(FilterSaga.setCounties(selectedCountyOption));
            if (selectedCountyOption.length > 0) {
                filteredCoverages = FilterUtility.filterMultiCountyCoverageOptions(selectedCountyOption, landsContext, CoverageAndPremiumLines);
            }
            else {
                filteredCoverages = FilterUtility.filterMultiCountyCoverageOptions(CoverageAndPremiumLines.Counties, landsContext, CoverageAndPremiumLines);
            }
            setCoverageOptions(filteredCoverages);
            if (!!selectedCoverage && selectedCoverage!.length > 0) {
                let coverages = filteredCoverages.filter(c => selectedCoverage.findIndex(cov => cov.value === c.value) > -1);
                setSelectedCoverage(coverages);
                dispatch(FilterSaga.setCoverages(coverages));
                if (coverages.length > 0) {
                    filteredUnits = FilterUtility.filterUnitOptions(coverages, landsContext, CoverageAndPremiumLines);
                } else {
                    filteredUnits = FilterUtility.filterUnitOptions(filteredCoverages, landsContext, CoverageAndPremiumLines);
                }

            }
            else {
                filteredUnits = FilterUtility.filterUnitOptions(filteredCoverages, landsContext, CoverageAndPremiumLines);
            }

            setUnitNumberOptions(filteredUnits);

            let units = [] as ISelectList[];
            if (!!selectedUnitNumber && selectedUnitNumber!.length > 0) {
                units = filteredUnits.filter(u => selectedUnitNumber!.findIndex(un => un.value === u.value) > -1);
                setSelectedUnitNumber(units);
                dispatch(FilterSaga.setUnitNumbers(units));
            }

            ResetFilterDropdownOptions(units, filteredUnits, selectedFarmNumber, selectedTractNumber, selectedSection, selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedUnits = (options: MultiValue<ISelectList> | SingleValue<ISelectList>) => {
        let selectedUnits: ISelectList[] = [];

        if (options !== null) {
            if (Array.isArray(options)) {
                selectedUnits = options.map(i => i);
            } else {
                let value = (options as ISelectList);
                selectedUnits.push(value);
            }
            setSelectedUnitNumber(selectedUnits);
            dispatch(FilterSaga.setUnitNumbers(selectedUnits));
            ResetFilterDropdownOptions(selectedUnits, unitNumberOptions, selectedFarmNumber, selectedTractNumber, selectedSection, selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    }

    const ResetFilterDropdownOptions = (selectedUnit: ISelectList[], filteredUnitOptions: ISelectList[], selectedFarm: ISelectList[], selectedTract: ISelectList[], selectedSection: ISelectList[], selectedPlace: ISelectList[], selectedBU: ISelectList[], selectedOU: ISelectList[]) => {
        let filter = null;
        if (!!selectedUnit && selectedUnit.length > 0) {
            filter = FilterUtility.getCascadingFilters(selectedUnit, landState.Lands, selectedFarm, selectedTract, selectedSection, selectedPlace, selectedBU);
        }
        else {
            filter = FilterUtility.getCascadingFilters(filteredUnitOptions!, landState.Lands, selectedFarm, selectedTract, selectedSection, selectedPlace, selectedBU);

        }
        ReselectFilterDropdowns(filter, selectedFarm, selectedTract, selectedSection, selectedPlace, selectedBU, selectedOU);
        setFilter(filter);
    }
    const ReselectFilterDropdowns = (filter: FilterSaga.IFilter, selectedFarm: ISelectList[], selectedTract: ISelectList[], selectedSection: ISelectList[], selectedPlace: ISelectList[], selectedBU: ISelectList[], selectedOU: ISelectList[]) => {
        let SelectedFilterObj: FilterSaga.IFilter = { Counties: [], Coverages: [], UnitNumbers: [], FarmNumbers: [], TractNumbers: [], Sections: [], PlaceNames: [], OtherIds: [], ShowNLFUnits: false, BasicUnits: [], OptionalUnits: [] };

        if (!!selectedFarm && selectedFarm.length > 0) {
            SelectedFilterObj.FarmNumbers = filter.FarmNumbers.filter(f => selectedFarm.findIndex(sf => sf.value == f.value) > -1);
            setSelectedFarmNumber(SelectedFilterObj.FarmNumbers);
            dispatch(FilterSaga.setFarmNumbers(SelectedFilterObj.FarmNumbers));
        }
        if (selectedTract != undefined && selectedTract!.length > 0) {
            SelectedFilterObj.TractNumbers = filter.TractNumbers.filter(t => selectedTract.findIndex(st => st.value == t.value) > -1);
            setSelectedTractNumber(SelectedFilterObj.TractNumbers);
            dispatch(FilterSaga.setTractNumbers(SelectedFilterObj.TractNumbers));
        }
        if (selectedSection != undefined && selectedSection!.length > 0) {
            SelectedFilterObj.Sections = filter.Sections.filter(s => selectedSection.findIndex(ss => ss.value == s.value) > -1);
            setSelectedSection(SelectedFilterObj.Sections);
            dispatch(FilterSaga.setSections(SelectedFilterObj.Sections));
        }
        if (selectedPlace != undefined && selectedPlace!.length > 0) {
            SelectedFilterObj.PlaceNames = filter.PlaceNames.filter(p => selectedPlace.findIndex(sp => sp.value == p.value) > -1);
            setSelectedPlaceName(SelectedFilterObj.PlaceNames);
            dispatch(FilterSaga.setPlaceNames(SelectedFilterObj.PlaceNames));
        }
        if (selectedBU != undefined && selectedBU!.length > 0) {
            SelectedFilterObj.BasicUnits = filter.BasicUnits.filter(b => selectedBU.findIndex(sb => sb.value == b.value) > -1);
            setSelectedBasicUnit(SelectedFilterObj.BasicUnits);
            dispatch(FilterSaga.setBasicUnits(SelectedFilterObj.BasicUnits));
        }

        if (selectedOU != undefined && selectedOU!.length > 0) {
            SelectedFilterObj.OptionalUnits = filter.OptionalUnits.filter(ou => selectedOU.findIndex(so => so.value == ou.value) > -1);
            setSelectedOptionalUnit(SelectedFilterObj.OptionalUnits);
            dispatch(FilterSaga.setOptionalUnits(SelectedFilterObj.OptionalUnits));
        }
    }


    const handleSelectedTractNumber = (options: MultiValue<ISelectList>) => {
        if (options !== null) {
            setSelectedTractNumber(options.map(i => i));
            dispatch(FilterSaga.setTractNumbers(options));
            ResetFilterDropdownOptions(selectedUnitNumber, unitNumberOptions, selectedFarmNumber, options.map(i => i), selectedSection, selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedSection = (options: MultiValue<ISelectList>) => {
        if (options !== null) {
            setSelectedSection(options.map(i => i));
            dispatch(FilterSaga.setSections(options));

            ResetFilterDropdownOptions(selectedUnitNumber, unitNumberOptions, selectedFarmNumber, selectedTractNumber, options.map(i => i), selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedFarmNumber = (options: MultiValue<ISelectList>) => {
        if (options !== null) {
            setSelectedFarmNumber(options.map(i => i));
            dispatch(FilterSaga.setFarmNumbers(options));

            ResetFilterDropdownOptions(selectedUnitNumber, unitNumberOptions, options.map(i => i), selectedTractNumber, selectedSection, selectedPlaceName, selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedPlaceName = (options: MultiValue<ISelectList>) => {
        if (options !== null) {
            setSelectedPlaceName(options.map(i => i));
            dispatch(FilterSaga.setPlaceNames(options));

            ResetFilterDropdownOptions(selectedUnitNumber, unitNumberOptions, selectedFarmNumber, selectedTractNumber, selectedSection, options.map(i => i), selectedBasicUnit, selectedOptionalUnit);
        }
    };

    const handleSelectedBasicUnit = (option: SingleValue<ISelectList>) => {
        // stubbed:
        let basicUnits: ISelectList[] = [];
        if (option !== null) {
            basicUnits = [option];
        }
        dispatch(FilterSaga.setBasicUnits(basicUnits));
        setSelectedBasicUnit(basicUnits);

        ResetFilterDropdownOptions(selectedUnitNumber, unitNumberOptions, selectedFarmNumber, selectedTractNumber, selectedSection, selectedPlaceName, basicUnits, selectedOptionalUnit);
    };

    const handleSelectedOptionalUnit = (option: SingleValue<ISelectList>) => {
        // stubbed:
        let optionalUnits: ISelectList[] = [];
        if (option !== null) {
            optionalUnits = [option];
        }

        dispatch(FilterSaga.setOptionalUnits(optionalUnits));
        setSelectedOptionalUnit(optionalUnits);
    };

    const assignDefaultCoverage = () => {
        if ((!filterState.Filter.Coverages || filterState.Filter.Coverages.length <= 0) && selectedCoverage && selectedCoverage.length >= 0  && landsContext.PremiumLineOid != undefined && landsContext.PremiumLineOid > 0) {
            let defaultCoverage = CoverageAndPremiumLines.MpciCoverages.filter(opt => opt.value == landsContext.CoverageOid?.toString());
            if (defaultCoverage.length > 0) {
                let coverage = PolicyUtility.getSelectedCoverage(landsContext.Coverages, landsContext.CoverageOid?.toString());
                setUseUnitMultiSelect(!PolicyUtility.isPRF(coverage));
                setSelectedCoverage(defaultCoverage);
                dispatch(FilterSaga.setCoverages(defaultCoverage));
                let filteredUnits = FilterUtility.filterUnitOptions(defaultCoverage, landsContext, CoverageAndPremiumLines);
                setUnitNumberOptions(filteredUnits);
            } 
        }
    }

    const assignDefaultShowUnitNumber = () => {
        let unit = landsContext.Coverages?.find(c => c.OID == landsContext.CoverageOid)?.MpciPremiumLines.find(m => m.OID == landsContext.PremiumLineOid);
        let isNLFUnit: boolean = (unit) ? unit.NoLongerFarming : false;
        setShowNLFUnitNumber(isNLFUnit);
        dispatch(FilterSaga.setShowNLFUnits(isNLFUnit));
    }

    const assignDefaultUnitNumber = () => {
        if ((!filterState.Filter.UnitNumbers || filterState.Filter.UnitNumbers.length <= 0) && selectedUnitNumber && selectedUnitNumber.length >= 0 && landsContext.PremiumLineOid != undefined && landsContext.PremiumLineOid > 0) {
            let defaultUnitNumber = CoverageAndPremiumLines.MpciPremiumLines.filter(opt => opt.value == landsContext.PremiumLineOid?.toString());
            if (defaultUnitNumber.length > 0) {
                setSelectedUnitNumber(defaultUnitNumber);
                dispatch(FilterSaga.setUnitNumbers(defaultUnitNumber));
                assignDefaultShowUnitNumber();
                setFilter(FilterUtility.getCascadingFilters(defaultUnitNumber, landState.Lands));
            }
        }
    }

    const assignDefaultCounty = () => {
        if ((!filterState.Filter.Counties || filterState.Filter.Counties.length <= 0) && selectedCounty && selectedCounty.length >= 0 && landsContext.PremiumLineOid != undefined && landsContext.PremiumLineOid > 0) {
            let countyCode = landsContext.Coverages?.find(c => c.OID == landsContext.CoverageOid)?.CountyCode
            let selectedCounty = CoverageAndPremiumLines.Counties.filter(opt => opt.value == countyCode);
            if (selectedCounty != undefined) {
                setSelectedCounty(selectedCounty);
                dispatch(FilterSaga.setCounties(selectedCounty));
                let filteredCoverages = FilterUtility.filterMultiCountyCoverageOptions(selectedCounty, landsContext, CoverageAndPremiumLines);
                setCoverageOptions(filteredCoverages);
            }
        }
    }

    const handleChangeShowWarning = (filterFunction: (option?: any) => void, option?: any) => {
        if (landState.HasEdit) {
            setShowWarning(true);
            setNextFilterFunction((() => () => {
                filterFunction(option);
                dispatch(setLandsHasEdit(false))
            }))
        } else {
            filterFunction(option)
        }
    }

    return (
        <>
            <Accordion>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header className='m-0'>
                        <strong>Filter</strong>
                    </Accordion.Header>
                    <Accordion.Body>
                        <CustomAlert
                            id='filter-change-warning'
                            show={showWarning}
                            type={AlertType.Warning}
                            variant="danger"
                            onClose={() => setShowWarning(false)}
                            onConfirm={() => {
                                setShowWarning(false);
                                nextFilterFunction();
                            }}
                            continueLabel='Continue'
                            closeLabel='Cancel'
                        >
                            Unsaved data will be lost when Filter options are changed. Do you wish to Continue with filter changes? Or click cancel to make further edits.
                        </CustomAlert>
                        <Alert
                            data-testid='test-filter-maxlength-warning'
                            id='filter-maxlength-warning'
                            show={filterState.MaxLandsReached}
                            variant="danger"
                        >
                            Maximum record limit ({MAX_LANDS_TO_DISPLAY}) exceeded. Please apply additional filters.
                        </Alert>
                        <Form id='filter-form'>
                            <Row>
                                <Col xs='12' sm='12' md='5' lg='2' xl='2' xxl='2' className='mt-1 p-1' data-testid='filterCountyDiv'>
                                    <Form.Group controlId='filterCounty'>
                                        <Form.Label className='{styles.nowrap}'>County</Form.Label>
                                        <Select
                                            name='county'
                                            closeMenuOnSelect={false}
                                            data-testid='testFilterCounty'
                                            id='filterCounty'
                                            isClearable={false}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedCounty, option)}
                                            isMulti
                                            options={CoverageAndPremiumLines.Counties}
                                            value={selectedCounty}
                                            isDisabled={showWarning}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='12' sm='12' md='7' lg='3' xl='3' xxl='3' className='mt-1 p-1' data-testid='filterCoverageDiv'>
                                    <Form.Group controlId='filterCoverage'>
                                        <Form.Label className='{styles.nowrap}'>Coverage</Form.Label>
                                        <Select
                                            name='Coverage'
                                            closeMenuOnSelect={false}
                                            data-testid='testFilterCoverage'
                                            id='filterCoverage'
                                            isClearable={false}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedCoverages, option)}
                                            isMulti
                                            options={coverageOptions}
                                            value={selectedCoverage}
                                            isDisabled={showWarning}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='12' sm='12' md='12' lg='7' xl='7' xxl='7' className='mt-1 p-1' data-testid='filterUnitNumberDiv'>
                                    <Form.Group controlId='filterUnitNumber'>
                                        <Form.Label className='{styles.nowrap}'>Unit Number</Form.Label>

                                        {useUnitMultiSelect ? (
                                            <Select
                                                name='UnitNumber'
                                                closeMenuOnSelect={false}
                                                id='filterUnitNumber'
                                                isClearable={false}
                                                isMulti
                                                options={unitNumberOptions}
                                                isDisabled={showWarning}
                                                onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedUnits, option)}
                                                value={selectedUnitNumber}
                                            />
                                        ) : (
                                            <Select
                                                name='UnitNumber'
                                                closeMenuOnSelect={true}
                                                id='filterUnitNumber'
                                                isClearable={false}
                                                isMulti={false}
                                                options={unitNumberOptions}
                                                isDisabled={showWarning}
                                                onChange={(option: SingleValue<ISelectList>) => handleChangeShowWarning(handleSelectedUnits, option)}
                                                value={selectedUnitNumber}
                                            />
                                        )}

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='6' sm='6' md='4' lg='2' xl='2' xxl='2' className='mt-1 p-1' data-testid='filterFNDiv'>
                                    <Form.Group controlId='filterFN'>
                                        <Form.Label className='{styles.nowrap}'>FN</Form.Label>
                                        <Select
                                            name='county'
                                            closeMenuOnSelect={false}
                                            data-testid='testFilterCounty'
                                            id='filterCounty'
                                            isClearable={false}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedFarmNumber, option)}
                                            isMulti
                                            options={filter.FarmNumbers}
                                            value={selectedFarmNumber}
                                            isDisabled={showWarning}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='6' sm='6' md='4' lg='2' xl='2' xxl='2' className='mt-1 p-1' data-testid='filterTractDiv'>
                                    <Form.Group controlId='filterTract'>
                                        <Form.Label className='{styles.nowrap}'>Tract</Form.Label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            data-testid='testFilterTract'
                                            id='filterTract'
                                            isClearable={false}
                                            isMulti
                                            options={filter.TractNumbers}
                                            isDisabled={showWarning}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedTractNumber, option)}
                                            value={selectedTractNumber}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='6' sm='6' md='4' lg='2' xl='2' xxl='2' className='mt-1 p-1' data-testid='filterSecTwpRngDiv'>
                                    <Form.Group controlId='filterSecTwpRng'>
                                        <Form.Label className='{styles.nowrap}'>Sec/Twp/Rng</Form.Label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            data-testid='testFilterSecTwpRng'
                                            id='filterSecTwpRng'
                                            isClearable={false}
                                            isDisabled={showWarning}
                                            isMulti
                                            options={filter.Sections}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedSection, option)}
                                            value={selectedSection}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='6' sm='6' md='3' lg='2' xl='2' xxl='2' className='mt-1 p-1' data-testid='filterPlaceNameDiv'>
                                    <Form.Group controlId='filterPlaceNameCtrl'>
                                        <Form.Label className='{styles.nowrap}'>Place Name</Form.Label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            data-testid='testfilterPlaceName'
                                            id='filterPlaceName'
                                            isDisabled={showWarning}
                                            isClearable={false}
                                            isMulti
                                            options={filter.PlaceNames}
                                            onChange={(option: MultiValue<ISelectList>) => handleChangeShowWarning(handleSelectedPlaceName, option)}
                                            value={selectedPlaceName}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='6' sm='6' md='3' lg='1' xl='1' xxl='1' className='mt-1 p-1' data-testid='filterBasicUnitDiv'>
                                    <Form.Group controlId='filterBasicUnitCtrl'>
                                        <Form.Label className='{styles.nowrap}'>BU</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            data-testid='testFilterBasicUnit'
                                            id='filterBasicUnit'
                                            isDisabled={showWarning}
                                            isClearable={true}
                                            isMulti={false}
                                            options={filter.BasicUnits}
                                            onChange={(option: SingleValue<ISelectList>) => handleChangeShowWarning(handleSelectedBasicUnit, option)}
                                            value={selectedBasicUnit}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs='6' sm='6' md='3' lg='1' xl='1' xxl='1' className='mt-1 p-1' data-testid='filterOptionalUnitDiv'>
                                    <Form.Group controlId='filterOptionalUnitCtrl'>
                                        <Form.Label className='{styles.nowrap}'>OU</Form.Label>
                                        <Select
                                            closeMenuOnSelect={true}
                                            data-testid='testFilterOptionalUnit'
                                            id='filterOptionalUnit'
                                            isDisabled={showWarning}
                                            isClearable={true}
                                            isMulti={false}
                                            options={filter.OptionalUnits}
                                            onChange={(option: SingleValue<ISelectList>) => handleChangeShowWarning(handleSelectedOptionalUnit, option)}
                                            value={selectedOptionalUnit} />
                                    </Form.Group>
                                </Col>
                                <Col xs='12' sm='6' md='3' lg='2' xl='2' xxl='2' className='mt-1 p-1'>
                                    <Row>
                                        <Col xs='6' sm='6' md='6' lg='7' xl='9' xxl='8' data-testid='btnShowNLFUnitsDiv'>
                                            <Form.Group controlId='btnShowNLFUnitsCtrl'>
                                                <Form.Label className='{styles.nowrap}'>Show NLF Units</Form.Label>
                                                <OverlayTrigger delay={{ show: 100, hide: 200 }} overlay={<Tooltip>Show/Hide 'No Longer Farming' Units</Tooltip>} placement='bottom'>
                                                    <Form.Check
                                                        className={styles.nowrap}
                                                        data-testid='btnShowNLFUnits'
                                                        id='btnShowNLFUnits'
                                                        onChange={e => handleChangeShowWarning(onShowNLFsClick)}
                                                        type='switch'
                                                        checked={!!showNLFUnitNumber}
                                                    />
                                                </OverlayTrigger>
                                            </Form.Group>
                                        </Col>
                                        <Col xs='6' sm='6' md='6' lg='5' xl='3' xxl='4' className='mt-4 p-1'>
                                            <OverlayTrigger placement='bottom' overlay={<Tooltip>Clear Filters</Tooltip>}>
                                                <Button
                                                    data-testid='btnClearFilters'
                                                    variant='outline-accent5'
                                                    onClick={() => handleChangeShowWarning(onClearFilterClick)}
                                                >
                                                    <i className='bi bi-eraser'></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
}
export default Filter;
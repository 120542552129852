import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useFormik } from "formik";
import * as yup from 'yup';
import { URLs, ERRORS } from '../../../commom/constant';

//Import API gateway
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import { IShareholder } from './viewmodels/IShareholder';
import { IShareholderSearch } from './viewmodels/IShareholderSearch';
import { ErrorUtility } from '../../../manager/common/errorUtility';

export interface INewShareholder {
    FirstName: string;
    MiddleName?: string;
    LastName: string;
    Suffix?: string;
    IsBusiness?: boolean;
    BusinessName: string;
    MpciPolicyOID: string;
}

const initialValues: INewShareholder = {
    IsBusiness: false,
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Suffix: '',
    BusinessName: '',
    MpciPolicyOID: ''
}

interface IProps {
    growerId: string;
    mpciPolicyId: string;
    setIsLoading: (value: boolean) => void;
    addShareholderHandler: (value: IShareholder) => void;
    errorHandler: (value: string) => void;
    shareholderSearch: IShareholderSearch;
}

const validationSchema = yup.object().shape({
    IsBusiness: yup.boolean(),
    FirstName: yup.string().when("IsBusiness", {
        is: false,
        then: yup.string().required('First Name is required')
    }),
    LastName: yup.string().when("IsBusiness", {
        is: false,
        then: yup.string().required('Last Name is required')
    }),
    BusinessName: yup.string().when("IsBusiness", {
        is: true,
        then: yup.string().required('Business Name is required')
    }),
});



export default function AddNewShareholder(props: IProps) {

    const api = useApiGateway();//Init API Gateway
    const [checked, setChecked] = useState(false);
    const inputFirstName = useRef<HTMLInputElement>(null);
    const inputBusinessName = useRef<HTMLInputElement>(null);
    const postShareholderData = async (growerid: string, mpcipolicyid: string, mpciShareholder: INewShareholder) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders";
        const res = await api.post(shareholderapiurl, ApplicationIdValues.Paw, mpciShareholder);
        return res;
    };

    const getShareholderData = async (growerid: string, mpcipolicyid: string, shareholderid: string) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders/" + shareholderid+"?includeCoverages=false";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    };
    const getShareholderCoveragesData = async (growerid: string, mpcipolicyid: string, shareholderid: string, shareholderSearch: IShareholderSearch) => {
        const coverageapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders/" + shareholderid +"/Coverages/AdvancedSearch";
        const res = await api.post(coverageapiurl, ApplicationIdValues.Paw, shareholderSearch);
        return res;
    };
    const handleChange = () => {
        setChecked(!checked);
        formik.resetForm({
            values: {
                IsBusiness: !checked,
                FirstName: '',
                MiddleName: '',
                LastName: '',
                Suffix: '',
                BusinessName: '',
                MpciPolicyOID: ''
            },
            errors: {},
            submitCount: 0,
        });
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            props.setIsLoading(true);
            values.MpciPolicyOID = props.mpciPolicyId;
            postShareholderData(props.growerId, props.mpciPolicyId, values)
                .then((data) => {
                    formik.resetForm();
                    let shareholderId = data.Ids[0];
                    getShareholderData(props.growerId, props.mpciPolicyId, shareholderId)
                        .then((data) => {
                            let shareholder: IShareholder;
                            shareholder = data;
                            let shareholderSearch = props.shareholderSearch;
                            shareholderSearch.IsAssociated = false;
                            getShareholderCoveragesData(props.growerId, props.mpciPolicyId, shareholderId, shareholderSearch)
                                .then((data) => {
                                    shareholder.ShareholderCoverages = data;
                                    props.addShareholderHandler(shareholder);
                                    props.setIsLoading(false);

                                    inputFirstName?.current?.focus();
                                    inputBusinessName?.current?.focus();
                                })
                                .catch((err) => {
                                    props.setIsLoading(false);
                                    console.log(err);
                                    if (!ErrorUtility.handleApiGatewayError(err, props.errorHandler)) {
                                        props.errorHandler(ERRORS.get_coverages_api_failed);
                                    }
                                });
                        })
                        .catch((err) => {
                            props.setIsLoading(false);
                            console.log(err);
                            if (!ErrorUtility.handleApiGatewayError(err, props.errorHandler)) {
                                props.errorHandler(ERRORS.get_shareholders_api_failed);
                            }
                        });
                })
                .catch((err) => {
                    props.setIsLoading(false);
                    console.log(err);
                    if (!ErrorUtility.handleApiGatewayError(err, props.errorHandler)) {
                        props.errorHandler(ERRORS.add_shareholders_api_failed);
                    }
                });
        }
    });

    return (
        <Container fluid >
            <div className="m-1 square border border-1 border-dark">
                <h5 className="m-2">New Shareholder</h5>
                <Form className="m-3" onSubmit={formik.handleSubmit} noValidate>
                    <Row className="text-center">
                        <Form.Group as={Col} md="2" controlId="isBusiness">
                            <div className='d-flex justify-content-evenly align-items-center mt-3 h-100 w-100'>
                                <span>Person</span>
                                <Form.Check type="switch" id="isBusiness" className="switchInput" data-testid="formIsBusinessInput"
                                    onChange={handleChange}
                                />
                                <span>Business</span>
                            </div>
                        </Form.Group>

                        <Col md="9">
                            {!checked && <Row id="frmIndivisual" >

                                <Form.Group as={Col} controlId="FirstName" data-testid="formFirstName">
                                    <Form.Label className="text-center">First Name</Form.Label>
                                    <Form.Control
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.FirstName && !formik.errors.FirstName}
                                        isInvalid={formik.touched.FirstName && !!formik.errors.FirstName}
                                        maxLength={20}
                                        value={formik.values.FirstName}
                                        ref={inputFirstName}
                                        data-testid="formFirstNameInput"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.FirstName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} controlId="MiddleName" data-testid="formMiddleName">
                                    <Form.Label>Middle Name</Form.Label>
                                    <Form.Control
                                        onChange={formik.handleChange}
                                        maxLength={20}
                                        value={formik.values.MiddleName}
                                        data-testid="formMiddleNameInput"
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="LastName" data-testid="formLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        onChange={formik.handleChange}
                                        isValid={formik.touched.LastName && !formik.errors.LastName}
                                        isInvalid={formik.touched.LastName && !!formik.errors.LastName}
                                        maxLength={50}
                                        value={formik.values.LastName}
                                        data-testid="formLastNameInput"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.LastName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="2" controlId="Suffix" data-testid="formSuffix">
                                    <Form.Label>Suffix</Form.Label>
                                    <Form.Control className="mx-auto w-75"
                                        onChange={formik.handleChange}
                                        maxLength={10}
                                        value={formik.values.Suffix}
                                        data-testid="formSuffixInput" />
                                </Form.Group>
                            </Row>}

                            {checked && <Row id="frmBusiness">
                                <Form.Group as={Col} controlId="BusinessName" data-testid="formBusinessName">
                                    <Form.Label>Business Name</Form.Label>
                                    <Form.Control onChange={formik.handleChange}
                                        isValid={formik.touched.BusinessName && !formik.errors.BusinessName}
                                        isInvalid={formik.touched.BusinessName && !!formik.errors.BusinessName}
                                        maxLength={50}
                                        value={formik.values.BusinessName}
                                        ref={inputBusinessName}
                                        data-testid="formBusinessNameInput"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {formik.errors.BusinessName}
                                    </Form.Control.Feedback>
                                </Form.Group>

                            </Row>}
                        </Col>
                        <Col md="1" className="d-flex align-items-center h-200" >
                            <Button variant="default" type="submit" data-testid="btnAdd">Add</Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </Container>
    );
}
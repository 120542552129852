import React, { useRef } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';

function InputValidation(props: any): JSX.Element  {
    const { label, onChange, onBlur, message, floatLabel, error, innerRef, ...rest } = props;
    const inputRef = useRef<HTMLInputElement>(null)

    const formControl = (
        <Form.Control
            data-testid={rest.name}
            onChange={onChange}
            onBlur={onBlur}
            ref={!!innerRef ? innerRef : inputRef}
            isInvalid={!!error}
            { ...rest }
        />
    )

    return (
        <Form.Group>
            {floatLabel ? (
                <FloatingLabel label={label}>
                    {formControl}
                    <Form.Control.Feedback data-testid={`invalid-${rest.name}`} type="invalid">{error}</Form.Control.Feedback>
                </FloatingLabel>
            ) : (
                <>
                    ({label ? <Form.Label>{label}</Form.Label> : null})
                    {formControl}
                    <Form.Control.Feedback data-testid={`invalid-${rest.name}`} type="invalid">{error}</Form.Control.Feedback>
                </>
            )
            }
            
        </Form.Group>
    )
}

export default InputValidation;
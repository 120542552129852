import React, { useEffect, useState, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { SORTOPTION, MAX_LANDS_TO_DISPLAY } from "../../../commom/constant";
import { FilterUtility } from '../../../manager/lands/filterUtility';
import { GlobalState } from '../../../store';
import * as FilterSaga from '../../../store/filter';
import * as LandSaga from '../../../store/land';
import ViewFarmLand from "./viewFarmLand";
import styles from './viewFarmLands.module.scss';
import { ICoverageAndPremiumLines } from './viewmodels/iCoverageAndPremiumLines';
import * as LoadSpinnerSaga from '../../../store/loadingSpinner';
import { useDispatch } from "react-redux";
import { findIndex, forEach, isEqual, omit } from 'lodash';
import { setLandsHasEdit } from "store/land/actions";
import * as FieldsSaga from '../../../store/field';
import * as PremSaga from '../../../store/premiumLineInformation';
import { PolicyUtility } from '../../../manager/lands/policyUtility';
import { IViewLand } from './viewmodels/iViewLands';
import { useApiGateway } from "../../../components/apiGateway/useApiGateway";
import { LandsContext } from './landsContext';

interface ViewFarmLandsProps extends ICoverageAndPremiumLines {
    collapseAll: boolean;
}

function ViewFarmLands({ collapseAll, ...coverageAndPremiumLines }: ViewFarmLandsProps) {
    //Selectors
    const landState = useSelector<GlobalState, LandSaga.LandState>((state) => state.landState);
    const filterState = useSelector<GlobalState, FilterSaga.FilterState>((state) => state.filterState);
    const selectFieldsStore = useSelector<GlobalState, FieldsSaga.FieldsState>((state) => state.fieldsState);
    const premiumLineStore = useSelector<GlobalState, PremSaga.PremiumLineInformation>((state) => state.premiumLineInformationState);

    //state
    const [lands, setLands] = useState<IViewLand[]>([]);
    const [showUnitNumberClick, setShowUnitNumberClick] = useState(false);
    const [viewAllFields, setViewAllFields] = useState(false);
    const [sortIcon, setSortIcon] = useState('bi bi-sort-up');
    const [filterCounty, setFilterCounty] = useState('');
    const [filterCoverage, setFilterCoverage] = useState('');
    const [filterUnitNumber, setFilterUnitNumber] = useState('');
    const [selectedOptionToSort, setSelectedOptionToSort] = useState(SORTOPTION.None.toString());
    const [isAscendingSortType, setIsAscendingSortType] = useState(true);
    let sortedCoverageDetails: IViewLand[] = [];

    //defaults
    const landsContext = useContext(LandsContext);
    const dispatch = useDispatch();
    const api = useApiGateway();

    const onShowUnitNumberClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setShowUnitNumberClick((current: boolean) => !current);
    }

    const onExpandCLUsClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setViewAllFields((e.target as HTMLInputElement).checked);
    }

    function sort() {
        let isAscending = true;
        if (sortIcon == 'bi bi-sort-down') {
            setSortIcon('bi bi-sort-up')
            setIsAscendingSortType(isAscending);
        } else {
            setSortIcon('bi bi-sort-down')
            isAscending = false;
            setIsAscendingSortType(isAscending);
        }
        sortCoverages(selectedOptionToSort, isAscending)
    }

    useEffect(() => {
        premiumLineUpdate();
    }, [selectFieldsStore]);

    useEffect(() => {
        var filteredLands = FilterUtility.getFilteredData(landState, filterState, coverageAndPremiumLines.MpciPremiumLines);

        if (filteredLands.length > MAX_LANDS_TO_DISPLAY) {
            filteredLands = [];
            dispatch(FilterSaga.maxLandsReached(true))
        } else if (filterState.MaxLandsReached == undefined || filterState.MaxLandsReached == true) {
            dispatch(FilterSaga.maxLandsReached(false))
        }
        
        getFilteredCounty();
        getFilteredCoverage();
        getFilteredUnitNumber();

        sortedCoverageDetails = FilterUtility.sortCoverageDetails(filteredLands, selectedOptionToSort, isAscendingSortType)!;
        if (sortedCoverageDetails) {
            setLands([...sortedCoverageDetails]);
        }
        else {
            setLands([]);
        }


        updateFieldStore(FilterUtility.premiumLineList(sortedCoverageDetails));

    }, [filterState, landState.Lands]);

    const premiumLineUpdate = () => {
        var updateAcresActions = PolicyUtility.generatePremiumLineReportedAcres(selectFieldsStore.FieldsStore, premiumLineStore)
        updateAcresActions.forEach(action => {
            dispatch(PremSaga.updatePremiumLineTotalAcres(action.PremuimLineOid, action.LandOid, action.Oid, action.ReportedUnitAcres))
        });
    }

    useEffect(() => {
        dispatch(LoadSpinnerSaga.disableLoadingSpinner());
        if (sortedCoverageDetails) {
            dispatch(FilterSaga.setFilteredLands(sortedCoverageDetails));
        }
    }, [landState.Lands]);

    const sortCoverages = (options: string, isAscending: boolean): void => {
        var sortedCoverageDetails = FilterUtility.sortCoverageDetails(lands, options, isAscending);
        if (sortedCoverageDetails) {
            setLands([...sortedCoverageDetails]);
        }
    }

    const getFilteredCounty = (): void => {
        var county = filterState.Filter.Counties;
        if (county && county.length == 1) {
            setFilterCounty(filterState.Filter.Counties[0].label.toString());
        } else {
            setFilterCounty('');
        }
    }

    const getFilteredCoverage = (): void => {
        var coverage = filterState.Filter.Coverages;
        if (coverage && coverage.length == 1) {
            setFilterCoverage(filterState.Filter.Coverages[0].label.toString());
        } else {
            setFilterCoverage('');
        }
    }

    const getFilteredUnitNumber = (): void => {
        var unitNumber = filterState.Filter.UnitNumbers;
        if (unitNumber && unitNumber.length == 1) {
            setFilterUnitNumber(filterState.Filter.UnitNumbers[0].label.toString());
        } else {
            setFilterUnitNumber('');
        }
    }

    function updateFieldStore(lands: number[]) {
        lands.forEach(land => {
            let fieldRequests = PolicyUtility.getAllFieldsRequestByPremiumLineOid(selectFieldsStore.FieldsStore, api, landsContext.ReinsuranceYear, land)

            if (fieldRequests && fieldRequests.length > 0) {
                fieldRequests.forEach(request => {
                    dispatch(FieldsSaga.getFields(request))
                })
            }
        })
    }

    const updateLand = (updatedLand: IViewLand, mpciPremiumLineId?: number): void => {
        const nextLands = [...lands];
        if (updatedLand.IsPrimary && mpciPremiumLineId) {
            const toggleIndex = nextLands.findIndex((l) => Number(l.UnitNumber?.value) === mpciPremiumLineId && l.IsPrimary === true)
            if (toggleIndex >= 0) {
                nextLands.splice(toggleIndex, 1, { ...nextLands[toggleIndex], IsPrimary: false, IsSnapshotMatching: isSnapshotMatching({ ...nextLands[toggleIndex], IsPrimary: false }) })
            }
        }
        const index = findIndex(nextLands, { OID: updatedLand.OID })
        nextLands.splice(index, 1, { ...updatedLand, IsSnapshotMatching: isSnapshotMatching(updatedLand) })
        if (!landState.HasEdit) {
            dispatch(setLandsHasEdit(true))
        }
        setLands(nextLands)
    }

    const isSnapshotMatching = (modifiedLand: IViewLand): boolean => {
        const omitValues = ['Snapshot', 'IsSnapshotMatching', 'TotalAcres', 'HasError']
        return isEqual(
            omit({ ...modifiedLand, TractNumber: modifiedLand.TractNumber ?? '', Section: modifiedLand.Section?.label ?? '' }, omitValues),
            omit({ ...modifiedLand.Snapshot, TractNumber: modifiedLand?.Snapshot?.TractNumber ?? '', Section: modifiedLand?.Snapshot?.Section?.label ?? '' }, omitValues)
        )
    }

    const calculateLock = (land: IViewLand): boolean => {
        if (land.CompanionFlag == 'C' || land.CompanionFlag == 'BC') {
            return true;
        }
        if (land.MpciPremiumLineOID && premiumLineStore.PremiumLines) {
            let premiumLine = premiumLineStore.PremiumLines.find(prem => prem.PremiumLineOid == land.MpciPremiumLineOID)
            if (premiumLine) {
                return !premiumLine.UserCanEditPremiumLine;
            }
        }
        return false;
    }

    return (
        <>
            <Col className={styles.viewFarmLandsCoverageDetailsHeader}>
                <Row>
                    {filterCounty.length > 0 && <Col xs='12' sm="12" md="2" lg='2' xl='2' xxl='2' data-testid="selectedCountyName" >
                        <div style={{ fontWeight: "bold", fontSize: 14 }} className='h5 d-flex text-center align-items-center'>
                            {filterCounty}
                        </div>
                    </Col>
                    }
                    {filterCoverage.length > 0 && <Col xs='12' sm="12" md="4" lg='4' xl='4' xxl='3' data-testid="selectedCoverageName" >
                        <div style={{ fontWeight: "bold", fontSize: 14 }} className='h5 d-flex text-center align-items-center'>
                            {filterCoverage}
                        </div>

                    </Col>
                    }
                    {filterUnitNumber.length > 0 && <Col xs='12' sm="12" md="6" lg='6' xl='6' xxl='7' data-testid="selectedUnitNumber">
                        <div style={{ fontWeight: "bold", fontSize: 14 }} className='h5 d-flex text-center align-items-center'>
                            {filterUnitNumber}
                        </div>
                    </Col>
                    }
                </Row>
            </Col>
            <Col className={styles.viewFarmLandsOptions}>
                <Row>
                    <Col >
                        <Form.Check className={styles.nowrap} type="switch" data-testid="btnShowUnitNumber" id="btnShowUnitNumber"
                            label={'Show Coverage/Units'} defaultChecked={showUnitNumberClick} onClick={e => onShowUnitNumberClick(e)}
                        />
                    </Col>
                    <Col >
                        <Form.Check type="switch" data-testid="btnExpandCLUs" id="btnExpandCLUs"
                            label={'View All Fields'} onClick={e => onExpandCLUsClick(e)}
                        />
                    </Col>
                    <Col >
                        <Row>
                            <Col xs='11' sm="11" md="11" lg='10' xl='10' xxl='10' className='p-1'>
                                <Form.Select
                                    id={`sort`}
                                    data-testid={`sortList`}
                                    value={selectedOptionToSort}
                                    onChange={(e) => {
                                        setSelectedOptionToSort(e.target.value)
                                        sortCoverages(e.target.value, isAscendingSortType)
                                    }}
                                >
                                    <option value='Sort by...'>{'Sort by...'}</option>
                                    <option value='County'>{'County'}</option>
                                    <option value='Coverage'>{'Coverage'}</option>
                                    <option value='BU'>{'BU'}</option>
                                    <option value='OU'>{'OU'}</option>
                                    <option value='FN'>{'FN'}</option>
                                    <option value='Tract'>{'Tract'}</option>
                                    <option value='STR'>{'Sec/Twn/Rng'}</option>
                                    <option value='PlaceName'>{'Place Name'}</option>
                                    <option value='OtherId'>{'Other Id'}</option>
                                </Form.Select>
                            </Col>
                            <Col lg={1} xs={1} className='p-1 text-center align-items-center d-flex'>
                                <i className={sortIcon} onClick={sort} data-testid={`sortIcon`} style={{ fontSize: 20 }}></i>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
            {
                lands.length === 0 &&
                <Col xs={12} className='mt-3'>
                    <Row>
                        <div>No results</div>
                    </Row>
                </Col>
            }
            <div>
                {
                    lands
                        .map((land: IViewLand, i: number) => (
                            <ViewFarmLand
                                ShowUnitNumber={showUnitNumberClick}
                                CoverageAndPremiumLines={coverageAndPremiumLines}
                                ViewFSN={land}
                                ViewAllFields={viewAllFields}
                                key={land.OID}
                                updateLands={updateLand}
                                EditLock={calculateLock(land)}
                                CollapseAll={collapseAll}
                                index={i}
                            />
                        ))
                }
            </div>
        </>
    )
}

export default ViewFarmLands;
import { URLs } from '../../commom/constant';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { RefreshHighRiskLandIdResult } from '../../store/land';
import { ICluDeterminationCode } from '../../views/mpci/lands/viewmodels/iCluDeterminationCode';
import { ICounty } from '../../views/mpci/lands/viewmodels/iCounty';
import { MpciPremiumLineLandIdUpdates } from '../../views/mpci/lands/viewmodels/iMpciPremiumLineLandIdUpdates';
import { SaveCoverageResponse } from '../../views/mpci/lands/viewmodels/iSaveCoverageResponse';
import { ISecTwpRngReposList } from '../../views/mpci/lands/viewmodels/iSecTwpRngReposList';
import { IState } from '../../views/mpci/lands/viewmodels/iState';
import { IValidateLandId } from '../../views/mpci/lands/viewmodels/iValidateLandId';
import { IViewField } from '../../views/mpci/lands/viewmodels/iViewField';
import { IMpciPolicy } from './model/iMpciPolicy';

export const getMpciPolicy = (growerId: string, mpciPolicyId: string, fullStructure: boolean, api: ApiGateway): Promise<IMpciPolicy> => {
    return api.get(URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "?fullStructure=" + fullStructure, ApplicationIdValues.Paw);
};

export const getSecTwpRng = (stateCode: string, countyCode: string, farmNumber: string, tractNumber: string, api: ApiGateway): Promise<ISecTwpRngReposList[]> => {
    var servicePath = getSecTwpRngUrl(stateCode, countyCode, farmNumber, tractNumber);
    const res = api.get(servicePath, ApplicationIdValues.Paw);
    return res;
};

export const getAddNewFields = (mpciPolicyOid: number, coverageOid: string, premiumLineOid: string, farmNumber: string, tract: string, api: ApiGateway): Promise<IViewField[]> => {
    let url = ''
    if (tract != '') {
        url = URLs.paw_api_version + `/Growers/MpciPolicies/${mpciPolicyOid}/MpciCoverages/${coverageOid}/MpciPremiumLines/${premiumLineOid}/FarmNumbers/${farmNumber}/TractNumbers/${tract}/Fields`;
    } else {
        url = URLs.paw_api_version + `/Growers/MpciPolicies/${mpciPolicyOid}/MpciCoverages/${coverageOid}/MpciPremiumLines/${premiumLineOid}/FarmNumbers/${farmNumber}/Fields`;
    }

    return api.get(url, ApplicationIdValues.Paw);
}

function getSecTwpRngUrl(stateCode: string, countyCode: string, farmNumber: string, tractNumber: string) {
    if (tractNumber != '') {
        return URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${farmNumber}/Tracts/${tractNumber}/SectionTownshipRanges`;
    } else {
        return URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${farmNumber}/SectionTownshipRanges`;
    }
}

export const getCounties = (stateCode: string, api: ApiGateway): Promise<ICounty[]> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties`, ApplicationIdValues.Paw);;
};

export const getStates = (api: ApiGateway): Promise<IState[]> => {
    return api.get(URLs.paw_api_version + "/States", ApplicationIdValues.Paw);
};

export const getDeteminationCodes = (reinsuranceYear: number, api: ApiGateway): Promise<ICluDeterminationCode[]> => {
    return api.get(URLs.paw_api_version + `/ReinsuranceYears/${reinsuranceYear}/CluDeterminationCodes`, ApplicationIdValues.Paw);
};

export const getCIMSCLUAsOfDates = (api: ApiGateway): Promise<string[]> => {
    return api.get(URLs.paw_api_version + "/CIMSCLUAsOfDates", ApplicationIdValues.Paw);
};

export const validateFarmNumber = (stateCode: string, countyCode: string, FarmNumber: string, api: ApiGateway): Promise<IValidateLandId> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${FarmNumber}/IsValidLandId`,ApplicationIdValues.Paw);
}

export const validateFarmandTractNumber = (stateCode: string, countyCode: string, FarmNumber: string, tractNumber: string, api: ApiGateway): Promise<IValidateLandId> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${FarmNumber}/Tracts/${tractNumber}/IsValidLandId`,ApplicationIdValues.Paw);
}

export const validateFarmTractAndSection = (stateCode: string, countyCode: string, FarmNumber: string, tractNumber: string, sectionTownshipRange: string, api: ApiGateway): Promise<IValidateLandId> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${FarmNumber}/Tracts/${tractNumber}/SectionTownshipRanges/${sectionTownshipRange}/IsValidLandId`,ApplicationIdValues.Paw);
}

export const validateFarmNumberSectionTownshipRange = (stateCode: string, countyCode: string, FarmNumber: string, sectionTownshipRange: string, api: ApiGateway): Promise<IValidateLandId> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${FarmNumber}/SectionTownshipRanges/${sectionTownshipRange}/IsValidLandId`, ApplicationIdValues.Paw);
}

export const validateSectionTownshipRange = (stateCode: string, countyCode: string, sectionTownshipRange: string, api: ApiGateway): Promise<IValidateLandId> => {
    return api.get(URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/SectionTownshipRanges/${sectionTownshipRange}/IsValidLandId`,ApplicationIdValues.Paw);
}

export const updateCoverageLegals = (api: ApiGateway, growerOid: number, policyOid: number, landIdUpdates: MpciPremiumLineLandIdUpdates): Promise<SaveCoverageResponse> => {
    return api.post(URLs.paw_api_version + `/Growers/${growerOid}/MpciPolicies/${policyOid}/Lands/Update`, ApplicationIdValues.Paw, landIdUpdates);
}

export const updatePremiumLines = (api: ApiGateway, growerOid: number, policyOid: number, premiumLineUpdates: number[]): Promise<SaveCoverageResponse> => {
    return api.put(URLs.paw_api_version + `/Growers/${growerOid}/MpciPolicies/${policyOid}/PremiumLines`, ApplicationIdValues.Paw, { 'PremiumLinesToProcess': premiumLineUpdates });
}

export const refreshHighRisk = (api: ApiGateway,  mpciPolicyId: string, mpciPremiumLineLandIdOids: number[]): Promise<RefreshHighRiskLandIdResult[]> => {
    let url = URLs.paw_api_version + `/Growers/MpciPolicies/${mpciPolicyId}/Lands/Fields/RefreshHighRisk`;
    return api.put(url, ApplicationIdValues.Paw, mpciPremiumLineLandIdOids);
};
import React, { useEffect, useState, KeyboardEvent } from "react";

import {
    ExpandedState,
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getFilteredRowModel,
    getExpandedRowModel,
    ColumnDef,
    flexRender,
    Cell,
    SortingState,
    Row,
    getSortedRowModel,
} from "@tanstack/react-table";

import {
    FilterIcon,
    ChevronIcon,
    ExpandedChevronIcon,
    EditIcon,
    DeleteIcon,
} from "../../../components/columnfilter/Icons";
import CoverageSummary from "./CoverageSummary";

import styles from "./Shareholders.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ShareholderMedia.scss";
import { URLs, ERRORS, WARNINGS, TITLE, OPERATION, WINDOWTYPE, ExpandCollapseState } from "../../../commom/constant";
import { ShareholderVM } from "./viewmodels/ShareholderVM";
import { IShareholderInfo } from "./viewmodels/IShareholderInfo";
import EditShareholder from "./EditShareholder";
import { IShareholderCoverage } from "./viewmodels/IShareholderCoverage";
import { ShareholderCoverageVM } from "./viewmodels";
import { Form } from "react-bootstrap";
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import { ConfirmMessageModalWindow } from './../../../components/warningMessageModal/ConfirmMessageModalWindow';
import FilterShareholderSummary from "./FilterShareholderSummary";
import { IShareholderSearch } from "./viewmodels/IShareholderSearch";
import shareholdersEventBus from "./ShareholdersEventBus";
import { ErrorUtility } from "../../../manager/common/errorUtility";
import { IShareholder } from "./viewmodels/IShareholder";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../store";
import * as FeatureFlagsSaga from '../../../store/proAgFeatureFlags';
import { ProAgFeatureFlags } from "../../../manager/common/proAgFeatureFlags";

interface props {
    growerId: string;
    mpciPolicyId: string;
    shareholders: ShareholderVM[];
    setShareholderDataHandler: (shareholderData: ShareholderVM[]) => void;
    errorHandler: (error: string) => void;
    setIsLoading: (setIsLoading: boolean) => void;
    shareholderSearch: IShareholderSearch;
    shareholderSearchHandler: (shareholderSearchHandler: IShareholderSearch, expandCollapseState : ExpandCollapseState) => void;
    expandCollapseState: ExpandCollapseState;
    canEditPremiumLineShareholders?: boolean;
}



const ShareholdersSummary: React.FC<props> = ({
    growerId,
    mpciPolicyId,
    shareholders,
    setShareholderDataHandler,
    errorHandler,
    setIsLoading,
    shareholderSearch,
    shareholderSearchHandler,
    expandCollapseState,
    canEditPremiumLineShareholders
}) => {
    const [shareholdersData, setShareholdersData] = useState(shareholders);
    const [shareholdersToDisplay, setshareholdersToDisplay] =
        useState(shareholdersData);
    const [showGrowerView, setShowGrowerView] = React.useState<boolean>(false);
    const api = useApiGateway();//Init API Gateway

    const saveCoverageDataToPAW = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined,
        dataToSend: IShareholderCoverage[]
    ) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId + "/Coverages/";
        const res = await api.put(shareholderapiurl, ApplicationIdValues.Paw, dataToSend);
        return res;
    };
    const getShareholderData = async (growerid: string, mpcipolicyid: string, shareholderid: string) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "/Shareholders/" + shareholderid + "?includeCoverages=false";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    };
    const featureFlags = useSelector<GlobalState, FeatureFlagsSaga.FeatureFlagState>((featureFlags) => featureFlags.featureFlagState);

    const feature91701 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.Feature91701).map((y) => y.enabled).toString();
    useEffect(() => {
        setShareholdersData(() => shareholders);
    }, [shareholders]);
    useEffect(() => {
        let filtered = filterShareholders();
        setshareholdersToDisplay(filtered);
    }, [shareholdersData, showGrowerView]);

    useEffect(()=>{
      switch(expandCollapseState){
            case ExpandCollapseState.ExpandAll:
                table.toggleAllRowsExpanded(true);
                break;
            case ExpandCollapseState.CollapsAll:
                table.toggleAllRowsExpanded(false);
                break;
          default : 
                break;
        }
    },[expandCollapseState])


    const filterShareholders =() : ShareholderVM[]=>{
        return shareholdersData.filter((sh) => {
        let select:boolean;
            // Not grower view then select all shareholders
            select = (!showGrowerView) ? !sh.isGrower : true;
            return select;
        });
    }

    const columns: ColumnDef<ShareholderVM>[] = [
        {
            header: "Grower / Shareholder",
            accessorFn: (shareholder) => `${shareholder.getFormatedText()}`,
            enableSorting: true,
            sortDescFirst: true,
        },
        {
            header: "Insure Tenant/ Landlord",
            accessorKey: "insureTenantLandlord",
            accessorFn: (shareholder) =>
                `${shareholder.insureTenantLandlord ? "Yes" : "No"}`,
        },
        {
            header: "Transfer Of Right To Indemnity",
            accessorKey: "transferOfRightToIndemnity",
            accessorFn: (shareholder) =>
                `${shareholder.transferOfRightToIndemnity ? "Yes" : "No"}`,
        },
        {
            header: "Share %",
            accessorKey: "",
        },
    ];

    const [expanded, setExpanded] = React.useState<ExpandedState>({});
    const [sorting, setSorting] = React.useState<SortingState>([
        { id: "Grower / Shareholder", desc: false },
    ]);
    const [editedShareholderData, setEditedShareholderData] =
        React.useState<IShareholderInfo>({
            oid: 0,
            firstName: "",
            lastName: "",
            middleName: "",
            suffix: "",
        });
    const [showEditModal, setShowEditModal] = React.useState<boolean>(false);
    const table = useReactTable({
        data: shareholdersToDisplay,
        columns,
        state: {
            expanded,
            sorting,
            pagination: {
                pageIndex: 0,
                pageSize: shareholdersToDisplay.length,
            },
        },
        onExpandedChange: setExpanded,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: true,
    });
    const deleteShareholderDataFromPAW = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId;
        const res = await api.delete(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    };
    const deleteShareolderData = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined
    ) => {
        setIsLoading(true);
        deleteShareholderDataFromPAW(growerId, mpciPolicyId, shareholderId)
            .then((data) => {
                let currData = [...shareholdersData];
                let elIndex = currData.findIndex((el) => el.oid === shareholderId);
                currData.splice(elIndex, 1);
                setIsLoading(false);
                setShareholderDataHandler(currData);
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err);
                if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                    errorHandler(ERRORS.delete_shareholders_api_failed);
                }
            });
    };

    const associatePremiumLines = (
        growerId: string,
        mpciPolicyId: string,
        shareholderVM: ShareholderVM,
        sharePercentage: number,
        isDelete?: boolean,
        successCallback?: () => void
    ) => {
        let updated: boolean = false;

        setIsLoading(true);

        try {
            let shareholderVMToUpdate = shareholderVM.clone();

            shareholderVMToUpdate.shareholderCoverages.forEach((c) =>
                (c.premiumLines ?? []).forEach((p) => {
                    if (p.isSelected) {
                        updated = true;
                        if (isDelete) {
                            p.share = 0;
                            p.isAssociated = false;
                        } else {
                            p.isAssociated = true;
                            if (p.enableDetaillineShare) {
                                p.share = sharePercentage;
                            } else {
                                p.share = 0;
                            }
                        }
                    }
                })
            );

            if (updated) {
                let dataToSend = ShareholderCoverageVM.toIShareholderCoverages(
                    shareholderVMToUpdate.shareholderCoverages,
                    (c) =>
                        (c.premiumLines ?? []).findIndex((l) => l.isSelected ?? false) > -1,
                    (p) => p.isSelected
                );

                saveCoverageDataToPAW(
                    growerId,
                    mpciPolicyId,
                    shareholderVMToUpdate.oid,
                    dataToSend
                ).then((data) => {
                    if (feature91701! === "true") {
                        if (isDelete) {
                            shareholderVMToUpdate.setCompanionPremiumLines();
                        }
                    }
                    shareholderVMToUpdate.resetPremiumLineSelection();
                    shareholderVMToUpdate.resetErrors();
                    let newData = [...shareholdersData];
                    getShareholderData(growerId, mpciPolicyId, shareholderVMToUpdate.oid!.toString())
                        .then((data) => {
                            let shareholder: IShareholder;
                            shareholder = data;
                            shareholderVMToUpdate.isAssociatedAny = shareholder.IsAssociatedAny;
                            ShareholderVM.upsertShareholderVM(newData, shareholderVMToUpdate, feature91701);
                            setShareholderDataHandler(newData);
                        });

                    if (successCallback) successCallback();
                    shareholdersEventBus.publishAssociationChanged(shareholderVMToUpdate);
                    setIsLoading(false);
                }).catch((data: Response) => {
                    if (data.status == 400) {
                        data.json().then((response) => {
                            let currData = [...shareholdersData];
                            let premiumlineoids = Object.keys(response.Errors);
                            if (premiumlineoids.length > 0) {
                                let newData = currData.map((shareholder) => {
                                    if (shareholder.oid == shareholderVMToUpdate.oid) {
                                        shareholder.shareholderCoverages.map((coverage) => {
                                            coverage.premiumLines?.map((premiumline) => {
                                                if (
                                                    premiumlineoids.find(
                                                        (e) => e == premiumline.oid.toString()
                                                    )
                                                ) {
                                                    premiumline.errors =
                                                        response.Errors[premiumline.oid];
                                                } else {
                                                    premiumline.errors = undefined;
                                                }
                                                return premiumline;
                                            });
                                            return coverage;
                                        });
                                    }
                                    return shareholder;
                                });
                                setShareholderDataHandler(newData);
                            }
                            setIsLoading(false);
                        });
                    } else {
                        setIsLoading(false);
                        if (!ErrorUtility.handleApiGatewayError(data , errorHandler)) {
                            errorHandler(ERRORS.save_shareholderCoverage_api_failed);
                        }

                    }
                });
            }
        } catch (err) {
            console.log(err);
            errorHandler(ERRORS.save_shareholderCoverage_api_failed);
            setIsLoading(false);
        }
    };

    const resetCreatedTime = () => {
        let newData = shareholdersData.map((it) => {
            it.createdTime = 0;
            return it;
        });
        setShareholderDataHandler(newData);
    };
    return (
        <>
            <FilterShareholderSummary shareholderSearch={shareholderSearch} setIsLoading={setIsLoading} errorHandler={errorHandler} growerId={growerId} mpciPolicyId={mpciPolicyId}
                clearFilterHandler={() => setShowGrowerView(false)} shareholderSearchHandler={shareholderSearchHandler}/>
            <div className="p-2">
                <EditShareholder
                    fullData={shareholdersData}
                    setFullData={setShareholderDataHandler}
                    show={showEditModal}
                    growerId={growerId}
                    mpciPolicyId={mpciPolicyId}
                    onHide={() => {
                        setShowEditModal(false);
                    }}
                    shareholderData={editedShareholderData}
                    errorHandler={errorHandler}
                    setIsLoading={setIsLoading}
                />
                <div className="d-flex">
                    <p
                        className={`ps-2 pe-2`}
                    >
                        Hide Grower
                    </p>
              <Form.Check data-testid="displayGrowerView" type="switch" id="isGrower"  className="switchInput"  
               checked={showGrowerView}  onChange={(e)=>{
                if(e.target.checked){
                                setShowGrowerView(true);
                }else{
                                setShowGrowerView(false);
                            }
            }}/>
                    <p
                    >
                        Display Grower
                    </p>
                </div>

                <div className="h-2" />
                <table className="w-100">
                    <thead className={`${styles["bg-e9"]}`}>
                        {table.getHeaderGroups().map((headerGroup, i) => (
                            <tr key={i}>
                                {headerGroup.headers.map((header, index) => {
                                    if (index == 3)
                                        return (
                                            <th key={header.id} className="py-3 w-25" colSpan={3}>
                                                <div className="w-100 d-flex">
                                                    <div className="w-50 text-center pe-lg-3 ">
                                                        {flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}
                                                    </div>
                                                    <div className="w-50 text-center pe-lg-3">Actions</div>
                                                </div>
                                            </th>
                                        );
                                    else
                                        return (
                                            <th
                                                className={(index == 0 || index == 1) ? "py-3" : "py-3 w-25"}
                                                key={header.id}
                                                colSpan={index == 3 ? 3 : header.colSpan}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        className={
                                                            index == 0
                                                                ? "d-flex justify-content-between align-items-center"
                                                                : ""
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                index == 3
                                                                    ? "w-50 text-center pe-lg-3 pos-l-40" :
                                                                    index == 0 ? `d-flex justify-content-center align-items-center ps-3 ${styles["width50"]}` : "d-flex justify-content-center align-items-center ps-3"
                                                            }
                                                        >
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {{
                                                                desc: (
                                                                    <i
                                                                        onClick={() => {
                                                                            resetCreatedTime();
                                                                            header.column.toggleSorting(false);
                                                                        }}
                                                                        className={`${styles["cursor-pointer"]} ${styles["fs-1-5"]} bx bx-expand-vertical ps-1`}
                                                                    ></i>
                                                                ),
                                                                asc: (
                                                                    <i
                                                                        onClick={() => {
                                                                            resetCreatedTime();
                                                                            header.column.toggleSorting(true);
                                                                        }}
                                                                        data-testid="sortButton"
                                                                        className={`${styles["cursor-pointer"]} ${styles["fs-1-5"]} bx bx-expand-vertical ps-1`}
                                                                    ></i>
                                                                ),
                                                            }[header.column.getIsSorted() as string] ?? null}
                                                            {index == 0 && (
                                                                <FilterIcon
                                                                    column={header.column}
                                                                    showFilterModal={true}
                                                                />
                                                            )}
                                                        </div>
                                                        {index == 0 && <span className="px-2">Edit/Delete</span>}
                                                    </div>
                                                )}
                                            </th>
                                        );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody data-testid="tableBody" className="position-relative">
                        {table
                            .getRowModel()
                            .rows.sort((a, b) =>
                                table.getHeaderGroups()[0].headers[0].column.getIsSorted() ==
                                    "desc"
                                    ? `${b.original.getFormatedText()}`.localeCompare(
                                        `${a.original.getFormatedText()}`,
                                        undefined,
                                        { sensitivity: "base" }
                                    )
                                    : `${a.original.getFormatedText()}`.localeCompare(
                                        `${b.original.getFormatedText()}`,
                                        undefined,
                                        { sensitivity: "base" }
                                    )
                            )
                            .sort((a, b) => +b.original.createdTime - +a.original.createdTime)
                            .sort((a, b) => +b.original.isGrower - +a.original.isGrower)
                            .map((row, idx) => (
                                <ShareHolderRow
                                    row={row}
                                    setIsLoading={setIsLoading}
                                    key={idx}
                                    setShowEditModal={setShowEditModal}
                                    setEditedShareholderData={setEditedShareholderData}
                                    growerId={growerId}
                                    errorHandler={errorHandler}
                                    mpciPolicyId={mpciPolicyId}
                                    deleteShareholderHandler={deleteShareolderData}
                                    associateShareholderHandler={associatePremiumLines}
                                    expandCollapseState={expandCollapseState}
                                    canEditPremiumLineShareholders={canEditPremiumLineShareholders}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};


interface rowProps {
    row: Row<ShareholderVM>;
    setShowEditModal: (value: boolean) => void;
    setEditedShareholderData: (data: IShareholderInfo) => void;
    growerId: string;
    mpciPolicyId: string;
    deleteShareholderHandler: (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined
    ) => void;
    associateShareholderHandler: (
        growerId: string,
        mpciPolicyId: string,
        shareholderVM: ShareholderVM,
        sharePercentage: number,
        isDelete?: boolean,
        successCallback?: () => void
    ) => void;
    errorHandler: (error: string) => void;
    setIsLoading: (setIsLoading: boolean) => void;
    expandCollapseState: ExpandCollapseState;
    canEditPremiumLineShareholders?: boolean;
}

const ShareHolderRow: React.FC<rowProps> = ({
    row,
    setIsLoading,
    setShowEditModal,
    setEditedShareholderData,
    growerId,
    mpciPolicyId,
    deleteShareholderHandler,
    associateShareholderHandler,
    errorHandler,
    expandCollapseState,
    canEditPremiumLineShareholders,
}) => {
    const [sharePercentage, setSharePercentage] = React.useState("");
    const shareTextboxBlurHandler = (e: HTMLInputElement) => {
        if (isNaN(parseFloat(e.value))) {
            return (e.value = "");
        } else {
            let value: Number = parseFloat(e.value);
            if (value > 1) {
                value = 1.0;
            }
            e.value = String(Number((Number(value) * 100).toFixed(2)));
        }
    };
    const shareTextboxFocusHandler = (e: HTMLInputElement) => {
        let value: Number = e.value == "" ? 0 : parseFloat(e.value);
        e.value = String(Number((Number(value) / 100).toFixed(3)));
    };
    const shareTextboxKeyPressHandler = (e: KeyboardEvent) => {
        const re = /[0-9\.]+/g;
        if (!re.test(e.key)) {
            e.preventDefault();
        }
    };
    const [showWarningMessage, setshowWarningMessage] = React.useState<boolean>(false);
    const [shareholderOpeartion, setShareholderOpeartion] = React.useState<OPERATION>();
    const [WarningMessageBody, setWarningMessageBody] = React.useState<string>('');
    const [WarningMessageTitle, setWarningMessageTitle] = React.useState<string>('');
    const [WindowType, setWindowType] = React.useState<WINDOWTYPE>(WINDOWTYPE.None);

    const featureFlags = useSelector<GlobalState, FeatureFlagsSaga.FeatureFlagState>((featureFlags) => featureFlags.featureFlagState);
    const FFShareholderFixINC377941 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.ShareholderFixINC377941).map((y) => y.enabled);
    let ShareholderFixINC377941=false;
    if (FFShareholderFixINC377941 != undefined && FFShareholderFixINC377941 != null) {
        if (FFShareholderFixINC377941.length > 0) {
            ShareholderFixINC377941 = FFShareholderFixINC377941[0];
        }
    }
    const shareholderUS91498 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.ShareholderUS91498).map((y) => y.enabled).toString();
    const feature91701 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.Feature91701).map((y) => y.enabled).toString();
    const handleRemoveButtonClick = () => {
        if (feature91701! === "true") {
            var shareholderCoverageData =
                row.original.shareholderCoverages;
            var mpciPremiumlines = shareholderCoverageData.map((x) => x.premiumLines?.filter((y) => y.isSelected == true));
            var companionPremiumLines = mpciPremiumlines.map(x => x?.map(y => y.companionPremiumLine.filter(z => z.IsCompanionLinked == true)));
            
            var linkedMpciPremiumline = companionPremiumLines.flat().flat();
            if (linkedMpciPremiumline?.length != 0) {
                var sourceCompanionPremiumLines = linkedMpciPremiumline.filter(x => x?.IsSource == true);
                if (sourceCompanionPremiumLines?.length != 0) {
                    setshowWarningMessage(true);
                    setWarningMessageTitle(TITLE.Warning);
                    setWarningMessageBody(WARNINGS.companion_link_exist_premiumlines_selected_to_remove);
                    setWindowType(WINDOWTYPE.Alert);
                    setShareholderOpeartion(OPERATION.None);
                }
                else {
                    setshowWarningMessage(true);
                    setWindowType(WINDOWTYPE.Warning);
                    setWarningMessageTitle(TITLE.Warning);
                    setWarningMessageBody(WARNINGS.remove_associated_linked_premiumlines);
                    setShareholderOpeartion(OPERATION.Remove_Associated_Premiumlines);
                }
             }
             else {
                 setshowWarningMessage(true);
                 setWindowType(WINDOWTYPE.Warning);
                 setWarningMessageTitle(TITLE.Warning);
                 setWarningMessageBody(WARNINGS.remove_associated_premiumlines);
                 setShareholderOpeartion(OPERATION.Remove_Associated_Premiumlines);
             }
        }
        else {
            setshowWarningMessage(true);
            setWindowType(WINDOWTYPE.Warning);
            setWarningMessageTitle(TITLE.Warning);
            setWarningMessageBody(WARNINGS.remove_associated_premiumlines);
            setShareholderOpeartion(OPERATION.Remove_Associated_Premiumlines);
        }
    };
    return (
        <React.Fragment key={row.original.oid}>
            <tr
                id={`shareholderPanel-` + row.original.oid}
                className="text-center fix-grower-input-share"
            >
                {row.getAllCells().map((cell: Cell<ShareholderVM, unknown>, index) => (
                    <td
                        className={index == 0 ? "text-start" : ""}
                        key={index}
                        data-testid={cell.id
                            .split(`${row.index}_`)[1]
                            .replaceAll(" ", "")
                            .replaceAll(`'`, "")}
                    >
                        <div
                            className={
                                index == 1 || index == 2
                                    ? "d-flex justify-content-center"
                            : `d-flex justify-content-between ${styles["paddingLeftRight"]} ${
                      row.original.isGrower ? styles["growerRowMargin"] : ""
                                    }`
                            }
                        >
                            <div
                                onClick={() => row.toggleExpanded()}
                                className={` ${styles["cursor-pointer"]} d-flex`}
                            >
                                {index == 0 && (
                                    <span data-testid='toggleExpanded'>
                                        {row.getIsExpanded() ? (
                                            <ExpandedChevronIcon />
                                        ) : (
                                            <ChevronIcon />
                                        )}
                                    </span>
                                )}
                                <div
                  className={`shareholder-${row.original.oid} ${
                    index === 0 && row.original.isGrower
                                        ? styles["growerShareholderName"]
                                        : ""
                                        }`}
                                >
                                    {(index == 0 || !row.original.isGrower) &&
                                        flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </div>
                            </div>

                            {index == 0 && !row.original.isGrower && !row.original.insureTenantLandlord && !row.original.transferOfRightToIndemnity && canEditPremiumLineShareholders &&  (
                                <div className={`d-flex ${styles["w-78-5"]} px-4`}>
                                    <EditIcon
                                        onClick={() => {
                                            if (row.original.isGrower) return;
                                            setShowEditModal(true);
                                            setEditedShareholderData({
                                                oid: row.original.oid ?? 0,
                                                firstName: row.original.firstName,
                                                middleName: row.original.middleName,
                                                lastName: row.original.lastName,
                                                suffix: row.original.suffix,
                                                IsBusiness: row.original.isBusiness,
                                                BusinessName: row.original.businessName,
                                            });
                                        }}
                                    />

                                    {ShareholderFixINC377941 ? (!(
                                        row.original.isGrower ||
                                        row.original.insureTenantLandlord ||
                                        row.original.transferOfRightToIndemnity ||
                                        row.original.isAssociatedAny ||
                                        row.original.shareholderCoverages.findIndex(
                                            (c) =>
                                                c.premiumLines &&
                                                c.premiumLines?.findIndex((p) => p.isAssociated) > -1
                                        ) > -1
                                    ) ? (
                                        <span
                                            data-testid={`deleteicon_` + row.original.oid}
                                            className={`pe-3 ${styles["cursor-pointer"]} ${styles["fs-1-5"]}`}
                                            onClick={() => {
                                                setshowWarningMessage(true);
                                                setWindowType(WINDOWTYPE.Warning);
                                                setWarningMessageBody(WARNINGS.delete_shareholders);
                                                setWarningMessageTitle(TITLE.Warning);
                                                setShareholderOpeartion(OPERATION.Delete_Sharehoder);
                                            }}>
                                            <DeleteIcon />
                                        </span>
                                    ) : (
                                        <div></div>
                        )) : ( !(
                                        row.original.isGrower ||
                                        row.original.insureTenantLandlord ||
                                        row.original.transferOfRightToIndemnity ||
                                        row.original.shareholderCoverages.findIndex(
                                            (c) =>
                                                c.premiumLines &&
                                                c.premiumLines?.findIndex((p) => p.isAssociated) > -1
                                        ) > -1
                                    ) ? (
                                        <span
                                            data-testid={`deleteicon_` + row.original.oid}
                                            className={`pe-3 ${styles["cursor-pointer"]} ${styles["fs-1-5"]}`}
                                            onClick={() => {
                                                setshowWarningMessage(true);
                                                setWindowType(WINDOWTYPE.Warning);
                                                setWarningMessageBody(WARNINGS.delete_shareholders);
                                                setWarningMessageTitle(TITLE.Warning);
                                                setShareholderOpeartion(OPERATION.Delete_Sharehoder);
                                            }}>
                                            <DeleteIcon />
                                        </span>)
                                        : (
                                            <div></div>
                                        ))}
                                </div>
                            )}
                        </div>
                    </td>
                ))}

                {!row.original.isGrower && (
                    <>
                        {canEditPremiumLineShareholders && <td>
                            <input
                                disabled={row.original.isGrower}
                                data-testid="shareTextbox"
                                onKeyPress={(e) => shareTextboxKeyPressHandler(e)}
                                maxLength={5}
                                onBlur={(e) => shareTextboxBlurHandler(e.target)}
                                onFocus={(e) => {
                                    shareTextboxFocusHandler(e.target);
                                    setSharePercentage(e.target.value);
                                }}
                                value={sharePercentage}
                                onInput={(e) => setSharePercentage(e.currentTarget.value)}
                                className={`form-control text-end ${styles["inp-width"]} mx-auto d-flex flex-grow-1`}
                            />
                        </td>}
                        {canEditPremiumLineShareholders && <td className="align-item-cetner pt-1 d-flex justify-content-evenly ps-2">
                            <p
                                className={`ms-xl-2 ${styles["expcol-p"]} ${styles["cursor-pointer"]} pe-1 w-50 text-wrap mb-2`}
                                onClick={async () => {
                                    if (parseFloat(sharePercentage) >= 0) {
                                        setWindowType(WINDOWTYPE.Warning);
                                        setshowWarningMessage(true);
                                        setWarningMessageBody(WARNINGS.update_share_percentage_associated_premiumlines);
                                        setWarningMessageTitle(TITLE.Warning);
                                        setShareholderOpeartion(OPERATION.Update_Share_Percentage);
                                    }
                                }}
                            >
                                Associate / Update
                            </p>
                            <p
                                className={`${styles["expcol-p"]} ${styles["cursor-pointer"]} ps-2`}
                                onClick={() => {
                                    handleRemoveButtonClick();
                                }}
                            >
                                Remove
                            </p>
                        </td>}
                    </>
                )}
            </tr>
            {row.getIsExpanded() && (
                <tr>
                    <td colSpan={6}>
                        <CoverageSummary
                            shareholder={row.original}
                            shareCoverages={row.original.shareholderCoverages}
                            growerId={growerId}
                            setIsLoading={setIsLoading}
                            errorHandler={errorHandler}
                            mpciPolicyId={mpciPolicyId}
                            expandCollapseState={expandCollapseState}
                            canEditPremiumLineShareholders={canEditPremiumLineShareholders }
                        />
                    </td>
                </tr>
            )}
            <ConfirmMessageModalWindow
                show={showWarningMessage}
                windowType={WindowType}
                title={WarningMessageTitle}
                messageBody={WarningMessageBody}
                onConfirm={() => {
                    if (OPERATION.Delete_Sharehoder === shareholderOpeartion) {
                        deleteShareholderHandler(growerId, mpciPolicyId, row.original.oid);
                        setshowWarningMessage(false);
                        setShareholderOpeartion(OPERATION.None);
                        setWindowType(WINDOWTYPE.None);
                    }
                    else if (OPERATION.Update_Share_Percentage === shareholderOpeartion) {
                        var shareholderData = row.original;
                        var shareholderCoverageData =
                            row.original.shareholderCoverages;
                        var anyAssociatedLine = shareholderCoverageData.find(
                            (x) => x.premiumLines?.find((y) => y.isSelected)
                        );

                        if (anyAssociatedLine) {
                            associateShareholderHandler(
                                growerId,
                                mpciPolicyId,
                                shareholderData,
                                Number(
                                    (Number(parseFloat(sharePercentage)) * 100).toFixed(
                                        2
                                    )
                                ),
                                false,
                                () => {
                                    setSharePercentage("");
                                }
                            );
                            if (parseFloat(sharePercentage) > 1) {
                                setSharePercentage(Number((Number(parseFloat("1.0")) * 100).toFixed(2)).toString());
                            }
                            else {
                                setSharePercentage(Number((Number(parseFloat(sharePercentage)) * 100).toFixed(2)).toString());
                            }
                            setshowWarningMessage(false);
                            setShareholderOpeartion(OPERATION.None);
                            setWindowType(WINDOWTYPE.None);
                        } else {
                            setWindowType(WINDOWTYPE.Alert);
                            setWarningMessageTitle(TITLE.Warning);
                            setWarningMessageBody(WARNINGS.no_premiumlines_selected_for_association);
                            setShareholderOpeartion(OPERATION.None);
                        }
                    }
                    else if (OPERATION.Remove_Associated_Premiumlines === shareholderOpeartion) {
                        var shareholderData = row.original;
                        var shareholderCoverageData =
                            row.original.shareholderCoverages;
                        var anySelectedLine = shareholderCoverageData.find((x) =>
                            x.premiumLines?.find((y) => y.isSelected)
                        );
                        if (anySelectedLine) {
                            if (feature91701! === "true") {
                                    associateShareholderHandler(
                                        growerId,
                                        mpciPolicyId,
                                        shareholderData,
                                        0,
                                        true
                                    );
                                    setSharePercentage("");
                                    setshowWarningMessage(false);
                                    setShareholderOpeartion(OPERATION.None);
                                    setWindowType(WINDOWTYPE.None);
                                }
                            else {
                                if (shareholderUS91498! === "true") {
                                    var mpciPremiumlines = shareholderCoverageData.map((x) => x.premiumLines?.filter((y) => y.isSelected == true));
                                    var companionPremiumLines = mpciPremiumlines.map(x => x?.map(y => y.companionPremiumLine.filter(z => z.IsCompanionLinked == true)));

                                    var linkedMpciPremiumline = companionPremiumLines.flat().flat();

                                    if (linkedMpciPremiumline?.length != 0) {
                                        setWarningMessageTitle(TITLE.Warning);
                                        setWarningMessageBody(WARNINGS.companion_link_exist_premiumlines_selected_to_remove);
                                        setWindowType(WINDOWTYPE.Alert);
                                        setShareholderOpeartion(OPERATION.None);
                                    }
                                    else {
                                        associateShareholderHandler(
                                            growerId,
                                            mpciPolicyId,
                                            shareholderData,
                                            0,
                                            true
                                        );
                                        setSharePercentage("");

                                        setshowWarningMessage(false);
                                        setShareholderOpeartion(OPERATION.None);
                                        setWindowType(WINDOWTYPE.None);
                                    }
                                }
                                else {
                                    associateShareholderHandler(
                                        growerId,
                                        mpciPolicyId,
                                        shareholderData,
                                        0,
                                        true
                                    );
                                    setSharePercentage("");
                                    setshowWarningMessage(false);
                                    setShareholderOpeartion(OPERATION.None);
                                    setWindowType(WINDOWTYPE.None);
                                }
                            }
                        }
                        else {
                            setWarningMessageTitle(TITLE.Warning);
                            setWarningMessageBody(WARNINGS.no_premiumlines_selected_to_remove);
                            setWindowType(WINDOWTYPE.Alert);
                            setShareholderOpeartion(OPERATION.None);
                        }
                    }
                }}
                onClose={() => {
                    setshowWarningMessage(false);
                    setWindowType(WINDOWTYPE.None);
                    setShareholderOpeartion(OPERATION.None);
                }}
            />

        </React.Fragment>
    );
};

export default ShareholdersSummary;

import { IMpciPremiumLinePlantedCluPointOfReference } from '../../views/mpci/lands/viewmodels/iMpciPremiumLinePlantedCluPointOfReference';
import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

export interface IPointOfReference {
    GroupId: number;
    CanAutoPRF: boolean;
    MpciPremiumLinePointOfReferences: IMpciPremiumLinePlantedCluPointOfReference[];
    IsLoading: boolean;
    HasError: boolean;
    ErrorMsgForPOR?: string;
    GlobalCurrentGridWkt?: string;
}

export interface PORState {
    PORRequest: IPointOfReference[]
}
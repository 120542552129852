import { Reducer } from 'redux';
import * as StatesSaga from './';
import { IStates } from '../../manager/lands/model/iMpciPolicy';
import { ActionTypes, StatesActions, GetStatesResponseAction } from './actions';

const initialState: StatesSaga.StatesState = { States: [] as IStates[], Loading: undefined, HasError: undefined };

const reducer: Reducer<StatesSaga.StatesState, StatesActions> =
    (state: StatesSaga.StatesState = initialState, action: StatesActions): StatesSaga.StatesState => {
        switch (action.type) {
            case ActionTypes.GET_STATES_REQUEST:
                return { ...state, Loading: true };

            case ActionTypes.GET_STATES_REQUEST_SUCCESS:
                return { States: (action as GetStatesResponseAction).states, Loading: false, HasError: false };

            case ActionTypes.GET_STATES_REQUEST_FAIL:
                return { States: (action as GetStatesResponseAction).states, Loading: false, HasError: true };
        }

        return state;
    };

export default reducer;
import { Reducer } from 'redux';
import * as FilterSaga from './';
import {
    ActionTypes, FilterActions, SetFiltersRequestAction, SetCoveragesRequestAction, SetUnitNumbersRequestAction,
    SetCountiesRequestAction, SetTractNumbersRequestAction, SetSectionsRequestAction, SetFarmNumbersRequestAction,
    SetPlaceNamesRequestAction, SetShowNLFUnitsRequestAction, SetBasicUnitsRequestAction, SetOptionalUnitsRequestAction,
    MaxLandsReachedAction, SetFilteredLandsAction
} from './actions';

const initialState: FilterSaga.FilterState = {
    Filter: {
    } as FilterSaga.IFilter,
    MaxLandsReached: false,
    Lands: []
};

const reducer: Reducer<FilterSaga.FilterState, FilterActions> =
    (state: FilterSaga.FilterState = initialState, action: FilterActions): FilterSaga.FilterState => {
        switch (action.type) {
            case ActionTypes.SET_FILTERS_REQUEST_SUCCESS:
                const setFiltersRequestAction = action as SetFiltersRequestAction;
                return { ...state, Filter: setFiltersRequestAction.filters };
            case ActionTypes.SET_COUNTIES_REQUEST_SUCCESS:
                const setCountiesRequestAction = action as SetCountiesRequestAction;
                state.Filter.Counties = setCountiesRequestAction.counties;
                return { ...state };
            case ActionTypes.SET_COVERAGE_REQUEST_SUCCESS:
                const setCoveragesRequestAction = action as SetCoveragesRequestAction;
                state.Filter.Coverages = setCoveragesRequestAction.coverages;
                return { ...state };
            case ActionTypes.SET_UNITNUMBER_REQUEST_SUCCESS:
                const setUnitNumbersRequestAction = action as SetUnitNumbersRequestAction;
                state.Filter.UnitNumbers = setUnitNumbersRequestAction.unitNumbers;
                return { ...state };
            case ActionTypes.SET_TRACTNUMBER_REQUEST_SUCCESS:
                const SetTractNumbersRequestAction = action as SetTractNumbersRequestAction;
                state.Filter.TractNumbers = SetTractNumbersRequestAction.tractNumbers;
                return { ...state };
            case ActionTypes.SET_SECTIONS_REQUEST_SUCCESS:
                const SetSectionsRequestAction = action as SetSectionsRequestAction;
                state.Filter.Sections = SetSectionsRequestAction.sections;
                return { ...state };
            case ActionTypes.SET_FARMNUMBER_REQUEST_SUCCESS:
                const SetFarmNumbersRequestAction = action as SetFarmNumbersRequestAction;
                state.Filter.FarmNumbers = SetFarmNumbersRequestAction.farmNumbers;
                return { ...state };
            case ActionTypes.SET_PLACENAMES_REQUEST_SUCCESS:
                const SetPlaceNamesRequestAction = action as SetPlaceNamesRequestAction;
                state.Filter.PlaceNames = SetPlaceNamesRequestAction.placeNames;
                return { ...state };
            case ActionTypes.SET_SHOWNLFUNITS_REQUEST_SUCCESS:
                const setShowNLFUnitsRequestAction = action as SetShowNLFUnitsRequestAction;
                state.Filter.ShowNLFUnits = setShowNLFUnitsRequestAction.showNLFUnits;
                return { ...state };
            case ActionTypes.CLEAR_FILTER:
                return { ...state, Filter: { FarmNumbers: [], TractNumbers: [], Sections: [], PlaceNames: [], OtherIds: [], Counties: [], Coverages: [], UnitNumbers: [], ShowNLFUnits: false, BasicUnits: [], OptionalUnits: [] } };
            case ActionTypes.SET_BASICUNITS_REQUEST_SUCCESS:
                const SetBasicUnitsRequestAction = action as SetBasicUnitsRequestAction;
                state.Filter.BasicUnits = SetBasicUnitsRequestAction.basicUnits;
                return { ...state };
            case ActionTypes.SET_OPTIONALUNITS_REQUEST_SUCCESS:
                const SetOptionalUnitsRequestAction = action as SetOptionalUnitsRequestAction;
                state.Filter.OptionalUnits = SetOptionalUnitsRequestAction.optionalUnits;
                return { ...state };
            case ActionTypes.MAX_LANDS_REACHED:
                const maxLandsReachedAction = action as MaxLandsReachedAction;
                state.MaxLandsReached = maxLandsReachedAction.isMaxLandsReached;
                return { ...state };
            case ActionTypes.SET_FILTERED_LANDS:
                const setFilteredLandsAction = action as SetFilteredLandsAction;
                state.Lands = setFilteredLandsAction.lands;
                return { ...state };
        }
        return state;
    };

export default reducer;



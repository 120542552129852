import { ActionCreator } from 'redux';
import { DeleteCompanionLinksRequestAction, GetCompanionLinksRequestAction } from './actions';

export const getCompanionLinks: ActionCreator<GetCompanionLinksRequestAction> = (action: GetCompanionLinksRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    CompanionLinksRequest: action.CompanionLinksRequest
});

export const deleteCompanionLinks: ActionCreator<DeleteCompanionLinksRequestAction> = (action: DeleteCompanionLinksRequestAction) =>
({
    type: action.type,
    Api: action.Api,
    GrowerId: action.GrowerId,
    MpciPolicyId: action.MpciPolicyId,
    MpciCoverageId: action.MpciCoverageId,
    MpciPremiumLineId: action.MpciPremiumLineId,
    CompanionLinkId: action.CompanionLinkId,
    CompanionLinksRequest: action.CompanionLinksRequest
});
import React, { memo, useEffect } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { IInputDatePickerProps } from './iInputDatePickerProps';
import styles from './inputDatePicker.module.scss';

function InputDatePicker({ name, error, label, preventFutureDates, floatingLabel, onBlur, defaultValue, disabled, onChange, useRef, value, required, message, pattern, isInvalid, onKeyUp }: IInputDatePickerProps): JSX.Element {
    
    const maxDate = (): string | undefined => {
        if (preventFutureDates) {
            const current = new Date();
            const month = (current.getMonth() + 1).toString().padStart(2, "0");
            const day = (current.getDate()).toString().padStart(2, "0");
            const date = `${current.getFullYear()}-${month}-${day}`;
            return date;
        }
        else {
            return undefined;
        }
    }

    return(
        <Form.Group>
            {floatingLabel ? 
                (
                    <FloatingLabel label={label}>
                        <Form.Control
                            data-testid={name}
                            name={name}
                            id={name}
                            type='date'
                            max={maxDate()}
                            onBlur={onBlur}
                            value={value}
                            disabled={disabled}
                            onInput={onChange}
                            ref={useRef}
                            required={required}
                            pattern={pattern}
                            isInvalid={!!error}
                            onKeyUp={onKeyUp}
                        />
                        <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                    </FloatingLabel>
                ) : (
                    <>
                        {label ? <Form.Label>{label}</Form.Label> : null}
                        <Form.Control
                            data-testid={name}
                            name={name}
                            id={name}
                            type='date'
                            max={maxDate()}
                            onBlur={onBlur}
                            disabled={disabled}
                            value={value}
                            onInput={onChange}
                            ref={useRef}
                            required={required}
                            pattern={pattern}
                            isInvalid={!!error}
                            onKeyUp={onKeyUp}
                        />
                        <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                    </>

                )
            }
        </Form.Group>
    )
};

export default InputDatePicker;
import React, { memo } from 'react';
import { Form } from 'react-bootstrap';
import Select, { ActionMeta } from "react-select";
import { ISelectMultiProps } from './iSelectMultiProps';
import styles from './multiSelect.module.scss';

export const SelectMulti: React.FC<ISelectMultiProps> = (props: ISelectMultiProps) => {
    const { name, error, label, required, onChange, placeHolder, className } = props

    const normalStyle = {
        control: (base: any, state: { isFocused: any; }) => ({
            ...base,
            borderColor: '#ddd',
            height: !props.label ? '51px' : '',
            boxShadow: state.isFocused ? '0 0 6px #198754' : 0,
            '&:hover': {
                borderColor: '#198754',
            }
        })
    }

    const errorStyle = {
        control: (base: any) => ({
            ...base,
            borderColor: '#dc3545',
            boxShadow: 0,
            height: !props.label ? '51px' : '',
            '&:hover': {
                borderColor: '#dc3545',
                boxShadow: '0 0 10px #dc3545'
            },
            '&:focus': {
                borderColor: '#dc3545',
                boxShadow: '0 0 10px #dc3545',
            }
        })
    }

    const customStyles = (isInError()) ? errorStyle : normalStyle;

    function isInError(): boolean {
        if (error && error.length > 0) {
            return true;
        }
        return false;
    }

    const onhandleChange = (newValue: any, actionMeta: ActionMeta<any>) => {
        if (onChange) {
            onChange(newValue, actionMeta);
        }
    }

    const checkForUndefined = (value?: boolean): boolean | undefined => {
        if (value == undefined || value == null) {
            return undefined;
        } else {
            return value;
        }
    }

    return (
        <Form.Group controlId={`control-` + name} className={className}>
            {label ? <Form.Label>{label}</Form.Label> : null}
            <Select
                isSearchable={checkForUndefined(props.isSearchable)}
                isClearable={checkForUndefined(props.isClearable)}
                isDisabled={checkForUndefined(props.isDisabled)}
                isMulti={true}
                closeMenuOnSelect={checkForUndefined(props.closeMenuOnSelect)}
                options={props.options ? props.options : undefined}
                name={props.name}
                value={props.value ? props.value : undefined}
                defaultValue={props.defaultValue ? props.defaultValue : undefined}
                id={props.id ? props.id : undefined}
                onChange={onhandleChange}
                styles={customStyles}
                className={props.className ? props.className : undefined}
                menuPlacement={props.menuPlacement}
                placeholder={placeHolder}
            />
            <Form.Label data-testid={`error-msg-${name}`} className={styles.error}>
                {error}
            </Form.Label>
        </Form.Group>
    );
};

export default memo(SelectMulti);
import { IViewField } from '../../views/mpci/lands/viewmodels/iViewField';
import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

export interface IFieldsStore {
    LandOID: number;
    PremiumLineOID: number;
    IsLoading: boolean;
    Fields?: IViewField[];
    HasError: boolean;
    PreLoad: boolean;
}

export interface FieldsState {
    FieldsStore: IFieldsStore[]
}
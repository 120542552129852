import { ShareholderVM } from "./viewmodels/ShareholderVM";

const ShareholderAssociationChangedEvent = "ShareholderAssociationChanged";

const shareholdersEventBus = {

    subscribeAssociationChanged(callback: EventListenerOrEventListenerObject) {
        document.addEventListener(ShareholderAssociationChangedEvent, callback);
    },

    publishAssociationChanged(modifiedShareholderVM: ShareholderVM) {
        document.dispatchEvent(new CustomEvent(ShareholderAssociationChangedEvent, { detail: modifiedShareholderVM }));
    },

    unsubscribeAssociationChanged(callback: EventListenerOrEventListenerObject) {
        document.removeEventListener(ShareholderAssociationChangedEvent, callback);
    },

}

export default shareholdersEventBus;
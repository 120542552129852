import { ISelectList } from "components/forms/iSelectList";
import { Dispatch } from "redux";
import { DecimalPlaces, InsurancePlan } from '../../commom/constant';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { formatWithComma } from "../../components/utils/formatting/FormatUtils";
import { IFieldsStore } from "../../store/field";
import { ActionTypes, GetFieldsRequestAction } from '../../store/field/actions';
import { IExtendedUnitSelectList } from '../../store/land/index';
import { PORState } from '../../store/pointOfReference';
import * as PremiumLineInformationStore from '../../store/premiumLineInformation';
import { PremiumLineDetails } from "../../store/premiumLineInformation";
import { ActionTypes as PremiumLineInformationAction, UpdatePremiumLineAcres } from "../../store/premiumLineInformation/actions";
import { ILandsContext } from "../../views/mpci/lands/viewmodels/iLandsContext";
import { MpciPremiumLineLandIdUpdate } from "../../views/mpci/lands/viewmodels/IMpciPremiumLineLandIdUpdate";
import { MpciPremiumLineLandIdUpdates } from "../../views/mpci/lands/viewmodels/iMpciPremiumLineLandIdUpdates";
import { IViewField } from "../../views/mpci/lands/viewmodels/iViewField";
import { IViewLand } from "../../views/mpci/lands/viewmodels/iViewLands";
import { getSecTwpRng } from './api';
import { FieldUtility } from "./fieldUtility";
import { ICoverage, ILand, IPremiumLine, LandsViewModel } from "./model/iMpciPolicy";

export class PolicyUtility {
    static getAllFieldsRequestByPremiumLineOid(fieldStores: IFieldsStore[], api: ApiGateway, reinsuranceYear: number, MpciPremiumLineOID?: number): GetFieldsRequestAction[] {
        let list: GetFieldsRequestAction[] = [];

        if (fieldStores && fieldStores.length > 0) {
            fieldStores.forEach(store => {
                if (store.PremiumLineOID == MpciPremiumLineOID && store.PreLoad == false || (store.PreLoad == true && store.HasError == true)) {
                    let getFieldsAction: GetFieldsRequestAction = {
                        type: ActionTypes.GET_FIELDS_REQUEST,
                        api: api,
                        reinsuranceYear: reinsuranceYear,
                        landOID: store.LandOID,
                        fieldsStore:  {
                            PremiumLineOID: store.PremiumLineOID ? store.PremiumLineOID : 0,
                            LandOID: store.LandOID,
                            IsLoading: true,
                            Fields: [],
                            HasError: false,
                            PreLoad: store.PremiumLineOID == MpciPremiumLineOID ? true : false
                        }
                    }
                    list.push(getFieldsAction);
                }
            });
        }
        return list;
    }

    static getAllFieldsRequests(landList: IViewLand[], api: ApiGateway, reinsuranceYear: number, premiumLineOid: number): GetFieldsRequestAction[] {
        let response: GetFieldsRequestAction[] = [];

        landList.forEach(land => {
            let action: GetFieldsRequestAction = {
                type: ActionTypes.GET_FIELDS_REQUEST,
                api: api,
                reinsuranceYear: reinsuranceYear,
                landOID: land.OID,
                fieldsStore: {
                    PremiumLineOID: land.MpciPremiumLineOID ? land.MpciPremiumLineOID : 0,
                    LandOID: land.OID,
                    IsLoading: true,
                    Fields: [],
                    HasError: false,
                    PreLoad: land.MpciPremiumLineOID == premiumLineOid ? true : false
                }
            }
            response.push(action);
        });
        return response;
    }
    static calculateReportedAcres(details: PremiumLineDetails | undefined): string {
        if (details?.Acres) {
            var reportedAcres = details.Acres.map(function (item) { return Number(item.Acres) }).reduce((pt, a) => pt + a, 0).toString()
            return reportedAcres;
        }
        return '0'
    }

    static UpdateReportedAcres(dispatch: Dispatch, reportedAcres: string | undefined, premiumLineOid: number, landOid: number, field?: IViewField) {
        const lookUpId = FieldUtility.getFieldKey(field)
        if (reportedAcres) {
            dispatch(PremiumLineInformationStore.updatePremiumLineTotalAcres(premiumLineOid, landOid, lookUpId, Number(reportedAcres.toString().replace(/\,/g, ''))))
        }
        else {
            dispatch(PremiumLineInformationStore.updatePremiumLineTotalAcres(premiumLineOid, landOid, lookUpId, 0))
        }
    }

    static generatePremiumLineReportedAcres(fieldsStore: IFieldsStore[], acres: PremiumLineInformationStore.PremiumLineInformation): UpdatePremiumLineAcres[] {
        var updateList: UpdatePremiumLineAcres[] = [];

        acres.PremiumLines.forEach(premiumLine => {
            fieldsStore.forEach(fields => {
                if (fields.PremiumLineOID === premiumLine.PremiumLineOid) {

                    if (fields.Fields && fields.Fields?.length > 0) {

                        fields.Fields.forEach(field => {
                            let numberWithOutComma = field.ReportedAcres?.replace(/[, ]+/g, "")
                            if (Number(numberWithOutComma) > 0) {
                                const lookUpId = FieldUtility.getFieldKey(field);

                                let found = premiumLine.Acres.find(premiumLineField => premiumLineField.LandOid == fields.LandOID && lookUpId == premiumLineField.Oid)
                                if (!found) {
                                    updateList.push({
                                        type: PremiumLineInformationAction.UPDATE_PREMIUMLINEACRES,
                                        PremuimLineOid: premiumLine.PremiumLineOid,
                                        LandOid: fields.LandOID,
                                        Oid: lookUpId ? lookUpId.toString() : '',
                                        ReportedUnitAcres: Number(numberWithOutComma)
                                    })
                                }
                            }
                        })
                    }
                }
            });
        });

        return updateList;
    }

    static getSelectedCoverage(coverages: ICoverage[] | undefined, selectedCoverageOID: string | undefined): ICoverage | undefined {
        if (coverages && coverages.length > 0 && selectedCoverageOID) {
            let result = coverages.filter(cove => cove.OID.toString() == selectedCoverageOID);
            if (result.length == 1) {
                return result[0];
            }
        }

        return undefined;
    }

    static addSupplementalFieldDetail(fieldList: IViewField[], commodityCode: string, insurancePlanCode: string): IViewField[] {
        if (fieldList && fieldList.length > 0) {
            fieldList.forEach(field => {
                field.Acres = field.Acres ? parseInt(field.Acres).toFixed(2) : '';
                field.CommodityCode = commodityCode;
                field.InsurancePlanCode = insurancePlanCode;
                field.ReportedAcres = field.ReportedAcres ?? ''
            })
        }

        return fieldList.sort((a, b) => parseInt(a.FieldNumber ?? '') > parseInt(b.FieldNumber ?? '') ? 1 : -1) as IViewField[];
    }
    static getCounty = (coverage: ICoverage): ISelectList => {
        let county = {} as ISelectList;
        county.label = this.getCountyText(coverage);
        county.value = coverage.CountyCode ? coverage.CountyCode : "";
        return county;
    }

    static getCountyText(coverage: ICoverage): string {
        return `${coverage.County} (${coverage.CountyCode})`;
    }

    static getCoverage = (coverageObj: ICoverage): ISelectList => {
        let coverage = {} as ISelectList;
        coverage.label = this.getCoverageText(coverageObj);
        coverage.value = coverageObj.OID.toString();
        return coverage
    }

    static getCoverageText(coverage: ICoverage): string {
        const coverageDes = coverage.CoverageDescription.split('-');
        /*Since CoverageDescription (Actual data -> KS (20) - Harper (077) - WHEAT (0011) - RP (02) - EU - IRR 75 %/NI 85% - 100%) 
        contains State and County information in the beginning therefore need to remove it, as it is not required.*/
        coverageDes.splice(0, 2);
        var str = coverageDes.join('-').trim();
        return str;
    }

    static getUnitNumber = (coverage: ICoverage, premiumLine: IPremiumLine): IExtendedUnitSelectList => {
        let unitNumber = {} as IExtendedUnitSelectList;
        unitNumber.label = this.getUnitNumberText(premiumLine, coverage.CommodityAbbreviation, coverage.InsurancePlan, coverage.InsurancePlanCode);
        unitNumber.value = premiumLine.OID.toString();
        unitNumber.basicUnit = premiumLine.BasicUnit.toString();
        unitNumber.optionalUnit = premiumLine.OptionalUnit.toString();

        return unitNumber;
    }

    static getUnitNumberText(premiumLine: IPremiumLine, cropName: string, planType: string, planCode: string): string {
        var text = `${cropName} - ${planType}`;
        text = premiumLine.NoLongerFarming ?
            `${text} - NLF ${premiumLine.BasicUnit}-${premiumLine.OptionalUnit}` :
            `${text} - ${premiumLine.BasicUnit}-${premiumLine.OptionalUnit}`;

        if (premiumLine.ProcedureNumber)
            text = text + `-${premiumLine.ProcedureNumber}${premiumLine.ProcedureCode}`;
        text = text + ` - ${premiumLine.TypeAbbreviation} (${premiumLine.TypeCode}) - ${premiumLine.PracticeAbbreviation} (${premiumLine.PracticeCode}) - ${(premiumLine.SharePercent * 100).toFixed(DecimalPlaces.zero_decimal_places)}%`;
        if (planCode && planCode == InsurancePlan.rain_fall_index_code) {
            text = text + ` - Grid ID: ${premiumLine.GridID}`;
        }
        if (premiumLine.ParentMpciPremiumLineOID != undefined && premiumLine.ParentMpciPremiumLineOID > 0) {
            if (premiumLine.GuaranteePerAcre) {
                text = text + ` - G/A: ` + premiumLine.GuaranteePerAcre;
            }
            else {
                text = text + ` - G/A: 0`;
            }
            if (premiumLine.ReportedAcres) {
                text = text + ` - UnitAcres: ` + premiumLine.ReportedAcres;
            }
            else {
                text = text + ` - UnitAcres: 0.0`;
            }
            if (premiumLine.PlantDate) {
                text = text + ` - Plantdate: ` + premiumLine.PlantDate.substring(0, 10);
            }
            if (premiumLine.CropOptions) {
                text = text + ` - Croptions: ` + premiumLine.CropOptions;
            }
            if (premiumLine.GuaranteeReductionFlag) {
                if (premiumLine.GuaranteeReductionFlag == 'L' || premiumLine.GuaranteeReductionFlag == 'M') {
                    text = text + ` - LP`;
                }
            }
        }
        return text;
    }

    static getSectionTownshipRange = (land: ILand): ISelectList | undefined => {
        let section: ISelectList = { label: '', value: '' };
        if (land.Section && land.Township && land.TownshipDirection && land.Range && land.RangeDirection) {
            section.label = `(${land.Section}) ${land.Township}${land.TownshipDirection} ${land.Range}${land.RangeDirection}`;
            section.value = `${land.Section},${land.Township},${land.TownshipDirection},${land.Range},${land.RangeDirection}`;
            return section;
        }
        return undefined
    }

    static getLands(land: ILand, coverage: ICoverage, premiumLine: IPremiumLine, stateCode: string): IViewLand {
        let viewLand: IViewLand = {
            OID: land.OID,
            FarmNumber: land.FarmNumber,
            TractNumber: land.TractNumber,
            PlaceName: land.PlaceName,
            OtherId: land.OtherId,
            IsPrimary: land.IsPrimary,
            County: this.getCounty(coverage),
            Section: this.getSectionTownshipRange(land),
            Coverage: this.getCoverage(coverage),
            UnitNumber: this.getUnitNumber(coverage, premiumLine),
            StateCode: stateCode,
            TotalAcres: land.TotalAcres ? parseFloat(parseFloat(land.TotalAcres.toString()).toFixed(2)) : 0,
            ReportedAcres: land.ReportedAcres ? parseFloat(parseFloat(land.ReportedAcres.toString()).toFixed(2)) : 0,
            Fields: [],
            CompanionFlag: premiumLine.CompanionFlag,
            ParentMpciPremiumLineOID: premiumLine.ParentMpciPremiumLineOID,
            MpciPremiumLineOID: premiumLine.OID,
            IsPRF: PolicyUtility.isPRF(coverage),
            CanAutoPopulatePRF: PolicyUtility.canAutoPopulatePRF(coverage),
            GroupId: premiumLine.GroupId,
            IsValid: land.IsValid,
            IsCancelledCoverage: coverage.IsCanceled
        };
        viewLand.Snapshot = { ...viewLand };
        viewLand.IsSnapshotMatching = true;
        viewLand.HasError = false;
        return viewLand;
    }

    static getStoreFields(fieldsList: any[]): IViewField[] {
        let fields: IViewField[] = [];

        if (fieldsList != undefined && fieldsList.length > 0) {

            fieldsList.forEach(field => {
                let mappedField: IViewField =
                {
                    OID: field.Oid,
                    CluId: field.CluId,
                    AcreageTypeCode: field.AcreageTypeCode,
                    Acres: field.Acres ? formatWithComma(field.Acres) : undefined,
                    AdminCountyCode: field.AdminCountyCode,
                    AdminStateCode: field.AdminStateCode,
                    CIMSDate: field.CIMSDate ? field.CIMSDate : '',
                    Description: field.Description ? field.Description : '',
                    Determination: field.Determination,
                    LandIdType: field.FieldIdentifierTypeCode ? field.FieldIdentifierTypeCode : '',
                    FieldNumber: field.FieldNumber ? field.FieldNumber : '',
                    HighRiskCode: PolicyUtility.clearString(field.HighRiskCode),
                    IsRlu: field.IsRlu,
                    NonCrop: field.NonCrop,
                    PlantedDate: field.PlantedDate ? new Date(field.PlantedDate) : undefined,
                    ReportedAcres: formatWithComma(field.ReportedAcres),
                    RluId: field.RluId,
                    SectionEquivalentId: field.SectionEquivalentId,
                    SubCountyCode: PolicyUtility.clearString(field.SubCountyCode),
                    SubFieldNumber: PolicyUtility.clearString(field.SubFieldNumber),
                    UnratedCode: PolicyUtility.clearString(field.UnratedCode),
                    DataSource: field.DataSource,
                    ReferencePointLatitude: null,
                    ReferencePointLongitude: null
                }
                fields.push(mappedField)
            });
        }
        return fields;
    }

    static clearString(stringInQuestion: string): string | undefined {

        if (stringInQuestion && stringInQuestion.trim() == '')
            return undefined;
        else
            return stringInQuestion;
    }

    static getFields(premiumLine: IPremiumLine, insurancePlanCode: string, commodityCode: string): IViewField[] {
        let fields: IViewField[] = [];

        if (premiumLine.Lands[0] != undefined && premiumLine.Lands[0].Fields != undefined) {
            var fieldList = premiumLine.Lands[0].Fields;
            fieldList.forEach(field => {
                let mappedField: IViewField =
                {
                    OID: field.Oid,
                    CluId: field.CluId,
                    AcreageTypeCode: field.AcreageTypeCode,
                    Acres: field.Acres ? field.Acres : undefined,
                    AdminCountyCode: field.AdminCountyCode,
                    AdminStateCode: field.AdminStateCode,
                    CIMSDate: field.CIMSDate ? field.CIMSDate : '',
                    Description: field.Description ? field.Description : '',
                    Determination: field.Determination,
                    LandIdType: field.FieldIdentifierTypeCode ? field.FieldIdentifierTypeCode : '',
                    FieldNumber: field.FieldNumber ? field.FieldNumber : '',
                    HighRiskCode: field.HighRiskCode,
                    IsRlu: field.IsRlu,
                    NonCrop: field.NonCrop,
                    PlantedDate: field.PlantedDate ? new Date(field.PlantedDate) : undefined,
                    ReportedAcres: field.ReportedAcres,
                    RluId: field.RluId,
                    SectionEquivalentId: field.SectionEquivalentId,
                    SubCountyCode: field.SubCountyCode,
                    SubFieldNumber: field.SubFieldNumber ? field.SubFieldNumber : '',
                    UnratedCode: field.UnratedCode,
                    InsurancePlanCode: insurancePlanCode,
                    CommodityCode: commodityCode,
                    ReferencePointLatitude: null,
                    ReferencePointLongitude: null
                }
                fields.push(mappedField)
            });
        }

        return fields;
    }

    static getLandsViewModel(dispatch: Dispatch, coverages: ICoverage[], stateCode: string): LandsViewModel {
        let viewModel: LandsViewModel = {
            CoverageList: [],
            PremiumLineList: [],
            CountyList: [],
            LandList: []
        };

        if (this.hasItems(coverages)) {
            coverages.sort((a, b) => a.CommodityName.localeCompare(b.CommodityName)).forEach(coverage => {
                var formattedCoverage: ISelectList = { label: this.getCoverageText(coverage), value: coverage.OID.toString() };
                viewModel.CoverageList.push(formattedCoverage);

                var formattedCounty: ISelectList = { label: this.getCountyText(coverage), value: coverage.CountyCode };
                if (!viewModel.CountyList.some(e => e.value == formattedCounty.value))
                    viewModel.CountyList.push(formattedCounty);

                if (this.hasItems(coverage.MpciPremiumLines)) {
                    coverage.MpciPremiumLines.sort((x, y) => (+x.NoLongerFarming) - (+y.NoLongerFarming) || x.BasicUnit.localeCompare(y.BasicUnit) || x.OptionalUnit.localeCompare(y.OptionalUnit)
                        || x.Type.localeCompare(y.Type) || x.PracticeCode.localeCompare(y.PracticeAbbreviation) || x.SharePercent).forEach((premiumLine) => {
                            var formattedPremiumLine: ISelectList = {
                                label: this.getUnitNumberText(premiumLine, coverage.CommodityAbbreviation, coverage.InsurancePlan, coverage.InsurancePlanCode),
                                value: premiumLine.OID.toString()
                            };
                            if (premiumLine.CanEditPremiumLineLandIds == false) {
                                formattedPremiumLine.optionDisabled = true;
                            }
                            viewModel.PremiumLineList.push(formattedPremiumLine);

                            dispatch(
                                PremiumLineInformationStore.putPremiumLineInformation(
                                    premiumLine.OID,
                                    premiumLine.ReportedAcres,
                                    premiumLine.PlantDate,
                                    premiumLine.AcreageTypeCode,
                                    premiumLine.AcreageTypeDescription,
                                    premiumLine.GuaranteeReductionFlag,
                                    premiumLine.UserCanEditPremiumLine,
                                    premiumLine.HasClaim,
                                    premiumLine.CropOptions
                                ))
                            if (premiumLine.Lands && premiumLine.Lands.length > 0) {
                                premiumLine.Lands.map(e => {
                                    let premHelper = premiumLine;
                                    premHelper.Lands = [e];
                                    var viewland = this.getLands(e, coverage, premHelper, stateCode);
                                    viewModel.LandList.push(viewland);
                                });

                            };
                        })
                }
            })
        }

        return viewModel;
    }

    static hasItems(list: any[]): boolean {
        let hasItems = false;
        if (list != null && list != undefined && list.length > 0)
            hasItems = true;
        return hasItems
    }

    static isPreventedPlant(guaranteeReductionFlag: string | undefined): boolean {
        return (guaranteeReductionFlag === ('P' || 'E'));
    }

    static mapISelectList = (pascalList: any[]): ISelectList[] => {
        let optionList: ISelectList[] = [];
        pascalList.forEach(pascalObject => {
            let newVal: ISelectList = { label: pascalObject.Label, value: pascalObject.Value };
            optionList.push(newVal)
        })
        return optionList;
    }

    static async getSTR(stateCode: string, countyCode: string, farmNumber: string, tract: string, api: ApiGateway): Promise<ISelectList[]> {
        let optionsList: ISelectList[] = [];

        if (stateCode && countyCode && farmNumber) {
            let response = await getSecTwpRng(
                stateCode,
                countyCode,
                farmNumber,
                tract,
                api);

            if (response) {
                response.forEach(function (mapSTRList) {
                    let secTwpRngList: ISelectList = { label: mapSTRList.Label, value: mapSTRList.Value };
                    optionsList.push(secTwpRngList);
                });
            }
        }

        return optionsList;
    }

    static saveFarmLandApiMapper(apiList: any[]): IViewLand[] {
        let viewFSNList: IViewLand[] = [];

        apiList.forEach(apiObject => {
            let county: ISelectList = { label: apiObject.County.Label, value: apiObject.County.Value };
            let unitNumber: IExtendedUnitSelectList = { label: apiObject.UnitNumber.Label, value: apiObject.UnitNumber.Value, basicUnit: apiObject.UnitNumber.basicUnit, optionalUnit: apiObject.UnitNumber.optionalUnit };
            let coverage: ISelectList = { label: apiObject.Coverage.Label, value: apiObject.Coverage.Value };
            let newViewFSN: IViewLand = {
                OID: apiObject.OID,
                FarmNumber: apiObject.FarmNumber,
                TractNumber: apiObject.TractNumber,
                PlaceName: apiObject.PlaceName,
                OtherId: apiObject.OtherId,
                IsPrimary: apiObject.IsPrimary,
                IsValid: apiObject.IsValid,
                County: county,
                Coverage: coverage,
                UnitNumber: unitNumber,
                CompanionFlag: apiObject.CompanionFlag,
                Section: apiObject.Section ? { label: apiObject.Section, value: apiObject.Section } : undefined
            };
            newViewFSN.Snapshot = { ...newViewFSN };
            newViewFSN.IsSnapshotMatching = true;
            newViewFSN.HasError = false;
            viewFSNList.push(newViewFSN)
        })
        return viewFSNList;
    }

    static canViewFields(selectedCoverages: ISelectList[] | undefined,
        selectedUnitnumbers: ISelectList[] | undefined,
        secTwpRng: ISelectList | undefined,
        farmNumber: string, placeName?: string, otherId?: string): boolean {
        var flag: boolean = false;

        if (selectedCoverages?.length == 1 && selectedUnitnumbers?.length == 1) {
            if (farmNumber && farmNumber != '') {
                flag = true;
            } else {
                if (secTwpRng?.value) {
                    flag = true
                }
                else if (placeName && placeName != '') {
                    flag = true;
                }
                else if (otherId && otherId != '') {
                    flag = true;
                }
                else {
                    flag = false;
                }
            }
        }
        else {
            flag = false
        }
        return flag;
    }

    static canViewMap(selectedCoverages: ISelectList[] | undefined,
        selectedUnitnumbers: ISelectList[] | undefined,
        farmNumber: string, tractNumber: string): boolean {
        var flag: boolean = true;

        if (selectedCoverages?.length == 1 && selectedUnitnumbers?.length == 1) {
            if (farmNumber) {
                flag = false;
            }

        }
        else {
            flag = true
        }
        return flag;
    }

    static isRLUOnly(farmNumber?: string, secTwpRng?: string, placeName?: string, otherLandId?: string): boolean {
        var flag: boolean = false;
        if (farmNumber && farmNumber != '') {
            flag = false;
        } else if (secTwpRng && secTwpRng != '') {
            flag = false
        }
        else if (placeName && placeName.length > 0) {
            flag = true;
        }
        else if (otherLandId && otherLandId.length > 0) {
            flag = true;
        }
        return flag;
    }

    static isPRF(coverage: ICoverage | undefined): boolean {
        if (coverage != null) {
            if (coverage.InsurancePlanCode != null)
                return (coverage.InsurancePlanCode.includes('13') || coverage.InsurancePlanCode.includes('14')) && coverage.CommodityCode != '0332';
            else
                return false;
        } else {
            return false;
        }
    }

    static canAutoPopulatePRF(coverage: ICoverage): boolean {
        if (coverage.CommodityCode != null)
            return (coverage.CommodityCode == ('0088' || '1191'));
        else
            return false;
    }

    static MapViewFSNtoCovereageApi(list: IViewLand[], reInsuranceYear: string): MpciPremiumLineLandIdUpdates {
        var landIdUpdates: MpciPremiumLineLandIdUpdates = { MpciPremiumLineLandIdUpdates: [] }
        list.forEach(land => {
            let newLand: MpciPremiumLineLandIdUpdate =
            {
                OID: land.OID,
                FarmNumber: land.FarmNumber,
                TractNumber: land.TractNumber,
                Section: land.Section?.value,
                PlaceName: land.PlaceName,
                OtherId: land.OtherId,
                CountyCode: land.County?.value ?? '',
                StateCode: land.StateCode ? land.StateCode : '',
                IsPrimary: land.IsPrimary ? land.IsPrimary : false,
                Coverage: land.Coverage ? [land.Coverage] : [],
                UnitNumber: land.UnitNumber ? [land.UnitNumber] : [],
                Fields: land.Fields,
                ReInsuranceYear: reInsuranceYear,
            }
            landIdUpdates.MpciPremiumLineLandIdUpdates.push(newLand)
        });

        return landIdUpdates;
    }

    static CheckCoverRequestForErrors(coverageRequest: IViewLand[]): boolean {
        var hasError = false;
        coverageRequest.forEach(req => {
            req.Fields?.forEach(field => {
                if (field.HasError) {
                    hasError = true;
                }
            })
            if (req.HasError) {
                hasError = true;
            }
        })
        return hasError;
    }

    static UpdateAcreageTypeOnFields(landIdUpdates: MpciPremiumLineLandIdUpdates, PremiumLines: PremiumLineInformationStore.PremiumLineDetails[], porState: PORState) {
        landIdUpdates.MpciPremiumLineLandIdUpdates.forEach(land => {
            let premiumLineOid = land.UnitNumber[0].value;
            if (premiumLineOid) {
                let premiumLineInformation = PremiumLines.find(prem => prem.PremiumLineOid.toString() == premiumLineOid)
                if (premiumLineInformation) {
                    land.Fields?.forEach(field => {
                        field.AcreageTypeCode = premiumLineInformation?.AcreageTypeCode
                        porState.PORRequest?.forEach(porRequest => {
                            porRequest.MpciPremiumLinePointOfReferences.forEach(pointOfReference => {
                                if (pointOfReference.CluId == field.CluId) {
                                    field.ReferencePointLatitude = pointOfReference.Lat
                                    field.ReferencePointLongitude = pointOfReference.Lon
                                }
                            })
                        })
                    })
                }
            }
        })
    }

    static isCancelledCoverage(coverageId: number, landsContext: ILandsContext) {
        let isCancelled: boolean = false;
        let coverage = landsContext.Coverages?.filter(x => x.OID == coverageId);
        coverage?.map(i => {
            if (i.IsCanceled == true) {
                isCancelled = true;
            }
        });
        return isCancelled;
    }

    static isFieldsRequestLoadingForPremiumLine(fieldsStore: IFieldsStore[], premiumLineOid: number): boolean {
        if (fieldsStore && fieldsStore.length > 0 && premiumLineOid > 0) {
            return !!fieldsStore.find(store => store.PremiumLineOID === premiumLineOid && store.IsLoading)
        }
        return false;
    }

    static fieldsRequestLoadingForPremiumLineFailed(fieldsStore: IFieldsStore[], premiumLineOid: number): boolean {
        if (fieldsStore && fieldsStore.length > 0 && premiumLineOid > 0) {
            return !!fieldsStore.find(store => store.PremiumLineOID === premiumLineOid && store.HasError)
        }
        return false;
    }
}
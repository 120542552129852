import { all, fork } from 'redux-saga/effects';
import * as AuthenticationSaga from './authentication';
import * as NotificationSaga from './notification';
import * as LandSaga from './land';
import * as FieldsSaga from './field';
import * as StatesSaga from './states';
import * as FilterSaga from './filter';
import * as CompanionLinksSaga from './companionlink';
import * as FeatureFlagsSaga from './proAgFeatureFlags';
import * as LoadingSpinnerSaga from './loadingSpinner';
import * as PremiumLineInformation from './premiumLineInformation';
import * as PORSaga from './pointOfReference';
import * as SectionTownshipRangeSaga from './sectionTownshipRange'

export const Reducers = {
    authenticationState: AuthenticationSaga.reducer,
    notificationState: NotificationSaga.reducer,
    landState: LandSaga.reducer,
    statesState: StatesSaga.reducer,
    fieldsState: FieldsSaga.reducer,
    filterState: FilterSaga.reducer,
    companionLinksState: CompanionLinksSaga.reducer,
    loadSpinnerState: LoadingSpinnerSaga.reducer,
    featureFlagState: FeatureFlagsSaga.reducer,
    premiumLineInformationState: PremiumLineInformation.reducer,
    porState: PORSaga.reducer,
    sectionTownshipState: SectionTownshipRangeSaga.reducer
};

export const RootSaga = function* root() {
    const allSagas = [
        ...AuthenticationSaga.Sagas,
        ...NotificationSaga.Sagas,
        ...LandSaga.Sagas,
        ...FieldsSaga.Sagas,
        ...StatesSaga.Sagas,
        ...FilterSaga.Sagas,
        ...CompanionLinksSaga.Sagas,
        ...LoadingSpinnerSaga.Sagas,
        ...FeatureFlagsSaga.Sagas,
        ...PORSaga.Sagas,
        ...SectionTownshipRangeSaga.Sagas,
    ];

    yield all(
        allSagas.map(saga => fork(saga))
    );
}
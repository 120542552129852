import { ERRORS } from "../../commom/constant";

export class ErrorUtility {
    static handleApiGatewayError(response: any, errorHandler: (msg: string) => void): boolean {
        let handled = false;
        switch (response?.status) {
            case 401:
                if (errorHandler) errorHandler(ERRORS.session_timeout__api_failed);
                handled = true;
                break;
            case 503:
                if (errorHandler) errorHandler(ERRORS.paw_maintance_error);
                handled = true;
                break;
        }
        return handled;
    }
}


import { Action, ActionCreator } from 'redux';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IStates } from '../../manager/lands/model/iMpciPolicy';


const actionTypes = {
    GET_STATES_REQUEST: '@@states/GET_STATES_REQUEST',
    GET_STATES_REQUEST_SUCCESS: '@@states/GET_STATES_REQUEST_SUCCESS',
    GET_STATES_REQUEST_FAIL: '@@states/GET_STATES_REQUEST_FAIL'
}

export const ActionTypes = actionTypes;

export interface GetStatesRequestAction extends Action {
    api: ApiGateway;
};

export interface GetStatesResponseAction extends Action {
    type: string;
    states: IStates[];
};

export const getStatesSuccess: ActionCreator<GetStatesResponseAction> = (states: IStates[]) =>
({
    type: actionTypes.GET_STATES_REQUEST_SUCCESS,
    states: states
});

export const getStatesFail: ActionCreator<GetStatesResponseAction> = () =>
({
    type: actionTypes.GET_STATES_REQUEST_FAIL,
    states: [] as IStates[],
});

export type StatesActions = GetStatesRequestAction | GetStatesResponseAction;
import { ApiGateway } from "components/apiGateway/useApiGateway";
import { Action, ActionCreator } from "redux";
import { ISecTwpRngReposList } from "views/mpci/lands/viewmodels/iSecTwpRngReposList";

export const SectionTownshipActionTypes = {
    GET_SECTION_TOWNSHIP: '@@sectionTownship/GET_SECTION_TOWNSHIP',
    SET_SECTION_TOWNSHIP: '@@sectionTownship/SET_SECTION_TOWNSHIP',
    ERROR_SECTION_TOWNSHIP: '@@sectionTownship/ERROR_SECTION_TOWNSHIP',
}

export interface GetSectionTownshipRequestAction extends Action {
    api: ApiGateway,
    stateCode: string,
    countyCode: string,
    farmNumber: string,
    tract?: string,
}

export interface SectionTownshipErrorAction extends Action {
    errorCode: string;
};

export interface GetSectionTownshipAction extends Action {
    sectionTownshipRanges: Record<string, ISecTwpRngReposList[]>
}

export const setSectionTownshipAction: ActionCreator<GetSectionTownshipAction> = (ranges: Record<string, ISecTwpRngReposList[]>) => 
({
    type: SectionTownshipActionTypes.GET_SECTION_TOWNSHIP,
    sectionTownshipRanges: ranges
})

export const sectionTownshipErrorAction: ActionCreator<SectionTownshipErrorAction> = (errorCode: string) => ({
    type: SectionTownshipActionTypes.ERROR_SECTION_TOWNSHIP,
    errorCode: errorCode
})

export type SectionTownshipActions = GetSectionTownshipAction | SectionTownshipErrorAction;
import { GetSectionTownshipRequestAction } from "./actions";
import { URLs } from "commom/constant";
import ApplicationIdValues from "components/apiGateway/ApplicationIdValues";

export function GetSectionTownship({ api, stateCode, countyCode, farmNumber, tract }: GetSectionTownshipRequestAction): Promise<GetSectionTownshipRequestAction> {
    var url = getSectionTownshipUrl(stateCode, countyCode, farmNumber, tract);
    return api.get(url, ApplicationIdValues.Paw);
}

function getSectionTownshipUrl(stateCode: string, countyCode: string, farmNumber: string, tractNumber?: string) {
    if (tractNumber) {
        return URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${farmNumber}/Tracts/${tractNumber}/SectionTownshipRanges`;
    } else {
        return URLs.paw_api_version + `/States/${stateCode}/Counties/${countyCode}/Farms/${farmNumber}/SectionTownshipRanges`;
    }
}

export const formatWithComma = (value: string | undefined) => {
    let formattedNumber = "";
    const options = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    if(value){        
        let convertedNumber = Number(value);
        if(isNaN(convertedNumber!)){
            //check if it has commas already        
            let scrubbedValue = value.replace(/[^0-9\\.]+/g, "");
            let scrubbedNumber = Number(scrubbedValue)
            if(!isNaN(scrubbedNumber!)){
                formatNumber(scrubbedNumber);
            }       
        }else{
            formatNumber(convertedNumber);
        }
    }
    return formattedNumber;

    function formatNumber(formatValue: number) {
        formattedNumber = Intl.NumberFormat("en-US", options).format(formatValue);
    }
};
import { ICoverageAndPremiumLines } from "views/mpci/lands/viewmodels/iCoverageAndPremiumLines";
import { ILandsContext } from "views/mpci/lands/viewmodels/iLandsContext";
import { FilterState, IFilter } from "../../store/filter/index";
import { ISelectList } from "components/forms/iSelectList";
import { LandState } from "../../store/land/index";
import { SORTOPTION } from "../../commom/constant";
import { IViewLand } from "views/mpci/lands/viewmodels/iViewLands";


export class FilterUtility {
    static getFilters(viewFSNs: IViewLand[]): IFilter {
        let filter: IFilter = { Counties: [], Coverages: [], UnitNumbers: [], FarmNumbers: [], TractNumbers: [], Sections: [], PlaceNames: [], OtherIds: [], ShowNLFUnits: false, BasicUnits: [], OptionalUnits: [] };

        const fnOptions = viewFSNs.map(item => item.FarmNumber).filter((value, index, self) => self.indexOf(value) === index);
        const tractOptions = viewFSNs.map(item => item.TractNumber).filter((value, index, self) => self.indexOf(value) === index);
        const secTwpRngOptions = viewFSNs.map(item => item.Section).filter((value, index, self) => self.findIndex((v) => v?.label === value?.label) === index);
        const basicUnitOptions = viewFSNs.map(item => item.UnitNumber?.basicUnit).filter((value, index, self) => self.indexOf(value) === index);
        const optionalUnitOptions = viewFSNs.map(item => item.UnitNumber?.optionalUnit).filter((value, index, self) => self.indexOf(value) === index);

        if (fnOptions.length > 0) {
            filter.FarmNumbers = fnOptions.map(item => { let option = { label: item, value: item } as ISelectList; return option; });
        }

        if (tractOptions.length > 0) {
            filter.TractNumbers = tractOptions.map(item => { let option = { label: item, value: item } as ISelectList; return option; });
        }

        if (secTwpRngOptions.length > 0) {
            filter.Sections = secTwpRngOptions.map(item => { let option = { label: item?.label, value: item?.value } as ISelectList; return option; });
        }

        if (basicUnitOptions.length > 0) {
            filter.BasicUnits = basicUnitOptions.map(item => { let option = { label: item, value: item } as ISelectList; return option; });
        }

        if (optionalUnitOptions.length > 0) {
            filter.OptionalUnits = optionalUnitOptions.map(item => { let option = { label: item, value: item } as ISelectList; return option; });
        }

        return filter;
    }

    static getFilteredData(landState: LandState, filterState: FilterState, mpciPremiumLines: ISelectList[]) {
        let filterData = [...landState.Lands];

        if (filterState.Filter.Counties != undefined && filterState.Filter.Counties.length > 0) {
            filterData = filterData.filter((c) => filterState.Filter.Counties!.findIndex((u) => u?.value == c.County?.value) > -1)

        }
        if (filterState.Filter.Coverages != undefined && filterState.Filter.Coverages.length > 0) {
            filterData = filterData.filter((c) => filterState.Filter.Coverages!.findIndex((u) => u?.value == c.Coverage?.value) > -1)

        }
        if (filterState.Filter.UnitNumbers != undefined && filterState.Filter.UnitNumbers.length > 0) {
            filterData = filterData.filter((c) => filterState.Filter.UnitNumbers!.findIndex((u) => u?.value == c.UnitNumber?.value) > -1);

        }
        if (filterState.Filter.ShowNLFUnits != undefined && !filterState.Filter.ShowNLFUnits) {
            if (filterState.Filter.UnitNumbers.length > 0) {
                const filteredList = filterState.Filter.UnitNumbers.filter(
                    (unitnumber => !unitnumber.label.toString().includes('NLF')));
                filterData = filterData.filter((c) => filteredList!.findIndex((u) => u?.value == c.UnitNumber?.value) > -1);

            }
            else if (filterState.Filter.UnitNumbers.length == 0) {
                const filteredList = mpciPremiumLines.filter(
                    (unitnumber => !unitnumber.label.toString().includes('NLF')));
                filterData = filterData.filter((c) => filteredList!.findIndex((u) => u?.value == c.UnitNumber?.value) > -1);
            }
        }

        if (filterState.Filter.FarmNumbers != undefined && filterState.Filter.FarmNumbers.length > 0) {
            filterData = filterData.filter((fn) => filterState.Filter.FarmNumbers!.findIndex((f) => f?.value == fn.FarmNumber) > -1);
        }

        if (filterState.Filter.TractNumbers != undefined && filterState.Filter.TractNumbers.length > 0) {
            filterData = filterData.filter((f) => filterState.Filter.TractNumbers!.findIndex((tr) => tr?.value == f.TractNumber) > -1);
        }

        if (filterState.Filter.Sections != undefined && filterState.Filter.Sections.length > 0) {
            filterData = filterData.filter((f) => filterState.Filter.Sections!.findIndex((sc) => sc?.value == f.Section?.value) > -1);
        }

        if (filterState.Filter.PlaceNames != undefined && filterState.Filter.PlaceNames.length > 0) {
            filterData = filterData.filter((f) => filterState.Filter.PlaceNames!.findIndex((pl) => pl?.value == f.PlaceName?.trim()) > -1);
        }

        if (filterState.Filter.BasicUnits != undefined && filterState.Filter.BasicUnits.length > 0) {
            filterData = filterData.filter((f) => filterState.Filter.BasicUnits!.findIndex((bs) => bs?.value == f.UnitNumber?.basicUnit) > -1);
        }

        if (filterState.Filter.OptionalUnits != undefined && filterState.Filter.OptionalUnits.length > 0) {
            filterData = filterData.filter((f) => filterState.Filter.OptionalUnits!.findIndex((os) => os?.value == f.UnitNumber?.optionalUnit) > -1);
        }
        return filterData;
    }

    static filterCoverageOptions = (selectedCounty: ISelectList | null, landsContext: ILandsContext, coverageAndPremiumLines: ICoverageAndPremiumLines) => {
        let filteredCoverages: ISelectList[] = [];
        if (selectedCounty != null) {
            let coverageOids = landsContext.Coverages?.filter(c => c.CountyCode == selectedCounty.value)?.map(cov => cov.OID.toString());
            let filteredCoverages = coverageAndPremiumLines.MpciCoverages.filter(c => coverageOids?.includes(c.value));
            return filteredCoverages;
        }
        return filteredCoverages;

    }

    static filterUnitOptions = (selectCoverages: ISelectList[] | null, landsContext: ILandsContext, coverageAndPremiumLines: ICoverageAndPremiumLines) => {
        let filteredUnits: ISelectList[] = [];
        if (selectCoverages != null && selectCoverages!.length > 0) {
            let coverages = landsContext.Coverages?.filter(cov => selectCoverages!.findIndex(c => c.value == cov.OID.toString()) > -1);
            let premiumLineOids = coverages?.flatMap(x => x.MpciPremiumLines).map(opt => opt.OID.toString());
            filteredUnits = coverageAndPremiumLines.MpciPremiumLines.filter(c => premiumLineOids?.includes(c.value));
            return filteredUnits;
        }
        return filteredUnits;

    }

    static filterMultiCountyCoverageOptions = (selectedCounty: ISelectList[] | null, landsContext: ILandsContext, coverageAndPremiumLines: ICoverageAndPremiumLines) => {
        let filteredCoverages: ISelectList[] = [];
        if (selectedCounty != null) {
            let coverageOids = landsContext.Coverages?.filter(c => selectedCounty.findIndex(ct => ct.value == c.CountyCode.toString()) > -1)?.map(cov => cov.OID.toString());
            let filteredCoverages = coverageAndPremiumLines.MpciCoverages.filter(c => coverageOids?.includes(c.value));
            return filteredCoverages;
        }
        return filteredCoverages;

    }

    static getCascadingFilters(selectedUnitNumber: ISelectList[], lands: IViewLand[], selectedfn: (ISelectList[] | null) = null, selectedTract: (ISelectList[] | null) = null, selectedSections: (ISelectList[] | null) = null, selectedPlaceNames: (ISelectList[] | null) = null, selectedBUs: (ISelectList[] | null) = null, selectedOUs: (ISelectList[] | null) = null): IFilter {
        let filter: IFilter = { Counties: [], Coverages: [], UnitNumbers: [], FarmNumbers: [], TractNumbers: [], Sections: [], PlaceNames: [], OtherIds: [], ShowNLFUnits: false, BasicUnits: [], OptionalUnits: [] };
        let filteredLands = lands.filter(c => selectedUnitNumber.findIndex(u => u.value == c.UnitNumber?.value) > -1) //.map(item => { let option = { label: item.FarmNumber == '' ? '(blank)' : item.FarmNumber, value: item.FarmNumber } as ISelectList; return option; });

        let fns = filteredLands.map(item => { let option = { label: item.FarmNumber == '' ? '(blank)' : item.FarmNumber, value: item.FarmNumber } as ISelectList; return option; });
        filter.FarmNumbers = this.removeDuplicateSelectItems(fns);
        filter.FarmNumbers = filter.FarmNumbers.sort((self, element) => self.value?.localeCompare(element.value, undefined, { numeric: true }));

        if (selectedfn != null && selectedfn.length > 0) {
            let filteredfns = filteredLands.filter(t => selectedfn.findIndex(sf => sf.value == t.FarmNumber) > -1)
            if (filteredfns.length > 0) {
                filteredLands = filteredfns;
            }

        }
        let tracts = filteredLands.map(item => { let option = { label: item.TractNumber == '' ? '(blank)' : item.TractNumber, value: item.TractNumber } as ISelectList; return option; });
        filter.TractNumbers = this.removeDuplicateSelectItems(tracts);
        filter.TractNumbers = filter.TractNumbers.sort((self, element) => self.value?.localeCompare(element.value, undefined, { numeric: true }));

        if (selectedTract != null && selectedTract.length > 0) {
            let filteredtracts = filteredLands.filter(t => selectedTract.findIndex(sf => sf.value == t.TractNumber) > -1);
            if (filteredtracts.length > 0) {
                filteredLands = filteredtracts;
            }

        }
        let sections = filteredLands.map(item => { let option = { label: !item?.Section?.value ? '(blank)' : item.Section?.label, value: item.Section?.value } as ISelectList; return option });
        filter.Sections = this.removeDuplicateSelectItems(sections);
        filter.Sections = filter.Sections.sort((self, element) => self.value?.localeCompare(element.value));

        if (selectedSections != null && selectedSections.length > 0) {
            let filteredSections = filteredLands.filter(t => selectedSections.findIndex(sf => sf.value == t.Section?.value) > -1);
            if (filteredSections.length > 0) {
                filteredLands = filteredSections;
            }
        }
        let placeNames = filteredLands.map(item => { let option = { label: item.PlaceName?.trim() == '' || item.PlaceName?.trim() == null ? '(blank)' : item.PlaceName, value: item.PlaceName?.trim() == '' || item.PlaceName?.trim() == null ? '' : item.PlaceName } as ISelectList; return option; })
        filter.PlaceNames = this.removeDuplicateSelectItems(placeNames);
        filter.PlaceNames = filter.PlaceNames.sort((self, element) => self.label?.toString().localeCompare(element.label.toString()));

        if (selectedPlaceNames != null && selectedPlaceNames.length > 0) {
            let filteredPlaces = filteredLands.filter(t => selectedPlaceNames.findIndex(sf => sf.value == t.PlaceName) > -1);
            if (filteredPlaces.length > 0) {
                filteredLands = filteredPlaces;
            }
        }
        let basicUnits = filteredLands.map(item => { let option = { label: item.UnitNumber?.basicUnit, value: item.UnitNumber?.basicUnit } as ISelectList; return option; });
        filter.BasicUnits = this.removeDuplicateSelectItems(basicUnits);
        filter.BasicUnits = filter.BasicUnits.sort((self, element) => self.value.localeCompare(element.value));


        if (selectedBUs != null && selectedBUs.length > 0) {
            let filteredBUs = filteredLands.filter(t => selectedBUs.findIndex(sf => sf.value == t.UnitNumber?.basicUnit) > -1);
            if (filteredBUs.length > 0) {
                filteredLands = filteredBUs;
            }
        }

        let optionalUnits = filteredLands.map(item => { let option = { label: item.UnitNumber?.optionalUnit, value: item.UnitNumber?.optionalUnit } as ISelectList; return option; });
        filter.OptionalUnits = this.removeDuplicateSelectItems(optionalUnits);
        filter.OptionalUnits = filter.OptionalUnits.sort((self, element) => self.value.localeCompare(element.value));

        return filter;
    }

    static removeDuplicateSelectItems(selectItems: any) {
        return selectItems.filter((v: { [x: string]: any; }, i: any, a: any[]) => a.findIndex(v2 => ['label', 'value'].every(k => v2[k] === v[k])) === i);
    }

    static sortCoverageDetails = (lands: IViewLand[], selectedOptionToSort: string, isAscendingSortType: boolean) => {
        var landsData = [...lands];
        var sortAllCoverageDetailsByAscending = landsData.sort((a, b) => (String(a.County?.label!).localeCompare(String(b.County?.label!))) ||
            (String(a.Coverage?.label!).localeCompare(String(b.Coverage?.label!))) ||
            (Number(a.UnitNumber?.basicUnit) - Number(b.UnitNumber?.basicUnit)) ||
            (Number(a.UnitNumber?.optionalUnit) - Number(b.UnitNumber?.optionalUnit)) ||
            (Number(a.FarmNumber) - Number(b.FarmNumber)) ||
            (Number(a.TractNumber) - Number(b.TractNumber)) ||
            (String(a.Section?.label!).localeCompare(String(b.Section?.label!))) ||
            (a.PlaceName?.localeCompare(b.PlaceName!)) ||
            (Number(a.OtherId) - Number(b.OtherId)));
        var sortedData = [];
        switch (selectedOptionToSort) {
            case SORTOPTION.County:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(a.County?.label!).localeCompare(String(b.County?.label!))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.County?.label!).localeCompare(String(a.County?.label!))));
                }
                return sortedData;
                break;
            case SORTOPTION.Coverage:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(a.Coverage?.label!).localeCompare(String(b.Coverage?.label!))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.Coverage?.label!).localeCompare(String(a.Coverage?.label!))));
                }
                return sortedData;
                break;
            case SORTOPTION.BU:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(a.UnitNumber?.basicUnit) - Number(b.UnitNumber?.basicUnit))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(b.UnitNumber?.basicUnit) - Number(a.UnitNumber?.basicUnit))));
                }
                return sortedData;
                break;
            case SORTOPTION.OU:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(a.UnitNumber?.optionalUnit) - Number(b.UnitNumber?.optionalUnit))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(b.UnitNumber?.optionalUnit) - Number(a.UnitNumber?.optionalUnit))));
                }
                return sortedData;
                break;
            case SORTOPTION.FN:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (Number(a.FarmNumber) - Number(b.FarmNumber)));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(b.FarmNumber) - Number(a.FarmNumber))));
                }
                return sortedData;
                break;
            case SORTOPTION.Tract:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (Number(a.TractNumber) - Number(b.TractNumber)));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => ((Number(b.TractNumber) - Number(a.TractNumber))));
                }
                return sortedData;
                break;
            case SORTOPTION.STR:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(a.Section?.label!).localeCompare(String(b.Section?.label!))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.Section?.label!).localeCompare(String(a.Section?.label!))));
                }
                return sortedData;
                break;
            case SORTOPTION.PlaceName:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(a.PlaceName!).localeCompare(String(b.PlaceName!))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.PlaceName!).localeCompare(String(a.PlaceName!))));
                }
                return sortedData;
                break;
            case SORTOPTION.OtherId:
                if (isAscendingSortType) {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(a.OtherId!).localeCompare(String(b.OtherId!))));
                }
                else {
                    sortedData = sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.OtherId!).localeCompare(String(a.OtherId!))));
                }
                return sortedData;
                break;
            case SORTOPTION.None:
                if (isAscendingSortType) {
                    return sortAllCoverageDetailsByAscending;
                }
                else {
                    return sortAllCoverageDetailsByAscending.sort((a, b) => (String(b.County?.label!).localeCompare(String(a.County?.label!))) ||
                        (String(b.Coverage?.label!).localeCompare(String(a.Coverage?.label!))) ||
                        (Number(b.UnitNumber?.basicUnit) - Number(a.UnitNumber?.basicUnit)) ||
                        (Number(b.UnitNumber?.optionalUnit) - Number(a.UnitNumber?.optionalUnit)) ||
                        (Number(b.FarmNumber) - Number(a.FarmNumber)) ||
                        (Number(b.TractNumber) - Number(a.TractNumber)) ||
                        (String(b.Section?.label!).localeCompare(String(a.Section?.label!))) ||
                        (b.PlaceName?.localeCompare(a.PlaceName!)) ||
                        (Number(b.OtherId) - Number(a.OtherId)));
                }
                break;
        }
    }

    static premiumLineList = (lands: IViewLand[]) => {
        if (lands && lands.length > 0) {
            return lands.map(item => Number(item.MpciPremiumLineOID)).filter((value, index, self) => self.indexOf(value) === index)
        }
        return [];
    }
}
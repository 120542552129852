import React from 'react';
import { Popover, Tooltip } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styles from './ColumnFilter.module.scss';
import { HiTrash,HiExclamation } from 'react-icons/hi';

interface props {
  showFilterModal?: boolean;
  column: any;
}

export const FilterIcon: React.FC<props> = ({
  showFilterModal,
  column,
}) => {

const columnFilterValue = column.getFilterValue();

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <p>Show items with values that:</p>
        <label className={`${styles["filterlabel"]} py-1 ps-2`} id="dropdown-basic"> Contains </label>
        <input
          type="text"
          data-testid="filterInput"
          value={(columnFilterValue ?? '') as string}
          onChange={(e) => {
               column.setFilterValue(e.target.value)
               
          }}
          placeholder={`Search...`}
          className="w-36 border shadow rounded"
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      {showFilterModal ? (
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="right"
          overlay={popover}
        >
          <i className={`${styles['cursor-pointer']} ${styles['fs-1-5']} bx bx-search-alt-2 align-middle ps-1`} data-testid="filterIcon"></i>
        </OverlayTrigger>
      ) : (
        <i className={`${styles['cursor-pointer']} bx bx-search-alt-2 align-middle ps-1`}></i>
      )}
    </>
  );
};

export const ExpandedChevronIcon = () => {
  return (
    <i className={`${styles['cursor-pointer']} ${styles['fs-1-5']} bx bxs-chevron-down`} data-testid='toggle-down'></i>
  );
};

export const ChevronIcon = () => {
  return (
    <i className={`${styles['cursor-pointer']} ${styles['fs-1-5']} bx bxs-chevron-right`} data-testid='toggle-right'></i>
  );
};

export const DeleteIcon = () => {
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete Shareholder
        </Tooltip>
    );

    return (
       <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
        >
            <i data-testid="deleteButton" className={`ps-3`}> 
                <HiTrash/>
            </i>
        </OverlayTrigger>
    );
}

interface EditProps{
  onClick: () => void;
}
export const EditIcon: React.FC<EditProps> = ({onClick}) => {
  const renderTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Edit Shareholder
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
    <i data-testid="editButton" onClick={onClick} className={`${styles['cursor-pointer']} p-0 ${styles['po-t-3']} ${styles['me-5p']}  ${styles['c-green']} h5 bx bxs-pencil`} ></i>  
    </OverlayTrigger>
  );
}

interface ErrorProps{
  tooltipText:string
}

export const ErrorIcon: React.FC<ErrorProps> = ({tooltipText}) => {
  

  return (
    <OverlayTrigger
      placement="top"
      overlay={ <Tooltip id="button-tooltip1">{tooltipText}</Tooltip>}
    >
          <span data-testid="errorIcon">
    <HiExclamation color='red' className={`${styles['cursor-pointer']}`}/> 
    </span>
    </OverlayTrigger>
  );
}
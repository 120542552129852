import Spinner from 'react-bootstrap/Spinner';
import "./Spinner.scss";

function LoadSpinner(props: any) {

    return (
        <div className="loader-container" >
            <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
            />
        </div>
    );
}

export default LoadSpinner;
import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import { IViewField } from "./viewmodels/iViewField"
import InputText from "components/forms/inputText/InputText";
import { FieldUtility } from "manager/lands/fieldUtility";
import { useApiGateway } from "components/apiGateway/useApiGateway";
import { LandsContext } from "./landsContext";
import { isEmpty } from "lodash";

interface SectionEquivalentIdProps {
    updateField: (field: IViewField, isCluInformation?: boolean | undefined) => void;
    viewField: IViewField;
    error?: string;
    setAssociatedFieldDefaults?: (field: IViewField) => void;
}

export function SectionEquivalentId({ updateField, viewField, error, setAssociatedFieldDefaults }: SectionEquivalentIdProps): JSX.Element {

    const onChange = (e: BaseSyntheticEvent) => {
        const regex = /^[\w\d]{0,2}(?:-[\w\d]{0,7}){0,1}(?:-[\w\d]{0,2}){0,1}$/;
        let updatedValue = e.target.value
        const doesMatch = regex.test(updatedValue)

        if (doesMatch) {
            let formattedValue = '';
            if (viewField?.SectionEquivalentId?.endsWith('-') && viewField?.SectionEquivalentId?.length > e.target.value.length && !e.target.value.endsWith('-')) {
                formattedValue = updatedValue.slice(0, -1)
            } else {
                formattedValue = updatedValue
                .replace(/-/g, '')
                .replace(/^([\w\d]{0,2})([\w\d]{0,7})?([\w\d]{0,2})?$/, (match: string, p1: string, p2: string, p3: string) => {
                    let result = p1;
                    if (p1.length === 2) {
                        result += '-'
                    }
                    if (p2) {
                        result += p2;
                        if (p2.length === 7) {
                            result += '-'
                        }
                    }
                    if (p3) {
                        result += p3;
                    } else {
    
                    }
                    return result
                })
            }
            updateField({ ...viewField, SectionEquivalentId: formattedValue})
            if (!!setAssociatedFieldDefaults && !viewField.IsChecked){
                setAssociatedFieldDefaults({ ...viewField, SectionEquivalentId: formattedValue, IsChecked: true })
            }
        }
    }
    
    return <>
        <InputText
            name='section-equivalence'
            inputType="text"
            label='Sec Eq ID'
            floatLabel={true}
            value={viewField?.SectionEquivalentId ?? ''}
            onChange={onChange}
            error={error}
        />
    </>
}
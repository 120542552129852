import { Col, Container, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalState } from 'store';
import * as NotificationSaga from 'store/notification';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Layout: React.FC = props => {
    const notificationState = useSelector<GlobalState, NotificationSaga.NotificationState>((state) => state.notificationState);
    const dispatch = useDispatch();

    return (
        <>
            <Header /> 
            <Container fluid>
                <Row>
                    <Col xs="12" id="mainContent">
                        <Outlet />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        <Footer />
                    </Col>
                </Row>
            </Container>

            {notificationState.notifications.length > 0 ?
                <ToastContainer className="p-3" position="top-end">
                    {notificationState.notifications.map(notification => (
                        <Toast bg="warning" key={notification.id} onClose={() => dispatch(NotificationSaga.clearNotification(notification.id))}>
                            <Toast.Header>
                                <strong className="me-auto">{notification.title}</strong>
                            </Toast.Header>
                            <Toast.Body>{notification.body}</Toast.Body>
                        </Toast>
                    ))}
                </ToastContainer> : null
            }                        
        </>        
    );
}

export default Layout;
import React from "react"
import { ILandsContext } from "./viewmodels/iLandsContext"

export const landsContextTemplate: ILandsContext = {
    GrowerOid: 0,
    PolicyOid: 0,
    ReinsuranceYear: 0,
    StateCode: "0"
}

export const LandsContext = React.createContext<ILandsContext>(landsContextTemplate)
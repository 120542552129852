import { BaseSyntheticEvent, useState } from 'react';
import { CloseButton, Col, Row } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { AlertType } from "../../../components/alert/alertType";
import { CustomAlert } from '../../../components/alert/customAlert';
import { InputDecimal, InputSwitch, InputText } from '../../../components/forms/formBase';
import styles from './editField.module.scss';
import { AdminLocationSelect } from './select/adminLocationSelect';
import { CIMSDateSelect } from './select/cimsDateSelect';
import { DeterminationSelect } from './select/determinationSelect';
import { IEditField } from './viewmodels/iEditField';

const EditField = ({ FieldData, updateField, closeEditClu, errors }: IEditField) => {
    const [nonCrop, setNonCrop] = useState(FieldData.NonCrop);
    const [showAlert, setShowAlert] = useState(false);

    function validateChanges() {
        if (!FieldData.IsCluInformationSnapshotMatching) {
            setShowAlert(true);
        }
        else {
            closeEditClu(false);
        }
    }

    function reconcileChanges() {
        updateField({ ...FieldData.Snapshot, IsChecked: FieldData.IsChecked, Snapshot: FieldData.Snapshot })
        setShowAlert(!showAlert)
        closeEditClu(!showAlert);
    }

    function changeNonCrop() {
        updateField({ ...FieldData, NonCrop: !nonCrop })
        setNonCrop(!nonCrop)
    }

    return (
        <div className={styles.editFieldBorder}>
            <CustomAlert
                data-testid="closeFieldsAlert"
                id="closeFieldsAlert"
                show={showAlert}
                type={AlertType.Confirmation}
                variant="warning"
                onClose={reconcileChanges}
                onCancel={() => setShowAlert(false)}
            >
                Unsaved CLU Information will be lost on Exit
            </CustomAlert>
            <div className={styles.editFieldHeader} data-testid='editFieldHeader'>
                <Row className={styles.editFieldHeaderContent}>
                    <Col xs='11' sm='11' md='11' lg='11' xl='11' xxl='11'>
                        Edit CLU Information
                    </Col>
                    <Col xs='1' sm='1' md='1' lg='1' xl='1' xxl='1'>
                        <CloseButton
                            className={`float-end`}
                            data-testid="closeFieldsButton"
                            onClick={validateChanges}
                        />
                    </Col>
                </Row>
            </div>
            <Row className='m-1'>
                <Col xs='4' sm='4' md='2' lg='1' xl='1' xxl='1' className='p-1'>
                    <InputText
                        name={`clu_editFieldNumber_${FieldData.CluId}`}
                        label="Field #"
                        inputType={'text'}
                        floatLabel={true}
                        onChange={(e: BaseSyntheticEvent) => {
                            updateField({ ...FieldData, FieldNumber: e.target.value })
                        }}
                        error={errors?.FieldNumber}
                        value={FieldData.FieldNumber ? FieldData.FieldNumber : ''}
                        maxLength={7}
                    />
                </Col>
                <Col xs='4' sm='4' md='2' lg='1' xl='1' xxl='1' className='p-1'>
                    <InputText
                        name={`clu_editSubFieldId_${FieldData.CluId}`}
                        label="Sub Field #"
                        inputType={'text'}
                        floatLabel={true}
                        value={FieldData?.SubFieldNumber ? FieldData?.SubFieldNumber : ''}
                        onChange={(e: BaseSyntheticEvent) => {
                            updateField({ ...FieldData, SubFieldNumber: e.target.value })
                        }}
                        error={errors?.SubFieldNumber}
                        maxLength={7}
                    />
                </Col>
                <Col xs='4' sm='4' md='2' lg='1' xl='1' xxl='1' className='p-1'>
                    <InputDecimal
                        floatLabel={true}
                        label="Field Acres"
                        name={`clu_editFieldAcres_${FieldData.CluId}`}
                        onUpdate={(e: BaseSyntheticEvent) => {
                            updateField({ ...FieldData, Acres: e.target.value });
                        }}
                        value={FieldData.Acres ? FieldData.Acres : ''}
                    />
                </Col>
                <Col xs='12' sm='6' md='6' lg='5' xl='4' xxl='3' className='p-1'>
                    <InputText
                        name={`clu_editDescription_${FieldData.CluId}`}
                        label="Description"
                        inputType={'text'}
                        floatLabel={true}
                        onChange={(e: BaseSyntheticEvent) => {
                            updateField({ ...FieldData, Description: e.target.value })
                        }}
                        value={FieldData.Description ? FieldData.Description : ''}
                        maxLength={100}
                    />
                </Col>
                <Col xs='12' sm='6' md='6' lg='4' xl='5' xxl='2' className='p-1'>
                    <InputText
                        name={`clu_editCLU_Id_${FieldData.CluId}`}
                        label="CLU_Id"
                        inputType={'text'}
                        floatLabel={true}
                        value={FieldData.CluId ? FieldData.CluId : ''}
                        onChange={(e: BaseSyntheticEvent) => {
                            updateField({ ...FieldData, CluId: e.target.value })
                        }}
                        disabled={!FieldData.OID && !FieldData.ViewId}
                    />
                </Col>
                <AdminLocationSelect fieldData={FieldData} updateField={updateField} />
                <Col xs='12' sm='12' md='12' lg='6' xl='4' xxl='5' className='p-1'>
                    <DeterminationSelect fieldData={FieldData} updateField={updateField}/>
                </Col>
                <Col xs='6' sm='6' md='6' lg='2' xl='2' xxl='2' className='p-1'>
                    <CIMSDateSelect fieldData={FieldData} updateField={updateField} />
                </Col>
                <Col xs='3' sm='3' md='3' lg='2' xl='2' xxl='2' className='p-1'>
                    <InputSwitch
                        name={`clu_editNonCrop_${FieldData.CluId}`}
                        checked={!!FieldData.NonCrop}
                        onChange={changeNonCrop}
                        label="Non Crop"
                    />
                </Col>
            </Row>
        </div>)
}

export default EditField;
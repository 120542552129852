import { useFormik } from "formik";
import * as yup from 'yup';
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import * as NotificationSaga from 'store/notification';
import { useTitle } from 'services/browser';

interface ContactDetails {
    name: string;
    priority?: number;
    comments: string;
}

const initialValues: ContactDetails = {
    name: '',
    priority: undefined,
    comments: ''
}

const validationSchema = yup.object({
    name: yup.string().required('Name is required').min(3, 'Name must be at least 3 letters'),
    priority: yup.number().required('Select a priority'),
    comments: yup.string().required('Comments are requied')
});

export default () => {
    useTitle('ProAg React Template Contact Form');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (contactDetails: ContactDetails) => {
            // Call your api to post the form

            // Well leave it in a notification to the user
            dispatch(NotificationSaga.setNotification('Contact Form Submission',
                `Name: ${contactDetails.name} Priority: ${contactDetails.priority} Comments: ${contactDetails.comments}`));
        }
    });
    return (
        <>
            <h1>Contact Us</h1>
            <p>
                This is a sample contact for to demo the formik+yup packages and react-bootstrap form components.
            </p>

            <Form onSubmit={formik.handleSubmit} noValidate>
                <FloatingLabel controlId="name" label="Your Name" className="mb-3">
                    <Form.Control type="text" placeholder="Enter your name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.name && !formik.errors.name}
                        isInvalid={formik.touched.name && !!formik.errors.name}
                        value={formik.values.name} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.name}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel controlId="priority" label="Priority" className="mb-3">
                    <Form.Select aria-label="Choose your priority for this issue"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.priority && !formik.errors.priority}
                        isInvalid={formik.touched.priority && !!formik.errors.priority}>
                        <option value="">Please select</option>
                        <option value="1">Highest Priority</option>
                        <option value="2">Normal Priority</option>
                        <option value="3">Lowest Priority</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.priority}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <FloatingLabel controlId="comments" label="Comments" className="mb-3">
                    <Form.Control as="textarea" placeholder="Enter comments"
                        style={{ height: '200px' }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isValid={formik.touched.comments && !formik.errors.comments}
                        isInvalid={formik.touched.comments && !!formik.errors.comments}
                        value={formik.values.comments} />
                    <Form.Control.Feedback type="invalid">
                        {formik.errors.comments}
                    </Form.Control.Feedback>
                </FloatingLabel>

                <Button variant="primary" type="submit" className="mb-3">
                    Submit
                </Button>
            </Form>
        </>
    );
};
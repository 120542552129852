import { ApiGateway } from "components/apiGateway/useApiGateway";
import { ValidateSectionEquivalent } from "store/field/api";
import { IViewField } from "views/mpci/lands/viewmodels/iViewField";
import { IViewFieldErrors } from "views/mpci/lands/viewmodels/iViewFieldErrors";

export class FieldUtility {
    static NumberOnly =
        new RegExp("^[0-9]*$");

    static isNumberPopulatedAndGreaterThan0(number: string | undefined): boolean {
        if (number && parseFloat(number) > 0) {
            return true;
        }
        return false;
    }

    static isDatePopulated(date: Date | undefined): boolean {
        if (date) {
            return true;
        }
        return false;
    }

    static ValidRluId(rluId: string): boolean {
        const regex = new RegExp('^([R][0-9]+)$')
        return regex.test(rluId)
    }

    static IsCluIdGuid(cluId: string): boolean {
        const regex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i')
        return regex.test(cluId)
    }

    static getFieldKey(field?: IViewField): string {
        if (!!field?.OID) {
            return field?.OID?.toString()
        }
        return (field?.ViewId || field?.CluId || field?.RluId) ?? "";
    }

    static async ValidateSectionEquivalent(sectionEquivalentId: string, reinsuranceYear: string, api: ApiGateway): Promise<boolean> {
        let response = await ValidateSectionEquivalent(
            api,
            sectionEquivalentId,
            reinsuranceYear);

        return response;
    }

    static IsSectionEquivalentFormatValid(sectionEquivalentID: string): boolean {
        const regex = /^[\w\d]{2}(?:-[\w\d]{7}){1}(?:-[\w\d]{2}){1}$/;
        return regex.test(sectionEquivalentID)
    }
}
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import "./map.css";
import { useEffect, useState } from 'react';
import BingMaps from 'ol/source/BingMaps';
import Image from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';
import Zoom from 'ol/control/Zoom';
import VectorLayer from 'ol/layer/Vector';
import Fill from 'ol/style/Fill';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import VectorSource from 'ol/source/Vector';
import Filter from 'ol/format/WFS';
import GML2 from 'ol/format/GML2';
import Bbox from 'ol/loadingstrategy';
import { Feature } from 'ol';
import { Geometry } from 'ol/geom';
import Text from 'ol/style/Text';
import { IMapProps } from './props/iMapProps';
import { IClu } from './viewmodels/iClu';
import { LANDSCONSTANTS } from 'commom/constant';


export default (props: IMapProps) => {
    const { StateCode, CountyCode, FarmNumber, TractNumber, CluId, Geometry, ViewLandOID } = props

    const defaultMap = {} as Map;
    const [map, setMap] = useState(defaultMap);
  
    var bingLayerAerial = new TileLayer({
        visible: true,
        source: new BingMaps({
            imagerySet: 'AerialWithLabels',
            key: LANDSCONSTANTS.viewMapKey
        })
    });

    var mapServerUrlProxied = LANDSCONSTANTS.mapServerUrlProxied;
    var refLayerUrl = LANDSCONSTANTS.refLayerUrl;
    var refLayer = new Image({
        visible: true,
        source: new ImageWMS({
            url: refLayerUrl,
            params: {
                "LAYERS": "section,township,highrisknofilter,rainfall",
                "FORMAT": "image/png",
                'TRANSPARENT': 'true',
                'subcountycode': '',
                'reinsuranceYear': '2022'
            }
        })
    });

    useEffect(() => {
        const map = new Map({
            target: 'map_' + ViewLandOID,
            controls: [new Zoom],
            layers: [bingLayerAerial, refLayer],
            view: new View({
                center: [-8908887.277395891, 5381918.072437216],
                maxZoom: 19,
                zoom: 12
            }),
        });

        //TODO: this is just a sample shape. Actual implementation is still pending
        const clu = {} as IClu;
        clu.CimisState = StateCode ? StateCode : '';
        clu.CimsCounty = CountyCode ? CountyCode : '';
        clu.cluId = "";
        clu.FarmNumber = FarmNumber ? FarmNumber : '';
        clu.Geometry = "";
        clu.mapFileLocation = (window as any).globalConfig.map_file_location;
        clu.TractNumber = TractNumber ? TractNumber : '';

        makeMapDataRequest(clu, map);

        setMap(map);
    }, []);

    function getVectorSource() {
        return new VectorSource({
            format: new Filter({
                featureNS: 'http://mapserver.gis.umn.edu/mapserver',
                featureType: 'clu',
                gmlFormat: new GML2()
            })
        });
    }

    function makeMapDataRequest(clu: IClu, map: Map) {
        fetch(buildMapServerUrl(clu), {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            body: new URLSearchParams({
                s: clu.CimisState,
                c: clu.CimsCounty,
                f: clu.FarmNumber,
                t: clu.TractNumber
            }),
        }).then(data => data.text()).then(data => addShapeToMap(data, clu, map)).catch(error => handleError(error));
    }

    function handleError(error: any) {
        console.log(error);
    }

    function addShapeToMap(data: any, clu: IClu, map: Map) {
        var format = new GML2();
        var features = format.readFeatures(data);

        var shapeLayer = getVectorSource();
        shapeLayer.addFeatures(features);
        map.addLayer(new VectorLayer({
            source: shapeLayer,
            visible: true
        }));
        map.setView(new View({
            projection: 'EPSG:3857',
            center: [0, 0],
            zoom: 2,
        }));

        features.forEach(feature => setFeatureStyle(feature));

        cluLayerLoaderExtendSetter(shapeLayer, features, map, clu);
    }

    function setFeatureStyle(feature: Feature<Geometry>) {
        feature.setStyle(
            new Style({
                stroke: new Stroke({
                    color: "#000000",
                    width: 3
                }),
                fill: new Fill({
                    color: "#9ACD32"
                }),
                text: new Text({
                    textAlign: "center",
                    textBaseline: "middle",
                    font: "normal 12px Arial",
                    text: getLabelForEmbeddedMap(feature),
                    fill: new Fill({ color: "#000000" }),
                    stroke: new Stroke({ color: "#FFFFFF", width: 3 }),
                    offsetX: 0,
                    offsetY: 0,
                    rotation: 0
                })
            })
        );
    }

    function getLabelForEmbeddedMap(feature: Feature<Geometry>) {
        return feature.get("fieldnum") + "\n" + "(" + feature.get("calcacre") + ")";
    }

    function cluLayerLoaderExtendSetter(shapeLayer: VectorSource, features: Feature<Geometry>[], map: Map, clu: IClu) {
        if (features.length > 0) {
            map.getView().fit(shapeLayer.getExtent());
        }
    }

    function buildMapServerUrl(clu: IClu) {
        //URL to test with PAW locally
        //var mapServerUrlProxied = (window as any).globalConfig.localhost_mapServerUrlProxied;


        if (clu.FarmNumber == null || clu.FarmNumber.length == 0)
            return "";
        var hasTract = (clu.TractNumber != null && clu.TractNumber.length > 0);
        var mapFileName = hasTract ? "clubyfsntract.map" : "clubyfsn.map";
        return mapServerUrlProxied +
            "?map=" + clu.mapFileLocation + "/" + mapFileName +
            "&service=WFS" +
            "&version=1.1.0" +
            "&request=GetFeature" +
            "&OUTPUTFORMAT=GML2" +
            "&typename=" + "clu" +
            "&SRS=EPSG:3857" +
            "&maxFeatures=1000";
    }

    return (
        <>
            <>
                <div className='p-1'>
                    <div id={'map_' + ViewLandOID} className="map-container" />
                </div>
            </>
        </>
    );
}

import { ShareholderCoverageVM } from "./ShareholderCoverageVM";
import { IShareholder } from "./IShareholder";
import { ICompanionPolicyUnit } from ".././viewmodels/ICompanionPolicyUnit";

interface IDetaillineShares {
    Oid: number;
    AvailableShares: number;
}

export class ShareholderVM {
    oid?: number;
    mpciPolicyOID?: number;
    firstName: string = "";
    middleName?: string;
    lastName: string = "";
    suffix?: string;
    isBusiness?: boolean;
    isGrower: boolean = false;
    isSBI: boolean = false;
    businessName: string = "";
    transferOfRightToIndemnity: boolean = false;
    insureTenantLandlord: boolean = false;
    createdTime: number = 0;
    shareholderCoverages: ShareholderCoverageVM[] = [];
    isAssociatedAny?: boolean;

    public applyPremiumLineSummary(premiumLineSummary: IDetaillineShares[]) {
        for (var coverage of this.shareholderCoverages) {

            for (var premiumline of coverage.premiumLines ?? []) {

                if (this.insureTenantLandlord || this.transferOfRightToIndemnity) {
                    premiumline.enableDetaillineShare = true;
                    continue;
                }
                var objIndex = premiumLineSummary.findIndex((obj => obj.Oid == premiumline.oid));

                if (objIndex >= 0) {
                    premiumline.availableDetailLineShares = premiumLineSummary[objIndex].AvailableShares;
                    if ((premiumLineSummary[objIndex].AvailableShares <= 0 && (premiumline.share ?? 0) > 0) || (premiumLineSummary[objIndex].AvailableShares > 0 && (premiumline.share ?? 0) >= 0)) {
                        premiumline.enableDetaillineShare = true;
                    }
                    else {
                        premiumline.enableDetaillineShare = false;
                    }
                }
                else {
                    premiumline.enableDetaillineShare = true;
                    premiumline.availableDetailLineShares = 100;
                }
            }
        }
    }

    public resetPremiumLineSelection() {
        this.shareholderCoverages.forEach(c => (c.premiumLines??[]).forEach(p => p.resetSelection()));
    }
    public resetErrors() {
        this.shareholderCoverages.forEach(c => (c.premiumLines ?? []).forEach(p => p.errors = undefined));
    }
    public setCompanionPremiumLines() {
        this.shareholderCoverages.forEach((c) =>
            (c.premiumLines ?? []).forEach((p) => {
                if (p.isSelected && p.companionPremiumLine.length > 0) {
                    if (p.companionPremiumLine[0].IsCompanionLinked && p.companionPremiumLine[0].IsSource == false) {
                        let companion: ICompanionPolicyUnit[] = [{
                            MpciPremiumLineOid: p.oid,
                            IsCompanionLinked: false,
                            CompanionFlag: '',
                            CompanionLinkedMpciPremiumLineOID: '',
                            FormattedText: undefined,
                            CompanionPolicyNumber: '',
                            CompanionPolicyGrowerName: '',
                            CompanionPolicySharePercentage: undefined,
                            IsMatching: false,
                            IsSource: false,

                        }]
                        p.companionPremiumLine = companion;
                     }
                }
            }
            )
        );
    }
    public clone() : ShareholderVM {
        let result = new ShareholderVM();
        
        result.oid = this.oid;
        result.mpciPolicyOID = this.mpciPolicyOID;
        result.firstName = this.firstName;
        result.middleName = this.middleName;
        result.lastName = this.lastName;
        result.suffix = this.suffix;
        result.isBusiness = this.isBusiness;
        result.isGrower = this.isGrower;
        result.isSBI = this.isSBI;
        result.businessName = this.businessName;
        result.transferOfRightToIndemnity = this.transferOfRightToIndemnity;
        result.insureTenantLandlord = this.insureTenantLandlord;
        result.createdTime = this.createdTime;
        result.shareholderCoverages = ShareholderCoverageVM.cloneMany(this.shareholderCoverages);
        result.isAssociatedAny = this.isAssociatedAny;

        return result;
    }

    public static cloneMany(shareholderVMs: ShareholderVM[]): ShareholderVM[] {
        let result: ShareholderVM[] = [];

        if (shareholderVMs?.length > 0) {
            for (let shareholderVM of shareholderVMs) {
                result.push(shareholderVM.clone());
            }
        }

        return result;
    }

    public static fromIShareholder(shareholder: IShareholder, createdTime: number=Date.now()): ShareholderVM {

        let result = new ShareholderVM();

        result.oid = shareholder.OID,
        result.mpciPolicyOID = shareholder.MpciPolicyOID,
        result.firstName = shareholder.FirstName,
        result.middleName = shareholder.MiddleName,
        result.lastName = shareholder.LastName,
        result.suffix = shareholder.Suffix,
        result.isBusiness = shareholder.IsBusiness,
        result.isGrower = shareholder.IsGrower,
        result.isSBI = shareholder.IsSBI,
        result.businessName = shareholder.BusinessName,
        result.transferOfRightToIndemnity = shareholder.TransferOfRightToIndemnity,
        result.insureTenantLandlord = shareholder.InsureTenantLandlord,
        result.createdTime = createdTime,
        result.shareholderCoverages = ShareholderCoverageVM.fromIShareholderCoverages(shareholder.ShareholderCoverages);
        result.isAssociatedAny = shareholder.IsAssociatedAny;

        return result;
    }

    public static fromIShareholders(shareholders: IShareholder[], createdTime:number): ShareholderVM[] {
        var result: Array<ShareholderVM> = [];
        for (var shareholder of shareholders) {
            result.push(ShareholderVM.fromIShareholder(shareholder, createdTime));
        }

        return result;
    }

    public static calculateAvailableShares(shareholders: ShareholderVM[]) {
        var premiumLineSummary: IDetaillineShares[] = ShareholderVM.summarizePremiumLines(shareholders);

        for (var shareholder of shareholders) {
            shareholder.applyPremiumLineSummary(premiumLineSummary);
        }

        return shareholders;
    }

    public static summarizePremiumLines(shareholders: ShareholderVM[]): IDetaillineShares[] {
        let initialValues: IDetaillineShares[] = [];
        for (var shareholder of shareholders) {
            if (shareholder.insureTenantLandlord || shareholder.transferOfRightToIndemnity)
                continue;
            for (var coverage of shareholder.shareholderCoverages) {

                for (var premiumline of coverage.premiumLines ?? []) {

                    var objIndex = initialValues.findIndex((obj => obj.Oid === premiumline.oid));
                    if (objIndex >= 0) {
                        initialValues[objIndex].AvailableShares = (Math.round(initialValues[objIndex].AvailableShares * 100) / 100) - (premiumline.share ?? 0);
                    }
                    else {
                        initialValues.push({ Oid: premiumline.oid, AvailableShares: 100 - (premiumline.share ?? 0) });
                    }
                }
            }
        }
        return initialValues;
    }

    public static upsertShareholderVM(shareholderVMs: ShareholderVM[], shareholderVMToUpsert: ShareholderVM, feature91701?: string) {
        let foundIndex = shareholderVMs.findIndex(s => s.oid === shareholderVMToUpsert.oid);
        if (foundIndex > -1) {

            shareholderVMs[foundIndex].isAssociatedAny = shareholderVMToUpsert.isAssociatedAny;
            
            for (let c of shareholderVMs[foundIndex].shareholderCoverages) {
                
                let coverage = shareholderVMToUpsert.shareholderCoverages.find(cv => cv.oid === c.oid);
                if (coverage) {
                    for (let p of c.premiumLines!) {
                        
                        let prline = coverage.premiumLines?.find(pl => pl.oid === p.oid) ;
                        if (prline) {
                            p.isAssociated = prline.isAssociated;
                            p.share = prline.share;
                            p.isSelected = prline.isSelected;
                            p.errors = prline.errors;
                            if (feature91701 != undefined && feature91701 != "") {
                                if (feature91701 === "true") {
                                    p.companionPremiumLine = prline.companionPremiumLine;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            shareholderVMs.push(shareholderVMToUpsert);
        }
    }

    public getFormatedText(){        
        return this.isBusiness ? this.businessName 
        : this.suffix == "" 
        ? this.firstName.trim() + " " + this.middleName?.trim() + " " + this.lastName.trim()
        : this.firstName.trim() + " " + this.middleName?.trim() + " " + this.lastName.trim() + " " + this.suffix?.trim()
    }
}




import { useOktaAuth } from '@okta/okta-react';

export type ApiGateway = {
    get: (url: string, appId: string) => Promise<any>;
    getRetry: (url: string, appId: string) => Promise<any>;
    getFile: (url: string, appId: string) => Promise<any>;
    post: (
        url: string,
        appId: string,
        body: {}
    ) => Promise<any>;
    postFile: (
        url: string,
        appId: string,
        body: FormData
    ) => Promise<any>;
    put: (
        url: string,
        appId: string,
        body: {}
    ) => Promise<any>;
    delete: (url: string, appId: string) => Promise<any>;
};

export const useApiGateway = (includeDataInError?: boolean): ApiGateway => {
    const { authState, oktaAuth } = useOktaAuth();
    const authorization = `Bearer ${oktaAuth.getAccessToken() ?? ''}`;
    
    let apiUrl = (window as any).globalConfig?.ApiGateway_URL || "https://api-dev.nonprod-proag.com/";
    const postLogoutRedirectUri: string = (window as any).globalConfig?.Logout_Url;
    const signOut = () => oktaAuth.signOut({ postLogoutRedirectUri });

    if (!authState?.isAuthenticated) {
        signOut();
    }

    function handleResponseWrapper(urlString: string, requestOptions: any) {
        return fetch(urlString, requestOptions).then(handleResponse).catch((e)=> {
            if(shouldTryAgain(e)){
                return fetch(urlString, requestOptions).then(handleResponse).catch((e2)=> {
                    if(shouldTryAgain(e2)){
                        return fetch(urlString, requestOptions).then(handleResponse)
                    }
                    else{
                        return handleResponse(e2)
                    }
                });
            }
            else{
                return handleResponse(e)
            }
        });
    }

    function shouldTryAgain(responseObject: any): boolean{
        if(responseObject && (responseObject.status == '401' || responseObject.status == '503')){
            return false;
        }
        return true;
    }

    function handleResponse(response: {
        text: () => Promise<any>;
        ok: any;
        statusText: any;
        headers: any;
        status: any;
        url?: any;
    }) {
        if (response.status === 401) {
            signOut();
        }

        if (!response.ok) {
            return Promise.reject(response);
        } else {
            return response.text().then((text) => {
                const data = text && JSON.parse(text);

                if (!response.ok) {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
                return data;
            });
        }
    }

    function handleFileResponse(response: {
        arrayBuffer: () => Promise<any>;
        ok: any;
        statusText: any;
        status: any;
        headers: any;
    }) {
        if (response.status === 401) {
            signOut();
        }
        return response.arrayBuffer().then((buffer) => {
            const data = buffer;

            if (!response.ok) {
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    }

    return {
        get: function get(
            url: string,
            appId: string
        ) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: authorization,
                    ApplicationId: appId,
                },
            };
            return fetch(apiUrl + url, requestOptions).then(handleResponse);
        },
        getRetry: function get(
            url: string,
            appId: string
        ) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    Authorization: authorization,
                    ApplicationId: appId,
                },
            };
            return handleResponseWrapper(apiUrl + url, requestOptions);
        },

        getFile: function getFile(
            url: string,
            appId: string
        ) {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                    Authorization: authorization,
                    ApplicationId: appId,
                },
            };
            return fetch(apiUrl + url, requestOptions).then(handleFileResponse);
        },

        post: function post(
            url: string,
            appId: string,
            body: {}
        ) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorization,
                    ApplicationId: appId,
                },
                body: JSON.stringify(body),
            };
            return fetch(apiUrl + url, requestOptions).then(handleResponse);
        },

        postFile: function postFile(
            url: string,
            appId: string,
            body: FormData
        ) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    Authorization: authorization,
                    ApplicationId: appId,
                },
                body: body,
            };

            return fetch(apiUrl + url, requestOptions).then(handleFileResponse);
        },

        put: function put(
            url: string,
            appId: string,
            body: {}
        ) {
            const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authorization,
                    ApplicationId: appId,
                },
                body: JSON.stringify(body),
            };
            return fetch(apiUrl + url, requestOptions).then(handleResponse);
        },

        // prefixed with underscored because delete is a reserved word in javascript
        delete: function _delete(
            url: string,
            appId: string
        ) {
            const requestOptions = {
                method: 'DELETE',
                headers: {
                    Authorization: authorization,
                    ApplicationId: appId
                },
            };
            return fetch(apiUrl + url, requestOptions).then(handleResponse);
        },
    };
};

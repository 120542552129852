import { Action, ActionCreator } from 'redux';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { ICompanionLinksStore } from './index';

const actionTypes = {
    GET_COMPANIONLINKS_REQUEST: '@@companionlink/GET_COMPANIONLINKS_REQUEST',
    GET_COMPANIONLINKS_REQUEST_SUCCESS: '@@companionlink/GET_COMPANIONLINKS_REQUEST_SUCCESS',
    GET_COMPANIONLINKS_REQUEST_FAIL: '@@companionlink/GET_COMPANIONLINKS_REQUEST_FAIL',
    DELETE_COMPANIONLINKS_REQUEST: '@@companionlink/DELETE_COMPANIONLINKS_REQUEST',
    DELETE_COMPANIONLINKS_REQUEST_SUCCESS: '@@companionlink/DELETE_COMPANIONLINKS_REQUEST_SUCCESS',
    DELETE_COMPANIONLINKS_REQUEST_FAIL: '@@companionlink/DELETE_COMPANIONLINKS_REQUEST_FAIL',
}

export const ActionTypes = actionTypes;

export interface GetCompanionLinksRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    CompanionLinksRequest: ICompanionLinksStore;
};

export interface DeleteCompanionLinksRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    CompanionLinkId: string;
    CompanionLinksRequest: ICompanionLinksStore;
};

export interface GetCompanionLinksResponseAction extends Action {
    CompanionLinksRequest: ICompanionLinksStore;
};

export interface DeleteCompanionLinksResponseAction extends Action {
    CompanionLinksRequest: ICompanionLinksStore;
};

export const getCompanionLinksSuccess: ActionCreator<GetCompanionLinksResponseAction> = (companionLinksStore: ICompanionLinksStore) =>
({
    type: actionTypes.GET_COMPANIONLINKS_REQUEST_SUCCESS,
    CompanionLinksRequest: companionLinksStore
});

export const getCompanionLinksFail: ActionCreator<GetCompanionLinksResponseAction> = (companionLinksStore: ICompanionLinksStore) =>
({
    type: actionTypes.GET_COMPANIONLINKS_REQUEST_FAIL,
    CompanionLinksRequest: companionLinksStore
});

export const deleteCompanionLinksSuccess: ActionCreator<DeleteCompanionLinksResponseAction> = (companionLinksStore: ICompanionLinksStore) =>
({
    type: actionTypes.DELETE_COMPANIONLINKS_REQUEST_SUCCESS,
    CompanionLinksRequest: companionLinksStore
});

export const deleteCompanionLinksFail: ActionCreator<DeleteCompanionLinksResponseAction> = (companionLinksStore: ICompanionLinksStore) =>
({
    type: actionTypes.DELETE_COMPANIONLINKS_REQUEST_FAIL,
    CompanionLinksRequest: companionLinksStore
});

export interface DeleteCompanionLinkFailAction extends Action {
    errorCode: string;
};

export type CompanionLinksActions = GetCompanionLinksRequestAction | GetCompanionLinksResponseAction | DeleteCompanionLinksRequestAction | DeleteCompanionLinksResponseAction;
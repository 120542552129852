import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export { default as reducer } from './reducer';
export * from './actionsPublic';

export interface FeatureFlagState {
    FeatureFlags: IFeatureFlag[],
    Loading?: boolean;
    HasError?: boolean;
}

export interface IFeatureFlag {
    featureFlagId: number,
    name: string,
    enabled: boolean
}
import { put } from 'redux-saga/effects';
import { IFilter } from '.';
import { setFiltersSuccess, setCoveragesSuccess, setUnitNumbersSuccess, setCountiesSuccess, setTractNumbersSuccess, setFarmNumbersSuccess, setSectionsSuccess, setPlaceNamesSuccess,setShowNLFUnitsSuccess, setBasicUnitsSuccess, setOptionalUnitsSuccess, maxLandsReachedSuccess } from './actions';
import { ISelectList } from "components/forms/iSelectList";

export function* setFilters(filter: IFilter) {
    yield put(setFiltersSuccess(filter));
}

export function* setCounties(counties: ISelectList[]) {
    yield put(setCountiesSuccess(counties));
}

export function* setCoverages(coverages: ISelectList[]) {
    yield put(setCoveragesSuccess(coverages));
}

export function* setUnitNumbers(unitNumbers: ISelectList[]) {
    yield put(setUnitNumbersSuccess(unitNumbers));
}

export function* setTractNumber(tractNumber: ISelectList[]) {
    yield put(setTractNumbersSuccess(tractNumber));
}

export function* setFarmNumber(farmNumbers: ISelectList[]) {
    yield put(setFarmNumbersSuccess(farmNumbers));
}

export function* setSection(sections: ISelectList[]) {
    yield put(setSectionsSuccess(sections));
}

export function* setPlaceName(placeNames: ISelectList[]) {
    yield put(setPlaceNamesSuccess(placeNames));
}

export function* setBasicUnits(basicUnits: ISelectList[]) {
    yield put(setBasicUnitsSuccess(basicUnits));
}

export function* setOptionalUnits(optionalUnits: ISelectList[]) {
    yield put(setOptionalUnitsSuccess(optionalUnits));
}

export function* setShowNLFUnits(showNLFUnits: boolean) {
    yield put(setShowNLFUnitsSuccess(showNLFUnits));
}

export function* maxLandsReached(isMaxLandsReached: boolean) {
    yield put(maxLandsReachedSuccess(isMaxLandsReached));
}
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ERRORS } from '../../commom/constant';
import { GlobalState } from '../../store';
import ApplicationIdValues from '../apiGateway/ApplicationIdValues';
import { useApiGateway } from '../apiGateway/useApiGateway';
import { IUserAccess } from './viewmodels/IUserAccess';
import * as FeatureFlagsSaga from '../../store/proAgFeatureFlags';
import { ProAgFeatureFlags } from '../../manager/common/proAgFeatureFlags';

export interface PolicyInfo {
    FormattedHeaderPolicyNumberWithStateAbbr: string,
    ReinsuranceYear: number,
    StateCode: string,
    CommodityYear: number,
    GrowerName: string,
}
export interface props {
    growerId: string,
    mpciPolicyId: string ,
    errorHandler: (error: string) => void;
    userAccessHandler?: (userAccess: IUserAccess) => void;
}

export default function PolicyTitle(props: props) {
    const [policyInfo, setPolicyInfo] = React.useState<PolicyInfo>();
    const api = useApiGateway();
    const paw_api_version = (window as any).globalConfig.paw_api_version;

    
    function getPolicyUrl(growerid: string, mpcipolicyid: string) {
        return paw_api_version + "/Growers/" + growerid + "/MpciPolicies/" + mpcipolicyid + "?fullStructure=false";
    }
    async function getPolicy(growerid: string, mpcipolicyid: string) {
        const res = await api.get(getPolicyUrl(growerid, mpcipolicyid), ApplicationIdValues.Paw);
        return res;
    }

    const featureFlags = useSelector<GlobalState, FeatureFlagsSaga.FeatureFlagState>((featureFlags) => featureFlags.featureFlagState);
    const feature95188 = featureFlags?.FeatureFlags?.filter((x) => x.name == ProAgFeatureFlags.Feature95188).map((y) => y.enabled).toString();

    React.useEffect(() => {
        getPolicy(props.growerId, props.mpciPolicyId).then((data) => {
            let policyInfo = {} as PolicyInfo;
            policyInfo = data;
            let userAccess = {} as IUserAccess;

            if (feature95188! === "true") {
                userAccess.canEditPremiumLine = data.CanEditPremiumLine;
            }
            else {
                userAccess.canEditPremiumLine = true;
            }

            if (props.userAccessHandler != undefined) {
                props.userAccessHandler(userAccess);
            }
            setPolicyInfo(policyInfo)
        }).catch((err) => {
            if (err?.status == '503') {
                props.errorHandler(ERRORS.paw_maintance_error);
            }
            else {
                props.errorHandler(ERRORS.failed_to_fetch_policy_header);
            }
        });
    }, [feature95188])

    return (
        <Container fluid>
            <div className="h5 m-1 square border border-1 border-dark">
                <Row className="m-1 fw-bold">
                    <Col md="5" sm="12" data-testid="GrowerName" className='d-flex align-items-center'>{policyInfo?.GrowerName}</Col>
                    <Col md="5" sm="12" data-testid="FormattedHeaderPolicyNumberWithStateAbbr" className='d-flex align-items-center'>{policyInfo?.FormattedHeaderPolicyNumberWithStateAbbr}</Col>
                    <Col md="1" sm="12" data-testid="ReinsuranceYear" className='d-flex align-items-center'>RY: {policyInfo?.ReinsuranceYear}</Col>
                    <Col md="1" sm="12" data-testid="CommodityYear" className='d-flex align-items-center'>CY: {policyInfo?.CommodityYear}</Col>
                </Row>
            </div>
        </Container>
    );
}
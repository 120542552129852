import { call, put, takeEvery } from 'redux-saga/effects';
import { PolicyUtility } from '../../manager/lands/policyUtility';
import { ActionTypes, getFieldsFail, GetFieldsRequestAction, getFieldsSuccess } from './actions';
import * as Api from './api';

export function* getFieldsRequestSaga() {
    yield takeEvery(ActionTypes.GET_FIELDS_REQUEST, getFieldsRequest);
};

function* getFieldsRequest(action: GetFieldsRequestAction) {
    yield call(getFields, action);
}

function* getFields(action: GetFieldsRequestAction) {
    try {
        if (action.fieldsStore && action.fieldsStore.PreLoad) {

            yield Api.GetFields(action.api, action.reinsuranceYear, action.landOID).then(response => {
                if (action.fieldsStore) {
                    action.fieldsStore.Fields = PolicyUtility.getStoreFields(response)
                }
            })

            yield put(getFieldsSuccess(action.fieldsStore));
        }

    }
    catch (e: any) {
        yield put(getFieldsFail(action.fieldsStore));
    }
}
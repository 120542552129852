import { WARNINGS } from 'commom/constant';
import { parse } from 'date-fns';
import React, { BaseSyntheticEvent, useEffect } from 'react';
import { CloseButton, Col, OverlayTrigger, Row, Tooltip, TooltipProps } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { AlertType } from '../../../components/alert/alertType';
import { CustomAlert } from '../../../components/alert/customAlert';
import { PolicyUtility } from '../../../manager/lands/policyUtility';
import { GlobalState } from '../../../store';
import { IFieldsStore } from '../../../store/field';
import * as PremiumLineInformationStore from '../../../store/premiumLineInformation';
import AddNewField from './addNewField';
import { LandsContext } from './landsContext';
import ViewCompanionLink from './viewCompanionLink';
import ViewField from './viewField';
import styles from './viewFields.module.scss';
import ViewFieldsDetails from './viewFieldsDetails';
import { IViewFieldsProps } from './viewmodels/iViewFieldsProps';
import { IViewLand } from './viewmodels/iViewLands';
import { FieldUtility } from 'manager/lands/fieldUtility';

const ViewFields = (viewFieldsProps: IViewFieldsProps) => {
    const landState = useSelector<GlobalState, IViewLand[]>((state) => state.landState.Lands);
    const fieldsRequest = useSelector<GlobalState, IFieldsStore[]>((state) => state.fieldsState.FieldsStore);
    const land = landState.filter(c => c.OID == viewFieldsProps.FnId);
    const isDisable = ((land != undefined) && (land.length > 0)) ? (land[0].CompanionFlag == "C" || land[0].CompanionFlag == "BC" || viewFieldsProps.editLock == true) : false;
    const landsContext = React.useContext(LandsContext);
    const dispatch = useDispatch();

    const premiumLineList = useSelector<GlobalState, PremiumLineInformationStore.PremiumLineInformation>((premiumLine) => premiumLine.premiumLineInformationState);
    const [showAlert, setShowAlert] = React.useState(false);
    const [isRLUOnly, setIsRLUOnly] = React.useState(false);
    const [totalAcres, setTotalAcres] = React.useState('0.00');
    const [totalAcresLoading, setTotalAcresLoading] = React.useState(true);
    const [totalAcresFailed, setTotalAcresFailed] = React.useState(false);

    const coverage = PolicyUtility.getSelectedCoverage(landsContext.Coverages, viewFieldsProps.SelectedCoverage);
    let isPRF = PolicyUtility.isPRF(coverage);
    const countyCode = coverage?.CountyCode ?? ''

    const premiumLine = premiumLineList?.PremiumLines?.find(prem => prem.PremiumLineOid == viewFieldsProps.MpciPremiumLineId);
    const hasSectionEquivalence = !!premiumLine?.Croptions?.includes('UG');

    const renderHeaderCheckboxTooltip = (props: JSX.IntrinsicAttributes & TooltipProps & React.RefAttributes<HTMLDivElement>) => (
        <Tooltip id='clearButton-Tooltip' {...props}>
            Select All Rows
        </Tooltip>
    );

    useEffect(() => {
        var rluOnly = PolicyUtility.isRLUOnly(viewFieldsProps.FarmNumber, viewFieldsProps.SectionTownshipRange, viewFieldsProps.PlaceName, viewFieldsProps.OtherLandId );
        setIsRLUOnly(rluOnly);
    }, [viewFieldsProps]);

    useEffect(() => {
        setTotalAcresLoading(PolicyUtility.isFieldsRequestLoadingForPremiumLine(fieldsRequest, viewFieldsProps.MpciPremiumLineId))
        setTotalAcresFailed(PolicyUtility.fieldsRequestLoadingForPremiumLineFailed(fieldsRequest, viewFieldsProps.MpciPremiumLineId))
    }, [JSON.stringify(fieldsRequest)]);

    useEffect(() => {
        if (!totalAcresLoading) {
            setTotalAcres(PolicyUtility.calculateReportedAcres(premiumLineList.PremiumLines.find(prem => prem.PremiumLineOid == viewFieldsProps.MpciPremiumLineId)));
        }
    }, [totalAcresLoading,JSON.stringify(premiumLineList.PremiumLines)]);

    function onCloseFields() {
        if (viewFieldsProps.Fields.some((field) => !field.IsCluInformationSnapshotMatching || !field.IsFieldDetailsSnapshotMatching || !!field.ViewId)) {
            setShowAlert(true);
        } else {
            closeFields();
        }
    }

    function closeFields() {       
        setShowAlert(false);
        viewFieldsProps.CloseAddFSNFields(true);
    }

    function onCancelAlert() {
        setShowAlert(false);
    }

    function returnHeader() {
        let final: { className: string, value: string, key: string }[] = [];

        if (viewFieldsProps.FarmNumber) {
            final.push({ className: styles.bold, value: 'Farm Number: ', key: '1' });
            final.push({ className: styles.regular, value: viewFieldsProps.FarmNumber, key: '2' });
        }
        if (viewFieldsProps.Tract) {
            final.push({ className: styles.bold, value: '  Tract: ', key: '3' });
            final.push({ className: styles.regular, value: viewFieldsProps.Tract, key: '4' });
        }
        if (viewFieldsProps.SectionTownshipRange) {
            final.push({ className: styles.bold, value: '  Sec/Twp/Rng: ', key: '5' });
            final.push({ className: styles.regular, value: viewFieldsProps.SectionTownshipRange, key: '6' });
        }
        if (viewFieldsProps.UnitNumber) {
            final.push({ className: styles.bold, value: ' Unit Number: ', key: '7' });
            final.push({ className: styles.regular, value: viewFieldsProps.UnitNumber, key: '8' });
        }
        return (<div className={"float-xl-start ps-2"}>
            {final.map(headerRecord => (<span key={headerRecord.key} className={headerRecord.className}>{headerRecord.value}</span>))}
        </div>
        )
    }

    const onChangeSelectAll = (e: BaseSyntheticEvent) => {
        const shouldSelectAll: boolean = (e.target).checked;
        const viewFields = [...viewFieldsProps.Fields]
        viewFields.forEach((field) => {
            if (shouldSelectAll) {
                if (!!premiumLine?.PlantDate) {
                    field.PlantedDate = parse(premiumLine?.PlantDate, "yyyy-MM-dd'T'HH:mm:ss", new Date());
                }
                field.ReportedAcres = field.Acres;
            } else {
                field.PlantedDate = undefined;
                field.ReportedAcres = '';
            }
            field.IsChecked = shouldSelectAll;
            viewFieldsProps.updateField(field, false);
            PolicyUtility.UpdateReportedAcres(dispatch, field.ReportedAcres, viewFieldsProps.MpciPremiumLineId, viewFieldsProps.FnId, field)
        })
    }


    return (
        <div className={styles.border} data-testid="view-fields">
            <CustomAlert
                id="closeFieldsAlert"
                show={showAlert}
                variant="warning"
                onClose={closeFields}
                onCancel={onCancelAlert}
                type={AlertType.Confirmation}
            >
                {WARNINGS.landId_fields_unsaved_data_warning}
            </CustomAlert>
            <div className={styles.header}
                data-testid='showFieldsHeader'
            >
                <Row className={styles.headerObjects}>
                    <Col
                        xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}
                    >
                        <div className={'d-flex align-items-center'} >
                            <OverlayTrigger delay={{ show: 50, hide: 200 }} overlay={renderHeaderCheckboxTooltip} placement='top'>
                                <input type="checkbox" className={`float-start`} data-testid='HeaderSelectAllToggle' id='HeaderSelectAllToggle' disabled={isDisable}
                                    onChange={e => onChangeSelectAll(e)}></input>
                            </OverlayTrigger>
                            {returnHeader()}
                        </div>
                    </Col>
                    <Col
                        xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}
                    >
                        <CloseButton
                            className={`float-end`}
                            data-testid="closeFieldsButton"
                            onClick={onCloseFields}
                        />
                    </Col>
                </Row>
            </div>
            <ViewFieldsDetails
                isDisabled={isDisable}
                fieldProps={viewFieldsProps}
                premiumLineList={premiumLineList}
                totalReportedAcres={totalAcres}
                updateViewField={viewFieldsProps.updateField}
                editLock={viewFieldsProps.editLock}
                isFieldsLoading={totalAcresLoading}
                fieldsLoadFailed={totalAcresFailed}
            />
            {viewFieldsProps.MpciPremiumLineId != 0 &&
                <div>
                    <ViewCompanionLink data-testid={`viewCompanionComponent`} ShowUnlinkButton={false} MpciPremiumLineId={viewFieldsProps.MpciPremiumLineId}
                        MpciCoverageId={viewFieldsProps.MpciCoverageId} ParentMpciPremiumLineId={viewFieldsProps.ParentMpciPremiumLineId}
                        IsShowHeader={false} UnitNumberDescription={""} CloseCompanionLinkage={() => void 0} />
                </div>
            }

            <div>
                {(viewFieldsProps.IsLoading == true || viewFieldsProps.IsLoading == undefined) ?
                    <Spinner className={styles.spinner} animation={"border"} />
                    :
                    (isDisable == false) &&
                    <AddNewField
                        PremiumLineOid={viewFieldsProps.MpciPremiumLineId}
                        LandOid={viewFieldsProps.FnId}
                        AdminCountyCode={countyCode}
                        StateCode={landsContext.StateCode.toString()}
                        SelectedDetailLinePlantedDate={premiumLine?.PlantDate}
                        IsPreventedPlant={PolicyUtility.isPreventedPlant(premiumLine?.GuaranteeReductionFlag)}
                        IsPRF={isPRF}
                        IsPlantDateRequired={coverage?.IsPlantDateRequired ?? false}
                        IsRLUOnly={isRLUOnly}
                        addField={viewFieldsProps.addField}
                        hasSectionEquivalence={hasSectionEquivalence}
                        ReinsuranceYear={landsContext.ReinsuranceYear.toString()}
                    />}
                {
                    viewFieldsProps.Fields.map((field, index) => (
                        <ViewField
                            key={FieldUtility.getFieldKey(field)}
                            FnId={viewFieldsProps.FnId}
                            PremiumLineOid={viewFieldsProps.MpciPremiumLineId}
                            ViewField={field}
                            ShowCopy={viewFieldsProps.IsViewFromCoverageSection}
                            SelectedDetailLinePlantedDate={premiumLine?.PlantDate}
                            IsPreventedPlant={PolicyUtility.isPreventedPlant(premiumLine?.GuaranteeReductionFlag)}
                            IsPRF={isPRF}
                            IsPlantDateRequired={coverage?.IsPlantDateRequired ?? false}
                            updateField={viewFieldsProps.updateField}
                            Index={index}
                            Tract={viewFieldsProps.Tract}
                            editLock={viewFieldsProps.editLock}
                            hasSectionEquivalence={hasSectionEquivalence}
                            ReinsuranceYear={landsContext.ReinsuranceYear.toString()}
                        />
                    ))
                }
                {(viewFieldsProps.IsLoading == false && viewFieldsProps.Fields.length == 0 && viewFieldsProps.IsViewFromCoverageSection == true && isDisable) &&
                    <div className="p-2 text-center">No Fields Available to View</div>
                }
            </div>
        </div >
    )
}
export default ViewFields;
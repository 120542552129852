import { SelectForm } from "components/forms/formBase";
import { getDeteminationCodes } from "manager/lands/api";
import { useState, useEffect, BaseSyntheticEvent, useContext } from "react";
import { IViewField } from "../viewmodels/iViewField";
import { useApiGateway } from "components/apiGateway/useApiGateway";
import { LandsContext } from "../landsContext";
import { ISelectList } from "components/forms/iSelectList";

interface DeterminationSelectProps {
    fieldData: IViewField;
    updateField: (field: IViewField, isCluInformation?: boolean) => void;
}

export function DeterminationSelect({ fieldData, updateField }: DeterminationSelectProps): JSX.Element {
    const landsContext = useContext(LandsContext);
    const api = useApiGateway();
    const [determinationCodeOptions, setDeterminationCodeOptions] = useState<ISelectList[]>(() => [{ label: fieldData.Determination ? fieldData.Determination : 'Loading', value: fieldData.Determination ? fieldData.Determination : 'Loading' }])

    useEffect(() => {
        fetchDeterminationCodes()
    }, []);

    const fetchDeterminationCodes = (): void => {
        getDeteminationCodes(landsContext.ReinsuranceYear, api).then((determinationCodes) => {
            setDeterminationCodeOptions(determinationCodes.map(code => { return { label: code.CLUDeterminationDescription, value: code.CLUDeterminationCode}}))
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <>
            <SelectForm
                name={`clu_DeterminationCode_${fieldData.CluId}`}
                label={'Determination'}
                value={fieldData.Determination ? fieldData.Determination : ''}
                onChange={(e: BaseSyntheticEvent) => {
                    updateField({ ...fieldData, Determination: e.target.value })
                }}
                options={determinationCodeOptions}
                isClearable={true}
            />
        </>
    )
}

import { Button, Row, Col } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import styles from './Save.module.scss';

function Save() {
    return (
        <>
            <div  >
                <Row className="float-end" >
                    <Col className="pull-right">
                        <Button data-testid="btnSave" variant="default">Save</Button>{' '}
                        <Button data-testid="btnContinue" variant="primary">Save & Continue</Button>{' '}
                        <Button data-testid="btnCancel" variant="warning">Cancel</Button>{' '}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Save;
import { plantedCluDataSource } from 'commom/constant';
import { formatWithComma } from 'components/utils/formatting/FormatUtils';
import { format, isValid, parse } from 'date-fns';
import { difference, isEmpty } from 'lodash';
import { View } from 'ol';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { Badge, Col, Collapse, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FormButton } from '../../../components/forms/button/formbutton';
import { InputDatePicker, InputDecimal, InputText } from '../../../components/forms/formBase';
import LandLabel from '../../../components/label/LandLabel';
import { FieldUtility } from '../../../manager/lands/fieldUtility';
import { GlobalState } from '../../../store';
import { useFieldValidation } from './customHooks/useFieldValidation';
import EditField from './editField';
import styles from './viewFields.module.scss';
import { IViewFieldProps } from './viewmodels/iViewFieldProps';
import { IViewLand } from './viewmodels/iViewLands';
import PreventedPlantDisplay from './viewPreventPlantDisplay';
import { SectionEquivalentId } from './sectionEquivalentID';
import { PolicyUtility } from 'manager/lands/policyUtility';
import { IViewField } from './viewmodels/iViewField';

function ViewField({ FnId, ViewField, ShowCopy, SelectedDetailLinePlantedDate, IsPreventedPlant, IsPRF, IsPlantDateRequired, Index, updateField, Tract, editLock, PremiumLineOid, hasSectionEquivalence, ReinsuranceYear }: IViewFieldProps) {
    const landState = useSelector<GlobalState, IViewLand[]>((state) => state.landState.Lands);
    const land = landState.filter(c => c.OID == FnId);
    const isDisable = ((land != undefined) && (land.length > 0)) ? (land[0].CompanionFlag == "C" || land[0].CompanionFlag == "BC") || editLock : false;
    const [showEditCLU, setShowEditCLU] = useState(false);
    const dispatch = useDispatch();
    const dateRef = useRef<HTMLInputElement>(null);
    const { errors, clearErrors  } = useFieldValidation({
        Field: ViewField, 
        IsPreventedPlant: IsPreventedPlant, 
        IsPlantDateRequired: IsPlantDateRequired,
        HasSectionEquivalentId: hasSectionEquivalence,
        IsDisabled: isDisable,
        ReinsuranceYear: ReinsuranceYear
    });
    const isCluIdGuid = FieldUtility.IsCluIdGuid(ViewField.CluId ?? '');

    useEffect(() => {
        const hasErrors = !isEmpty(errors)
        if (hasErrors !== ViewField.HasError) {
            updateField({ ...ViewField, HasError: hasErrors })
        }
    }, [errors])

    const hrTooltip = (
        <>
            <u> High Risk Codes:</u> <br />
            C - Land is located entirely in a Map Area(may or may not be High Risk) <br />
            P - Land is partially located in a High Risk Area
        </>
    );


    const uraTooltip = (
        <>
            <u>Unrated Sub County Codes:</u><br />
            C - Completely covered by Map Area<br />
            P - Partially covered by Map Area
        </>
    );

    function getEditCLUToolTip(): string {
        if (ViewField.IsRlu) {
            return 'Disabled for RLU';
        }
        if (!!errors.ReportedAcres) {
            return 'Reported Acres is Required';
        }
        if (showEditCLU) {
            return 'Hide Edit CLU';
        }
        return 'Edit CLU';
    }

    const fieldTooltip: JSX.Element = (
        <div id="button-tooltip">
            Tract: {!!Tract ? Tract : 'N/A'}
            {!isCluIdGuid && <div>{`Manually created`}</div>}
            {ViewField.DataSource && <div>{`Data Source: ${plantedCluDataSource[ViewField.DataSource]}`}</div>}
        </div>
    );

    function fieldNumClickEffect() {
        if (ViewField.IsChecked) {
            setShowEditCLU(!showEditCLU)
        }
    }

    function landLabel(): string {
        return !isCluIdGuid ? ViewField.FieldNumber + '*' : ViewField.FieldNumber ?? '';
    }

    function getFieldIdDisplay() {
        if (ViewField.IsRlu) {
            return (
                <InputText
                    floatLabel={true}
                    label={"RLU #"}
                    name={`Rlu#_${FnId}_${ViewField.OID}`}
                    inputType='input'
                    className={styles.rlu}
                    value={ViewField.RluId ? ViewField.RluId : ''}
                    onChange={(e: BaseSyntheticEvent) => {
                        updateField({ ...ViewField, RluId: e.target.value }, false)
                    }}
                    error={errors.RluId}
                    maxLength={10}
                />
            );
        }
        else {
            return (
                <div
                    data-testid={`field-number-label`}
                    onClick={!isDisable ? fieldNumClickEffect : undefined}>
                    <LandLabel
                        Label={"Field #"}
                        Name={`field-number-${Index}`}
                        Value={landLabel()}
                        isLink={ViewField.IsChecked && !isDisable}
                        ToolTip={ViewField.IsChecked ? fieldTooltip : undefined}
                    ></LandLabel>
                </div>
            )
        }
    }

    const onChangePlantDate = (e: any) => {
        updateField({ ...ViewField, PlantedDate: parse(e.target.value, 'yyyy-MM-dd', new Date()), IsChecked: true }, false);
    }

    const onChangeAssociateField = (isChecked: boolean) => {
        const nextField = { ...ViewField, IsChecked: isChecked }
        setAssociatedFieldDefaults(nextField)
    }

    const setAssociatedFieldDefaults = (field: IViewField): void => {
        if (field.IsChecked) {
            if (!!SelectedDetailLinePlantedDate) {
                updateField({ ...field, ReportedAcres: field.Acres, PlantedDate: parse(SelectedDetailLinePlantedDate, "yyyy-MM-dd'T'HH:mm:ss", new Date()) }, false)
            }
            else {
                updateField({ ...field, ReportedAcres: field.Acres }, false);
            }
            PolicyUtility.UpdateReportedAcres(dispatch, field.Acres, PremiumLineOid, FnId, field )
        } else {
            updateField({ ...field, ReportedAcres: '', PlantedDate: undefined, SectionEquivalentId:  ViewField.Snapshot?.SectionEquivalentId}, false);
            PolicyUtility.UpdateReportedAcres(dispatch, '0', PremiumLineOid, FnId, field )
            clearErrors()
        }
    }

    const revertField = () => {
        updateField({ ...ViewField.Snapshot, Snapshot: { ...ViewField.Snapshot }, IsCluInformationSnapshotMatching: true, IsFieldDetailsSnapshotMatching: true })
        PolicyUtility.UpdateReportedAcres(dispatch, ViewField.Snapshot?.ReportedAcres, PremiumLineOid, FnId, ViewField.Snapshot)
        clearErrors()
    }

    return (
        <>
            <Row
                className={`${styles.border} ${Index % 2 ? 'alternatingBackground' : ''} hoverBackground`}
                data-testid={'view-field'}
            >

                <Col
                    xs={4}
                    sm={2}
                    lg={1}
                >
                    <Row
                        className={`h-100`}
                    >
                        <Col
                            className={`${!ViewField.IsCluInformationSnapshotMatching || !ViewField.IsFieldDetailsSnapshotMatching || !!ViewField.ViewId ? 'change-indicator' : ``}`}
                        ></Col>
                        <Col
                            xs={4}
                            className={'align-items-center d-flex'}
                        >
                            <Form.Check
                                data-testid={`select-field-button`}
                                checked={!!ViewField.IsChecked}
                                disabled={isDisable}
                                onChange={e => onChangeAssociateField((e.target).checked)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onChangeAssociateField(!ViewField.IsChecked)
                                    }
                                }}
                            />
                        </Col>
                        <Col
                            xs={4}
                            className={'align-items-center d-flex'}
                        >

                            <Badge
                                className={styles.clu}
                                pill bg={`${ViewField.IsRlu ? 'success' : 'primary'}`} >
                                {ViewField.IsRlu ? 'RLU' : 'CLU'}
                            </Badge>
                        </Col>
                    </Row>
                </Col>

                <Col
                    xs={4}
                    sm={2}
                    xxl={1}
                    className={`mt-1 p-1`}
                >
                    {getFieldIdDisplay()}
                </Col>
            
                <Col
                    xs={4}
                    sm={2}
                    xxl={1}
                    className={`mt-1 p-1`}
                >
                    <LandLabel
                        Label={"Sub Field #"}
                        Value={ViewField.SubFieldNumber}
                        Name={`sub-field-number-${Index}`}
                    />
                </Col>

                <Col
                    xs={12}
                    sm={7}
                    xxl={2}
                    className={`p-0`}
                >
                    <Row className={'p-0'}>
                        <Col
                            className={`mt-1 p-1`}
                            xs={4}
                        >
                            <LandLabel
                                Label={"HR"}
                                Value={ViewField.HighRiskCode}
                                Name={`HighRiskCode$_${FnId}_${ViewField.OID}`}
                                ToolTip={hrTooltip}
                            ></LandLabel>
                        </Col>

                        <Col
                            xs={4}
                            data-testid={`UnratedCode${ViewField.CluId}`}
                            className={`mt-1 p-1`}
                        >
                            <LandLabel
                                Label={"URA"}
                                Value={ViewField.UnratedCode}
                                Name={`URA_${FnId}_${ViewField.OID}`}
                                ToolTip={uraTooltip}
                            ></LandLabel>
                        </Col>

                        <Col
                            xs={4}
                            data-testid={`SubCountyCode${ViewField.CluId}`}
                            className={`mt-1 p-1`}
                        >
                            <LandLabel
                                Label={"Sub County"}
                                Value={ViewField.SubCountyCode}
                                Name={`SubCounty_${FnId}_${ViewField.OID}`}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col
                    xs={2}
                    xxl={1}
                    className={`mt-1 p-1`}
                >
                    <LandLabel
                        Label={"Field Acres"}
                        Value={formatWithComma(ViewField.Acres)}
                        Name={"FieldAcres"}
                    ></LandLabel>
                </Col>
            
                <Col
                    xs={3}
                    xxl={hasSectionEquivalence ? 1 : 2}
                    className={`mt-1 p-1`}
                >
                    <InputDecimal
                        floatLabel={true}
                        label={"Reported Acres"}
                        name={`ReportedAcres_${FnId}_${ViewField.OID}`}
                        disabled={isDisable}
                        value={ViewField.ReportedAcres ? ViewField.ReportedAcres : ''}
                        onUpdate={(e: BaseSyntheticEvent) => {
                            if (e.target.value !== ViewField.ReportedAcres) {
                                PolicyUtility.UpdateReportedAcres(dispatch, e.target.value, PremiumLineOid, FnId, ViewField )
                                updateField({ ...ViewField, ReportedAcres: e.target.value, IsChecked: true }, false)
                            }
                        }}
                        error={errors.ReportedAcres}
                    />
                </Col>

                <Col
                    xs={hasSectionEquivalence ? 5 : 4}
                    xxl={hasSectionEquivalence ? 4 : 3}
                    className={`p-0`}
                >
                    <Row className={'mt-1 p-1'}>
                        <Col xs={hasSectionEquivalence ? 5 : 10}>
                            <InputDatePicker
                                floatingLabel={true}
                                name={`PlantDate${ViewField.CluId}`}
                                label={'Plant Date'}
                                value={!!ViewField.PlantedDate && isValid(ViewField.PlantedDate) ? format(ViewField.PlantedDate, 'yyyy-MM-dd') : ''}
                                preventFutureDates={true}
                                disabled={isDisable}
                                error={errors.PlantDate}
                                onChange={onChangePlantDate}
                                useRef={dateRef}
                            />
                        </Col>
                        { hasSectionEquivalence &&
                            <Col
                                xs={5}
                                className={`g-0`}
                            >
                                <SectionEquivalentId
                                    updateField={updateField}
                                    viewField={ViewField}
                                    error={errors.SectionEquivalentId}
                                    setAssociatedFieldDefaults={setAssociatedFieldDefaults}
                                />
                            </Col>
                        }
                        <Col
                            xs={2}
                            className={`text-center d-flex align-items-center`}
                        >
                            <PreventedPlantDisplay
                                isPreventedPlant={IsPreventedPlant}
                                isPRF={IsPRF}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col
                    xs={hasSectionEquivalence ? 1 : 2}
                    xxl={1}
                    className='align-self-center'
                >
                    <Row className={`mt-1 p-1`}>
                        <Col
                            xs={6}
                            key={`${ViewField.CluId}_revertChanges`} className={`text-center g-0`} >

                            <FormButton
                                name={`revertOverlay_${FnId}_${ViewField.OID}`}
                                icon={!ViewField.IsCluInformationSnapshotMatching || !ViewField.IsFieldDetailsSnapshotMatching || !!ViewField.ViewId ? 'bi bi-arrow-counterclockwise' : 'bi bi-circle-half'}
                                variant={'outline-accent5'}
                                toolTip={"Revert Changes"}
                                disabled={isDisable}
                                onClick={revertField}
                            />
                        </Col>
                        <Col
                            xs={6}
                            key={`${ViewField.CluId}_editCLUField`} className={`text-center g-0`} >

                            <FormButton
                                name={`editOverlay_${FnId}_${ViewField.OID}`}
                                icon={'bi bi-pencil-square'}
                                variant={'outline-accent5'}
                                toolTip={getEditCLUToolTip()}
                                disabled={isDisable || ViewField.IsRlu || !ViewField.IsChecked}
                                onClick={fieldNumClickEffect}
                            />
                        </Col>
                    </Row>
                </Col>

                <Collapse in={showEditCLU}>
                    <span>
                        {showEditCLU && <EditField
                            FieldData={ViewField}
                            closeEditClu={() => setShowEditCLU(false)}
                            errors={errors}
                            updateField={updateField}
                        />
                        }
                    </span>
                </Collapse>
            </Row>

        </>
    );
}

export default ViewField;
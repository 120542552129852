import { Action, ActionCreator } from 'redux';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IFieldsStore } from './index';

const actionTypes = {
    GET_FIELDS_REQUEST: '@@field/GET_FIELDS_REQUEST',
    GET_FIELDS_REQUEST_SUCCESS: '@@field/GET_FIELDS_REQUEST_SUCCESS',
    GET_FIELDS_REQUEST_FAIL: '@@field/GET_FIELDS_REQUEST_FAIL'
}

export const ActionTypes = actionTypes;

export interface GetFieldsRequestAction extends Action {
    api: ApiGateway;
    reinsuranceYear: number,
    landOID: number,
    fieldsStore?: IFieldsStore;
};

export interface GetFieldsResponseAction extends Action {
    fieldsStore: IFieldsStore;
};

export const getFieldsSuccess: ActionCreator<GetFieldsResponseAction> = (fieldsStore: IFieldsStore) =>
({
    type: actionTypes.GET_FIELDS_REQUEST_SUCCESS,
    fieldsStore: fieldsStore
});

export const getFieldsFail: ActionCreator<GetFieldsResponseAction> = (fieldsStore: IFieldsStore) =>
({
    type: actionTypes.GET_FIELDS_REQUEST_FAIL,
    fieldsStore: fieldsStore
});
export type FieldsActions = GetFieldsRequestAction | GetFieldsResponseAction;
import React, { useEffect } from 'react';
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IShareholderSearch } from './viewmodels/IShareholderSearch';
import { ExpandCollapseState, ERRORS, URLs } from '../../../commom/constant';
import ApplicationIdValues from '../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import shareholdersEventBus from "./ShareholdersEventBus";
import styles from "./Shareholders.module.scss";
import { ErrorUtility } from '../../../manager/common/errorUtility';

interface IProps {
    shareholderSearch: IShareholderSearch;
    shareholderSearchHandler: (shareholderSearchHandler: IShareholderSearch, expandCollapseState : ExpandCollapseState) => void;
    growerId: string;
    mpciPolicyId: string;
    setIsLoading: (setIsLoading: boolean) => void;
    errorHandler: (error: string) => void;
    clearFilterHandler : () => void;
}

interface ISelect {
    Text: string;
    Value: string;
}
interface PremiumLines {
    BasicUnit: string;
    OptionalUnit: string;
    HasShareholder: boolean;
}
interface IMpciCoverageSearch {
    GetFullStructure: boolean;
    IncludePremiumlines: boolean;
    GetOnlyShareholderAllowed: boolean;
}
export interface IFilterShareholderSummary {
    CountyCode: string;
    CountyName: string;
    DisplayName: string;
    MpciCoverageOID?: number;
    CommodityCode: string;
    CommodityName: string;
    MpciPremiumLines: PremiumLines[];
}

function FilterShareholderSummary(props: IProps) {
    const [selectedCounty, setSelectedCounty] = React.useState<string>();
    const [county, setCounty] = React.useState<ISelect[]>([]);
    const [selectedCoverage, setSelectedCoverage] = React.useState<string>();
    const [coverage, setCoverage] = React.useState<ISelect[]>([]);
    const [selectedCrop, setSelectedCrop] = React.useState<string>();
    const [crop, setCrop] = React.useState<ISelect[]>([]);
    const [selectedBasicUnit, setSelectedBasicUnit] = React.useState<string>();
    const [basicUnit, setBasicUnit] = React.useState<string[]>([]);
    const [selectedOptionalUnit, setSelectedOptionalUnit] = React.useState<string>();
    const [optionalUnit, setOptionalUnit] = React.useState<string[]>([]);
    const [associatedOnly, setAssociatedOnly] = React.useState<boolean>(props.shareholderSearch.IsAssociated);
    const [ddlData, setDdlData] = React.useState<IFilterShareholderSummary[]>([]);
    const [filteredDdlData, setFilteredDdlData] = React.useState<IFilterShareholderSummary[]>([]);
    const mpciCoverageSearch: IMpciCoverageSearch = {
        GetFullStructure: false,
        IncludePremiumlines: true,
        GetOnlyShareholderAllowed: true
    }
    const api = useApiGateway();//Init API Gateway
    const getFilterShareholderSummaryData = async (growerId: string, mpciPolicyId: string, mpciCoverageSearch: IMpciCoverageSearch) => {
        const shareholderapiurl = `${URLs.paw_api_version}/Growers/${growerId}/MpciPolicies/${mpciPolicyId}/Coverages/AdvancedSearch`;
        const res = await api.post(shareholderapiurl, ApplicationIdValues.Paw, mpciCoverageSearch)
        return res;
    }
    function subscribeEventBus() {
        shareholdersEventBus.subscribeAssociationChanged(shareholderAssociationChangedHandler);
    }
    function unsubscribeEventBus() {
        shareholdersEventBus.unsubscribeAssociationChanged(shareholderAssociationChangedHandler);
    }
    function removeDuplicateSelectItems(selectItems: any) {
        return selectItems.filter((v: { [x: string]: any; }, i: any, a: any[]) => a.findIndex(v2 => ['Text', 'Value'].every(k => v2[k] === v[k])) === i);
    }

    function removeDuplicateStrings<T>(array: T[]) {
        return array.filter((value, index) => array.indexOf(value) === index);
    }

    const shareholderAssociationChangedHandler = () => refreshDdlData();

    const refreshDdlData = () => {
        props.setIsLoading(true);
        getFilterShareholderSummaryData(props.growerId, props.mpciPolicyId, mpciCoverageSearch)
            .then((data) => {
                props.setIsLoading(false);

                let ddlFilterData = data.map((val: any): IFilterShareholderSummary => ({
                    CountyCode: val.CountyCode,
                    CountyName: val.CountyName,
                    DisplayName: val.DisplayName,
                    MpciCoverageOID: val.OID,
                    CommodityCode: val.CommodityCode,
                    CommodityName: val.CommodityName,
                    MpciPremiumLines: val.mpciPremiumLines
                }));
                setDdlData(ddlFilterData);
            })
            .catch((err) => {
                props.setIsLoading(false);
                if (!ErrorUtility.handleApiGatewayError(err, props.errorHandler)) {
                    props.errorHandler(ERRORS.get_filterShareholderSummary_api_failed);
                }
                
            })
    }
    useEffect(() => {
        subscribeEventBus();
        refreshDdlData();
        return () => {
            unsubscribeEventBus();
        }
    }, []);

    useEffect(() => {
        setSelectedCounty(props.shareholderSearch.CountyCode!);
        setSelectedCoverage(String(props.shareholderSearch.MpciCoverageOID));
        setSelectedCrop(props.shareholderSearch.CommodityCode!);
        setSelectedBasicUnit(props.shareholderSearch.BasicUnit?.trimEnd() ?? '');
        setSelectedOptionalUnit(props.shareholderSearch.OptionalUnit!);
        setAssociatedOnly(props.shareholderSearch.IsAssociated);
    }, [props.shareholderSearch]);

    useEffect(() => {
        if (associatedOnly) {
            let datatemp: IFilterShareholderSummary[] = JSON.parse(JSON.stringify(ddlData));
            let datatempfiltered = datatemp.filter(d =>
                d.MpciPremiumLines.findIndex(p => p.HasShareholder == true) > -1
            );
            datatempfiltered.map(d => d.MpciPremiumLines = datatemp[datatemp.findIndex(ddl => d.MpciCoverageOID == ddl.MpciCoverageOID)].MpciPremiumLines.filter(p => p.HasShareholder == true));
            setFilteredDdlData(datatempfiltered);
        }
        else {
            setFilteredDdlData([...ddlData]);
        }
    }, [ddlData, associatedOnly]);

    useEffect(() => {
        let ddlCounty = filteredDdlData.map((val: any): ISelect => ({ Text: val.CountyName, Value: val.CountyCode }));
        setCounty(removeDuplicateSelectItems(ddlCounty));
        refreshCounty(selectedCounty!);
        refreshCoverage(String(selectedCoverage));
        refreshCrop(selectedCrop ?? '');
        refreshBasicUnit(selectedBasicUnit ?? '');
        setSelectedOptionalUnit(selectedOptionalUnit!);
    }, [filteredDdlData, selectedCoverage]);

    const resetDDLCoverage = () => {
        setCoverage([]);
        setSelectedCoverage('');
    };

    const resetDDLCrop = () => {
        setCrop([]);
        setSelectedCrop('');
    };

    const resetDDLBasicUnit = () => {
        setBasicUnit([]);
        setSelectedBasicUnit('');
    };

    const resetDDLOptionalUnit = () => {
        setOptionalUnit([]);
        setSelectedOptionalUnit('');
    };

    const refreshCounty = (countyCode: string) => {
        setSelectedCounty(countyCode);
        resetDDLCoverage();
        resetDDLCrop();
        resetDDLBasicUnit();
        resetDDLOptionalUnit();
        if (countyCode != 'Select ...' && countyCode != '') {
            let coverages = filteredDdlData.filter((val) => val.CountyCode === countyCode);
            let ddlCoverage = coverages.map((val: any): ISelect => ({ Text: val.DisplayName, Value: val.MpciCoverageOID }));
            setCoverage(ddlCoverage);
        }
       
    };

    const refreshCoverage = (coverageOid: string) => {

        setSelectedCoverage(coverageOid);
        resetDDLCrop();
        resetDDLBasicUnit();
        resetDDLOptionalUnit();
        if (coverageOid != 'Select ...' && coverageOid != '' && coverageOid != undefined) {
            let coverage = filteredDdlData.filter((val) => val.MpciCoverageOID === parseInt(coverageOid));
            let ddlCrop = coverage.map((val: any): ISelect => ({ Text: val.CommodityName, Value: val.CommodityCode }));
            setCrop(ddlCrop);
        }

    };

    const refreshCrop = (commodityCode: string) => {
        setSelectedCrop(commodityCode);
        if (commodityCode != 'Select ...' && commodityCode != '' && commodityCode != undefined) {
            if (filteredDdlData != undefined && selectedCoverage != "undefined" && selectedCoverage != undefined && selectedCoverage != "") {
                let ddlBasicUnit = filteredDdlData.filter((val) => val.MpciCoverageOID === parseInt(selectedCoverage ?? ""))[0] ? filteredDdlData.filter((val) => val.MpciCoverageOID === parseInt(selectedCoverage ?? ""))[0].MpciPremiumLines.map(bu => bu.BasicUnit).flat() : undefined;
                ddlBasicUnit != undefined ? setBasicUnit(removeDuplicateStrings(ddlBasicUnit!.sort())) : resetDDLBasicUnit();
            }
        }
        else {
            resetDDLBasicUnit();
            resetDDLOptionalUnit();
        }
    };

    const refreshBasicUnit = (basicUnit: string) => {
        setSelectedBasicUnit(basicUnit);
        resetDDLOptionalUnit();
        if (basicUnit != 'Select ...' && basicUnit != '') {
            if (filteredDdlData != undefined && selectedCoverage != "undefined" && selectedCoverage != undefined && selectedCoverage != "") {
                let optionalUnitsGroupByBasicUnit = filteredDdlData.filter((val) => val.MpciCoverageOID === parseInt(selectedCoverage ?? ""))[0] ? filteredDdlData.filter((val) => val.MpciCoverageOID === parseInt(selectedCoverage ?? ""))[0].MpciPremiumLines.reduce((BU: { [x: string]: any[]; }, { BasicUnit, OptionalUnit }: any) => {
                    if (!BU[BasicUnit]) BU[BasicUnit] = [];
                    BU[BasicUnit].push(OptionalUnit);
                    return BU;
                }, {}) : undefined;

                if (optionalUnitsGroupByBasicUnit != undefined) {
                    let optionalUnits = optionalUnitsGroupByBasicUnit[basicUnit];
                    if (optionalUnits != undefined) {
                        setOptionalUnit(removeDuplicateStrings(optionalUnits.sort()));
                    }
                }
            }
        }
    };

    const onClearFilterClick =(e: any)=>{
        e.preventDefault();
        
        props.clearFilterHandler();
        setSelectedCoverage('');
        setSelectedBasicUnit('');
        setSelectedCounty('');
        setSelectedCrop('');
        setSelectedOptionalUnit('');
        setAssociatedOnly(false);
        let dataToUpdate: IShareholderSearch = {
            CountyCode: "",
            MpciCoverageOID:  0,
            MpciPremiumLineOID: 0,
            CommodityCode: "",
            BasicUnit: "",
            OptionalUnit: "",
            IsAssociated: false
        };
        props.shareholderSearchHandler(dataToUpdate, ExpandCollapseState.CollapsAll);
    }

    function handleSubmit(event: any) {
        event.preventDefault();
        props.setIsLoading(true);
        let dataToUpdate: IShareholderSearch = {
            CountyCode: (selectedCounty == "Select ..." ? "" : selectedCounty) ?? "",
            MpciCoverageOID: isNaN(parseInt(selectedCoverage!)) ? 0 : parseInt(selectedCoverage!),
            MpciPremiumLineOID: 0,
            CommodityCode: (selectedCrop == "Select ..." ? "" : selectedCrop) ?? "",
            BasicUnit: (selectedBasicUnit == "Select ..." ? "" : selectedBasicUnit) ?? "",
            OptionalUnit: (selectedOptionalUnit == "Select ..." ? "" : selectedOptionalUnit) ?? "",
            IsAssociated: associatedOnly
        };

        if(dataToUpdate.CountyCode == "" && dataToUpdate.BasicUnit == "" && dataToUpdate.OptionalUnit == "" &&
            dataToUpdate.MpciCoverageOID == 0 && dataToUpdate.CommodityCode =="" &&  !associatedOnly ){
            props.shareholderSearchHandler(dataToUpdate, ExpandCollapseState.CollapsAll);
        }
        else{
            props.shareholderSearchHandler(dataToUpdate, ExpandCollapseState.ExpandAll);
        }

    };

    return (
        <Container fluid >
            <div className="m-1 square border border-1 border-dark">
                <h5 className="m-2">Filter / View </h5>
                <Form className="m-3" noValidate onSubmit={handleSubmit}>
                    <Row>
                        <Col md="3">
                            <div className="d-flex">
                                <p
                                    className={`pe-2`}
                                >
                                    Associated
                                </p>
                                <Form.Check data-testid="displayAllView" type="switch" id="isDisplayAll" className="switchInput" checked={!associatedOnly} onChange={(e) => {
                                    if (e.target.checked) {
                                        setAssociatedOnly(false);
                                    } else {
                                        setAssociatedOnly(true);
                                    }
                                }} />
                                <p>
                                    Display All
                                </p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="text-center">
                        <Col md="11" lg="11" sm="12">
                            <Row>
                                <Form.Group as={Col} md="2" controlId="County">
                                    <Form.Label  className="text-center">County</Form.Label>
                                    <Form.Select data-testid="ddlCounty" value={selectedCounty} onChange={(e) => { refreshCounty(e.target.value) }}>
                                        <option>Select ...</option>
                                        {county.map((option) => (
                                            <option key={option.Value} value={option.Value}>
                                                {option.Text}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="5" controlId="Coverage">
                                    <Form.Label className="text-center">Coverage</Form.Label>
                                    <Form.Select data-testid="ddlCoverage" value={selectedCoverage} onChange={(e) => { refreshCoverage(e.target.value) }}>
                                        <option>Select ...</option>
                                        {coverage.map((option) => (
                                            <option key={option.Value} value={option.Value}>
                                                {option.Text}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} md="2" controlId="Crop">
                                    <Form.Label className="text-center">Crop</Form.Label>
                                    <Form.Select data-testid="ddlCrop" value={selectedCrop} onChange={(e) => { refreshCrop(e.target.value) }}>
                                        <option>Select ...</option>
                                        {crop.map((option) => (
                                            <option key={option.Value} value={option.Value}>
                                                {option.Text}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="ps-0" controlId="BasicUnit">
                                    <Form.Label className="text-center">Basic Unit</Form.Label>
                                    <Form.Select data-testid="ddlBasicUnit" value={selectedBasicUnit} onChange={(e) => { refreshBasicUnit(e.target.value) }}>
                                        <option>Select ...</option>
                                        {basicUnit.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} className="ps-0" controlId="OptionalUnit">
                                    <Form.Label className={`text-center ${styles["minWidth-MaxContent"]}`}>Optional Unit</Form.Label>
                                    <Form.Select data-testid="ddlOptionalUnit" value={selectedOptionalUnit} onChange={(e) => { setSelectedOptionalUnit(e.target.value) }} >
                                        <option>Select ...</option>
                                        {optionalUnit.map(option => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>


                            </Row>
                        </Col>
                        <Col md="1" lg="1" sm="12" xs="12" className="d-flex align-items-end"> <Row>
                            <Col md="4" sm="6" xs="6" className="d-flex justify-content-center" >
                                <OverlayTrigger placement='bottom' overlay={<Tooltip>Search</Tooltip>}>
                                    <Button variant="default" type="submit" data-testid="btnSearch" > <i className="bi bi-search"></i></Button>
                                </OverlayTrigger>
                        </Col>
                            <Col md="8" sm="6" xs="6" className="d-flex" >
                                <OverlayTrigger placement='bottom' overlay={<Tooltip>Clear Filter</Tooltip>}>
                                    <Button variant="default" type="reset" data-testid="btnClearFilter" onClick={onClearFilterClick}><i className='bi bi-eraser'></i></Button>
                                </OverlayTrigger>
                        </Col>
                        </Row>
                        </Col>
                    </Row>

                </Form>
            </div>

        </Container>

    );
}

export default FilterShareholderSummary;
import { takeLatest, put, call } from 'redux-saga/effects';
import { ActionTypes, GetStatesRequestAction, getStatesSuccess, getStatesFail } from './actions';
import * as Api from './api';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';

export function* getStatesRequestSaga() {
    yield takeLatest(ActionTypes.GET_STATES_REQUEST, getStatesRequest);
};

function* getStatesRequest(action: GetStatesRequestAction) {
    yield call(getStates, action.api);
}

function* getStates(api: ApiGateway) {
    try {
        const states: any[] = yield call(Api.getStates, api);
        yield put(getStatesSuccess(states));
    }
    catch {
        yield put(getStatesFail());
    }
}
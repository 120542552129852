import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store';
import * as FeatureFlagsSaga from 'store/proAgFeatureFlags';
import { URLs } from '../../commom/constant';
import ApplicationIdValues from '../../components/apiGateway/ApplicationIdValues';
import { ApiGateway, useApiGateway } from '../../components/apiGateway/useApiGateway';


export class ProAgFeatureFlags {
    //Region - Feature Flag
    // DevOps - Feature 59207: SHAREHOLDER reporting/management
    static readonly Feature59207: string = 'FEATURE 59207';
    static readonly ShareholderUS91498: string = 'Shareholder US#91498';
    // Devops - Shareholder Fix INC377941: Delete Icon issue 
    static readonly ShareholderFixINC377941: string = 'Shareholder Fix INC377941';
    //Devops - Feature 91701: Shareholder - New - Remove Share % When Linked to a Target Policy
    static readonly Feature91701 = "FEATURE 91701";
    //EndRegion - Feature Flag

    //Feature 95188: READ ONLY access to non-UW users in the new UI.
    static readonly Feature95188 = "FEATURE 95188";



    //functions
    static getFeatureFlag(name: string, defaultValue: boolean) {
        const [result, setResult] = useState(defaultValue);
        const featureFlags = useSelector<GlobalState, FeatureFlagsSaga.FeatureFlagState>((featureFlags) => featureFlags.featureFlagState);
        const api = useApiGateway();
        const featureFlagId = Number(ProAgFeatureFlags.getFeatureFlagId(name, featureFlags));
        if (featureFlagId > 0) {
            const featureFlag = new Promise<any>((resolve, reject) => { resolve(ProAgFeatureFlags.getFeatureFlagFromAdminTool(featureFlagId, api)) });
            featureFlag.then((res) => { setResult(res.enabled); });
            featureFlag.catch((err) => { console.log(`Rejected:`, err) });
        }
        return result;
    };

    static getFeatureFlagId(name: string, featureFlags: any) {
        const featureFlagId = featureFlags.FeatureFlags.filter((x: { name: string; }) => x.name == name).map((y: { featureFlagId: any; }) => y.featureFlagId);
        return featureFlagId;
    };

    static getFeatureFlagFromAdminTool = async (featureFlagId: number, api: ApiGateway) => {
        return await api.get(`${URLs.paw_api_version}/applications/${URLs.paw_application_id}/featureflags/${featureFlagId}`, ApplicationIdValues.AdminTool);
    };
}


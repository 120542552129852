import { Action, ActionCreator } from 'redux';
import { ISaveFSN } from '.';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IViewLand } from 'views/mpci/lands/viewmodels/iViewLands';

export const ActionTypes = {
    SAVE_LANDS_REQUEST: '@@lands/SAVE_LANDS_REQUEST',
    SAVE_LANDS_REQUEST_SUCCESS: '@@lands/SAVE_LANDS_REQUEST_SUCCESS',
    SAVE_LANDS_REQUEST_FAIL: '@@lands/SAVE_LANDS_REQUEST_FAIL',
    SET_LANDS_REQUEST: '@@lands/SET_LANDS_REQUEST',
    SET_LANDS_HAS_EDIT: '@@lands/SET_LANDS_HAS_EDIT',
    SET_LANDS_SHOULD_SAVE_COVERAGE: '@@lands/SET_LANDS_SHOULD_SAVE_COVERAGE',
    RESET_LANDS_COVERAGE_REQUEST: '@@lands/RESET_LANDS_COVERAGE_REQUEST',
    SET_LANDS_COVERAGE_REQUEST: '@@lands/SET_LANDS_COVERAGE_REQUEST',
    SET_LANDS_REQUEST_SUCCESS: '@@lands/SET_LANDS_REQUEST_SUCCESS',
    PUT_LANDS_REQUEST: '@@lands/PUT_LANDS_REQUEST',
    PUT_LANDS_REQUEST_SUCCESS: '@@lands/PUT_LANDS_REQUEST_SUCCESS',
    DELETE_LANDS_REQUEST: '@@lands/DELETE_LANDS_REQUEST',
    DELETE_LANDS_REQUEST_SUCCESS: '@@lands/DELETE_LANDS_REQUEST_SUCCESS',
    DELETE_LANDS_REQUEST_FAIL: '@@lands/DELETE_LANDS_REQUEST_FAIL'
};

export interface SetLandsRequestAction extends Action {
    lands: IViewLand[];
};

export interface SetLandsHasEditAction extends Action {
    hasEdit: boolean;
}

export interface SetLandsShouldSaveCoverage extends Action {
    shouldSaveCoverage: boolean;
}

export interface SetLandsCoverageRequest extends Action {
    land: IViewLand;
}

export interface SaveLandsRequestAction extends Action {
    api: ApiGateway,
    growerId: number,
    mpciPolicyId: number,
    saveRequest: ISaveFSN;
};

export interface PutLandsRequestAction extends Action {
    mpciPremiumLineId: number;
    mpciPremiumLineLandId: number;
    lands: IViewLand[];
};

export interface DeleteLandsRequestAction extends Action {
    api: ApiGateway,
    growerId: number,
    mpciPolicyId: number,
    mpciCoverageId: number
    mpciPremiumLineId: number;
    mpciPremiumLineLandId: number;
    lands: IViewLand[];
};

export interface DeleteLandsResponseAction extends Action {
    lands: IViewLand[];
};

export interface PutLandsResponseAction extends Action {
    lands: IViewLand[];
};

export interface SaveLandsSuccessAction extends Action {
    lands: IViewLand[];
};

export interface SaveLandsFailAction extends Action {
    errorCode: string;
};

export interface DeleteLandsFailAction extends Action {
    errorCode: string;
};

export const setLandsSuccess: ActionCreator<SetLandsRequestAction> = (lands: IViewLand[]) =>
({
    type: ActionTypes.SET_LANDS_REQUEST,
    lands: lands
});

export const saveLandsSuccess: ActionCreator<SaveLandsSuccessAction> = (lands: IViewLand[]) =>
({
    type: ActionTypes.SAVE_LANDS_REQUEST_SUCCESS,
    lands: lands
});

export const saveLandsFail: ActionCreator<SaveLandsFailAction> = (errorCode: string) =>
({
    type: ActionTypes.SAVE_LANDS_REQUEST_FAIL,
    errorCode: errorCode
});

export const putLandsSuccess: ActionCreator<PutLandsResponseAction> = (lands: IViewLand[]) =>
({
    type: ActionTypes.PUT_LANDS_REQUEST_SUCCESS,
    lands: lands
});

export const deleteLandsSuccess: ActionCreator<DeleteLandsResponseAction> = (lands: IViewLand[]) =>
({
    type: ActionTypes.DELETE_LANDS_REQUEST_SUCCESS,
    lands: lands
});

export const deleteLandsFail: ActionCreator<DeleteLandsFailAction> = (errorCode: string) =>
({
    type: ActionTypes.DELETE_LANDS_REQUEST_FAIL,
    errorCode: errorCode
});

export const setLandsHasEdit: ActionCreator<SetLandsHasEditAction> = (hasEdit: boolean) =>
({
    type: ActionTypes.SET_LANDS_HAS_EDIT,
    hasEdit: hasEdit
});

export const setLandsShouldSaveCoverage: ActionCreator<SetLandsShouldSaveCoverage> = (shouldSave: boolean) => 
({
    type: ActionTypes.SET_LANDS_SHOULD_SAVE_COVERAGE,
    shouldSaveCoverage: shouldSave
});

export const setLandsCoverageRequest: ActionCreator<SetLandsCoverageRequest> = (land: IViewLand) => 
({
    type: ActionTypes.SET_LANDS_COVERAGE_REQUEST,
    land: land
});

export const resetLandsCoverageRequest: ActionCreator<void> = () => ({
    type: ActionTypes.RESET_LANDS_COVERAGE_REQUEST
})

export type LandsActions = 
    SetLandsRequestAction |
    PutLandsRequestAction |
    SaveLandsRequestAction |
    SaveLandsFailAction |
    PutLandsResponseAction |
    SetLandsHasEditAction |
    SetLandsShouldSaveCoverage |
    SetLandsCoverageRequest |
    DeleteLandsRequestAction |
    DeleteLandsResponseAction |
    DeleteLandsFailAction;

import { Reducer } from 'redux';
import * as FeatureFlagSaga from './';
import { IFeatureFlag } from '.';
import { ActionTypes, FeatureFlagActions, GetFeatureFlagResponseAction } from './actions';

const initialState: FeatureFlagSaga.FeatureFlagState = { FeatureFlags: [] as IFeatureFlag[], Loading: undefined, HasError: undefined };

const reducer: Reducer<FeatureFlagSaga.FeatureFlagState, FeatureFlagActions> =
    (featureFlags: FeatureFlagSaga.FeatureFlagState = initialState, action: FeatureFlagActions): FeatureFlagSaga.FeatureFlagState => {
        switch (action.type) {
            case ActionTypes.GET_FEATUREFLAGS_REQUEST:
                return { ...featureFlags, Loading: true };

            case ActionTypes.GET_FEATUREFLAGS_REQUEST_SUCCESS:
                return { FeatureFlags: (action as GetFeatureFlagResponseAction).featureFlags, Loading: false, HasError: false };

            case ActionTypes.GET_FEATUREFLAGS_REQUEST_FAIL:
                return { FeatureFlags: (action as GetFeatureFlagResponseAction).featureFlags, Loading: false, HasError: true };
        }

        return featureFlags;
    };

export default reducer;
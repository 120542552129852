import { useEffect, useState } from "react";
import { IViewField } from "../viewmodels/iViewField";
import { IViewFieldErrors } from "../viewmodels/iViewFieldErrors";
import { FieldUtility } from "manager/lands/fieldUtility";
import { isValid } from "date-fns";
import { useApiGateway } from "components/apiGateway/useApiGateway";

interface UseFieldValidation {
    errors: IViewFieldErrors;
    triggerValidation: (field: IViewField) => Promise<IViewFieldErrors>;
    clearErrors: () => void;
}

export interface UseFieldValidationProps {
    Field?: IViewField;
    ReinsuranceYear?: string;
    IsPreventedPlant?: boolean;
    IsPlantDateRequired?: boolean, 
    HasSectionEquivalentId?: boolean,
    IsDisabled?: boolean
}
export function useFieldValidation(props: UseFieldValidationProps) : UseFieldValidation {
    const [errors, setErrors] = useState<IViewFieldErrors>({})
    const api = useApiGateway();


    useEffect(() => {
        if (!!props.Field) {
            validateField(props.Field);
        }
    }, [
            props.Field?.FieldNumber,
            props.Field?.SubFieldNumber,
            props.Field?.Description,
            props.Field?.ReportedAcres,
            props.Field?.PlantedDate,
            props.Field?.RluId,
            props.Field?.IsChecked,
            props.Field?.SectionEquivalentId
        ])

    const validateField = async (fieldToValidate: IViewField): Promise<IViewFieldErrors> => {
        const nextErrors: IViewFieldErrors = {}
        if(!props.IsDisabled){
            if (!fieldToValidate.IsRlu) {
                validateFieldNumber(fieldToValidate, nextErrors);
                validateSubFieldNumber(fieldToValidate, nextErrors);
                if (props.IsPlantDateRequired && !props.IsPreventedPlant && FieldUtility.isNumberPopulatedAndGreaterThan0(fieldToValidate.ReportedAcres) 
                    && !isValid(fieldToValidate.PlantedDate)) {
                    nextErrors.PlantDate = 'Plant Date Required'
                }
                if (isValid(fieldToValidate.PlantedDate) && !FieldUtility.isNumberPopulatedAndGreaterThan0(fieldToValidate.ReportedAcres)) {
                    nextErrors.ReportedAcres = 'Reported Acres Required'
                }
            }
            //RLU
            else {
                if (!fieldToValidate.RluId) {
                    nextErrors.RluId = 'RLU Id is required'
                }
                if (fieldToValidate.RluId && !FieldUtility.ValidRluId(fieldToValidate.RluId)) {
                    nextErrors.RluId = 'Must be format R123...'
                }
            }
            //both
            if (isValid(fieldToValidate.PlantedDate) && !!fieldToValidate?.PlantedDate 
                    && fieldToValidate.PlantedDate > new Date()) {
                nextErrors.PlantDate = 'Must be less than today'
            }
            //Sec Eq ID 
            if ((fieldToValidate.IsChecked || fieldToValidate.IsChecked === undefined) && props.HasSectionEquivalentId && !fieldToValidate.SectionEquivalentId) {
                nextErrors.SectionEquivalentId = 'Sec Eq ID is required'
            }
            if ((fieldToValidate.IsChecked || fieldToValidate.IsChecked === undefined) && props.HasSectionEquivalentId && fieldToValidate.SectionEquivalentId) {                
                const doesMatch = FieldUtility.IsSectionEquivalentFormatValid(fieldToValidate?.SectionEquivalentId)
                if (doesMatch) {
                    var isSectionEquivalentValid = await FieldUtility.ValidateSectionEquivalent(fieldToValidate?.SectionEquivalentId, props.ReinsuranceYear??'', api)
                    if (!isSectionEquivalentValid){
                        nextErrors.SectionEquivalentId = 'Sec Eq ID is invalid'
                    }
                } else {
                    nextErrors.SectionEquivalentId = 'Sec Eq ID is invalid'
                }
            }
                
        }
        setErrors(nextErrors)
        return nextErrors
    }

    const clearErrors = (): void => {
        setErrors({})
    }

    const validateFieldNumber = (field: IViewField, nextErrors: IViewFieldErrors): void => {
        if (!field.FieldNumber) {
            nextErrors.FieldNumber = 'Field Number Required'
        }
        else if (!FieldUtility.NumberOnly.test(field.FieldNumber)) {
            nextErrors.FieldNumber = 'Numeric Only'
        }
    }

    const validateSubFieldNumber = (field: IViewField, nextErrors: IViewFieldErrors): void => {
        if (field.SubFieldNumber) {
            if (!FieldUtility.NumberOnly.test(field.SubFieldNumber)) {
                nextErrors.SubFieldNumber = 'Numeric Only'
            }
        }
    }

    return {
        errors: errors,
        triggerValidation: validateField,
        clearErrors: clearErrors
    }
}
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ApplicationIdValues from '../apiGateway/ApplicationIdValues';
import { useApiGateway } from '../apiGateway/useApiGateway';
import styles from './Footer.module.scss';

interface Response {
    CoBrowsingEnable: boolean;
    CoBrowsingText: string;
    CopyrightCalendarYear: string;
    CopyrightCompanyName: string;
    Link1Route: string;
    Link1Target: string;
    Link1Text: string;
    Link2Route: string;
    Link2Target: string;
    Link2Text: string;
    Link3Route: string;
    Link3Target: string;
    Link3Text: string;
    Link4Route: string;
    Link4Target: string;
    Link4Text: string;
    VersionInformation: string;
    CopyrightMessage: string;
}

const Footer = () => {
    const defaultResponse = {} as Response
    const [response, setResponse] = useState(defaultResponse);
    const paw_url = (window as any).globalConfig.paw_url;
    const footer_url = (window as any).globalConfig.paw_footer_endpoint;
    const api = useApiGateway();

    useEffect(() => {
        fetchfooter();
    }, []);

    const fetchfooter = async () =>
    {
        await api.get(footer_url, ApplicationIdValues.Paw).then((data) => {
            storeResponse(data);  
        }).catch((err) => {
            handleError(err);
        });                 
    }

    function storeResponse(data: any) {
        var response: Response = data;
        updateResponseLinks(response);
        setResponse(response);
    }

    function updateResponseLinks(response: Response) {
        response.Link1Route = paw_url + response.Link1Route;
        response.Link2Route = paw_url + response.Link2Route;
        response.Link3Route = paw_url + response.Link3Route;
        response.Link4Route = paw_url + response.Link4Route;
        response.CopyrightMessage = "Copyright @" + response.CopyrightCalendarYear + ", " + response.CopyrightCompanyName;
    }

    function handleError(error: any)
    {
        console.log("Error Reaching PAW!");
        console.log(error);  
    }

    return (
        <footer className={`${styles.footer}`}>
            <Row>
                <Col md="2" xs="12">
                    <Row>
                        <a className={`${styles.footerLink}`} href={response.Link1Route}>{response.Link1Text}</a>
                    </Row>
                    <Row>
                        <a className={`${styles.footerLink}`} href={response.Link3Route}>{response.Link3Text}</a>
                    </Row>
                </Col>
                <Col md="2" xs="12">
                    <Row>
                        <a className={`${styles.footerLink}`} href={response.Link2Route}>{response.Link2Text}</a>
                    </Row>
                    <Row>
                        <a className={`${styles.footerLink}`} href={response.Link4Route}>{response.Link4Text}</a>
                    </Row>
                </Col>
                <Col className={`${styles.footerText}`} md="4" xs="12">
                    {response.CopyrightMessage}
                </Col>
                <Col className={`${styles.footerText}`} md="4" xs="12">
                    <div dangerouslySetInnerHTML={{ __html: response.VersionInformation }} />
                </Col>
            </Row> 
        </footer>
    );
}
export default Footer;  
import React, { useRef, BaseSyntheticEvent } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { IInputDecimalProps } from './iInputDecimalProps';
import styles from './inputDecimal.module.scss';
import { formatWithComma } from 'components/utils/formatting/FormatUtils';

export const InputDecimal: React.FC<IInputDecimalProps> = ({ name, disabled, defaultValue, floatLabel, label, onUpdate, error, required, pattern, value }: IInputDecimalProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    
    function onblur(e: BaseSyntheticEvent) {
        e.target.value = formatWithComma(e.target.value);
        if (onUpdate) {
            onUpdate(e)
        }
    }

    function formatValueOnChange(e: BaseSyntheticEvent): void {
 
        if (!!onUpdate) {
            onUpdate(e);
        }
        let val = e.target.value;
        val = val.replace(/[^0-9\\.]+/g, "");
        const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
        if (match) {
            const value = match[1] + match[2];
            e.target.value = value;
        }
        if (onUpdate) {
            onUpdate(e)
        }
    }
    
    function getComponent() {
        if (floatLabel) {
            return (
                <Form.Group>
                    <FloatingLabel label={label}>
                        <Form.Control
                            data-testid={name}
                            id={name}
                            type={"input"}
                            onChange={formatValueOnChange}
                            onBlur={onblur}
                            disabled={disabled}
                            defaultValue={defaultValue}
                            name={name}
                            required={required}
                            pattern={pattern}
                            ref={inputRef}
                            value={value}
                            isInvalid={!!error}
                        />
                        <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                    </FloatingLabel>
                </Form.Group>
            )
        }
        else {
            return (
                <>
                    <Form.Control
                        data-testid={name}
                        id={name}
                        type={"input"}
                        onChange={formatValueOnChange}
                        onBlur={onblur}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        name={name}
                        required={required}
                        pattern={pattern}
                        ref={inputRef}
                        value={value}
                        isInvalid={!!error}
                    />
                    <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                </>
            )
        }
    }

    return (
        getComponent()
    );
};

export default InputDecimal;
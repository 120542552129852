import { OktaAuth } from '@okta/okta-auth-js';
import { config } from '../../components/okta/config';
import { useOktaAuth } from '@okta/okta-react';

//Render logout Component
export default () => {
    const oktaAuth = new OktaAuth(config.oidc);
    /*const { oktaAuth } = useOktaAuth();*/
        
    oktaAuth.tokenManager.clear();
    oktaAuth.signOut();

    return (<></>);
}

//export default Logout;
import { ActionCreator } from 'redux';
import { ISaveFSN } from '.';
import { ActionTypes, DeleteLandsRequestAction, PutLandsRequestAction, SaveLandsRequestAction, SetLandsRequestAction } from './actions';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IViewLand } from 'views/mpci/lands/viewmodels/iViewLands';

export const setLands: ActionCreator<SetLandsRequestAction> = (lands: IViewLand[]) =>
    ({ type: ActionTypes.SET_LANDS_REQUEST_SUCCESS, lands: lands });

export const saveLands: ActionCreator<SaveLandsRequestAction> = (api: ApiGateway, growerId: number, policyOid: number, request: ISaveFSN) =>
({
    type: ActionTypes.SAVE_LANDS_REQUEST,
    api: api,
    growerId: growerId,
    mpciPolicyId: policyOid,
    saveRequest: request
});

export const putLands: ActionCreator<PutLandsRequestAction> = (mpciPremiumLineId: number, mpciPremiumLineLandId: number, lands: IViewLand[]) =>
    ({ type: ActionTypes.PUT_LANDS_REQUEST, mpciPremiumLineId: mpciPremiumLineId, mpciPremiumLineLandId: mpciPremiumLineLandId, lands: lands });

export const deleteLands: ActionCreator<DeleteLandsRequestAction> = (api: ApiGateway, growerId: number, mpciPolicyId: number, mpciCoverageId: number,
    mpciPremiumLineId: number, mpciPremiumLineLandId: number, lands: IViewLand[]) =>
({
    type: ActionTypes.DELETE_LANDS_REQUEST,
    api: api, 
    growerId: growerId,
    mpciPolicyId: mpciPolicyId,
    mpciCoverageId: mpciCoverageId,
    mpciPremiumLineId: mpciPremiumLineId,
    mpciPremiumLineLandId: mpciPremiumLineLandId,
    lands: lands
});
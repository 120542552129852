import { Action, ActionCreator } from 'redux';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { IPointOfReference } from './index';

const actionTypes = {
    GET_POR_REQUEST: '@@pointOfReference/GET_POR_REQUEST',
    GET_POR_REQUEST_SUCCESS: '@@pointOfReference/GET_POR_REQUEST_SUCCESS',
    GET_POR_REQUEST_FAIL: '@@pointOfReference/GET_POR_REQUEST_FAIL',
    DELETE_POR_REQUEST: '@@pointOfReference/DELETE_POR_REQUEST',
    DELETE_POR_REQUEST_SUCCESS: '@@pointOfReference/DELETE_POR_REQUEST_SUCCESS',
    AUTOMATE_POR_REQUEST: '@@pointOfReference/AUTOMATE_POR_REQUEST',
    AUTOMATE_POR_REQUEST_SUCCESS: '@@pointOfReference/AUTOMATE_POR_REQUEST_SUCCESS',
    AUTOMATE_POR_REQUEST_FAIL: '@@pointOfReference/AUTOMATE_POR_REQUEST_FAIL',
    DELETE_POR_REQUEST_FAIL: '@@pointOfReference/DELETE_POR_REQUEST_FAIL',
    SAVE_POR_REQUEST: '@@pointOfReference/SAVE_POR_REQUEST',
    SAVE_POR_REQUEST_SUCCESS: '@@pointOfReference/SAVE_POR_REQUEST_SUCCESS',
    SAVE_POR_REQUEST_FAIL: '@@pointOfReference/SAVE_POR_REQUEST_FAIL',
    RESET_POR: '@@pointOfReference/RESET_POR',
}

export const ActionTypes = actionTypes;

export interface GetPORRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    PORRequest: IPointOfReference;
};

export interface DeletePORRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    CluId: string;
    PORRequest: IPointOfReference;
};

export interface AutomatePORRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    PORRequest: IPointOfReference;
};

export interface SavePORRequestAction extends Action {
    Api: ApiGateway;
    GrowerId: string;
    MpciPolicyId: string;
    MpciCoverageId: string;
    MpciPremiumLineId: number;
    PointOfReference: IPointOfReference;
    PORRequest: IPointOfReference;
};

export interface GetPORResponseAction extends Action {
    PORRequest: IPointOfReference;
};

export interface DeletePORResponseAction extends Action {
    PORRequest: IPointOfReference;
};

export interface AutomatePORResponseAction extends Action {
    PORRequest: IPointOfReference;
};

export interface SavePORResponseAction extends Action {
    PORRequest: IPointOfReference;
};

export interface ResetPORResponseAction extends Action {
    PORRequest: IPointOfReference;
};

export const getPORSuccess: ActionCreator<GetPORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.GET_POR_REQUEST_SUCCESS,
    PORRequest: pointOfReference
});

export const getPORFail: ActionCreator<GetPORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.GET_POR_REQUEST_FAIL,
    PORRequest: pointOfReference
});

export const automatePORSuccess: ActionCreator<AutomatePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.AUTOMATE_POR_REQUEST_SUCCESS,
    PORRequest: pointOfReference
});

export const automatePORFail: ActionCreator<AutomatePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.AUTOMATE_POR_REQUEST_FAIL,
    PORRequest: pointOfReference
});

export const deletePORSuccess: ActionCreator<DeletePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.DELETE_POR_REQUEST_SUCCESS,
    PORRequest: pointOfReference
});

export const deletePORFail: ActionCreator<DeletePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.DELETE_POR_REQUEST_FAIL,
    PORRequest: pointOfReference
});

export const savePORSuccess: ActionCreator<SavePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.SAVE_POR_REQUEST_SUCCESS,
    PORRequest: pointOfReference
});

export const savePORFail: ActionCreator<SavePORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.SAVE_POR_REQUEST_FAIL,
    PORRequest: pointOfReference
});

export const resetPOR: ActionCreator<ResetPORResponseAction> = (pointOfReference: IPointOfReference) =>
({
    type: actionTypes.RESET_POR,
    PORRequest: pointOfReference
});

export type PORActions = GetPORRequestAction | GetPORResponseAction | DeletePORRequestAction | DeletePORResponseAction | AutomatePORRequestAction | AutomatePORResponseAction | SavePORRequestAction | SavePORResponseAction | ResetPORResponseAction;
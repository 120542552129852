import { Reducer } from 'redux';
import { ERRORS, LANDSCONSTANTS } from '../../commom/constant';
import * as PORSaga from './';
import { ActionTypes, PORActions } from './actions';

const initialState: PORSaga.PORState = {
    PORRequest: []
};

const reducer: Reducer<PORSaga.PORState, PORActions> =
    (state: PORSaga.PORState = initialState, action: PORActions): PORSaga.PORState => {
        switch (action.type) {
            case ActionTypes.GET_POR_REQUEST:
                if (action.PORRequest != undefined) {
                    action.PORRequest.IsLoading = true
                    if (state.PORRequest.findIndex(Field => Field.GroupId == action.PORRequest?.GroupId) == -1) {
                        state.PORRequest.push(action.PORRequest);
                    }
                    else {
                        state.PORRequest.splice(state.PORRequest.findIndex(item =>
                            item.GroupId == action.PORRequest?.GroupId
                        ), 1)
                        state.PORRequest.push(action.PORRequest);
                    }
                }
                return { ...state };

            case ActionTypes.GET_POR_REQUEST_SUCCESS:
                let nextSuccessState = [ ...state.PORRequest ] as PORSaga.IPointOfReference[]
                if (action.PORRequest != undefined) {
                    nextSuccessState = nextSuccessState.map((request) => {
                        if (request.GroupId === action.PORRequest.GroupId) {
                            request = { ...action.PORRequest, IsLoading: false }
                        }
                        return request;
                    })
                }
                return { PORRequest: nextSuccessState }

            case ActionTypes.GET_POR_REQUEST_FAIL:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1)

                    action.PORRequest.HasError = true
                    action.PORRequest.IsLoading = false
                    action.PORRequest.ErrorMsgForPOR = ERRORS.landId_get_por_api_failed
                    state.PORRequest.push(action.PORRequest)
                }
                return { ...state };

            case ActionTypes.DELETE_POR_REQUEST:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1);

                    action.PORRequest.IsLoading = true;
                    state.PORRequest.push(action.PORRequest);
                }
                return { ...state };

            case ActionTypes.DELETE_POR_REQUEST_SUCCESS:
                let nextDeleteState = [ ...state.PORRequest ]
                if (action.PORRequest != undefined) {
                    nextDeleteState = nextDeleteState.map((request) => {
                        if (request.GroupId === action.PORRequest.GroupId) {
                            request = { ...action.PORRequest, IsLoading: false, HasError: false }
                        }
                        return request;
                    })
                }
                return { PORRequest: nextDeleteState };

            case ActionTypes.DELETE_POR_REQUEST_FAIL:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1);

                    action.PORRequest.HasError = true;
                    action.PORRequest.IsLoading = false;
                    action.PORRequest.ErrorMsgForPOR = ERRORS.landId_delete_por_api_failed;
                    state.PORRequest.push(action.PORRequest);
                }
                return { ...state };

            case ActionTypes.AUTOMATE_POR_REQUEST:
                if (action.PORRequest != undefined) {
                    action.PORRequest.IsLoading = true;
                    if (state.PORRequest.findIndex(item => item.GroupId == action.PORRequest?.GroupId) == -1) {
                        state.PORRequest.push(action.PORRequest);
                    }
                    else {
                        state.PORRequest.splice(state.PORRequest.findIndex(item =>
                            item.GroupId == action.PORRequest?.GroupId
                        ), 1)
                        state.PORRequest.push(action.PORRequest);
                    }
                }
                return { ...state };

            case ActionTypes.AUTOMATE_POR_REQUEST_SUCCESS:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1);

                    action.PORRequest.IsLoading = false;
                    action.PORRequest.HasError = false;
                    state.PORRequest.push(action.PORRequest);
                }
                return { ...state }

            case ActionTypes.AUTOMATE_POR_REQUEST_FAIL:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1)

                    action.PORRequest.HasError = true;
                    action.PORRequest.IsLoading = false;
                    action.PORRequest.ErrorMsgForPOR = ERRORS.landId_automate_por_api_failed;
                    state.PORRequest.push(action.PORRequest)
                }
                return { ...state };

            case ActionTypes.SAVE_POR_REQUEST:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1);

                    action.PORRequest.IsLoading = true;
                    state.PORRequest.push(action.PORRequest);
                }
                return { ...state };

            case ActionTypes.SAVE_POR_REQUEST_SUCCESS:
                let nextSaveState = [ ...state.PORRequest ]
                if (action.PORRequest != undefined) {
                    nextSaveState = nextSaveState.map((request) => {
                        if (request.GroupId === action.PORRequest.GroupId) {
                            request = { ...action.PORRequest, IsLoading: false, HasError: false }
                        }
                        return request;
                    })
                }
                return { PORRequest: nextSaveState };

            case ActionTypes.SAVE_POR_REQUEST_FAIL:
                if (action.PORRequest != undefined) {
                    state.PORRequest.splice(state.PORRequest.findIndex(item =>
                        item.GroupId == action.PORRequest?.GroupId
                    ), 1)

                    action.PORRequest.HasError = true;
                    action.PORRequest.IsLoading = false;
                    action.PORRequest.ErrorMsgForPOR = ERRORS.landId_save_por_api_failed;
                    state.PORRequest.push(action.PORRequest)
                }
                return { ...state };

            case ActionTypes.RESET_POR:
                return { ...state, PORRequest:[] };
        }
        return state;
    };
export default reducer;
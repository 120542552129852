import { Reducer } from 'redux';
import * as LoadSpinnerSaga from './';
import { ActionTypes, EnableSpinnerAction, LoadSpinnerActions } from './actions';
import { remove } from 'lodash';

const intialState: LoadSpinnerSaga.LoadSpinnerState = { LoadingKeys: [] }

const reducer: Reducer<LoadSpinnerSaga.LoadSpinnerState, LoadSpinnerActions> =
    (state: LoadSpinnerSaga.LoadSpinnerState = intialState, action: LoadSpinnerActions): LoadSpinnerSaga.LoadSpinnerState => {
        switch (action.type) {
            case ActionTypes.ENABLE_SPINNER:
                const enableSpinnerAction = action as EnableSpinnerAction
                let enableState = { ...state }
                if (!enableState.LoadingKeys.includes(enableSpinnerAction.key)) {
                    enableState.LoadingKeys.push(enableSpinnerAction.key)
                }
                return { ...enableState }
            case ActionTypes.DISABLE_SPINNER:
                const disableSpinnerAction = action as EnableSpinnerAction
                let disableState = { ...state }
                if (disableState.LoadingKeys.includes(disableSpinnerAction.key)) {
                    disableState.LoadingKeys = remove(disableState.LoadingKeys, disableSpinnerAction.key)
                }
                return { ...disableState }
        }
        return state;
    };

export default reducer;
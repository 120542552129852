
export class API {
    static readonly growerAPI = "data/mock/productionReportingHeader.json";
    static readonly shareholderHeaderAPI = "data/mock/mpciPolicy.json";
}

export class TABLEEXPAND {
    static readonly collapseAction = 'collapse';
    static readonly expandAction = 'expand';
}

export class TABLESELECTION {
    static readonly selectAction = 'select';
    static readonly unSelectAction = 'unselect';
}

export class URLs {
    static readonly paw_api_url = (window as any).globalConfig?.paw_url + (window as any).globalConfig?.paw_api_path;
    static readonly paw_api_version = (window as any).globalConfig?.paw_api_version;
    static readonly paw_application_id = (window as any).globalConfig?.PAWApplicationId;
}

export class ERRORS {
    static readonly get_shareholders_api_failed: string = 'Cannot Load Policy Information from ProAgWorks';
    static readonly update_shareholders_api_failed: string = 'Failed to Update Shareholder Information to ProAgWorks';
    static readonly delete_shareholders_api_failed: string = 'Failed to Delete Shareholder from ProAgWorks';
    static readonly add_shareholders_api_failed: string = 'Failed to Add Shareholder Information to ProAgWorks';
    static readonly save_shareholderCoverage_api_failed: string = 'Failed to Save Shareholder Coverage Information to ProAgWorks';
    static readonly get_companion_policies_api_failed = 'Cannot load companion policy information from ProAgWorks';
    static readonly get_companion_policy_units_api_failed = 'Cannot load companion policy units from ProAgWorks';
    static readonly update_companion_policy_api_failed = 'Cannot update companion policy to ProAgWorks';
    static readonly get_transferOfRights_api_failed: string = 'Cannot Load Transfer Of Rights Information from ProAgWorks';
    static readonly update_transferOfRights_api_failed: string = 'Failed to Update Transfer Of Rights Information to ProAgWorks';
    static readonly failed_to_fetch_policy_header: string = 'Failed to fetch Policy header.';
    static readonly generic_api_failed = 'Please try again. If error persists, please contact helpdesk';
    static readonly paw_maintance_error = 'The site is currently down for scheduled maintenance.';
    static readonly get_filterShareholderSummary_api_failed = 'Cannot load shareholder summary filter from ProAgWorks';
    static readonly get_coverages_api_failed: string = 'Cannot Load shareholder coverages from ProAgWorks';
    static readonly session_timeout__api_failed: string = 'Session Timed Out. Process Not Complete.  Please Close Shareholders and Relaunch from ProAgWorks.';
    static readonly landId_get_fields_api_failed: string = 'Unable to retrieve Field Details. To try again, please close fields and reopen or contact the Help Desk.';
    static readonly landId_get_companionLinks_api_failed: string = 'Unable to retrieve Companion linkage Details. To try again, please close companion linkage and reopen or contact the Help Desk.';
    static readonly landId_get_str_api_failed: string = 'Failed to load Section/Township/Range Data. Try again or enter manually';
    static readonly landId_fields_readonly: string = 'FN/Tract data fields are read-only when Field Details are displayed.';
    static readonly landId_create_lands_api_failed: string = 'Land Identifier(s) cannot be saved.  Please try again or contact the Help Desk.';
    static readonly landId_delete_companionLinks_api_failed: string = 'Unable to Unlink. Please try again and contact the Helpdesk when it reoccurs.';
    static readonly disable_for_target_policy_unit: string = 'Linked to a Source Policy; Adding FN / Tract is Not Allowed; Unselect to Proceed';
    static readonly landId_get_por_api_failed: string = 'Unable to retrieve point of reference Details. Please try again or contact the Help Desk';
    static readonly landId_delete_por_api_failed: string = 'Unable to delete point of reference Details. Please try again or contact the Help Desk';
    static readonly landId_automate_por_api_failed: string = 'Unable to automate point of reference Details. Please try again or contact the Help Desk';
    static readonly landId_save_por_api_failed: string = 'Unable to save point of reference Details. Please try again or contact the Help Desk';
    static readonly landId_automated_por_multiple_grid_error: string = 'Fields are on multiple grids, POR cannot be automated.  Please add the following POR manually.';
}

export class WARNINGS {
    static readonly delete_lands: string = 'Are you sure you want to delete?';
    static readonly delete_shareholders: string = 'Are you sure you want to delete this Shareholder from this Policy and any Premium Lines?';
    static readonly remove_associated_premiumlines: string = 'Are you sure you want to remove the Associated premium lines for this shareholder?';
    static readonly no_premiumlines_selected_for_association: string = 'No premium lines selected for association.';
    static readonly no_premiumlines_selected_to_remove: string = 'No premium lines selected to remove.';
    static readonly update_share_percentage_associated_premiumlines: string = 'Do you want to Update the Share Percentage of Associated Premium Lines?';
    static readonly unlink_companion_link: string = 'You have chosen to break a companion link for this unit.Do you wish to continue?';
    static readonly no_result_found_shareholder: string = 'No results found. Revise criteria or Display All.';
    static readonly companion_link_exist_premiumlines_selected_to_remove: string = 'Remove is not allowed. Please unlink from the source detailed line first then try Remove again.';
    static readonly remove_associated_linked_premiumlines: string = 'Are you sure you want to remove the associated premium lines for this shareholder and unlink the companion policy?';
    static readonly filter_section_mismatch: string = 'Revise the Filter section to view added FN/Tract';
    static readonly landId_delete_por_validation: string = 'A Point of Reference must exist within the Grid ID';
    static readonly landId_claims_warning: string = 'Updates are not allowed on lines reflecting \'In Batch\', \'Posted\' or \'Closed\' claim status or if a Claims Escrow Validation (CEV) lock is in place.';
    static readonly landId_fields_unsaved_data_warning: string = 'Unsaved Field Details data will be lost on Exit.';
    static readonly landId_refresh_high_risk_warning: string = 'Unable to refresh, please try again or contact Help Desk.';
    static readonly landId_porwithingrid_validation: string = 'The POR must be within the current Grid ID';
    static readonly landId_por_duplicatedclu_validation: string = 'Only one Point of Reference can exist per field !';
    static readonly landId_por_validation_failed: string = 'No fields available for POR. Create an RLU for any additional POR.';
    static readonly landId_por_rlu_outside_clu_validation: string = 'The selected POR must stay outside of any CLU boundaries';
    static readonly landId_por_clu_validation: string = 'The selected POR must stay inside a CLU boundary';
    static readonly landId_dupliate_por_warning: string = 'The Point of Reference already exist !';
}

export class LANDSCONSTANTS {
    static readonly showFieldsToolTip: string = 'View Fields. Minimum one Selection, disabled for multiple selections.';
    static readonly showFieldsToolTipForCoverageSection: string = 'FN, Sec/Twp/Rng, Place Name or Other Id is required to View Fields.';
    static readonly viewMapKey: string = (window as any).globalConfig.MsVirtualEarthKey;
    static readonly mapServerUrlProxied: string = (window as any).globalConfig.mapServerUrlProxied;
    static readonly refLayerUrl: string = LANDSCONSTANTS.mapServerUrlProxied + '?map=' + (window as any).globalConfig.map_file_location + '/referenceLayers.map';
    static readonly map_file_location: string = (window as any).globalConfig.map_file_location;
    static readonly deletePORToolTip: string = 'Clicking will delete this POR permanently.';
}

export class TITLE {
    static readonly Warning: string = "Warning";
}

export enum ShareholderType {
    Grower = 'Grower',
    SBI = 'SBI',
    Shareholder = 'Shareholder',
}

export enum OPERATION {
    None,
    Delete_Sharehoder,
    Remove_Associated_Premiumlines,
    Update_Share_Percentage,
    Unlink,
    No_Result_Found_Shareholder
}

export enum WINDOWTYPE {
    None,
    Alert,
    Warning
}

export class InsurancePlan {
    static readonly rain_fall_index_code: string = '13';
}

export class DecimalPlaces {
    static readonly zero_decimal_places: number = 0;
    static readonly one_decimal_places: number = 1;
    static readonly two_decimal_places: number = 2;
}

export enum ExpandCollapseState {
    NoChange,
    ExpandAll,
    CollapsAll
}

export class SUCCESS {
    static readonly landID_create_lands_api_success: string = 'New Land Identifier(s) have been added.';
    static readonly landID_delete_companionlink_api_success: string = 'Companion unlinking was successful.';
}

export enum CompanionHeaderText {
    S = 'Source Policy Link',
    C = 'Companion Link Exists',
}

export enum SORTOPTION {
    County = 'County',
    Coverage = 'Coverage',
    BU = 'BU',
    OU = 'OU',
    Tract = 'Tract',
    STR = 'STR',
    PlaceName = 'PlaceName',
    OtherId = 'OtherId',
    FN = 'FN',
    None = 'Sort by...'
}

export const plantedCluDataSource = {
    'C': 'Internal CLU WebService',
    'U': 'UI via Internal CLU WebService',
    'W': 'WebService Uploaded',
    'M': 'Integrated Mapping sync-up',
    'B': 'Land Id Utility Batch Update',
    'F': 'Fast Edit CLU'
}

export class FSNValidation {

    static readonly defaultTooltip: string = "Validate";
    static readonly fnTooltipError: string = "Enter FN or Sec/Twp/Rng to validate"
    static readonly strTooltipError: string = "Invalid Section Township Range";
}

export const GUARANTEE_REDUCTION_FLAG = {
    'E': 'Eligible Prevent Plant Acres from other unit',
    'F': 'Thinned Acre Reduction',
    'L': 'Late Plant',
    'M': 'Max Late Plant',
    'P': 'Prevented Plant',
}

export enum PRFFieldType {
    CLU = 'CLU',
    RLU = 'RLU',
    APICULTURE = 'ApiCulture'
}

export const MAX_LANDS_TO_DISPLAY = 100;
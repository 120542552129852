import { result } from 'lodash';
import { Action, ActionCreator } from 'redux';
import { PremiumLineUpdateResults } from '.';

const actionTypes = {
    PUT_PREMIUMLINEINFORMATION: '@@PremiumLineInformation/PUT_PREMIUMLINEINFORMATION_REQUEST',
    UPDATE_PREMIUMLINEINFORMATION: '@@PremiumLineInformation/UPDATE_PREMIUMLINEINFORMATION_REQUEST',
    UPDATE_PREMIUMLINEACRES: '@@PremiumLineInformation/UPDATE_PREMIUMLINETOTALACRES',
    DELETE_PREMIUMLINEACRES: '@@PremiumLineInformation/DELETE_PREMIUMLINETOTALACRES',
    UPDATE_PREMIUMLINERESULTS: '@@PremiumLineInformation/UPDATE_PREMIUMLINERESULTS',
    CLEAR_PREMIUMLINERESULTS: '@@PremiumLineInformation/CLEAR_PREMIUMLINERESULTS',
}

export const ActionTypes = actionTypes;

export interface PutPremiumLineInformation extends Action {
    PremuimLineOid: number;
    ReportedUnitAcres: number;
    PlantDate: string;
    AcreageTypeCode: string;
    AcreageTypeDescription: string;
    GuaranteeReductionFlag: string;
    UserCanEditPremiumLine: boolean;
    HasClaim: boolean;
    Croptions: string;
}

export interface UpdatePremiumLineInformation extends Action {
    PremuimLineOid: number;
    ReportedUnitAcres: number;
}

export interface UpdatePremiumLineAcres extends Action {
    PremuimLineOid: number;
    LandOid: number;
    Oid: string;
    ReportedUnitAcres: number;
}

export interface DeletePremiumLineAcres extends Action {
    PremuimLineOid: number;
    LandOid: number;
}

export interface UpdatePremiumLineResults extends Action {
    Results: PremiumLineUpdateResults[]
}

export interface ClearPremiumLineResults extends Action {
}

export const putPremiumLineInformation: ActionCreator<PutPremiumLineInformation> = (
    premiumLineOid: number,
    totalUnitAcres: number,
    plantDate: string,
    acreageTypeCode: string,
    acreageTypeDescription: string,
    guaranteeReductionFlag: string,
    userCanEditPremiumLine: boolean,
    hasClaim: boolean,
    croptions: string
) => ({
    type: actionTypes.PUT_PREMIUMLINEINFORMATION,
    PremuimLineOid: premiumLineOid,
    ReportedUnitAcres: totalUnitAcres,
    PlantDate: plantDate,
    AcreageTypeCode: acreageTypeCode,
    AcreageTypeDescription: acreageTypeDescription,
    GuaranteeReductionFlag: guaranteeReductionFlag,
    UserCanEditPremiumLine: userCanEditPremiumLine,
    HasClaim: hasClaim,
    Croptions: croptions
})

export const updatePremiumLineInformation: ActionCreator<UpdatePremiumLineInformation> = (
    premiumLineOid: number,
    totalUnitAcres: number,
) => ({
    type: actionTypes.UPDATE_PREMIUMLINEINFORMATION,
    PremuimLineOid: premiumLineOid,
    ReportedUnitAcres: totalUnitAcres,
})

export const updatePremiumLineAcres: ActionCreator<UpdatePremiumLineAcres> = (premiumLineOid: number, landOid: number, oid: string, totalUnitAcres: number) => ({
    type: actionTypes.UPDATE_PREMIUMLINEACRES,
    PremuimLineOid: premiumLineOid,
    LandOid: landOid,
    Oid: oid,
    ReportedUnitAcres: totalUnitAcres,
})

export const deletePremiumLineAcres: ActionCreator<DeletePremiumLineAcres> = (premiumLineOid: number, landOid: number) => ({
    type: actionTypes.DELETE_PREMIUMLINEACRES,
    PremuimLineOid: premiumLineOid,
    LandOid: landOid
})

export const updatePremiumLineResults: ActionCreator<UpdatePremiumLineResults> = (results: PremiumLineUpdateResults[]) => ({
    type: actionTypes.UPDATE_PREMIUMLINERESULTS,
    Results: results
})

export const clearPremiumLineResults: ActionCreator<ClearPremiumLineResults> = () => ({
    type: actionTypes.CLEAR_PREMIUMLINERESULTS
})

export type PremiumLineInformationActions =
    PutPremiumLineInformation |
    UpdatePremiumLineInformation |
    UpdatePremiumLineAcres |
    DeletePremiumLineAcres | 
    ClearPremiumLineResults |
    UpdatePremiumLineResults;
import { AlertType } from "components/alert/alertType";
import { CustomAlert } from "components/alert/customAlert";
import { RefreshHighRiskLandIdResult } from "store/land";

interface RefreshHighRiskNotificationsProps {
    refreshHighRiskLandIdResults?: RefreshHighRiskLandIdResult[];
}


export function RefreshHighRiskNotifications({ refreshHighRiskLandIdResults }: RefreshHighRiskNotificationsProps): JSX.Element {
    return (
        <>
            {refreshHighRiskLandIdResults?.some((p) => (p.Success == false)) && (
                <CustomAlert type={AlertType.Information} variant="danger" show={true} id="refreshHighRiskButtonAlert" >
                    {refreshHighRiskLandIdResults?.map((response) => (
                        <div key={response.MpciPremiumLineLandIdOid}>
                            <div>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{response.MpciPremiumLineLandIdOid} - {response.ErrorMessage}</div>
                        </div>
                    ))}
                </CustomAlert>
            )}

        </>
    )
}
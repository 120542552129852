import { AuthenticationState } from '.';
import { throwErrors } from 'services/apiConfig';

const apiUrl = '';

export const getUser = () =>
    fetch(apiUrl + '/auth/Profile', { mode: 'cors', credentials: 'include' })
        .then(throwErrors)
        .then(response => response.json() as Promise<AuthenticationState>);

export const login = () => {
    window.location.href = apiUrl + '/auth/login';
}

export const logout = async () => {
    window.location.href = apiUrl + '/auth/logout';
}
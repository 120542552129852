import { ShareholderType } from "../../../../commom/constant";
import { ICompanionPolicyUnit } from "./ICompanionPolicyUnit";
import { IPremiumLine } from "./IPremiumLine";
import { IShareholderCoverage } from "./IShareholderCoverage";
import { ShareholderCoverageVM } from "./ShareholderCoverageVM";

export class ShareholderPremiumLineVM {
    oid: number = 0;
    state: string = "";
    county: string = "";
    crop: string = "";
    type: string = "";
    practice: string = "";
    planCode: string = "";
    commodityCode: string = "";
    basicUnit: string = "";
    optionalUnit: string = "";
    share?: number = 0;
    companionPremiumLine!: ICompanionPolicyUnit[];
    shareholderType: ShareholderType = ShareholderType.Shareholder;
    isAssociated?: boolean;
    enableDetaillineShare: boolean = false;
    availableDetailLineShares: number = 0;
    isSelected: boolean = false;
    errors?: string[];
    resetSelection() {
        this.isSelected = this.isAssociated??false;
    }

    clone() {
        let result = new ShareholderPremiumLineVM();

        result.oid = this.oid;
        result.state = this.state;
        result.county = this.county;
        result.crop = this.crop;
        result.basicUnit = this.basicUnit;
        result.optionalUnit = this.optionalUnit;
        result.isAssociated = this.isAssociated;
        result.shareholderType = this.shareholderType;
        result.companionPremiumLine = this.companionPremiumLine;
        result.share = this.share;
        result.type = this.type;
        result.practice = this.practice;
        result.planCode = this.planCode;
        result.commodityCode = this.commodityCode;
        result.enableDetaillineShare = this.enableDetaillineShare;
        result.availableDetailLineShares = this.availableDetailLineShares;
        result.isSelected = this.isSelected;

        return result;
    }

    static cloneMany(shareholderPremiumLineVMs: ShareholderPremiumLineVM[]) {
        let result: ShareholderPremiumLineVM[] = [];

        if (shareholderPremiumLineVMs?.length > 0) {
            for (let shareholderPremiumLine of shareholderPremiumLineVMs) {
                result.push(shareholderPremiumLine.clone());
            }
        }

        return result;
    }

    static fromIPremiumLine(shareCoverage: IShareholderCoverage, premiumLine: IPremiumLine) {
        let result = new ShareholderPremiumLineVM();

        result.oid = premiumLine.OID;
        result.state = shareCoverage.StateAbbreviation ?? "";
        result.county = shareCoverage.County ?? "";
        result.crop = shareCoverage.CommodityName ?? "";
        result.basicUnit = premiumLine.BasicUnit ?? "";
        result.optionalUnit = premiumLine.OptionalUnit ?? "";
        result.isAssociated = premiumLine.IsAssociated ?? false;
        result.shareholderType = premiumLine.ShareholderType;
        result.share = premiumLine.SharePercent;
        result.companionPremiumLine = premiumLine.CompanionPremiumLine;
        result.type = premiumLine.Type ?? "";
        result.practice = premiumLine.Practice ?? "";
        result.planCode = shareCoverage.PlanCode ?? "";
        result.commodityCode =  shareCoverage.CommodityCode ?? "";
        result.enableDetaillineShare = false;
        result.availableDetailLineShares = 0;
        result.resetSelection();

        return result;
    }

    toIPremiumLine(): IPremiumLine {
        let shareholderPremiumLine: IPremiumLine = {
            OID: this.oid,
            SharePercent: this.share,
            IsAssociated: this.isAssociated,
            ShareholderType: this.shareholderType,
            CompanionPremiumLine: this.companionPremiumLine
        };

        return shareholderPremiumLine;
    }

    static fromIShareholderCoverage(shareCoverage: IShareholderCoverage): ShareholderPremiumLineVM[] {
        var result: Array<ShareholderPremiumLineVM> = [];

        for (var premiumLine of shareCoverage.PremiumLines) {
            result.push(ShareholderPremiumLineVM.fromIPremiumLine(shareCoverage, premiumLine));
        }

        return result;
    }

    static toIPremiumLines(shareholderPremiumLineVMs: ShareholderPremiumLineVM[], predicatePremiumLineVM?: (premiumLine: ShareholderPremiumLineVM) => boolean): IPremiumLine[] {
        let shareholderPremiumLines: IPremiumLine[] = [];
        for (let shareholderPremiumLineVM of shareholderPremiumLineVMs) {
            if (predicatePremiumLineVM == undefined || predicatePremiumLineVM(shareholderPremiumLineVM))
            {
                shareholderPremiumLines.push(shareholderPremiumLineVM.toIPremiumLine());
            }
        }
        return shareholderPremiumLines;
    }
}
import { call, put, takeEvery } from 'redux-saga/effects';
import { ERRORS } from '../../commom/constant';
import { ActionTypes, getPORFail, GetPORRequestAction, getPORSuccess, DeletePORRequestAction, deletePORSuccess, automatePORSuccess, AutomatePORRequestAction, automatePORFail, deletePORFail, SavePORRequestAction, savePORSuccess, savePORFail } from './actions';
import * as Api from './api';

export function* getPORRequestSaga() {
    yield takeEvery(ActionTypes.GET_POR_REQUEST, getPORRequest);
};

export function* deletePORRequestSaga() {
    yield takeEvery(ActionTypes.DELETE_POR_REQUEST, deletePORRequest);
};

export function* automatePORRequestSaga() {
    yield takeEvery(ActionTypes.AUTOMATE_POR_REQUEST, automatePORRequest);
};

export function* savePORRequestSaga() {
    yield takeEvery(ActionTypes.SAVE_POR_REQUEST, savePORRequest);
};

function* getPORRequest(action: GetPORRequestAction) {
    yield call(getPOR, action);
}

function* deletePORRequest(action: DeletePORRequestAction) {
    yield call(deletePOR, action);
}

function* automatePORRequest(action: AutomatePORRequestAction) {
    yield call(automatePOR, action);
}

function* savePORRequest(action: SavePORRequestAction) {
    yield call(savePOR, action);
}

function* getPOR(action: GetPORRequestAction) {
    try {
        if (action.PORRequest) {

            yield Api.GetPOR(action.Api, action.GrowerId, action.MpciPolicyId, action.MpciCoverageId, action.MpciPremiumLineId).then(response => {
                if (action.PORRequest) {
                    action.PORRequest.MpciPremiumLinePointOfReferences = response.MpciPremiumLinePointOfReferences;
                    action.PORRequest.GroupId = response.GroupId;
                    action.PORRequest.CanAutoPRF = response.CanAutoPRF;
                    action.PORRequest.GlobalCurrentGridWkt = response.GlobalCurrentGridWkt;
                }
            })

            yield put(getPORSuccess(action.PORRequest));
        }

    }
    catch (e: any) {
        yield put(getPORFail(action.PORRequest));
    }
}

function* deletePOR(action: DeletePORRequestAction) {
    try {
        yield Api.DeletePOR(action.Api, action.GrowerId, action.MpciPolicyId, action.MpciCoverageId, action.MpciPremiumLineId, action.CluId, action.PORRequest.GroupId).then(response => {
            action.PORRequest.MpciPremiumLinePointOfReferences = action.PORRequest.MpciPremiumLinePointOfReferences.map(p => {
                {
                    if (action.CluId.includes(p.CluId)) {
                        p.Lat = null;
                        p.Lon = null;
                    }
                }
                return p;
            }
            );
            action.PORRequest.CanAutoPRF = true;
        });
        yield put(deletePORSuccess(action.PORRequest));
    }
    catch (e: any) {
        yield put(deletePORFail(action.PORRequest));
    }

}

function* automatePOR(action: AutomatePORRequestAction) {
    try {
        if (action.PORRequest) {
           
            yield Api.AutomatePOR(action.Api, action.GrowerId, action.MpciPolicyId, action.MpciCoverageId, action.MpciPremiumLineId).then(response => {
              
                if (action.PORRequest) {
                    action.PORRequest.MpciPremiumLinePointOfReferences = response.MpciPremiumLinePointOfReferences;
                    action.PORRequest.GroupId = response.GroupId;
                    action.PORRequest.CanAutoPRF = response.CanAutoPRF;
                    action.PORRequest.ErrorMsgForPOR = response.ValidateAutoPORMsg != '' ? ERRORS.landId_automated_por_multiple_grid_error : response.ValidateAutoPORMsg;
                    action.PORRequest.GlobalCurrentGridWkt = response.GlobalCurrentGridWkt;
                }
            })
            yield put(automatePORSuccess(action.PORRequest));

        }
    }
    catch (e: any) {
        yield put(automatePORFail(action.PORRequest));
    }

}

function* savePOR(action: SavePORRequestAction) {

    try {
        yield Api.SavePOR(action.Api, action.GrowerId, action.MpciPolicyId, action.MpciCoverageId, action.MpciPremiumLineId, action.PointOfReference).then(response => {
            action.PORRequest.CanAutoPRF = response;

        });
        yield put(savePORSuccess(action.PORRequest));
    }
    catch (e: any) {
        yield put(savePORFail(action.PORRequest));
    }

}
import { call, put, takeEvery } from 'redux-saga/effects';
import { ISaveFSN } from '.';
import { ApiGateway } from '../../components/apiGateway/useApiGateway';
import { PolicyUtility } from '../../manager/lands/policyUtility';
import { ActionTypes, deleteLandsFail, DeleteLandsRequestAction, deleteLandsSuccess, PutLandsRequestAction, saveLandsFail, SaveLandsRequestAction, saveLandsSuccess, SetLandsHasEditAction, setLandsSuccess } from './actions';
import * as Api from './api';
import { IViewLand } from 'views/mpci/lands/viewmodels/iViewLands';
import { SaveCoverageResponse } from '../../views/mpci/lands/viewmodels/iSaveCoverageResponse';
import { updatePremiumLineResults } from '../premiumLineInformation/actions';
import { disableLoadingSpinner, enableLoadingSpinner } from 'store/loadingSpinner';
import { MessageUtility } from 'manager/common/messageUtility';

export function* setLands(lands: IViewLand[]) {
    yield put(setLandsSuccess(lands));
}

export function* saveLandsSaga() {
    yield takeEvery(ActionTypes.SAVE_LANDS_REQUEST, saveLandEffect)
}

export function* deleteLandsSaga() {
    yield takeEvery(ActionTypes.DELETE_LANDS_REQUEST, deleteLandsRequest);
};

function* saveLandEffect(action: SaveLandsRequestAction) {
    yield call(saveLands, action.api, action.growerId, action.mpciPolicyId, action.saveRequest)
}

function* deleteLandsRequest(action: DeleteLandsRequestAction) {
    yield call(deleteLands, action);
}

export function* saveLands(api: ApiGateway, growerId: number, mpciPolicyId: number, fsnRequest: ISaveFSN) {
    if (api) {
        let savedFSNs = [] as IViewLand[];
        let er = '';
        let results: SaveCoverageResponse = {}

        yield Api.SaveLands(api, growerId, mpciPolicyId, fsnRequest)
            .then(response => {
                results = response;
                savedFSNs = PolicyUtility.saveFarmLandApiMapper(response.PremiumLineLandIdResponse)
            })
            .catch((error) => {
                er = error;
            });

        yield put(updatePremiumLineResults(results.PremiumLineUpdateResults))

        if (er == '') {
            yield put(saveLandsSuccess(savedFSNs))
        }
        else {
            let response = er as unknown as any
            if (response.status != null && response.status != undefined && response.status != '') {
                yield put(saveLandsFail(response.status))
            }
            else {
                yield put(saveLandsFail('API Failure'))
            }
        }
    }
}

function* deleteLands(action: DeleteLandsRequestAction) {
    try {
        yield put(enableLoadingSpinner('delete-land'))
        let responseId: any;
        yield Api.DeleteLands(action.api, action.growerId, action.mpciPolicyId, action.mpciCoverageId, action.mpciPremiumLineId, action.mpciPremiumLineLandId)
            .then(response => { responseId = response });
        action.lands = action.lands.filter(c => c.OID != action.mpciPremiumLineLandId);
        action.lands = action.lands.map(m => {
            if (m.OID == responseId && Number(m.UnitNumber?.value) == action.mpciPremiumLineId) {
                m.IsPrimary = true;
            }
            return m;
        });

        yield put(deleteLandsSuccess(action.lands));
    }
    catch (e: any) {
        if (e.status != null && e.status != undefined && e.status != '') {
            yield put(deleteLandsFail(e.status));
        }
        else {
            yield put(deleteLandsFail('API Failure'));
        }
    } finally {
        yield put(disableLoadingSpinner('delete-land'))
    }
}

export function* setLandsHasEditSaga() {
    yield takeEvery(ActionTypes.SET_LANDS_HAS_EDIT, setLandsHasEditEffect   )
}

export function* setLandsHasEditEffect(action: SetLandsHasEditAction) {
    if(action){
        yield MessageUtility.sendMessage({type: "lands-hasedits", body: action.hasEdit.toString()})
    }
};
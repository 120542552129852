import { Reducer } from 'redux';
import * as CompanionLinksSaga from './';
import { ActionTypes, CompanionLinksActions } from './actions';

const initialState: CompanionLinksSaga.CompanionLinksState = {
    CompanionLinksRequest: []
};

const reducer: Reducer<CompanionLinksSaga.CompanionLinksState, CompanionLinksActions> =
    (state: CompanionLinksSaga.CompanionLinksState = initialState, action: CompanionLinksActions): CompanionLinksSaga.CompanionLinksState => {
        switch (action.type) {
            case ActionTypes.GET_COMPANIONLINKS_REQUEST:
                const companionLinksRequest = state.CompanionLinksRequest

                if (action.CompanionLinksRequest != undefined) {
                    action.CompanionLinksRequest.IsLoading = true;

                    if (companionLinksRequest.length > 0 && companionLinksRequest.find(c => c.MpciPremiumLineID != action.CompanionLinksRequest?.MpciPremiumLineID)) {
                        companionLinksRequest.push(action.CompanionLinksRequest);
                    }
                }
                return { ...state, CompanionLinksRequest: companionLinksRequest };

            case ActionTypes.GET_COMPANIONLINKS_REQUEST_SUCCESS:
                if (action.CompanionLinksRequest != undefined) {
                    state.CompanionLinksRequest.splice(state.CompanionLinksRequest.findIndex(item =>
                        item.MpciPremiumLineID == action.CompanionLinksRequest?.MpciPremiumLineID
                    ), 1);

                    action.CompanionLinksRequest.IsLoading = false;
                    state.CompanionLinksRequest.push(action.CompanionLinksRequest);
                }
                return { ...state };

            case ActionTypes.GET_COMPANIONLINKS_REQUEST_FAIL:
                if (action.CompanionLinksRequest != undefined) {
                    state.CompanionLinksRequest.splice(state.CompanionLinksRequest.findIndex(item =>
                        item.MpciPremiumLineID == action.CompanionLinksRequest?.MpciPremiumLineID
                    ), 1);

                    action.CompanionLinksRequest.HasError = true;
                    action.CompanionLinksRequest.IsLoading = false;
                    state.CompanionLinksRequest.push(action.CompanionLinksRequest);
                }
                return { ...state };

            case ActionTypes.DELETE_COMPANIONLINKS_REQUEST:
                if (action.CompanionLinksRequest != undefined) {
                    state.CompanionLinksRequest.splice(state.CompanionLinksRequest.findIndex(item =>
                        item.MpciPremiumLineID == action.CompanionLinksRequest?.MpciPremiumLineID
                    ), 1);

                    action.CompanionLinksRequest.IsLoading = true;
                    state.CompanionLinksRequest.push(action.CompanionLinksRequest);
                }
                return { ...state };

            case ActionTypes.DELETE_COMPANIONLINKS_REQUEST_SUCCESS:
                if (action.CompanionLinksRequest != undefined) {
                    state.CompanionLinksRequest.splice(state.CompanionLinksRequest.findIndex(item =>
                        item.MpciPremiumLineID == action.CompanionLinksRequest?.MpciPremiumLineID
                    ), 1);

                    action.CompanionLinksRequest.IsLoading = false;
                    action.CompanionLinksRequest.HasError = false;
                    state.CompanionLinksRequest.push(action.CompanionLinksRequest);
                }
                return { ...state };

            case ActionTypes.DELETE_COMPANIONLINKS_REQUEST_FAIL:
                if (action.CompanionLinksRequest != undefined) {
                    state.CompanionLinksRequest.splice(state.CompanionLinksRequest.findIndex(item =>
                        item.MpciPremiumLineID == action.CompanionLinksRequest?.MpciPremiumLineID
                    ), 1);
                    action.CompanionLinksRequest.IsLoading = false;
                    action.CompanionLinksRequest.HasError = true;
                    state.CompanionLinksRequest.push(action.CompanionLinksRequest);
                }
                return { ...state};

        }
        return state;
    };
export default reducer;
import React, { memo } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { IInputTextProps } from './iInputTextProps';
import styles from './inputText.module.scss';

export const InputText: React.FC<IInputTextProps> = (props: IInputTextProps) => {
    const { name, label, value, onBlur, onChange, disabled, inputType, className, readOnly, defaultValue, error, innerRef, floatLabel, onKeyPress, maxLength } = props

    function getComponent() {
        if (floatLabel) {
            return (
                <FloatingLabel
                    label={label}
                >
                    <Form.Control
                        data-testid={name}
                        id={name}
                        type={inputType}
                        value={value}
                        isInvalid={!!error}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                        className={className}
                        readOnly={readOnly}
                        defaultValue={defaultValue}
                        ref={innerRef}
                        name={name}
                        onKeyPress={onKeyPress}
                        maxLength={maxLength}
                    />
                    <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                </FloatingLabel>
            );
        }
        else {
            return (
                <Form.Group>
                    {label ? <Form.Label>{label}</Form.Label> : null}
                    <Form.Control
                        data-testid={name}
                        id={name}
                        type={inputType}
                        value={value}
                        isInvalid={!!error}
                        onChange={onChange}
                        onBlur={onBlur}
                        disabled={disabled}
                        className={className}
                        readOnly={readOnly}
                        defaultValue={defaultValue}
                        ref={innerRef}
                        name={name}
                        onKeyPress={onKeyPress}
                        maxLength={maxLength}
                    />
                    <Form.Control.Feedback data-testid={`invalid-${name}`} type="invalid">{error}</Form.Control.Feedback>
                </Form.Group>
            );
        }
    }


    return (
        getComponent()
    );
};

export default memo(InputText);
import React, { memo, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { ActionMeta, GroupBase } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { CreatableUtility } from './creatableUtility';
import { ISelectCreatableProps } from './iSelectCreateableProps';
import styles from './selectCreateable.module.scss';
import { ISelectList } from '../iSelectList';

export const SelectCreatable: React.FC<ISelectCreatableProps> = (props: ISelectCreatableProps) => {
    const selectRef = useRef(null)
    const [inputValue, setInputValue] = React.useState('')

    const normalStyle = {
        control: (base: any, state: { isFocused: any; }) => ({
            ...base,
            borderColor: '#ddd',
            height: !props.label ? '51px' : '',
            backgroundColor: props.isDisabled ? '#d9d9d9' : '#fff',
            boxShadow: state.isFocused ? '0 0 6px #198754' : 0,
            '&:hover': {
                borderColor: '#198754',
            }
        })
    }

    const errorStyle = {
        control: (base: any) => ({
            ...base,
            borderColor: '#dc3545',
            boxShadow: 0,
            height: !props.label ? '51px' : '',
            '&:hover': {
                borderColor: '#dc3545',
                boxShadow: '0 0 10px #dc3545'
            },
            '&:focus': {
                borderColor: '#dc3545',
                boxShadow: '0 0 10px #dc3545',
            }
        })
    }

    const customStyles = (props.error) ? errorStyle : normalStyle;

    const onCreatableSelectChange = (value: any, actionMeta: ActionMeta<any>) => {
        if (value) {
            props.handleChange(value)
        }
        if (actionMeta.action == 'clear') {
            props.handleChange(undefined)
        }
        else if (actionMeta.action == 'create-option') {
            const nextValue = CreatableUtility.sectionTownRangeFormat(value.label)
            props.handleChange({ label: nextValue, value: nextValue })
        }
    }

    const handleFocus = (element: any) => {
        if (props.onFocus) {
            props.onFocus(element)
        }
        if (!!props.value && selectRef.current) {
            const ref = selectRef as any;
            const nextValueProps = props.value as ISelectList;
            ref.current.handleInputChange({ currentTarget: { label: nextValueProps.label, value: nextValueProps.label }})
        }
    }

    const onInputBlur = () => {
        if (inputValue) {
            const nextValue = CreatableUtility.sectionTownRangeFormat(inputValue)
            props.handleChange({ label: nextValue, value: nextValue })
        }
      };

    return (
        <Form.Group controlId={`select-creatable`} onFocus={handleFocus} >
            {props.label ? <Form.Label>{props.label}</Form.Label> : null}
            <div data-testid={props.name}>
                <CreatableSelect
                    { ...props }
                    onChange={onCreatableSelectChange}
                    onBlur={onInputBlur}
                    onInputChange={setInputValue}
                    styles={customStyles}
                    placeholder='Select...or Sec/Twp/Rng'
                    value={props?.value ?? ""}
                    ref={selectRef}
                />
                { !!props.error && (
                    <label id={props.name + '.error'} data-testid={props.name + '.error'} className={styles.error} >{props.error}</label>
                )}
            </div>
        </Form.Group>
    );
}

export default memo(SelectCreatable);
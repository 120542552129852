import {
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect } from "react";
import {
  FilterIcon,
  ExpandedChevronIcon,
  ChevronIcon,
} from "../../../components/columnfilter/Icons";
import PremiumLineSummary from "./PremiumLineSummary";
import { ShareholderCoverageVM } from "./viewmodels";
import styles from "./Shareholders.module.scss";
import { ExpandCollapseState, ShareholderType } from "commom/constant";
import { ShareholderVM } from "./viewmodels/ShareholderVM";

interface props {
  shareCoverages: ShareholderCoverageVM[];
  shareholder: ShareholderVM;
  growerId: string;
  mpciPolicyId: string;
  errorHandler: (error: string) => void;
  setIsLoading: (setIsLoading: boolean) => void;
  expandCollapseState: ExpandCollapseState;
  canEditPremiumLineShareholders?: boolean
}

const CoverageSummary: React.FC<props> = ({
  shareCoverages,
  shareholder,
  setIsLoading,
  growerId,
  mpciPolicyId,
  errorHandler,
  expandCollapseState,
  canEditPremiumLineShareholders
}) => {
  
  useEffect(()=>{
    switch(expandCollapseState){
        case ExpandCollapseState.ExpandAll:
          table.toggleAllRowsExpanded(true);
          break;
        case ExpandCollapseState.CollapsAll:
          table.toggleAllRowsExpanded(false);
          break;
        default : 
          break; 
    }
  },[expandCollapseState])

  const tableColumns: ColumnDef<ShareholderCoverageVM>[] = [
    {
      accessorKey: "title",
      columns: [
        {
          accessorKey: "state",
          header: "State",
        },
        {
          accessorKey: "county",
          header: "County",
        },
        {
          accessorKey: "crop",
          header: "Crop",
        },
        {
          accessorKey: "null",
          header: "",
        },
        {
          accessorKey: "basicUnit",
          header: "Basic Unit",
        },
        {
          accessorKey: "optionalUnit",
          header: "Optional Unit",
        },
        {
          accessorKey: "null",
          header: "",
        },
        {
          accessorKey: "share",
          header: "Share %",
        },
      ],
    },
    {
      accessorKey: "state",
      header: "State",
    },
    {
      accessorKey: "county",
      header: "County",
      enableHiding: true,
    },
    {
      accessorKey: "crop",
      header: "Crop",
      enableHiding: true,
    },
    {
      accessorKey: "type",
      header: "Type",
      enableHiding: true,
    },
    {
      accessorKey: "practice",
      header: "Practice",
      enableHiding: true,
    },

    {
      accessorKey: "basicUnit",
      header: "Basic Unit",
      enableHiding: true,
    },
    {
      accessorKey: "optionalUnit",
      header: "Optional Unit",
      enableHiding: true,
    },

    {
      accessorKey: "share",
      header: "Share %",
      enableHiding: true,
    },
  ];

  const [expanded, setExpanded] = React.useState<ExpandedState>({});
  const table = useReactTable({
    data: shareCoverages,
    columns: tableColumns,
    state: {
      expanded,
      pagination: {
        pageIndex: 0,
        pageSize: shareCoverages.length,
      },
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  const getUniqueShareholderType = () => {
    return shareholder.isGrower
      ? ShareholderType.Grower
      : shareholder.isSBI
      ? ShareholderType.SBI
      : ShareholderType.Shareholder;
  };
    return (
        <React.Fragment key={`${getUniqueShareholderType()}-${shareholder.oid}`}>
      <table className="w-100">
              <thead className={`${styles["bg-f09"]}`}>
                  {table.getRowModel().rows.length>0 && table.getHeaderGroups().map((_headerGroup) => {
            if (_headerGroup.headers.length === 16) return "";
            return (
              <tr key={_headerGroup.id} className="coverageTableHeader">
                {_headerGroup.headers.map((_header, i) => {
                  if (i == 0) return;
                  return (
                    <th
                      key={_header.id}
                      className="text-center"
                      data-testid="coverageheader"
                      colSpan={_header.colSpan}
                    >
                      {i < 4 ? (
                        _header.id !== "1_title_state" && (
                          <>
                            {flexRender(
                              _header.column.columnDef.header,
                              _header.getContext()
                            )}
                            {_header.id != "1_null_null" &&
                              i < _headerGroup.headers.length - 5 && (
                                <FilterIcon
                                  column={_header.column}
                                  showFilterModal={true}
                                />
                              )}
                          </>
                        )
                      ) : (
                        <span
                          className={
                            i == _headerGroup.headers.length - 1
                              ? `${styles["handleSharePosition"]}`
                              : ""
                          }
                        >
                          {flexRender(
                            _header.column.columnDef.header,
                            _header.getContext()
                          )}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody></tbody>
      </table>

      {table.getRowModel().rows.map((_row) => {
        return (
          <div key={_row.original.oid} className="my-3">
            <div
              className={`${styles["bg-f5"]} px-xl-4 m-0 py-2 d-flex px-lg-5 px-md-3`}
            >
              <span onClick={() => _row.toggleExpanded()} data-testid="expandcollapse">
                {_row.getIsExpanded() ? (
                  <ExpandedChevronIcon  />
                ) : (
                  <ChevronIcon/>
                )}
              </span>
              <p data-testid="coveragetitle" className="m-0 p-0">
                {_row.original.title}
              </p>
            </div>
            {_row.getIsExpanded() && _row.original.premiumLines && (
                    <PremiumLineSummary
                        errorHandler={errorHandler}
                        setIsLoading={setIsLoading}
                        premiumLines={_row.original.premiumLines}
                        growerId={growerId} mpciPolicyId={mpciPolicyId} shareholder={shareholder} coverageId={_row.original.oid} canEditPremiumLineShareholders={canEditPremiumLineShareholders} />
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default CoverageSummary;

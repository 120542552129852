import { useApiGateway } from "components/apiGateway/useApiGateway";
import SelectCreatable from "components/forms/selectCreateable/SelectCreatable";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "store";
import { SectionTownshipState } from "store/sectionTownshipRange";
import { SectionTownshipActionTypes } from "store/sectionTownshipRange/actions";
import { ISelectList } from "components/forms/iSelectList";

interface SectionTownshipSelectProps {
    onChange: (e?: ISelectList) => void,
    stateCode?: string;
    countyCode?: string;
    farmNumber?: string;
    tract?: string;
    isDisabled?: boolean;
    value?: ISelectList | undefined;
    label?: string;
    error?: string;
}

export function SectionTownshipSelect({ stateCode, countyCode, farmNumber, tract, onChange, isDisabled, value, label, error }: SectionTownshipSelectProps): JSX.Element {
    const api = useApiGateway();
    const dispatch = useDispatch();
    const sectionTownshipState = useSelector<GlobalState, SectionTownshipState>((state) => state.sectionTownshipState);

    const key = `${farmNumber ?? ''}${stateCode ?? ''}${countyCode ?? ''}${tract ?? ''}`

    const onFocus = (): void => {
        if (!sectionTownshipState.sectionTownShips[key] && !!stateCode && !!countyCode && !!farmNumber) {
            dispatch({ type: SectionTownshipActionTypes.GET_SECTION_TOWNSHIP, api, stateCode, countyCode, farmNumber, tract })
        }
    }

    return (
        <>
            <SelectCreatable
                name="Sec/Twp/Rng"
                handleChange={onChange}
                isLoading={sectionTownshipState.isLoading}
                isDisabled={isDisabled}
                options={sectionTownshipState?.sectionTownShips[key]}
                isClearable={true}
                tabSelectsValue={true}
                isSearchable={true}
                value={value ?? ''}
                error={error}
                onFocus={onFocus}
                label={label}
            />
        </>
    )
}
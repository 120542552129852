import * as AllSagas from './sagas';
import { IViewField } from '../../views/mpci/lands/viewmodels/iViewField';
export const Sagas = Object.values(AllSagas);
export * from './actionsPublic';
export { default as reducer } from './reducer';
import { ISelectList } from "components/forms/iSelectList";
import { IViewLand } from 'views/mpci/lands/viewmodels/iViewLands';

export interface IExtendedUnitSelectList extends ISelectList {
    basicUnit?: string,
    optionalUnit?: string
}

export interface ISaveFSN {
    FarmNumber: string,
    TractNumber: string,
    Section: string,
    OtherId: string,
    PlaceName: string,
    IsPrimary: boolean,
    CountyCode: string,
    StateCode: string,
    CommodityCode: string,
    InsurancePlanCode: string,
    ReInsuranceYear: string,
    Coverage?: ISelectList[],
    UnitNumber?: ISelectList[]
    Fields?: IViewField[],
}

export interface LandState {
    Lands: IViewLand[],
    HasEdit?: boolean,
    IsSaving?: boolean,
    ErrorStatusCode?: string,
    ShouldSaveCoverage?: boolean,
    CoverageRequest: IViewLand[],
}

export interface   RefreshHighRiskLandIdResult {
    MpciPremiumLineLandIdOid: number;
    Success: boolean;
    ErrorMessage?: string;
}
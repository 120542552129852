import { ISelectList } from 'components/forms/iSelectList';
import { IViewLand } from '../../views/mpci/lands/viewmodels/iViewLands';
import * as AllSagas from './sagas';
export const Sagas = Object.values(AllSagas);
export * from './actionsPublic';
export { default as reducer } from './reducer';

export interface IFilter {
    FarmNumbers: ISelectList[];
    TractNumbers: ISelectList[];
    Sections: ISelectList[];
    PlaceNames: ISelectList[];
    OtherIds: ISelectList[];
    Counties: ISelectList[];
    Coverages: ISelectList[];
    UnitNumbers: ISelectList[];
    ShowNLFUnits: boolean;
    BasicUnits: ISelectList[];
    OptionalUnits: ISelectList[];
}

export interface FilterState {
    Filter: IFilter,
    MaxLandsReached: boolean,
    Lands: IViewLand[]
}
import React from 'react';
import { Alert, Row, Col, Button } from 'react-bootstrap';
import { IProps } from './iProps';
import { AlertType } from "./alertType";

export const CustomAlert: React.FC<IProps> = (props: IProps) => {
    const { show, id, type, dismissible, children, variant, onClose, onCancel, onConfirm, continueLabel, closeLabel } = props;

    function renderButtons(alertType: any) {
        switch (alertType) {
            case AlertType.Confirmation:
                return (
                    <Col className="align-self-center align-items-center" md="4">
                    <Button
                        id={`${id}-cancelbtn`}
                        data-testid={`${id}-cancelbtn`}
                        variant="default"
                        onClick={onCancel}
                        className="float-end me-2"
                        type="button"
                    >
                        {continueLabel ?? 'Cancel'}
                    </Button>
                    <Button
                        id={`${id}-closebtn`}
                        data-testid={`${id}-closebtn`}
                        variant="default"
                        onClick={onClose}
                        className="float-end me-2"
                        type="button"
                    >
                        { closeLabel ?? 'Exit'}
                        </Button>
                    </Col>
                );
            case AlertType.Warning:
                return (

                    <Col className="align-self-center align-items-center" md="4">
                        <Button
                            id={`${id}-closebtnwarning`}
                            data-testid={`${id}-closebtnwarning`}
                            variant="default"
                            onClick={onClose}
                            className="float-end me-2"
                            type="button"
                        >
                            { closeLabel ?? 'Cancel '}
                        </Button>
                        <Button
                            id={`${id}-okbtnwarning`}
                            data-testid={`${id}-okbtnwarning`}
                            variant="default"
                            onClick={onConfirm}
                            className="float-end me-2"
                            type="button"
                        >
                            { continueLabel ?? 'Ok'}
                        </Button>
                    </Col>);
        }
    }

    return (
        <>
            <Alert
                id={id}
                data-testid={id}
                show={show}
                variant={variant}
                dismissible={dismissible}
                onClose={onClose}
            >
                <Row>
                    <Col data-testid={`${id}-content`} md={type == AlertType.Warning ? "8" : "12"}>
                        {children}
                    </Col>
                    {renderButtons(type)}
                </Row>
            </Alert>

        </>
    )
}
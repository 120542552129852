import { useState } from 'react';
import styles from './Header.module.scss';
import { Offcanvas, Row, Col, Form } from 'react-bootstrap';
import { FaCaretDown } from 'react-icons/fa';
import { ProductionReporting } from './';

function Header(productionReporting: ProductionReporting) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Row>
                <Col lg={4} md={4} xs={7} className="fs-4">
                    <Form.Label data-testid="growerName" className={`${styles.cursorpointer} m-0`} onClick={handleShow}> {productionReporting.header.GrowerName}<FaCaretDown /></Form.Label>
                </Col>
                <Col lg={2} md={4} xs={3} className="fs-4">
                    <Form.Label className="m-0" data-testid="policyType">{productionReporting.header.LineOfBusiness}</Form.Label>
                </Col>
                <Col lg={1} md={4} xs={2} className="fs-4 ">
                    <Form.Label className="m-0" data-testid="reinsuranceYear"> {productionReporting.header.ReinsuranceYear}</Form.Label>
                </Col>
                <Col lg={2} md={4} xs={4} className="fs-4 ">
                    <Form.Label className="m-0" data-testid="stateName"> {productionReporting.header.StateName}</Form.Label>
                </Col>
                <Col lg={3} md={6} xs={6} className="fs-4">
                    <Form.Label className="m-0" data-testid="policyNumberDisplay">{productionReporting.header.StateCode}-{productionReporting.header.AipCode}-{productionReporting.header.PolicyNumber}</Form.Label>
                </Col>
            </Row>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Offcanvas</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    Some text as placeholder. In real life you can have the elements you
                    have chosen. Like, text, images, lists, etc.
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;
import React from "react";
import {
    Col,
    Form,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from "react-bootstrap";
import styles from "./Shareholders.module.scss";
import { ERRORS, TITLE, URLs, WARNINGS, WINDOWTYPE } from "../../../commom/constant";
import { ICompanionPolicyUnit } from "./viewmodels/ICompanionPolicyUnit";
import Button from "react-bootstrap/Button";
import { ShareholderPremiumLineVM } from "./viewmodels";
//Import API gateway
import ApplicationIdValues from './../../../components/apiGateway/ApplicationIdValues';
import { useApiGateway } from './../../../components/apiGateway/useApiGateway';
import { ICompanionPolicy } from "./viewmodels/ICompanionPolicy";
import { ConfirmMessageModalWindow } from './../../../components/warningMessageModal/ConfirmMessageModalWindow';
import { ErrorUtility } from "../../../manager/common/errorUtility";

interface props {
    growerId: string;
    mpciPolicyId: string;
    shareholderId: number | undefined;
    coverageId: number | undefined;
    errorHandler: (error: string) => void;
    premiumlineId: number | undefined;
    setIsLoading: (setIsLoading: boolean) => void;
    companionPolicyLink: ICompanionPolicyUnit;
    prlines: ShareholderPremiumLineVM[];
    setPrlines: (setPrlines: ShareholderPremiumLineVM[]) => void;
    canEditPremiumLineShareholders?: boolean;
}

export const MESSAGE_NO_MATCHING_POLICY_FOUND = `No Matching Policies.Companion Link Cannot Be Processed`;

const CompanionLink: React.FC<props> = ({
    growerId,
    mpciPolicyId,
    shareholderId,
    setIsLoading,
    coverageId,
    premiumlineId,
    companionPolicyLink,
    errorHandler,
    prlines,
    setPrlines,
    canEditPremiumLineShareholders
}) => {
    const api = useApiGateway();//Init API Gateway

    const [selectedCompanionPolicy, setSelectedCompanionPolicy] = React.useState<string>();
    const [defaultCompanionPolicy, setDefaultCompanionPolicy] = React.useState<string>("");
    const [selectedCompanionPolicyUnit, setSelectedCompanionPolicyUnit] = React.useState<ICompanionPolicyUnit>();
    const [defaultCompanionPolicyUnit, setDefaultCompanionPolicyUnit] = React.useState<ICompanionPolicyUnit[]>();
    const [companionPolicies, setCompanionPolicies] = React.useState<ICompanionPolicy[]>([]);
    const [companionPolicyUnits, setCompanionPolicyUnits] = React.useState<ICompanionPolicyUnit[]>([]);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [showWarningMessage, setshowWarningMessage] = React.useState<boolean>(false);
    const [companionPremiumLine, setCompanionPremiumLine] = React.useState<ICompanionPolicyUnit>(companionPolicyLink);
    const [ConfirmationMessageBody, setConfirmationMessageBody] = React.useState<string>('');
    const [ConfirmationMessageTitle, setConfirmationMessageTitle] = React.useState<string>('');

    const [WindowType, setWindowType] = React.useState<WINDOWTYPE>(WINDOWTYPE.None);

    const getCompanionPolicies = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined,
        coverageId: number | undefined,
        premiumlineId: number | undefined
    ) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId + "/Coverages/" + coverageId + "/PremiumLines/" + premiumlineId + "/CompanionPolicies";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    };

    const getCompanionPolicyUnits = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined,
        coverageId: number | undefined,
        premiumlineId: number | undefined,
        companionPolicyId: string
    ) => {
        const shareholderapiurl = URLs.paw_api_version + "/Growers/" + growerId + "/MpciPolicies/" + mpciPolicyId + "/Shareholders/" + shareholderId + "/Coverages/" + coverageId + "/PremiumLines/" + premiumlineId + "/CompanionPolicies/" + companionPolicyId + "/CompanionPremiumLines";
        const res = await api.get(shareholderapiurl, ApplicationIdValues.Paw);
        return res;
    };

    function setPremiumLinesData(
        companionPolicy: ICompanionPolicyUnit,
        premiumlineId: number | undefined,
        prlines: ShareholderPremiumLineVM[],
        setPrlines: (setPrlines: ShareholderPremiumLineVM[]) => void,
    ) {
        const index = prlines.findIndex(
            (el) => el.oid == premiumlineId
        );
        let newPrLines = prlines;
        newPrLines[index].companionPremiumLine = [];
        newPrLines[index].companionPremiumLine.push(companionPolicy);
        setPrlines(newPrLines);
    }

    const updateLink = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined,
        coverageId: number | undefined,
        premiumlineId: number | undefined,
        companionPolicy: ICompanionPolicyUnit,
        errorHandler: (error: string) => void,
        setIsLoading: (setIsLoading: boolean) => void,
        setShowModal: (setShowModal: boolean) => void,
        setCompanionPremiumLine: (setCompanionPremiumLine: ICompanionPolicyUnit) => void,
        setCompanionPolicyUnits: (setCompanionPolicyUnits: ICompanionPolicyUnit[]) => void,
        setSelectedCompanionPolicyUnit: (setSelectedCompanionPolicyUnit?: ICompanionPolicyUnit) => void,
        setSelectedCompanionPolicy: (setSelectedCompanionPolicy: string) => void,
        prlines: ShareholderPremiumLineVM[],
        setPrlines: (setPrlines: ShareholderPremiumLineVM[]) => void,
        defaultCompanionPolicy: string,
        defaultCompanionPolicyUnit: ICompanionPolicyUnit[],
    ) => {
        setIsLoading(true);

        const updateapiurl =
            URLs.paw_api_version +
            "/Growers/" +
            growerId +
            "/MpciPolicies/" +
            mpciPolicyId +
            "/Shareholders/" +
            shareholderId +
            "/Coverages/" +
            coverageId +
            "/PremiumLines/" +
            premiumlineId +
            "/CompanionPolicies/" +
            companionPolicy.CompanionPolicyNumber +
            "/CompanionPremiumLines/" +
            companionPolicy.CompanionLinkedMpciPremiumLineOID;

        const res = await api.put(updateapiurl, ApplicationIdValues.Paw, companionPolicy)
            .then((response) => {
                setIsLoading(false);
                setCompanionPremiumLine(companionPolicy);
                setPremiumLinesData(companionPolicy, premiumlineId, prlines, setPrlines);
                if (defaultCompanionPolicy == "Policy #") {
                    setCompanionPolicyUnits([]);
                    setSelectedCompanionPolicy("Policy #");
                    if (!companionPolicy.IsCompanionLinked) {
                        setSelectedCompanionPolicyUnit(undefined);
                    }
                }
                else {
                    if (defaultCompanionPolicyUnit != undefined && defaultCompanionPolicy != undefined) {
                        setSelectedCompanionPolicy(defaultCompanionPolicy);
                        setCompanionPolicyUnits(defaultCompanionPolicyUnit);
                        let selectedUnits = defaultCompanionPolicyUnit.filter((unit: ICompanionPolicyUnit) => unit.IsMatching);
                        let matchingUnit = selectedUnits.length > 0 && selectedUnits[0].IsMatching ? selectedUnits[0] : undefined;
                        if (matchingUnit != undefined) {
                            matchingUnit!.IsCompanionLinked = true;
                        }
                        setSelectedCompanionPolicyUnit(matchingUnit);
                    }
                }

                setShowModal(false);
            })
            .catch((err) => {
                setIsLoading(false);
                if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                    errorHandler(ERRORS.update_companion_policy_api_failed);
                }
            });
        return res;
    };

    const createNewUnit = async (
        growerId: string,
        mpciPolicyId: string,
        shareholderId: number | undefined,
        coverageId: number | undefined,
        premiumlineId: number | undefined,
        companionPolicy: ICompanionPolicyUnit,
        errorHandler: (error: string) => void,
        setIsLoading: (setIsLoading: boolean) => void,
        setShowModal: (setShowModal: boolean) => void,
        setCompanionPremiumLine: (setCompanionPremiumLine: ICompanionPolicyUnit) => void,
        prlines: ShareholderPremiumLineVM[],
        setPrlines: (setPrlines: ShareholderPremiumLineVM[]) => void,
    ) => {
        setIsLoading(true);

        const createapiurl =
            URLs.paw_api_version +
            "/Growers/" +
            growerId +
            "/MpciPolicies/" +
            mpciPolicyId +
            "/Shareholders/" +
            shareholderId +
            "/Coverages/" +
            coverageId +
            "/PremiumLines/" +
            premiumlineId +
            "/CompanionPolicies/" +
            companionPolicy.CompanionPolicyNumber +
            "/CompanionPremiumLines";

        const res = await api.post(createapiurl, ApplicationIdValues.Paw, companionPolicy)
            .then((response) => {
                setIsLoading(false);
                getCompanionPolicyUnits(
                    growerId,
                    mpciPolicyId,
                    shareholderId,
                    coverageId,
                    premiumlineId,
                    companionPolicy.CompanionPolicyNumber
                ).then((res) => {
                    let companionlink = res.filter((unit: ICompanionPolicyUnit) => unit.CompanionLinkedMpciPremiumLineOID == response.Ids[0]);
                    setCompanionPremiumLine(companionlink[0]);
                    setPremiumLinesData(companionlink[0], premiumlineId, prlines, setPrlines);
                }).catch((err) => {
                    if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                        errorHandler(ERRORS.get_companion_policy_units_api_failed);
                    }
                });

                setShowModal(false);
            })
            .catch((err) => {
                setIsLoading(false);
                if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                    errorHandler(ERRORS.update_companion_policy_api_failed);
                }
            });
        return res;
    };

    React.useEffect(() => {
        getCompanionPolicies(
            growerId,
            mpciPolicyId,
            shareholderId,
            coverageId,
            premiumlineId
        ).then((data) => {
            if (data != undefined) {
                if (data.length > 0) {
                    let policyNumber = data[0].IsMatching ? data[0].CompanionPolicyNumber : "Policy #";
                    if (policyNumber != "Policy #") {
                        getCompanionPolicyUnits(
                            growerId,
                            mpciPolicyId,
                            shareholderId,
                            coverageId,
                            premiumlineId,
                            policyNumber
                        ).then((res) => {
                            if (res != undefined) {
                                if (res.length > 0) {
                                    let units = res.filter((unit: ICompanionPolicyUnit) => (unit.IsCompanionLinked == false && unit.CompanionPolicySharePercentage! < 100));
                                    let selectedUnits = units.filter((unit: ICompanionPolicyUnit) => unit.IsMatching);
                                    let matchingUnit = selectedUnits.length > 0 && selectedUnits[0].IsMatching ? selectedUnits[0] : "Unit";
                                    if (matchingUnit != "Unit") {
                                        matchingUnit.IsCompanionLinked = true;
                                    }
                                    setCompanionPolicyUnits(units);
                                    setSelectedCompanionPolicyUnit(matchingUnit);
                                    setDefaultCompanionPolicyUnit(units);
                                }
                            }
                        }).catch((err) => {
                            if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) { 
                                errorHandler(ERRORS.get_companion_policy_units_api_failed);
                            }
                        });
                    }
                    setSelectedCompanionPolicy(policyNumber);
                    setDefaultCompanionPolicy(policyNumber);
                    setCompanionPolicies(data);
                }
            }
        }).catch((err) => {
            if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) { 
                errorHandler(ERRORS.get_companion_policies_api_failed);
            }
        });
    }, []);
    const isCompanionAvailable = companionPolicies.length > 0;
    const handleSubmit: (value: any) => Promise<any> = async (value) => {
        let data;

        if (value == `Update`) {
            data = {
                ...selectedCompanionPolicyUnit!,
            };

            if (selectedCompanionPolicyUnit!.FormattedText == `Create New Unit`) {
                createNewUnit(growerId, mpciPolicyId, shareholderId, coverageId, premiumlineId, data, errorHandler,
                    setIsLoading, setShowModal, setCompanionPremiumLine, prlines, setPrlines,
                )
            }
            else {
                updateLink(growerId, mpciPolicyId, shareholderId, coverageId, premiumlineId, data, errorHandler, setIsLoading, setShowModal,
                    setCompanionPremiumLine, setCompanionPolicyUnits, setSelectedCompanionPolicyUnit, setSelectedCompanionPolicy, prlines,
                    setPrlines, defaultCompanionPolicy, defaultCompanionPolicyUnit!
                )
            }

        } else {
            data = {
                ...selectedCompanionPolicyUnit!,
                CompanionPolicyNumber: companionPremiumLine.CompanionPolicyNumber,
                CompanionLinkedMpciPremiumLineOID: companionPremiumLine.CompanionLinkedMpciPremiumLineOID,
                IsCompanionLinked: false,
            };

            updateLink(growerId, mpciPolicyId, shareholderId, coverageId, premiumlineId, data, errorHandler, setIsLoading, setShowModal,
                setCompanionPremiumLine, setCompanionPolicyUnits, setSelectedCompanionPolicyUnit, setSelectedCompanionPolicy,
                prlines, setPrlines, defaultCompanionPolicy, defaultCompanionPolicyUnit!
            )
        }
    };
    React.useEffect(()=>{
        setCompanionPremiumLine(companionPolicyLink);
    },[companionPolicyLink]);

    let linkedPolicyNumber = companionPremiumLine?.CompanionPolicyNumber!;

    let linkedGrowerName = companionPremiumLine?.CompanionPolicyGrowerName!;

    let linkedSharePercentage = companionPremiumLine?.CompanionPolicySharePercentage!;

    const tooltipText= () => {
        if (!companionPremiumLine.IsCompanionLinked) {
            return "Link Companion Policy";
        }
        else {
            if (companionPremiumLine.IsSource) {
                return `Linked to Source Policy - ${linkedPolicyNumber} - ${linkedGrowerName} - ${linkedSharePercentage}%`;
            }
            else {
                return `Companion Policy Exists - ${linkedPolicyNumber} - ${linkedGrowerName} - ${linkedSharePercentage}%`;
            }
        }
    }

    const CompanionLinkText = () => {
        if (companionPremiumLine.IsCompanionLinked) {
            if (companionPremiumLine.IsSource)
                return "Info";
            else
                return "Unlink";
        }
        else {
            if (canEditPremiumLineShareholders) {
                return "Link";
            }
        }
    }

    const renderTooltip = (props: any) => (
        <Tooltip data-testid="tooltip" id="button-tooltip" {...props}>
            {tooltipText()}
        </Tooltip>
    );
    return (
        <>
            <Modal
                data-testid="modal"
                show={showModal}
                onHide={() => {
                    if (defaultCompanionPolicy == undefined || defaultCompanionPolicy == "Policy #") {
                        setCompanionPolicyUnits([]);
                        setSelectedCompanionPolicyUnit(undefined);
                        setSelectedCompanionPolicy("Policy #");
                    }
                    else {
                        if (defaultCompanionPolicyUnit != undefined && defaultCompanionPolicy != undefined) {
                            setSelectedCompanionPolicy(defaultCompanionPolicy);
                            setCompanionPolicyUnits(defaultCompanionPolicyUnit!);
                            let selectedUnits = defaultCompanionPolicyUnit!.filter((unit: ICompanionPolicyUnit) => unit.IsMatching);
                            let matchingUnit = selectedUnits.length > 0 && selectedUnits[0].IsMatching ? selectedUnits[0] : undefined;
                            if (matchingUnit != undefined) {
                                matchingUnit!.IsCompanionLinked = true;
                            }
                            setSelectedCompanionPolicyUnit(matchingUnit);
                        }
                    }
                    setShowModal(false);
                }}
                backdrop={false}
                dialogClassName="w-50"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header className="edit-modal-header" closeButton closeVariant="white">
                    <Modal.Title id="contained-modal-title-vcenter">
                        Link Companion Policy
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Form
                        className="m-2"
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                        noValidate
                    >
                        <Row id="frmIndivisual d-flex">
                            <div
                                className={`d-flex justify-content-between align-items-center`}
                            >
                                <select
                                    value={selectedCompanionPolicy}
                                    onChange={(e) => {
                                        setSelectedCompanionPolicy(e.target.value);
                                        getCompanionPolicyUnits(
                                            growerId,
                                            mpciPolicyId,
                                            shareholderId,
                                            coverageId,
                                            premiumlineId,
                                            e.target.value
                                        ).then((res) => {
                                            let units = res.filter((unit: ICompanionPolicyUnit) => (unit.IsCompanionLinked == false && unit.CompanionPolicySharePercentage! < 100));
                                            setCompanionPolicyUnits(units);
                                            setSelectedCompanionPolicyUnit(undefined);
                                        }).catch((err) => {
                                            if (!ErrorUtility.handleApiGatewayError(err, errorHandler)) {
                                                errorHandler(ERRORS.get_companion_policy_units_api_failed);
                                            }
                                        });
                                    }}
                                    data-testid="ddPolicy"
                                    className={`me-3 ms-1 form-select text-center ${styles["inp-120"]}`}
                                >
                                    <option hidden>Policy #</option>
                                    {companionPolicies.map((el) => (
                                        <option key={el.CompanionPolicyNumber} value={el.CompanionPolicyNumber}>
                                            {el.CompanionPolicyNumber}
                                        </option>
                                    ))}
                                </select>
                                <select
                                    disabled={selectedCompanionPolicy == "Policy #"}
                                    value={selectedCompanionPolicyUnit?.CompanionLinkedMpciPremiumLineOID}
                                    onChange={(e) => {
                                        const unit = companionPolicyUnits.find(
                                            (el) =>
                                                el.CompanionLinkedMpciPremiumLineOID == e.target.value
                                        );
                                        if (unit) {
                                            unit.IsCompanionLinked = true;
                                            setSelectedCompanionPolicyUnit({ ...unit });
                                        }
                                    }}
                                    className="form-select me-3"
                                    aria-label="Select Unit"
                                    data-testid="ddUnit"
                                >
                                    <option hidden>Unit</option>
                                    {companionPolicyUnits.map((el) => (
                                        <option
                                            key={el.CompanionLinkedMpciPremiumLineOID}
                                            value={el.CompanionLinkedMpciPremiumLineOID}

                                        >
                                            {el.FormattedText}
                                        </option>
                                    ))}
                                </select>
                                <Form.Group as={Col}>
                                    <Button
                                        onClick={() => handleSubmit(`Update`).then(() => { })}
                                        variant="default"
                                        type="submit"
                                        data-testid="btnSubmit"
                                        disabled={(selectedCompanionPolicyUnit?.CompanionPolicyNumber == undefined || selectedCompanionPolicyUnit?.FormattedText == undefined)}
                                    >
                                        Update
                                    </Button>
                                </Form.Group>
                            </div>
                            {
                                <p style={{ color: "red" }}>
                                    {isCompanionAvailable
                                        ? ``
                                        : MESSAGE_NO_MATCHING_POLICY_FOUND}

                </p>
              }
            </Row>
          </Form>
        </Modal.Body>
        </Modal>
          <ConfirmMessageModalWindow
              show={showWarningMessage}
              windowType={WindowType}
              title={ConfirmationMessageTitle}
              messageBody={ ConfirmationMessageBody }
              onConfirm={() => handleSubmit(`Remove`).then(() => { setshowWarningMessage(false); })}
              onClose={() => {
                  setshowWarningMessage(false);
              }}
          />
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <p data-testid="anchorLink"
          onClick={() => {
              if (!companionPremiumLine.IsCompanionLinked) {
                 setShowModal(true);
              } else {
                  if (!companionPremiumLine.IsSource && canEditPremiumLineShareholders) {
                      setConfirmationMessageTitle(TITLE.Warning);
                      setConfirmationMessageBody(WARNINGS.unlink_companion_link);
                      setshowWarningMessage(true);
                      setWindowType(WINDOWTYPE.Warning);
                  }
            }
          }}
          className = {`${styles["expcol-p"]} ${styles["cursor-pointer"]} text-center ps-2`}
        >
                    {CompanionLinkText() }
        </p>
      </OverlayTrigger>
    </>
  );
};

export default CompanionLink;

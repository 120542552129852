import { ISelectList } from "../../components/forms/iSelectList";
import { IViewLandErrors } from "../../views/mpci/lands/viewmodels/IViewLandErrors";
import { IViewLand } from "../../views/mpci/lands/viewmodels/iViewLands";

export class SaveFarmLandUtility {
    static STRRegex =
        new RegExp(/^\(\d\d\d\)\s\d\d\d[NnSs]\s\d\d\d[EeWw]$|(^\(\d\d\d\)\s\d\d\d[EeWw]\s\d\d\d[NnSs]$)/);

    static isPopulated(myString: string | undefined | null): boolean {
        if (myString == undefined || myString == null || myString == '' || myString.trim() == '') {
            return false;
        }
        else {
            return true;
        }
    }

    static isSelectListPopulated(selectList: ISelectList | undefined | null): boolean {
        if (selectList == undefined || selectList == null || selectList?.value == '') {
            return false;
        }
        else {
            return true;
        }
    }

    static stringFails(myString: string | undefined | null, limit: number): boolean {
        if (myString != undefined && myString != null && myString != '' && myString.length > limit) {
            return false;
        }
        else {
            return true;
        }
    }

    //FarmLands
    static validateViewLand(viewLand: IViewLand): IViewLandErrors {
        let errors: IViewLandErrors = {};
        if (!this.isPopulated(viewLand.FarmNumber) &&
            !this.isSelectListPopulated(viewLand.Section) &&
            !this.isPopulated(viewLand.PlaceName) &&
            !this.isPopulated(viewLand.OtherId)) {
            errors.FarmNumber = 'Enter FN';
            errors.Section = 'or Sec/Twp/Rng';
            errors.PlaceName = 'or Place Name';
            errors.OtherId = 'or Other Id';
        }
        if (!this.isSelectListPopulated(viewLand.County)) {
            errors.County = 'County is a required field';
        }
        if (this.isSelectListPopulated(viewLand.Section)
            && viewLand.Section && viewLand.Section.label
            && !this.STRRegex.test(viewLand.Section.label.toString())
        ) {
            errors.Section = 'Invalid Section Township Range';
        }
        if (!this.isSelectListPopulated(viewLand.Coverage)) {
            errors.Coverage = 'Coverage is a required field';
        }
        if (!this.isSelectListPopulated(viewLand.UnitNumber)) {
            errors.UnitNumber = 'Unit Number is a required field';
        }
        if (this.isPopulated(viewLand.PlaceName) && !this.stringFails(viewLand.PlaceName, 100)) {
            errors.PlaceName = 'Maximum 100 characters';
        }
        if (this.isPopulated(viewLand.TractNumber) && !this.stringFails(viewLand.TractNumber, 7)) {
            errors.TractNumber = 'Maximum 7 characters';
        }
        if (this.isPopulated(viewLand.FarmNumber) && !this.stringFails(viewLand.FarmNumber, 7)) {
            errors.FarmNumber = 'Maximum 7 characters';
        }
        if (this.isPopulated(viewLand.OtherId) && !this.stringFails(viewLand.OtherId, 100)) {
            errors.OtherId = 'Maximum 100 characters';
        }

        return errors;
    }
}
import { AlertType } from "components/alert/alertType";
import { CustomAlert } from "components/alert/customAlert";
import { ISelectList } from "components/forms/iSelectList";
import { isEmpty } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../store";
import * as PremSaga from '../../../store/premiumLineInformation';
import { PremiumLineUpdateResults } from "../../../store/premiumLineInformation";

interface SaveNotificationProps {
    premiumLineUpdateResults?: PremiumLineUpdateResults[];
    mpciPremiumLines?: ISelectList[];
    postSaveAlert?: PostSaveAlert;
    preSaveAlerts?: string[];
    lists: number[];
    setList: Dispatch<SetStateAction<number[]>>;
    updatePremiumLines: () => Promise<void>;
    clearResults: () => Promise<void>;
    setPostSaveAlert: Dispatch<SetStateAction<PostSaveAlert | undefined>>;
}

export interface PostSaveAlert {
    message: string;
    isError: boolean;
}

export function SaveNotifications({ premiumLineUpdateResults, mpciPremiumLines, postSaveAlert, preSaveAlerts, lists, setList, updatePremiumLines, clearResults, setPostSaveAlert }: SaveNotificationProps): JSX.Element {
    const premiumLineStore = useSelector<GlobalState, PremSaga.PremiumLineInformation>((state) => state.premiumLineInformationState);

    function updateList(e: any, premiumLineOid: number) {
        if (e.target.checked) {
            if (!lists.includes(premiumLineOid))
                setList([...lists, premiumLineOid])
        }
        else {
            setList([...lists.filter(thing => thing != premiumLineOid)])
        }
    }

    return (
        <>
            {preSaveAlerts &&
                <CustomAlert
                    type={AlertType.Information}
                    variant="warning"
                    show={true}
                    id="preSaveButtonAlert"
                    dismissible={true}
                >
                    {preSaveAlerts.toString()}
                </CustomAlert>
            }
            <CustomAlert
                type={AlertType.Information}
                variant={postSaveAlert?.isError ? 'danger' : 'warning'}
                show={!isEmpty(postSaveAlert?.message)}
                id="saveButtonError"
                onClose={() => setPostSaveAlert({message: '', isError: false})}
                dismissible={true}
            >
                {postSaveAlert?.message}
            </CustomAlert>
            {
                premiumLineUpdateResults && premiumLineUpdateResults.length > 0 && (
                    <CustomAlert
                        type={AlertType.Information}
                        variant="warning"
                        show={true}
                        id="saveButtonAlert"
                    >
                        {premiumLineUpdateResults?.map((response) => (
                            <div
                                key={response.PremiumLineOid}
                                data-testid={`mpciPremiumLine-${response.PremiumLineOid}`}
                            >
                                <Row>
                                    <Col><p
                                        key={`here${response.PremiumLineOid}`}
                                    >{mpciPremiumLines?.find(premiumLine => Number(premiumLine.value) == response.PremiumLineOid)?.label}</p></Col>
                                    <Col>
                                        {(response.Results && response.Results[0] != 'Updated Successfully.') &&
                                            <Form.Check
                                                className="mt-1 float-md-end"
                                                onChange={(e) => updateList(e, response.PremiumLineOid)}
                                                data-testid={`updatelistcheckbox-${response.PremiumLineOid}`}
                                                disabled={!(response.AllowsUpdates && !premiumLineStore.PremiumLines?.find(premiumLine => Number(premiumLine.PremiumLineOid) == response.PremiumLineOid)?.HasClaim)}
                                            />}
                                    </Col>
                                </Row>
                                {response.Results?.map(error => {
                                    if (error.length > 0) {
                                        return <div key={crypto.randomUUID()} data-testid={`process-result-${response.PremiumLineOid}`}>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{error}</div>
                                    }
                                })}
                                <br></br>
                            </div>
                        ))}
                        <div
                            className={`float-md-end`}
                        >
                            <Row>
                                <Col xs='4' md='auto' className="mt-1">
                                    <Button
                                        data-testid={`clear-results`}
                                        onClick={clearResults}
                                    >Close</Button>
                                </Col>
                                <Col xs='4' md='auto' className="mt-1">
                                    <Button
                                        data-testid={`updatePremiumLines-button`}
                                        onClick={updatePremiumLines}
                                        hidden={lists.length === 0}
                                    >Update Premium Lines</Button>
                                </Col>
                            </Row>

                        </div>

                    </CustomAlert>
                )}
        </>
    )
}
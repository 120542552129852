import { Routes, Route, Navigate } from 'react-router-dom';
import { routePaths } from 'services/routing/routes';
import { OktaAuthProvider } from './components/okta/OktaAuthProvider';
import { LoginCallback } from '@okta/okta-react';
import Home from 'views/home';
import Contact from 'views/contact';
import PR from 'views/productionreporting';
import Shareholders from 'views/mpci/shareholders';
import Lands from './views/mpci/lands';
import Layout from './components/layout/Layout';
import Blank from 'views/blank';
import Logout from 'views/logout';
import './global.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { SecureRoutes } from './components/okta/SecureRoutes';

export default () => {
    return (

        <OktaAuthProvider>
            <Routes>
                <Route
                    path={routePaths.loginCallback}
                    element={<LoginCallback loadingElement={<div>Loading...</div>} />}
                />
                <Route element={<SecureRoutes />}>
                    <Route path={routePaths.blank} element={<Blank />} />
                    <Route path={routePaths.logout} element={<Logout />} />
                    <Route path={routePaths.lands} element={<Lands />} />
                    <Route path={routePaths.shareholders} element={<Shareholders />} />
                    <Route element={<Layout />}>
                        <Route path={routePaths.home} element={<Home />} />
                        <Route path={routePaths.contact} element={<Contact />} />
                        <Route path={routePaths.pr} element={<PR />} />
                        <Route path="*" element={<Navigate to={routePaths.home} />} />
                    </Route>
                </Route>
            </Routes>
        </OktaAuthProvider >

    );
};
import React, { memo } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IInputSwitchProps } from './iInputSwitchProps';

export default memo(function InputSwitch ({ name, label, onChange, checked, disabled, toolTip, innerRef, ...rest }: IInputSwitchProps) {

    function withToolTip() {
        return (
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{toolTip}</Tooltip>}>
                {inputSwitch()}
            </OverlayTrigger>
        )
    }

    function inputSwitch() {
        return (
            <Form.Check
                data-testid={name}
                type="switch"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                id={name}
                ref={innerRef}
                { ...rest }
            />
        )
    }

    return (
        <Form.Group controlId={`check-` + name}>
            {label ? <Form.Label>{label}</Form.Label> : null}
            {toolTip ? withToolTip() :
                inputSwitch()
            }
        </Form.Group>
    );
});
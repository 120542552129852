import { call, put, takeEvery } from 'redux-saga/effects';
import { ActionTypes, getCompanionLinksFail, GetCompanionLinksRequestAction, getCompanionLinksSuccess, DeleteCompanionLinksRequestAction, deleteCompanionLinksSuccess, deleteCompanionLinksFail } from './actions';
import * as Api from './api';
 
export function* getCompanionLinksRequestSaga() {
    yield takeEvery(ActionTypes.GET_COMPANIONLINKS_REQUEST, getCompanionLinksRequest);
};

export function* deleteCompanionLinksRequestSaga() {
    yield takeEvery(ActionTypes.DELETE_COMPANIONLINKS_REQUEST, deleteCompanionLinksRequest);
};

function* getCompanionLinksRequest(action: GetCompanionLinksRequestAction) {
    yield call(getCompanionLinks, action);
}

function* deleteCompanionLinksRequest(action: DeleteCompanionLinksRequestAction) {
    yield call(deleteCompanionLinks, action);
}

function* getCompanionLinks(action: GetCompanionLinksRequestAction) {
    try {
        yield Api.GetCompanionLinks(action.GrowerId, action.MpciCoverageId, action.MpciPolicyId, action.MpciPremiumLineId, action.Api).then(response => {
            if (action.CompanionLinksRequest != undefined) {
                action.CompanionLinksRequest.CompanionLinks = response;
            }
        });
        yield put(getCompanionLinksSuccess(action.CompanionLinksRequest));
    }
    catch (e: any) {
        yield put(getCompanionLinksFail(action.CompanionLinksRequest));
    }
}

function* deleteCompanionLinks(action: DeleteCompanionLinksRequestAction) {
    try {
        yield Api.DeleteCompanionLinks(action.GrowerId, action.MpciCoverageId, action.MpciPolicyId, action.MpciPremiumLineId, action.CompanionLinkId, action.Api);
        action.CompanionLinksRequest.CompanionLinks = action.CompanionLinksRequest.CompanionLinks.filter(c => c.CompanionLinkId.toString() != action.CompanionLinkId);
        yield put(deleteCompanionLinksSuccess(action.CompanionLinksRequest));
    }
    catch (e: any) {
        action.CompanionLinksRequest.ErrorStatusCode = e.status;
        yield put(deleteCompanionLinksFail(action.CompanionLinksRequest));
    }
}
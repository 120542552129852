import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routePaths } from 'services/routing/routes';
import styles from './Header.module.scss';
import { GlobalState } from 'store';
import * as AuthenticationSaga from 'store/authentication';

const Header = () => {
    const authentication = useSelector<GlobalState, AuthenticationSaga.AuthenticationState>(state => state.authenticationState);

    return (
        <header className="d-flex">
            <Navbar className={`${styles.navbar} flex-grow-1 d-flex`} role="navigation">
                <Navbar.Brand>
                    <Link to={routePaths.home}>
                        <img src="/proag_logo.png" />
                    </Link>
                </Navbar.Brand>
                
                <div className="text-white pe-3">{authentication.claims?.find(c => c.type == 'name')?.value ?? ''}</div>
            </Navbar>
            <div className={`${styles.header_back_button_div} d-flex`}>
                <button onClick={() => history.back()} type="button" className={`${styles.back_button} btn btn-primary`}>
                    Back
                </button>
            </div>
        </header>
    );
}

export default Header;